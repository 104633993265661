import { confirmationTitles, confirmationMessages, confirmationTexts } from './../../../shared/enums/confirmation';
import { ConfirmationService } from './../../../shared/services/confirmation.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { AppInsightsService } from 'wre-core-lib';
import { Subscription, throwError } from 'rxjs';
import { AppsCategory } from '../../../models/apps-category';

@Component({
  selector: 'app-apps-category-create-update',
  templateUrl: './apps-category-create-update.component.html',
  styleUrls: []
})
export class AdminAppsCategoryCreateUpdateComponent implements OnInit, OnDestroy {

  @ViewChild('newCategoryForm', { read: NgForm }) newCategoryForm: any;

  newCategory: AppsCategory;
  oldCategory: AppsCategory;
  configureStepStatus: string;
  reviewStepStatus: string;
  categoryId: number;
  title: string;
  hasData = false;
  buttonAction: string;
  subscription: Subscription;

  get loading(): boolean { return this._loading;  }
  private _loading: boolean = true;

  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private appInsightsService: AppInsightsService,
    private _confirmationService: ConfirmationService,
    private adminService: AdminService) {
    this.appInsightsService.componentName = 'AppsCategoryCreateUpdateComponent';
  }

  ngOnInit() {
    this.setTitle.emit('Gallagher Re Gateway');
    this.newCategory = new AppsCategory();
    this.newCategory.isValid = true;
    if (this.categoryId !== undefined) {
      this.title = 'Edit Workspace';
      this.buttonAction = 'Update';
      this.getCategory(this.categoryId);
    } else {
      this.buttonAction = 'Add';
      this.hasData = true;
      this.title = 'New Workspace';
      this._loading = false;
    }
  }

  private addAppsCategory(category: AppsCategory) {
    this.appInsightsService.methodName = 'addAppsCategory';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );

      this.adminService.addAppsCategory(category).subscribe(httpResponse => {
        const result = httpResponse.body as boolean;
        if (result === true) {
          this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
          this.closeModal();
          this.refreshData.emit(true);
        } else {
          this.appInsightsService.trackTrace(this.appInsightsService.msgMethodError);
          this._loading = false;
        }

      });
    } catch (error) {
      this._loading = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  private editAppsCategory(category: AppsCategory) {
    this.appInsightsService.methodName = 'editAppsCategory';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.adminService.editAppsCategory(category).subscribe(httpResponse => {
        const result = httpResponse.body as boolean;
        if (result === true) {
          this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
          this.closeModal();
          this.refreshData.emit(true);
        } else {
          this._loading = false;
          this.appInsightsService.trackTrace(this.appInsightsService.msgMethodError);
        }

      });
    } catch (error) {
      this._loading = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  onAdd(newCategoryForm: NgForm) {
    if (newCategoryForm.invalid) {
      return;
    }
    this._loading = true;
    this.newCategory = newCategoryForm.value;
    if (this.buttonAction === 'Add') {
      this.addAppsCategory(this.newCategory);
    } else {
      this.newCategory.id = this.categoryId;
      this.editAppsCategory(this.newCategory);
    }
  }

  confirmClose() {
    if ((!!this.categoryId && this.newCategory.equal(this.oldCategory)) || (!this.categoryId && !this.newCategoryForm.valid)) {
      this.closeModal();
      return;
    }

    this.subscription = this._confirmationService.openModal(confirmationTitles.defaultTitle, confirmationMessages.defaultMessage, confirmationTexts.defaultConfirmationText)
      .subscribe((close: boolean) => {
        if (close) {
          this._confirmationService.closeModal();
          this.closeModal();
        }
      });
  }

  private closeModal() {
    this.activeModal.close();
  }

  private getCategory(id: number) {
    this.appInsightsService.methodName = 'getCategory';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    try {
      this.adminService.getAppsCategory(id).subscribe(httpResponseCategory => {
        this.newCategory = Object.assign(new AppsCategory(), httpResponseCategory.body);
        this.oldCategory = Object.assign(new AppsCategory(), httpResponseCategory.body);
        this.hasData = true;
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
        this._loading = false;
      });
    } catch (error) {
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  ngOnDestroy() { this.subscription?.unsubscribe(); }

}
