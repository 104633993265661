<div class="d-flex align-items-center justify-content-center vh-100">
    <div class="screen-placeholder">
      <div class="row flex-column text-center">
            <div class="col">
                <i class="fas fa-tools mb-4"></i>
            </div>
            <div class="col">
                <h3><strong>Tool Name</strong> is down for maintenance</h3>
            </div>
            <div class="col">
                <p>The team is currently undertaking essential maintenance on this tool. Please come back leter once the work has been completed.</p>
            </div>
            <div class="col mt-4">
                <button class="btn btn-primary">Back to Gateway Dashboard</button>
            </div>
        </div>
    </div>
  </div>
