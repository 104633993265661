import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AdminFormService {

  public guidPattern: RegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  constructor(private fb: UntypedFormBuilder) { }

  /**
   * Create empty FormGroup for Security Role
   */
  createSecurityRoleFormGroup(): UntypedFormGroup {
    return this.fb.group({
      name: '',
      guid: ['', [Validators.required, Validators.pattern(this.guidPattern)]]
    });
  }

  /**
   * Custom Validators
   */

  // Validate if the control is all whitespaces
  noWhitespaceValidator(control: UntypedFormControl): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    return !isWhitespace ? null : { whitespace: true };
  }
}
