import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export class WfmFrame {
  id: number;
  name: string;
  section: string;
  url: SafeResourceUrl;

  constructor(id: number, name: string, section: string, url?: SafeResourceUrl) {
    this.id = id;
    this.name = name;
    this.section = section;
    this.url = url;
  }
}

const baseEmbedUrl = 'https://e12-re-idws040d/WorkflowManagerGateway?Gateway=Y';
const frames = [
  {name: 'Project',               section: 'project'},
  {name: 'Database',              section: 'database'},
  {name: 'Data Export/Import',    section: 'dataexportimport'},
  {name: 'Applications',          section: 'application'},
  {name: 'Reports',               section: 'reports'},
  {name: 'Administration',        section: 'administration'},
  {name: 'External Applications', section: 'external'},
  {name: 'CatTrac',               section: 'cattrac'},
  {name: 'Geocode',               section: 'geocode'},
  {name: 'WEM',                   section: 'wem'},
];

@Injectable({
  providedIn: 'root'
})
export class WorkFlowManagerService {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  getBaseUrl() {
    return baseEmbedUrl;
  }

  getFrames(): WfmFrame[] {
    return frames.map((frame, idx) => new WfmFrame(idx, frame.name, frame.section, baseEmbedUrl + frame.section));
  }

  getFrame(idx: number): WfmFrame {
    return new WfmFrame(
      idx,
      frames[idx].name,
      frames[idx].section,
      this.sanitizer.bypassSecurityTrustResourceUrl(baseEmbedUrl + frames[idx].section)
    );
  }
}
