<div class="row">
  <div class="col-md-10">
    <wre-toolkit-text-field class="w-100" labelText="Name" inputId="name" [control]="form.get('name')">
    </wre-toolkit-text-field>
  </div>
  <div class="col-md-2 pt-4 mt-2">
    <wre-toolkit-toggle-switch
      [control]="form.get('isActive')"
      inputId="isActive"
      labelText="Active">
    </wre-toolkit-toggle-switch>
  </div>
</div>

<div class="row pt-4 mt-2">
  <div class="col-12">
    <wtw-user-lookup-wrapper
      labelText="ROLE ADMINISTRATOR(S)"
      inputId="role-admin-userlookup"
      inputName="role-admin-userlookup"
      [userTypes]="adminUserTypes"
      [control]="form.get('admins')"
      [isInvalid]="false"
      noSearchResultText="No users found"
      [isDisabled]="form.get('admins').disabled"
      [readOnly]="form.get('admins').disabled"
      emptyListText="No admins have been selected.">
    </wtw-user-lookup-wrapper>
  </div>
</div>
