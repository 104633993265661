export class User {
    id: string;
    displayName: string;
    givenName: string;
    mail: string;
    officeLocation: string;
    surname: string;
    userPrincipalName: string;
    companyName: string;
  
  constructor(id?: string, displayName?: string, givenName?: string,
    mail?: string, officeLocation?: string, surname?: string,
    userPrincipalName?: string, companyName?: string) {
      this.id = id;
      this.displayName = displayName;
      this.givenName = givenName;
      this.mail = mail;
      this.officeLocation = officeLocation;
      this.surname = surname;
      this.userPrincipalName = userPrincipalName;
      this.companyName = companyName;
    }
  }
  