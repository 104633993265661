<wre-toolkit-slideout [width]="750" [isopened]="isOpen">
    <div class="slideout-header d-flex align-items-center">
        <h3 class="slideout-title">USER DETAILS</h3>
        <div class="slideout-options ms-auto">
            <button type="button" class="btn btn-icon" aria-label="Close" id="sidebarCloseButton"
                (click)="closeSidebar()" tabindex="-1"><i class="fal fa-times"></i>
            </button>
        </div>
    </div>
    <div class="slideout-body">
        <wre-user-details [userId]="userId"></wre-user-details>
    </div>
</wre-toolkit-slideout>
