import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { WreNotification, WreNotificationType } from '../../../../../models/notification';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SafrAdminService } from './../../../../../admin-portal/admin/services/safr-admin.service';
import { SafrManagementService } from './../../../../../admin-portal/admin/services/safr-management.service';
import { Field, FieldOptionsRequest } from './../../../../../admin-portal/admin/models/safr-type';
import { Subscription } from 'rxjs';
import { ConfirmationService } from './../../../../../shared/services/confirmation.service';
import { confirmationTitles, confirmationMessages, confirmationTexts } from './../../../../../shared/enums/confirmation';

@Component({
  selector: 'wre-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: []
})
export class EditModalComponent implements OnInit, OnDestroy {

  title = 'Edit Query Recipient(s)';
  form: UntypedFormGroup;
  fieldId: string;
  notification: WreNotification;
  field: Field;
  subscription: Subscription;

  get loading(): boolean { return this._loading; }
  get isDisabled() {
    return this.field !== undefined
      ? !this.isReviewersChanged(this.field.options.filter(o => o.active === true), this.form.get('reviewers').value) || this.form.invalid
      : true;
  }

  private _loading = false;

  constructor(
    public _activeModal: NgbActiveModal,
    private _fb: UntypedFormBuilder,
    private _safrAdminService: SafrAdminService,
    private _safrManagementService: SafrManagementService,
    private _confirmationService: ConfirmationService) { }

  ngOnInit() {
    this._loading = true;
    this._safrAdminService.getField(this.fieldId).subscribe(response => {
      this.field = response;
      this.field.options.sort((a, b) => (a.name > b.name) ? 1 : -1);
      this.form = this.createRecipientForm();
      this.form.patchValue({ reviewers: [...this.field.options.filter(o => o.active === true) ] });
      this._loading = false;
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  createRecipientForm() {
    return this._fb.group({ reviewers: [[], { validators: this.reviewerValidator(true) }] });
  }

  handleError(error: any): any {
    console.error(error);
    const message = 'SafrErrorMessages.genericError';
    const notification = new WreNotification(WreNotificationType.error, message);
    this.notify(notification);
  }

  private notify(notification: WreNotification) {
    this.notification = notification;
    setTimeout(() => this.notification = null, 5000);
  }

  async saveChanges() {
    this._loading = true;
    const fieldOptionsRequest = new FieldOptionsRequest(this.form.get('reviewers').value);
    await this._safrAdminService.updateField(this.fieldId, fieldOptionsRequest).subscribe(response => {
      this._safrManagementService.loadFields();
      this.closeModal();
      this._loading = false;
    }, error => {
      this.handleError(error);
      this._loading = false;
    });
  }

  private isReviewersChanged(reviewersToUpdate, newReviewers): boolean {
    return !(JSON.stringify(reviewersToUpdate) === JSON.stringify(newReviewers) && reviewersToUpdate.length === newReviewers.length);
  }

  confirmClose() {
    if (this.isDisabled) {
      this.closeModal();
      return;
    }

    this.subscription = this._confirmationService
      .openModal(confirmationTitles.defaultTitle, confirmationMessages.defaultMessage, confirmationTexts.defaultConfirmationText)
      .subscribe((close: boolean) => {
      if (close) {
        this._confirmationService.closeModal();
        this.closeModal();
      }
    });
  }

  closeModal() {
    this._activeModal.close();
  }

  private reviewerValidator(isConversationEnabled: boolean ): ValidatorFn {
    return (control: UntypedFormGroup): ValidationErrors | null => {
      const recipients = isConversationEnabled ? control.value : [];
      const notValid = (isConversationEnabled && (!recipients || recipients.length === 0));

      return notValid ? { 'noReviewersProvided': true } : null;
    };
  }
}


