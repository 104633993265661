import { Component, OnDestroy, OnInit } from '@angular/core';
import { SrtOverviewAccessFormServiceService } from '../../../services/srt-overview-access-form-service.service';
import { SrtOverviewAccessListService } from '../../../services/srt-overview-access-list.service';
import { SrtOverviewAccessApiService } from '../../../services/srt-overview-access-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReferralsFieldManagementService } from '../../../services/referrals-field-management.service';
import { DynamicFieldType } from '../../../enums/dynamic-field-type.enum';
import { FieldOrderType } from '../../../models/field-order-type';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { Observable, Subscription } from 'rxjs';
import { OverviewAccessModalBaseComponent } from '../../../../overview-access/components/overview-access-modal-base.component';
import { SrtOverviewAccess } from '../../../models/srt-overview-access';

@Component({
  selector: 'wre-srt-overview-access-modal',
  templateUrl: './srt-overview-access-modal.component.html',
  styleUrls: ['../../../../safr-admin/safr-overview-wrapper/safr-overview-modal-wrapper/safr-overview-access-modal/safr-overview-access-modal.component.sass', './srt-overview-access-modal.component.sass']
})
export class SrtOverviewAccessModalComponent extends OverviewAccessModalBaseComponent<SrtOverviewAccess> implements OnInit, OnDestroy {

  teams: string[] = [];

  get selectedTeams(): string[] {
    return this.form.get('access').get('teams').value ?? [];
  }

  get fieldServiceLoading(): Observable<boolean> {
    return this.fieldService.loading$;
  }

  loading: boolean;
  error: string;

  subscription: Subscription;

  constructor(protected formService: SrtOverviewAccessFormServiceService,
              protected dataService: SrtOverviewAccessListService,
              protected apiService: SrtOverviewAccessApiService,
              protected activeModal: NgbActiveModal,
              protected fieldService: ReferralsFieldManagementService,
              protected confirmationService: ConfirmationService) {
    super(activeModal, confirmationService, apiService, dataService, formService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.fieldService.field$.subscribe(fields => {
      const teamField = fields.find(f => f.fieldType === DynamicFieldType.ReferralDetailsRequesteeBusinessTeam);
      this.teams = teamField != null
        ? teamField.options
          .filter(o => o.active)
          .sort((a, b) => teamField.order === FieldOrderType.Alphabetical
              ? a.value.localeCompare(b.value)
              : a.order - b.order)
          .map(o => o.value)
        : [];
    });

    this.fieldService.loadFields(this.fieldService.currentReferralType);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
