import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { AppInsightsService } from 'wre-core-lib';
import { Subscription, throwError } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { NgbModalOptions, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminAppsCategoryCreateUpdateComponent } from '../apps-category-create-update/apps-category-create-update.component';
import { AdminDeleteComponent } from '../../admin/delete/delete.component';
import { AdminDataRefreshService } from '../../admin/services/admin-data-refresh.service';
import { RefreshDataType, TabTitles } from '../../admin/models/refresh-data-type';
import { filter } from 'rxjs/operators';
import { SecurityWorkspaceService } from '../../security-admin/services/security-workspace.service';
import { UserPermissions } from 'src/app/models/admin';
import { AppsCategory } from 'src/app/models/apps-category';

@Component({
  selector: 'app-apps-category',
  templateUrl: './apps-category.component.html',
  styleUrls: ['./apps-category.component.css', './apps-category.component.sass']
})
export class AdminAppsCategoryComponent implements OnInit, OnDestroy {
  offset = '0';
  errorMessage: any;
  appCategories: AppsCategory[];
  columnNames: any[] = [];
  isloaderVisible = false;
  showDeleteAlert = false;
  deleteConflict = false;
  deleteCategoryId: number;
  deleteCategoryName: string;
  deleteCategoryMessage_h5: string;
  deleteCategoryMessage_p: string;
  isConfirmModal = true;
  isDeleteConfirm = false;
  margin = 321;
  tableHeight = window.innerHeight - this.margin;
  roles: string[] = [];

  private dataRefreshSubscription: Subscription;

  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  @Output() isAppCategoryChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() setExperienceTitle: EventEmitter<any> = new EventEmitter();

  @Input() set refreshData(value: boolean) {
    if (value === true) {
      this.isAppCategoryChanged.emit(false);
      this.getAppsCategories(this.showDeleteAlert);
    }
  }

  get hasEditPermission() {
    return this.roles.includes(UserPermissions.GatewayWorkspacesEdit);
  }

  get hasDeletePermission() {
    return this.roles.includes(UserPermissions.GatewayWorkspacesDelete);
  }

  constructor (private appInsightsService: AppInsightsService,
               private adminService: AdminService,
               private adminDataRefreshService: AdminDataRefreshService,
               private modalService: NgbModal,
               public activeModal: NgbActiveModal,
               private securityWorkspaceService: SecurityWorkspaceService) {
      this.appInsightsService.componentName = 'AdminComponent';
      this.roles = this.securityWorkspaceService.currentPermissions;
  }

  ngOnInit() {
    this.setExperienceTitle.emit(TabTitles.Workspaces);
    this.setTitle.emit('Gallagher Re Gateway');
    this.getAppsCategories(this.showDeleteAlert);
    window.onresize = (e) => {
      if (this.tableHeight !== window.innerHeight - this.margin) {
        this.resizeTable();
      }
    };

    this.dataRefreshSubscription = this.adminDataRefreshService.refreshData$
      .pipe(filter(r => r === RefreshDataType.GatewayWorkspaces))
      .subscribe(() => this.getAppsCategories(this.showDeleteAlert));
  }

  ngOnDestroy() {
    this.dataRefreshSubscription.unsubscribe();
  }

  resizeTable() {
    this.tableHeight = window.innerHeight - this.margin;
  }

  getAppsCategories(showDeleteAlert: boolean) {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'getAppsCategories';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.adminService.getAppsCategories().subscribe(appCategoriesResponse => {
        this.appCategories = appCategoriesResponse.body;
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
        this.isloaderVisible = false;
        this.isAppCategoryChanged.emit(true);
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  enableDisableCategory(id: number) {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'enableDisableCategory';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.adminService.enableDisableAppsCategory(id).subscribe(httpResponse => {
        const result = httpResponse.body as boolean;
        if (result === true) {
          this.getAppsCategories(this.showDeleteAlert);
          this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
        }
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  deleteCategory(id: number, name: string) {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'deleteCategory';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.showDeleteAlert = false;
      this.deleteConflict = false;
      this.deleteCategoryName = '';
      this.adminService.deleteAppsCategory(id).subscribe(httpResponse => {
        const result = httpResponse.body;
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
        this.getAppsCategories(this.deleteConflict);
      }, error => {
        if (error.status === 409) {
          this.deleteConflict = true;
          this.deleteCategoryName = name;
          this.isloaderVisible = false;
          this.isConfirmModal = false;
          this.open();
        }
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  onEdit(id: number) {
    this.editCategory(id);
  }

  onDelete(id: number, name: string) {
    this.isConfirmModal = true;
    this.deleteCategoryId = id;
    this.deleteCategoryName = name;
    this.open();
  }

  open() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(
      AdminDeleteComponent,
      modalOptions
    );

    if (this.isConfirmModal === true) {
      this.deleteCategoryMessage_h5 = 'Are you sure you want to delete workspace ';
      this.deleteCategoryMessage_p = 'This cannot be undone.';
    } else {
      this.deleteCategoryMessage_h5 = 'Unable to delete workspace ';
      this.deleteCategoryMessage_p = 'Tools are utilising this workspace.';
    }

    modalRef.componentInstance.isConfirmModal = this.isConfirmModal;
    modalRef.componentInstance.entityName = this.deleteCategoryName;
    modalRef.componentInstance.deleteEntityMessage_h5 = this.deleteCategoryMessage_h5;
    modalRef.componentInstance.deleteEntityMessage_p = this.deleteCategoryMessage_p;

    modalRef.componentInstance.deleteConfirmedChange.subscribe(($e) => {
      this.isDeleteConfirm = $e;
      if (this.isDeleteConfirm === true) {
        this.deleteCategory(this.deleteCategoryId, this.deleteCategoryName);
      }
    });

  }

  public editCategory(id: number) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'md',
      centered: true
    };

    const modalRef = this.modalService.open(
      AdminAppsCategoryCreateUpdateComponent,
      modalOptions
    );

    modalRef.componentInstance.categoryId = id;

    modalRef.componentInstance.refreshData.subscribe(($e) => {
      this.refreshData = $e;
      if (this.refreshData === true) {
        this.getAppsCategories(this.showDeleteAlert);
      }
    });
  }

  onStatusChange(id: number) {
    this.enableDisableCategory(id);
  }
}
