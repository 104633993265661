<div (click)="disableContextMenu()" oncontextmenu="return false;" class="client-advocacy-toolbar btn-toolbar d-flex align-items-center noselect tool-title">
    <h1 class="me-auto">Client Advocacy</h1>
</div>

<div class="wre-client-advocacy-container wre-main-container" id="client-advocacy-home-container">
    <div (click)="disableContextMenu()" oncontextmenu="return false;" class="btn-group me-auto noselect advocate-list-table-container" style="width: 100%;">
        <ul class="nav-pills nav" id="client-advocacy-menu" role="tablist" style="width: 100%;">
            <li class="nav-item d-flex">
                <a class="nav-link" id="tab-introduction" routerLink="introduction" routerLinkActive="active">
                    <span>Introduction</span>
                </a>
            </li>
            <li class="nav-item d-flex">
                <a class="nav-link" id="tab-advocate-list" routerLink="client-advocates" routerLinkActive="active">
                    <span>Client Advocate List</span></a>
            </li>
        </ul>
    </div>


    <div id="client-advocate-list" (click)="disableContextMenu()" oncontextmenu="return false;" class="container-fluid px-0 noselect">
        <router-outlet></router-outlet>
    </div>
</div>
