<div class="card">
    <div class="card-header">
        <h3 class="card-title">Quick Access</h3>
    </div>
    <wre-toolkit-loader *ngIf="isLoading"></wre-toolkit-loader>
    <div *ngIf="apps && !isLoading" class="card-body pb-0">
        <div class="quickaccess-tool">
            <nav ngbNav #nav="ngbNav" [activeId]="0" class="nav-tabs">
                <ng-container [ngbNavItem]="i" *ngFor="let item of apps | async; let i = index">
                    <div class="workspace-select">
                        <a ngbNavLink class="d-flex flex-row align-items-center justify-content-center">
                            <div class="workspace-select-icon me-2">
                                <i [ngClass]="getIconClass(item.name)" class="far"></i>
                            </div>
                            <div class="workspace-select-label">{{item.name}}</div>
                        </a>
                    </div>
                    <ng-template ngbNavContent>
                        <div class="row">
                            <div class="col">
                                <label class="form-control-label">Tool</label>
                            </div>
                        </div>
                        <div class="row tab-content" *ngFor="let citem of item.apps.concat(item.links)" [ngClass]="citem.isTool ? 'app' : 'link'">
                            <div class="col">
                                <div class="d-flex">
                                    <i class="far me-2" [ngClass]="citem.isTool ? 'fa-tools tool-icon' : 'fa-link link-icon'"></i>
                                    <a [target]="getTarget(citem)" rel="noopener" [href]="citem.url">
                                        <strong [class.link]="!citem.isTool">{{citem.name}}</strong>
                                    </a>
                                    <span *ngIf="citem.isNew" class="ms-auto badge" [ngClass]="citem.isTool ? 'bg-primary' : 'bg-secondary'">
                                        NEW
                                    </span>
                                </div>
                            </div>
                            <div class="col-auto d-flex justify-content-end">
                                <a [target]="getTarget(citem)" rel="noopener" [href]="citem.url">
                                    <i class="fas fa-long-arrow-alt-right"></i>
                                </a>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav" class=""></div>
        </div>
    </div>
</div>
