import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wre-confirm-close-modal',
  templateUrl: './confirm-close-modal.component.html',
  styleUrls: ['./confirm-close-modal.component.sass']
})
export class ConfirmCloseModalComponent  {

  title = 'Are you sure you want to exit?';

  @Output() closeConfirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private activeModal: NgbActiveModal) { }

  confirmClose() {
    this.closeConfirmed.emit();
    this.activeModal.close();
  }

  cancelClose() {
    this.activeModal.close();
  }

}
