<div class="wre-table-container w-100">
    <div #tableContainer class="table-responsive" (scroll)="offset = tableContainer.scrollLeft + 'px'">
        <table class="table wre-table fixed-column table-hover p-0 m-0">
            <thead class="text-center p-0 m-0" [style.left]="'-' + offset">
                <tr class="t-header">
                    <th class="text-nowrap col-ellipsis column-active" *ngIf="isAddedorRemoved"></th>
                    <th class="text-nowrap col-ellipsis column-active"></th>
                    <th class="text-nowrap col-ellipsis column-users">Name</th>
                    <th class="text-nowrap col-ellipsis column-adgroups">Type</th>
                    <th class="text-nowrap col-ellipsis column-email">Email Address/Id</th>
                    <th class="text-nowrap col-ellipsis column-domain">Domain</th>
                </tr>
            </thead>

            <tbody class="p-0 m-0">
                <tr class="tr-padding" *ngFor="let item of list;last as isLast" [ngClass]="{'border-bottom': isLast}">
                    <td class="text-nowrap col-ellipsis column-active" *ngIf="isAddedorRemoved">
                        <i class="far" [ngClass]=" {'fa-plus': item.isAdded, 'fa-minus': item.isRemoved}"></i>
                    </td>
                    <td class="text-nowrap col-ellipsis column-active">
                      <i class="far"
                      [ngClass]="item.type === UserType.adGroup ? 'fa-users' : item.type === UserType.servicePrinciple ? 'fa-desktop' : 'fa-user'"></i>
                    </td>
                    <td class="text-nowrap col-ellipsis column-users">
                        <span *ngIf="item.displayName && item.displayName.trim() === ','" class="ps-3">&#x2015;</span>
                        <span *ngIf="item.displayName && item.displayName.trim() !== ','">{{ item.displayName }}</span>
                    </td>
                    <td class="text-nowrap col-ellipsis column-adgroups">
                      {{ item.type === UserType.adGroup ? UserType.adGroup : item.type === UserType.servicePrinciple ?
                        UserType.servicePrinciple : 'User' }}
                    </td>
                    <td class="text-nowrap col-ellipsis column-email">
                      <span *ngIf="item.type !== UserType.adGroup">{{ item.email }}</span>
                      <span *ngIf="item.type === UserType.adGroup">&#x2015;</span>
                    </td>
                    <td class="text-nowrap col-ellipsis column-domain">
                      <span *ngIf="item.type === UserType.internalUser || item.type === UserType.adGroup"
                      class="badge bg-warning me-1">INT</span>
                    <span *ngIf="item.type === UserType.externalUser" class="badge bg-secondary">EXT</span>
                    <span *ngIf="item.type === UserType.servicePrinciple">&#8212;</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
