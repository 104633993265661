import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UserTypeFlag } from 'wre-authlib';

@Component({
  selector: 'wre-role-details-step',
  templateUrl: './role-details-step.component.html',
  styleUrls: ['./role-details-step.component.sass']
})
export class RoleDetailsStepComponent {

  @Input() form: UntypedFormGroup = null;
  @Input() hasRoleAdminAddPermission = false;
  adminUserTypes = UserTypeFlag.InternalUsers;
  multiselectLabel = 'Role Administrator(s)';
  emptyListText = 'No admins have been selected.';

}
