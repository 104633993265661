import { Injectable } from '@angular/core';
import { RefreshDataType } from '../models/refresh-data-type';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminDataRefreshService {

  private _refreshDataSource = new Subject<RefreshDataType>();

  refreshData$ = this._refreshDataSource.asObservable();

  refreshData(dataType: RefreshDataType) {
    this._refreshDataSource.next(dataType);
  }
}
