export class DateRange {
  begin: Date;
  end: Date;

  constructor(fromDate: Date, toDate: Date) {
    this.begin = fromDate;
    this.end = toDate;
  }

  public equal(dateRange: DateRange): boolean {
    return this.begin === dateRange.begin && this.end === dateRange.end;
  }

  copy(): DateRange {
    return new DateRange(this.begin, this.end);
  }
}
