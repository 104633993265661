import { Component, OnInit } from '@angular/core';
import items from '../../../../../assets/json/placement-excellence/glossary.json';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css']
})
export class GlossaryComponent implements OnInit {

  tableItems = items;

  constructor () { }

  ngOnInit() {
  }

}
