<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ userId ? 'Edit' : 'Add' }} User</h4>
  <button
    class="btn btn-icon"
    type="button"
    data-bs-dismiss="modal"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="confirmCloseModal()"
    tabindex="-1">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <ng-container *ngIf="loading; else modalBlock">
    <wre-toolkit-loader></wre-toolkit-loader>
  </ng-container>

  <ng-template #modalBlock>
    <div class="py-3" *ngIf="error">
      <div class="wre-form-error">
        <i class="fas fa-exclamation pe-2"></i> {{ error }}
        <i class="far fa-times ms-auto" (click)="this.error = null"></i>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <wre-user-lookup
          class="w-100"
          inputId="user-lookup"
          labelText="User Lookup"
          [control]="form.get('user')"
          [disabled]="form.get('user').disabled"
          [isInvalid]="form.get('user').errors?.invalidUser">
        </wre-user-lookup>
      </div>

      <div class="col-md-4 pt-3 pt-md-0">
        <wre-multiselect
          #teamsContainer
          inputTabIndex="1"
          inputId="teams"
          inputName="teams"
          labelText="Teams"
          placeholder="Please select"
          [control]="form.get('access').get('teams')"
          inputId="teams"
          [isInvalid]="
            !form.get('access').get('teams').valid && !form.get('access').get('teams').pristine
          "
          [disabled]="fieldServiceLoading | async"
          [list]="teams">
        </wre-multiselect>
      </div>
    </div>

    <div class="selected-container-flex">
      <div class="teams">
        <div class="teams-container">
          <span
            *ngFor="let item of selectedTeams"
            class="badge me-2 mb-2"
            [ngClass]="{
              'bg-info': teamsContainer.existingItems.includes(item),
              'bg-success': !teamsContainer.existingItems.includes(item)
            }"
            (click)="teamsContainer.toggle(item)">
            {{ item }}<span class="ps-2"><i class="far fa-times"></i></span>
          </span>
          <span class="default-message" *ngIf="selectedTeams.length === 0">
            No teams selected.
          </span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="modal-footer">
  <button
    type="button"
    ngbAutofocus
    class="btn btn-primary"
    (click)="submit()"
    [disabled]="loading || isDisabled">
    {{ userId ? 'Update' : 'Add' }} User
  </button>
</div>
