import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,  } from '@angular/forms';
import { AccessMapping } from '../models/access-mapping';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export abstract class OverviewAccessFormService<T> {

  overviewAccessForm: UntypedFormGroup;

  protected constructor(protected fb: UntypedFormBuilder) { }

  abstract createOverviewForm(): UntypedFormGroup;

  abstract patchFormWithAccessMapping(form: UntypedFormGroup, accessMapping: AccessMapping<T>): void;

  getAccessMappingFromForm(form: UntypedFormGroup): AccessMapping<T> {
    const accessMapping = Object.assign(new AccessMapping<T>(), form.getRawValue());
    accessMapping.user = User.fromLookupUser(form.getRawValue().user);

    return accessMapping;
  }

}
