<div class="" [attr.id]="getValidId(coreStep)">
    <div class="card mb-4">
        <div class="card-header" role="toolbar" aria-label="Toolbar Actions">
            <h6 class="card-title">{{coreStep}}</h6>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-4" style="min-width:310px;">
                    <div class="row pb-3" *ngIf="generalTask !== undefined && generalTask !== ''">
                        <div class="col-4 caption fw-bold">General Task</div>
                        <div class="col-6 small-content">{{generalTask}}</div>
                    </div>
                    <div class="row pb-3" *ngIf="responsibility !== undefined && responsibility !== ''">
                        <div class="col-4 caption fw-bold">Responsibility</div>
                        <div class="col-6 small-content">{{responsibility}}</div>
                    </div>
                    <div class="row pb-3" *ngIf="cemRequirement !== undefined && cemRequirement !== ''">
                        <div class="col-4 caption fw-bold">GEM Requirement Yes/No</div>
                        <div class="col-6 small-content"><span [ngClass]="{'badge bg-success': cemRequirement === 'Yes'}">{{cemRequirement}}</span></div>
                    </div>
                    <div class="row pb-3" *ngIf="guidanceReferenceLink !== undefined && guidanceReferenceLink !== ''">
                        <div class="col-4 caption fw-bold">Guidance / Reference Link</div>
                        <div [innerHTML]="guidanceReferenceLink" class="col-6" style="min-width:270px;"></div>
                    </div>
                </div>
                <div class="col-8" style="min-width: 430px;">
                    <div class="row pb-3" *ngIf="actionDetails !== undefined && actionDetails !== ''">
                        <div class="col-2 caption fw-bold">Action Details</div>
                        <div [innerHTML]="actionDetails" class="col-10 big-content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
