import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Field, SafrOption, SafrType, SubType } from '../models/safr-type';
import { SafrAdminService } from './safr-admin.service';

@Injectable({
  providedIn: 'root'
})
export class SafrManagementService {

  private _safrType$ = new BehaviorSubject<SafrType>(null);
  private _safrOption$ = new BehaviorSubject<SafrOption>(null);
  private _fields$ = new BehaviorSubject<Field[]>(null);
  private _loading = new BehaviorSubject<boolean>(false)
  private _errorMessage = new BehaviorSubject<string>(null);

  get safrType$() { return this._safrType$.asObservable(); }
  get safrOption$() { return this._safrOption$.asObservable(); }
  get fields$() { return this._fields$.asObservable(); }
  get loading$() { return this._loading.asObservable(); }
  get errorMessage$() { return this._errorMessage.asObservable(); }

  get safrType() { return this._safrType$.getValue(); }
  get safrOption() { return this._safrOption$.getValue(); }

  constructor(private _safrAdminService: SafrAdminService) { }

  setSafrTypes(safrType: SafrType) {
    this._safrType$.next(safrType);
    this._safrOption$.next(safrType.options[0]);
  }

  setCurrentSafrOption(safrOption: SafrOption) {
    this._safrOption$.next(safrOption);
  }

  loadFields() {
    this._loading.next(true);
    this._errorMessage.next('');
    this._safrAdminService.getFields(this.safrOption.type, this.safrOption.code).subscribe(
      response => {
      this._fields$.next(response);
      this._loading.next(false);
    }, error => {
        this.handleError(error);
        this._loading.next(false);
      });
  }

  private handleError = (err) => {
    this._errorMessage.next(err?.err || 'Error has occurred while retrieving fields.');
  }
}
