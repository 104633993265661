<div class="form-group mb-0">
  <div class="row mb-3">
    <div class="col-12 col-sm-4 col-lg-auto">
      <wre-toolkit-toggle-switch name="isActive" labelText="Active" [control]="newReportForm.get('isActive')">
      </wre-toolkit-toggle-switch>
    </div>
    <div class="col-12 col-sm-8 col-lg-auto mt-3 mt-sm-0">
      <wre-toolkit-toggle-switch name="isExternal" labelText="External" [control]="newReportForm.get('isExternal')">
      </wre-toolkit-toggle-switch>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <wre-toolkit-text-field class="w-100"
                              labelText="Name"
                              inputId="name"
                              [control]="newReportForm.get('name')">
      </wre-toolkit-text-field>
      <wre-toolkit-validation-error-message
          [control]="newReportForm.get('name')"
          errorName="required"
          message="Please enter report name.">
      </wre-toolkit-validation-error-message>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 mb-3">
      <label for="type" class="form-control-label">Type</label>
      <select class="form-control w-100"
              id="type"
              [formControl]="newReportForm.get('type')"
      >
        <option [ngValue]="null" disabled selected hidden>Select Type</option>
        <option *ngFor="let type of ReportType | typeEnumToArray"
                [value]="type.key"
                [hidden]="existingReport && type.key === ReportType.PowerBI">
          {{ type.label }}
        </option>
      </select>
      <wre-toolkit-validation-error-message
          [control]="newReportForm.get('type')"
          errorName="required"
          message="Please select type.">
      </wre-toolkit-validation-error-message>
    </div>
    <div class="col-sm-6 mb-3">
      <label for="category" class="form-control-label">Category</label>
      <select class="form-control w-100"
              id="category"
              [formControl]="newReportForm.get('areaId')"
      >
        <option [ngValue]="null" disabled selected hidden>{{ areasLoading ? 'Loading Categories...' : 'Select Category' }}</option>
        <option *ngFor="let area of areas"
                [value]="area.id">{{ area.name }}
        </option>
      </select>
      <wre-toolkit-validation-error-message
          [control]="newReportForm.get('areaId')"
          errorName="required"
          message="Please select category.">
      </wre-toolkit-validation-error-message>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <wre-toolkit-text-area class="w-100"
                             labelText="Summary"
                             inputId="summary"
                             [rows]="3"
                             [control]="newReportForm.get('summary')">
      </wre-toolkit-text-area>
      <wre-toolkit-validation-error-message
          [control]="newReportForm.get('summary')"
          errorName="required"
          message="Please enter summary.">
      </wre-toolkit-validation-error-message>
    </div>
  </div>
</div>
