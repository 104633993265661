import { LogLevel } from '@azure/msal-browser';
import { Roles, UserPermissions } from 'src/app/models/admin';

export const protectedResourceMapBase = new Map([
  ['https://graph.microsoft.com/v1.0/me', ['user.read']],
]);

export const environmentBase = {
  // COMMON/DEFAULT PROPERTIES
  environment: 'Dev',
  production: false,
  loggingEnabled: true,
  logLevel: LogLevel.Verbose,
  clientId: '970c4c3b-d855-4541-bf2c-af16361e02a1',
  authority: 'https://login.microsoftonline.com/6cacd170-f897-4b19-ac58-46a23307b80a',
  customScopes: [],
  domain_hint: 'ajg0.onmicrosoft.com',
  protectedResourceMap: protectedResourceMapBase,
  applicationName: 'ajgregateway', // NOTE: per what supplied by Rylan
  intranetUri: 'https://ajg0.sharepoint.com/search/Pages/results.aspx?k=',
  hideNavbarSearchExperienceForPathNames: ['dashboard'],
  adminGroups: [Roles.gatewayAdmin, Roles.reportingAdmin],
  rolesCacheSeconds: 10,
  authenticationCacheSeconds: 10,
  enableOnePlaceAuthentication: true,
  cookie: {
    enabled: true,
    scriptHtml: '//policy.cookiereports.com/71679ccd-en-gb.html',
  },
  isInternalApp: false,

  // Only used for now
  rolesConfig: {
    searchExperience: [UserPermissions.GatewaySearchView],
    slipcaseFeed: [UserPermissions.GatewaySlipcaseView],
    submitIdea: [Roles.internalUser],
    roadmaps: [Roles.internalUser],
    timeSurvey: [Roles.internalUser],
    adminArea: [UserPermissions.GatewayAdminCogView],
  },
  redirectUri: 'https://gateway{env}.gallagherre.com/login',
  gatewayUrl: 'https://gateway{env}.gallagherre.com/',
  postLogoutRedirectUri: 'https://gateway{env}.gallagherre.com/',

  // GATEWAY
  gatewayApiUrl: 'https://gateway{env}.gallagherre.com/gatewayapi/api/',
  timesurveyApiURI: 'https://gateway{env}.gallagherre.com/timesurveyapi/api',
  searchExperienceUrl: 'https://{env}.wresearch.willistowerswatson.com', // TODO: edgar - add correct url once we have it //NOT USED????

  // REPORTING
  powerBiApiUri: 'https://reportingapi{env}.gallagherre.com/api/',
  reportingUrl: 'https://reporting{env}.gallagherre.com/',
  reports: {
    sales: { categoryId: 11 },
  },

  // SAFR
  safrApiUrl: 'https://safrapi.{env}.ajgco.com/api/',
  // AUTHORISATION
  authorisationUrl: 'https://authorizationapi{env}.gallagherre.com/api/',
  // SANCTIONS
  sanctionsUrl: 'https://sanctionsidentificationapi.{env}.ajgco.com/api/',
  // SANCTIONS REFERRAL
  srtUrl: 'https://sanctionsreferralapi.{env}.ajgco.com/api/',

  //B2C Config
  b2cClientId: '7d69aa3a-f0da-4302-96c7-13433c3d4b9c',
  b2cAuthority: 'https://ajgb2c.b2clogin.com/ajgb2c.onmicrosoft.com/B2C_1_gre_b2c_gateway_si',
  b2cResetPasswordAuthority:
    'https://ajgb2c.b2clogin.com/ajgb2c.onmicrosoft.com/B2C_1_gre_b2c_gateway_pass_reset',
  b2cKnownAuthorities: ['ajgb2c.b2clogin.com'],
  b2cCustomScopes: [],
  b2cProtectedResourceMap: protectedResourceMapBase,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ReplaceEnvironmentWildcard(obj: any, replaceWith: string) {
  Object.keys(obj).forEach((e) => {
    if (typeof obj[e] === 'string' || obj[e] instanceof String) {
      const val = obj[e] as string;
      obj[e] = val
        .replace('{env}', replaceWith)
        .replace('https://.', 'https://')
        .replace('..', '.'); // NOTE: fix because api.{env}.ajgco when gets replaced by prod ' ', it makes the url contains '..'
    }
    if (Array.isArray(obj[e]) || (typeof obj[e] === 'object' && obj[e] !== null)) {
      ReplaceEnvironmentWildcard(obj[e], replaceWith);
    }
  });
}
