import { ConfirmationService } from './../../../shared/services/confirmation.service';
import { confirmationTitles, confirmationMessages, confirmationTexts } from './../../../shared/enums/confirmation';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup } from '@angular/forms';
import { TourStepData } from 'src/app/models/admin';
import { AdminService } from 'src/app/services/admin.service';
import { AppInsightsService } from 'wre-core-lib';
import { TourStepFormService } from '../services/tour-step-form.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

interface StepView {
  viewId: boolean;
  viewValue: string;
}

@Component({
  selector: 'wre-tour-steps-create-update',
  templateUrl: './tour-steps-create-update.component.html',
  styleUrls: ['./tour-steps-create-update.component.scss']
})
export class TourStepsCreateUpdateComponent implements OnInit, OnDestroy {

  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  @Output() refreshTourStepData: EventEmitter<boolean> = new EventEmitter();

  tourStepId: number;
  newTourStep: TourStepData;
  oldTourStep: TourStepData;

  tourStepForm: UntypedFormGroup;

  isLoaderVisible = false;
  error: string;

  editMode: boolean;
  buttonAction: string;
  popupTitle: string;
  subscription: Subscription;

  // Do not remove
  // Used to display inline training information
  // Variables are assigned values from tour-steps.component.ts
  isViewContent = false;
  viewContentTitle = '';
  viewContentData = '';

  viewType: StepView[] = [{'viewId': false, 'viewValue': 'Normal View'}, {'viewId': true, 'viewValue': 'Mobile View'}];

  constructor(
    public activeModal: NgbActiveModal,
    private appInsightsService: AppInsightsService,
    private _confirmationService: ConfirmationService,
    private adminService: AdminService,
    private tourStepFormService: TourStepFormService) {
    this.appInsightsService.componentName = 'TourStepsCreateUpdateComponent';
  }

  ngOnInit() {
    this.setTitle.emit('Gallagher Re Gateway');
    this.clearError();
    this.newTourStep = new TourStepData();
    this.tourStepForm = this.tourStepFormService.createTourStepFormGroup();
    if (this.tourStepId !== undefined) {
      this.editMode = true;
      this.popupTitle = 'Update Inline Training';
      this.buttonAction = 'Update';
      this.getTourStep(this.tourStepId);
    } else {
      this.editMode = false;
      this.popupTitle = 'New Inline Training';
      this.buttonAction = 'Add';
      this.newTourStep.isMobile = null;
      this.newTourStep.isActive = true;
    }
  }

  onSubmit() {
    if (this.tourStepForm.invalid) {
      return;
    }

    this.clearError();
    this.tourStepForm.markAsUntouched();
    this.tourStepForm.markAsPristine();

    this.isLoaderVisible = true;

    this.tourStepFormService.assignFormGroupToFormStep(this.tourStepForm, this.newTourStep);

    this.appInsightsService.methodName = this.editMode ? 'editTourStep' : 'getTourStep';
    this.appInsightsService.trackTrace(
      this.appInsightsService.msgMethodStart
    );

    if (!this.editMode) {
      this.adminService.addInlineTraining(this.newTourStep).subscribe(this.handleSubmissionSuccess, this.handleSubmissionError);
    } else {
      this.newTourStep.id = this.tourStepId;
      this.adminService.editInlineTraining(this.newTourStep).subscribe(this.handleSubmissionSuccess, this.handleSubmissionError);
    }
  }

  clearError(): void {
    this.error = null;
  }

  private getTourStep(id: number) {
    this.isLoaderVisible = true;

    this.appInsightsService.methodName = 'getTourStep';
    this.appInsightsService.trackTrace(
      this.appInsightsService.msgMethodStart
    );

    this.adminService.getInlineTraining(id).subscribe(
      data => {
        this.tourStepFormService.assignTourStepToFormGroup(data.body, this.tourStepForm);
        this.oldTourStep = Object.assign(new TourStepData(), data.body)
        this.isLoaderVisible = false;
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
    }, error => {
        this.error = 'Error occurred while retrieving the inline training step';
        this.isLoaderVisible = false;
        this.appInsightsService.trackException(error);
    });
  }

  confirmClose() {
    this.newTourStep.id = this.tourStepId;
    this.tourStepFormService.assignFormGroupToFormStep(this.tourStepForm, this.newTourStep);
    if ((this.editMode && this.newTourStep.equal(this.oldTourStep)) || (!this.editMode && !this.tourStepForm.valid)) {
      this.closeModal();
      return;
    }

    this.subscription = this._confirmationService.openModal(confirmationTitles.defaultTitle, confirmationMessages.defaultMessage, confirmationTexts.defaultConfirmationText)
      .subscribe((close: boolean) => {
        if (close) {
          this._confirmationService.closeModal();
          this.closeModal();
        }
      });
  }

  private closeModal = () => {
    this.activeModal.close();
  }

  private handleSubmissionSuccess = () => {
    this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
    this.refreshTourStepData.emit(true);
    this.closeModal();
    this.isLoaderVisible = false;
  }

  private handleSubmissionError = (error: HttpErrorResponse) => {
    if (error.status === 400) {
      // Bad request
      this.error = error.error;
    } else {
      this.error = 'Error occurred while updating the inline training step';
    }
    this.isLoaderVisible = false;
    this.appInsightsService.trackException(error);
  }

  ngOnDestroy() { this.subscription?.unsubscribe(); }

}
