import { Component, Input } from '@angular/core';
import { PermissionType } from '../../../../../../models/permission-type';

@Component({
  selector: 'wre-permissions-audit-table',
  templateUrl: './permissions-audit-table.component.html',
  styleUrls: ['./permissions-audit-table.component.sass']
})
export class PermissionsAuditTableComponent {

  @Input() list = [];
  @Input() isAddedorRemoved = false;
  offset = '0';
  PermissionType = PermissionType;
  hideCol = false;
}
