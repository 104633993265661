export enum UserType {
  adGroup = 'AD Group',
  internalUser = 'Internal User',
  internalUserGroup = 'Internal User/Group',
  externalUser = 'External User',
  users = 'users',
  groups = 'groups',
  externalUsers = 'externalUsers',
  servicePrinciple = 'Service Principle'
}
