import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { AdminDeleteComponent } from '../../admin/delete/delete.component';
import { NgbModalOptions, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, throwError } from 'rxjs';
import { AppInsightsService } from 'wre-core-lib';
import { AreasCreateUpdateComponent } from '../areas-create-update/areas-create-update.component';
import { ToggleService } from '../../../services/toggle.service';
import { AreaData } from '../../../models/admin';
import { AdminDataRefreshService } from '../../admin/services/admin-data-refresh.service';
import { filter } from 'rxjs/operators';
import { RefreshDataType, TabTitles } from '../../admin/models/refresh-data-type';
import { ReportingService } from '../services/reporting.service';

@Component({
  selector: 'wre-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.scss', './areas.component.sass']
})
export class AreasComponent implements OnInit, OnDestroy {

  offset = '0';
  areasList: AreaData[];
  isloaderVisible = false;
  areasId: number;
  areasName: string;
  deleteareasMessage_h5: string;
  deleteareasMessage_p: string;
  isConfirmModal = true;
  isDeleteConfirm = false;
  showDeleteAlert = false;
  deleteConflict = false;
  showInActiveReports = false;
  margin = 321;
  tableHeight = window.innerHeight - this.margin;

  private dataRefreshSubscription: Subscription;

  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  @Output() isAppareasChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() setExperienceTitle: EventEmitter<any> = new EventEmitter();

  @Input() set refreshareasData(value: boolean) {
    if (value === true) {
      this.isAppareasChanged.emit(false);
      this.getAreas();
    }
  }

  constructor(
    public activeModal: NgbActiveModal,
    private appInsightsService: AppInsightsService,
    private reportingService: ReportingService,
    private adminDataRefreshService: AdminDataRefreshService,
    private modalService: NgbModal,
    private toggleService: ToggleService) {
    this.appInsightsService.componentName = 'areassComponent';
  }

  ngOnInit() {
    this.setExperienceTitle.emit(TabTitles.Categories);
    this.setTitle.emit('Gallagher Re Gateway');
    this.getAreas();
    this.toggleService.currentMessage.subscribe(value => this.showInActiveReports = value);

    window.onresize = () => {
      if (this.tableHeight !== window.innerHeight - this.margin) {
        this.resizeTable();
      }
    };

    this.dataRefreshSubscription = this.adminDataRefreshService.refreshData$
      .pipe(filter(r => r === RefreshDataType.ReportingCategories))
      .subscribe(() => this.getAreas());
  }

  ngOnDestroy() {
    this.dataRefreshSubscription.unsubscribe();
  }

  resizeTable() {
    this.tableHeight = window.innerHeight - this.margin;
  }

  async getAreas() {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'getAreas';
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.areasList = (await this.reportingService.getPowerBiAreas()).sort((a, b) => a.name.localeCompare(b.name));
      this.isloaderVisible = false;
      this.isAppareasChanged.emit(true);
      this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  onEdit(id: number) {
    this.areasId = id;
    this.editAreas(this.areasId);
  }
  public editAreas(id: number) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true
    };

    const modalRef = this.modalService.open(
      AreasCreateUpdateComponent,
      modalOptions
    );

    modalRef.componentInstance.areaId = id;

    modalRef.componentInstance.refreshAreasData.subscribe(($e) => {
      this.refreshareasData = $e;
      if (this.refreshareasData === true) {
        this.getAreas();
      }
    });
  }

  onDelete(id: number, name: string) {
    this.isConfirmModal = true;
    this.areasId = id;
    this.areasName = name;
    this.open(false);
  }

  open(successInfo: boolean) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(
      AdminDeleteComponent,
      modalOptions
    );

    if (successInfo === false) {
      if (this.isConfirmModal === true) {
        this.deleteareasMessage_h5 = 'Are you sure you want to delete category';
        this.deleteareasMessage_p = 'This cannot be undone.';
      } else {
        this.deleteareasMessage_h5 = 'Unable to delete category';
        this.deleteareasMessage_p = 'Reports are utilising this category.';
      }
    }

    modalRef.componentInstance.isConfirmModal = this.isConfirmModal;

    if (successInfo === false) {
      modalRef.componentInstance.entityName = this.areasName;
    } else {
      modalRef.componentInstance.entityName = '';
    }

    modalRef.componentInstance.deleteEntityMessage_h5 = this.deleteareasMessage_h5;
    modalRef.componentInstance.deleteEntityMessage_p = this.deleteareasMessage_p;

    modalRef.componentInstance.deleteConfirmedChange.subscribe(($e) => {
      this.isDeleteConfirm = $e;
      if (this.isDeleteConfirm === true) {
        this.deleteArea(this.areasId, this.areasName);
      }
    });
  }

  async deleteArea(id: number, name: string) {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'deletearea';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.showDeleteAlert = false;
      this.deleteConflict = false;
      (await this.reportingService.deletePowerBiAreas(id)).subscribe(() => {
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);

        this.deleteareasMessage_h5 = 'Category Info';
        this.deleteareasMessage_p = this.areasName + ' deleted successfully.';
        this.getAreas();
        this.isConfirmModal = false;
        this.areasName = '';
      }, error => {
        if (error.status === 409) {
          this.deleteConflict = true;
          this.areasName = name;
          this.isloaderVisible = false;
          this.isConfirmModal = false;
          this.open(false);
        }
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

}
