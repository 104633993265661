<div class="review-cont">
  <div id="accordion" [ngClass]="{'pt-2 details': isDetailsView}">
    <div class="card" id="adminaccordion">
      <div class="card-header" id="headingZero">
        <div data-bs-toggle="collapse" data-bs-target="#collapse-admins" aria-expanded="true" aria-controls="collapse-admins"
          class="accordion-toggle w-100 d-flex justify-content-between">
          <div class="accordion-title fw-bold">Admins</div>
          <div class="accordion-info d-flex">
            <div class="d-flex me-4">
              <span class="header-label">Admin :&nbsp;</span>
              <span class="header-value fw-bold">{{ admins.length }}</span>
            </div>
            <div>
              <i class="fas fa-chevron-down"></i>
            </div>
          </div>
        </div>
      </div>

      <div id="collapse-admins" class="collapse show" aria-labelledby="collapse-admins-heading"
        [attr.data-bs-parent]="isDetailsView ? '#adminaccordion' : '#accordion'">
        <div class="card-body admin-card-body">
          <ng-container *ngIf="admins.length > 0; else noAdmins">
            <div *ngFor="let admin of admins" class="badge bg-success me-3">{{ admin.displayName }}</div>
          </ng-container>
          <ng-template #noAdmins>
            <div class="no-admin-text">No admins have been selected.</div>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="card" id="usersaccordion">
      <div class="card-header" id="headingOne">
        <div data-bs-toggle="collapse" data-bs-target="#collapse-users" [attr.aria-expanded]="isDetailsView"
          aria-controls="collapse-users" class="accordion-toggle w-100 d-flex justify-content-between">
          <div class="accordion-title">
            <strong>
              Users
            </strong>
          </div>
          <div class="accordion-info d-flex">
            <div class="d-flex me-4">
              <div class="me-3">
                <i class="far fa-user me-2"></i>
                <span class="header-label">Users :&nbsp;</span>
                <span class="header-value">
                  <strong>{{ userCount }}</strong>
                </span>
              </div>
              <div class="me-3">
                <i class="far fa-users me-2"></i>
                <span class="header-label">AD Groups :&nbsp;</span>
                <span class="header-value">
                  <strong>{{ adGroupCount }}</strong>
                </span>
              </div>
              <div>
                <i class="far fa-desktop me-2"></i>
                <span class="header-label">SPs :&nbsp;</span>
                <span class="header-value">
                  <strong>{{ servicePrincipleCount }}</strong>
                </span>
              </div>
            </div>
            <div>
              <i class="fas fa-chevron-down"></i>
            </div>
          </div>
        </div>
      </div>

      <div id="collapse-users" class="collapse" aria-labelledby="collapse-users-heading"
        [attr.data-bs-parent]="isDetailsView ? '#usersaccordion' : '#accordion'" [ngClass]="{'show': isDetailsView}">
        <div class="card-body pt-0">
          <wre-users-table [control]="!!form ? form.get('users'): null" [users]="users"
            [reviewStep]="isReview || isDetailsView"></wre-users-table>
        </div>
      </div>
    </div>

    <div class="card" id="permissionaccordion">
      <div class="card-header" id="headingTwo">
        <div data-bs-toggle="collapse" data-bs-target="#collapse-permissions" [attr.aria-expanded]="isDetailsView"
          aria-controls="collapseTwo" class="accordion-toggle w-100 d-flex justify-content-between">
          <div class="accordion-title">
            <strong>
              Permissions
            </strong>
          </div>
          <div class="accordion-info d-flex">
            <div class="d-flex me-4">
              <i class="far fa-key me-2 d-flex align-items-center"></i>
              <span class="header-label">Permissions :&nbsp;</span>
              <span class="header-value">
                <strong>{{ noOfActivePermissions }}</strong>
              </span>
            </div>
            <div>
              <i class="fas fa-chevron-down"></i>
            </div>
          </div>
        </div>
      </div>
      <div id="collapse-permissions" class="collapse" aria-labelledby="collapse-permissions-heading"
        [attr.data-bs-parent]="isDetailsView ? '#permissionaccordion' : '#accordion'" [ngClass]="{'show': isDetailsView}">
        <div class="card-body p-0">
          <wre-permissions-table [selectedPermissions]="permissionList" [isDetailsView]="isDetailsView">
          </wre-permissions-table>
        </div>
      </div>
    </div>
  </div>
</div>
