<div ngbDropdown container="body" class="dropdown">
  <label *ngIf="labelText" [for]="inputId" class="form-control-label">{{labelText}}</label>
  <input
         type="text"
         class="form-control dropdown w-100 custom-input"
         [class.custom-invalid]="isInvalid && control.touched"
         data-boundary="viewport"
         [disabled]="disabled"
         [id]="inputId"
         [name]="name"
         (input)="this.searchTerm = $event.target.value"
         (keyup)="onKeyUp($event)"
         [placeholder]="placeholderValue"
         (blur)="onBlur()"
         (focus)="onFocus()"
         [ngModel]="inputValue"
         [tabindex]="inputTabIndex"
         autocomplete="off">
  <span ngbDropdownToggle style="visibility: hidden; display: block;"></span>


  <!--    The actual form field is hidden so that we can send the LookupUser object rather than just a string-->

  <input hidden [formControl]="control">

  <div id="{{inputId}}-adUsersList" ngbDropdownMenu class="dropdown-menu">
      <a class="dropdown-item" *ngFor="let user of searchedUsers" ngbDropdownItem (click)="selectUser(user)">{{ user.displayName }} - {{ user.mail }}</a>
      <a class="dropdown-item" *ngIf="searchedUsers.length === 0">No user found.</a>
  </div>
</div>
