import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
// tslint:disable-next-line:max-line-length
import { AdminAppsCategoryCreateUpdateComponent } from '../../gateway-admin/apps-category-create-update/apps-category-create-update.component';
import { AdminAppsCreateUpdateComponent } from '../../gateway-admin/apps-create-update/apps-create-update.component';
// tslint:disable-next-line:max-line-length
import { AdminSecurityGroupsCreateUpdateComponent } from '../../gateway-admin/security-groups-create-update/security-groups-create-update.component';
import { TourStepsCreateUpdateComponent } from '../../gateway-admin/tour-steps-create-update/tour-steps-create-update.component';
import { ReportsCreateUpdateComponent } from '../../reporting-admin/reports-create-update/reports-create-update.component';
import { AreasCreateUpdateComponent } from '../../reporting-admin/areas-create-update/areas-create-update.component';
import { TourStepClearHistoryComponent } from '../../gateway-admin/tour-step-clear-history/tour-step-clear-history.component';
import { ToggleService } from '../../../services/toggle.service';
import { AdminDataRefreshService } from '../services/admin-data-refresh.service';
import { debounceTime, filter } from 'rxjs/operators';
import { RefreshDataType, TabTitles } from '../models/refresh-data-type';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { SecurityWorkspaceService } from '../../security-admin/services/security-workspace.service';
import { AdminExperience, UserPermissions } from './../../../models/admin';
import { AuthorisationService } from 'wre-authlib';
import { SafrType } from '../models/safr-type';
import { SafrManagementService } from '../services/safr-management.service';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'wre-admin-card',
  templateUrl: './admin-card.component.html',
  styleUrls: ['./admin-card.component.sass']
})
export class AdminCardComponent implements OnInit, OnDestroy {

  private _title = new BehaviorSubject<string>('');
  private _safrType = new BehaviorSubject<SafrType>(null);

  currentComponentRef: any;

  get safrType() {
    this._safrType.next(this.safrManagementService.safrType);
    return this._safrType.asObservable();
  }

  get safrOption() {
    return this.safrManagementService.safrOption;
  }

  get currentExperience$() {
    return this.adminService.currentExperience$;
  }

  currentExperience: string;

  get title() {
    return this._title.asObservable();
  }
  get isClearViewedHistoryVisible() {
    return this._title.getValue().toLowerCase() === TabTitles.InlineTraining;
  }
  showInActiveReports = false;
  refreshDataSubscription: Subscription;

  get isToggleButtonVisible() {
    return this._title.getValue().toLowerCase() === TabTitles.Categories || this._title.getValue().toLowerCase() === TabTitles.Reports;
  }

  get isSearchBarVisible() {
    return this._title.getValue().toLowerCase() === TabTitles.Reports || this._title.getValue().toLowerCase() === TabTitles.OverviewAccess;
  }

  hasAddPermission: boolean = false;
  roles: string[] = [];
  searchTerm: string;
  public subject: Subject<string> = new Subject();
  searchSubscription: Subscription;
  titleSubscription: Subscription;

  constructor(
    private authService: AuthorisationService,
    private modalService: NgbModal,
    private location: Location,
    private router: Router,
    private toggleService: ToggleService,
    private adminRefreshDataService: AdminDataRefreshService,
    private securityWorkspaceService: SecurityWorkspaceService,
    private safrManagementService: SafrManagementService,
    private adminService: AdminService
    ) {
      this.titleSubscription = combineLatest([
        this.title,
        this.currentExperience$,
        this.authService.getUserApplicationRolesWithPermissions()])
          .subscribe(([title, experience, permissionList]) => {
            this.securityWorkspaceService.setRolesWithPermissions(permissionList);
            this.roles = this.securityWorkspaceService.currentPermissions;
            this.currentExperience = experience;
            this.hasAddPermission = this.canAddPermission(title);
          });
    }

  ngOnInit(): void {
    this.searchSubscription = this.subject
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.applySearch();
      });
  }

  select(safrOption) {
    this.safrManagementService.setCurrentSafrOption(safrOption);
  }

  private canAddPermission(title: string): boolean {
      switch (title.toLowerCase()) {
      case TabTitles.Workspaces:
        return this.roles.includes(UserPermissions.GatewayWorkspacesAdd);

      case TabTitles.Tools:
        return this.roles.includes(UserPermissions.GatewayToolsAdd);

      case TabTitles.InlineTraining:
        return this.roles.includes(UserPermissions.GatewayInlineTrainingAdd);

      case TabTitles.Categories:
        return this.roles.includes(UserPermissions.InteractiveReportsCategoryAdd);

      case TabTitles.Reports:
        return this.roles.includes(UserPermissions.InteractiveReportsReportsAdd);

      case TabTitles.OverviewAccess:
        return this.currentExperience === AdminExperience.SRT
          ? this.roles.includes(UserPermissions.SrtOverviewAccessAdd)
          : true; // set true to display add button in overview access for SAFR
    }

    return false;
  }

  ngOnDestroy() {
    if (this.refreshDataSubscription) { this.refreshDataSubscription.unsubscribe(); }
    if (this.searchSubscription) { this.searchSubscription.unsubscribe(); }
    if (this.titleSubscription) { this.titleSubscription.unsubscribe(); }
  }

  toggleReports() {
    this.showInActiveReports = !this.showInActiveReports;
    this.toggleService.changeValue(this.showInActiveReports);
  }

  editExperiences() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'md',
      centered: true
    };
    let modalRef;

    switch (this._title.getValue().toLowerCase()) {
      case TabTitles.Workspaces: {
        modalRef = this.modalService.open(
          AdminAppsCategoryCreateUpdateComponent,
          modalOptions
        );
        this.refreshDataSubscription = (modalRef.componentInstance as AdminAppsCategoryCreateUpdateComponent).refreshData
          .pipe(filter((r: boolean) => r === true))
          .subscribe(() => {
            this.adminRefreshDataService.refreshData(RefreshDataType.GatewayWorkspaces);
            this.refreshDataSubscription.unsubscribe();
          });
        break;
      }

      case TabTitles.Tools: {
        modalOptions.size = 'lg';
        modalRef = this.modalService.open(
          AdminAppsCreateUpdateComponent,
          modalOptions
        );
        this.refreshDataSubscription = (modalRef.componentInstance as AdminAppsCreateUpdateComponent).refreshAppData
          .pipe(filter((r: boolean) => r === true))
          .subscribe(() => {
            this.adminRefreshDataService.refreshData(RefreshDataType.GatewayTools);
            this.refreshDataSubscription.unsubscribe();
          });
        break;
      }

      case TabTitles.SecurityGroups: {
        modalOptions.size = 'lg';
        modalRef = this.modalService.open(
          AdminSecurityGroupsCreateUpdateComponent,
          modalOptions
        );
        this.refreshDataSubscription = (modalRef.componentInstance as AdminSecurityGroupsCreateUpdateComponent).refreshSecurityGroupData
          .pipe(filter((r: boolean) => r === true))
          .subscribe(() => {
            this.adminRefreshDataService.refreshData(RefreshDataType.GatewaySecurityGroups);
            this.refreshDataSubscription.unsubscribe();
          });
        break;
      }

      case TabTitles.InlineTraining: {
        modalOptions.size = 'lg';
        modalRef = this.modalService.open(
          TourStepsCreateUpdateComponent,
          modalOptions
        );
        modalRef.componentInstance.isViewContent = false;
        this.refreshDataSubscription = (modalRef.componentInstance as TourStepsCreateUpdateComponent).refreshTourStepData
          .pipe(filter((r: boolean) => r === true))
          .subscribe(() => {
            this.adminRefreshDataService.refreshData(RefreshDataType.GatewayInlineTraining);
            this.refreshDataSubscription.unsubscribe();
          });
        break;
      }

      case TabTitles.Reports: {
        modalOptions.size = 'lg';
        modalRef = this.modalService.open(
          ReportsCreateUpdateComponent,
          modalOptions
        );
        this.refreshDataSubscription = (modalRef.componentInstance as ReportsCreateUpdateComponent).refreshReportData
          .pipe(filter((r: boolean) => r === true))
          .subscribe(() => {
            this.adminRefreshDataService.refreshData(RefreshDataType.ReportingReports);
            this.refreshDataSubscription.unsubscribe();
            this.applySearch();
          });
        break;
      }

      case TabTitles.Categories: {
        modalOptions.size = 'lg';
        modalRef = this.modalService.open(
          AreasCreateUpdateComponent,
          modalOptions
        );
        this.refreshDataSubscription = (modalRef.componentInstance as AreasCreateUpdateComponent).refreshAreasData
          .pipe(filter((r: boolean) => r === true))
          .subscribe(() => {
            this.adminRefreshDataService.refreshData(RefreshDataType.ReportingCategories);
            this.refreshDataSubscription.unsubscribe();
          });
        break;
      }

      case TabTitles.OverviewAccess: {
        if (this.currentExperience === AdminExperience.SRT) {
          this.router.navigate(['admin/sanctions-referral/overview-access/new']);
        } else {
          // SAFR
          this.router.navigate(['admin/safr/overview-access', { outlets: { modal: 'new' } } ]);
        }
        break;
      }

    }
  }

  onActivate(componentReference) {
    this.currentComponentRef = componentReference;
    componentReference.setExperienceTitle.subscribe((title) => setTimeout(() => {
      this._title.next(title);
    }));
    this.toggleService.changeValue(this.showInActiveReports);
  }

  public clearInlineViewedHistory() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'sm',
      centered: true
    };

    modalOptions.size = 'lg';
    let tourStepsModalRef = this.modalService.open(
      TourStepClearHistoryComponent,
      modalOptions
    );
  }

  refreshPage() {
    this.router.navigateByUrl('admin/gateway', { skipLocationChange: true }).then(() => {
      this.router.navigate([decodeURI(this.location.path())]);
    });
  }

  private applySearch() {
    if (!!this.currentComponentRef && this.currentComponentRef.isSearchAvailable) {
      this.currentComponentRef.searchedTerm = this.searchTerm;
      this.currentComponentRef.applySearch(this.searchTerm);
    }
  }

}
