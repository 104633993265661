<div class="permission-box d-flex" [class.selected]="isSelected" [class.disabled]="isDisabled" >
    <span *ngIf="isSelected" class="check-icon">
      <div class="selected">
        <i class="far fa-check" aria-hidden="true"></i>
      </div>
    </span>
    <div class="key-icon-wrapper pe-3">
      <i class="fal fa-key" [class.disabled-key]="isDisabled"></i>
    </div>
    <div class="text-wrapper">
      <div class="permission-name">{{ permissionGroup.name }}</div>
      <div class="permission-description pt-2" [title]="permissionGroup.description">{{ permissionGroup.description }}</div>
    </div>
</div>
