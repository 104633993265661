<div class="btn-group mb-3" *ngIf="(title | async) === 'fields'">
  <div *ngIf="(currentExperience$ | async) === 'SAFR'">
    <div ngbDropdown container="body">
      <button *ngIf="safrOption" class="btn btn-secondary" ngbDropdownToggle>
        {{ safrOption.type }}<i class="fas fa-long-arrow-alt-right"></i>{{ safrOption.subType }}
      </button>
      <div ngbDropdownMenu *ngIf="safrType | async">
        <button
          ngbDropdownItem
          *ngFor="let safrOption of (safrType | async).options"
          (click)="select(safrOption)">
          {{ safrOption.type }}<i class="fas fa-long-arrow-alt-right"></i>{{ safrOption.subType }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="(currentExperience$ | async) === 'Sanctions Referrals'">
    <div ngbDropdown container="body">
      <button *ngIf="safrOption" class="btn btn-secondary" ngbDropdownToggle>Reinsurance</button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem>Reinsurance</button>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-header" tourAnchor="admin.anchor" role="toolbar" aria-label="Toolbar Action">
    <h6 class="card-title me-auto">{{ title | async }}</h6>
    <div class="card-options d-flex align-items-center">
      <div class="d-flex align-items-center">
        <span class="wre-gateway-web-admin-toggle-padding-top">
          <wre-toolkit-toggle-switch
            [hidden]="!isToggleButtonVisible"
            (change)="toggleReports()"
            toggleState="true"
            labelText="Active">
          </wre-toolkit-toggle-switch>
        </span>
        <button
          id="btnClearInlineTraining"
          [hidden]="!isClearViewedHistoryVisible"
          type="button"
          (click)="clearInlineViewedHistory()"
          class="btn btn-secondary">
          <i class="fa"></i>Clear Viewed History
        </button>

        <button
          *ngIf="hasAddPermission"
          type="button"
          (click)="editExperiences()"
          class="btn btn-primary ms-3">
          <i class="far fa-plus me-1 me-md-2"></i>Add
        </button>

        <div class="ms-3 input-group prepend" *ngIf="isSearchBarVisible">
          <input
            class="form-control"
            id="searchText"
            maxlength="50"
            name="searchText"
            (input)="this.searchTerm = $event.target.value"
            (keyup)="this.subject.next()"
            type="text"
            placeholder="Search..." />
          <span class="input-group-text" id="basic-addon2"><i class="far fa-search"></i></span>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div id="dvAdmin" class="">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>
</div>
