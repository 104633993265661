import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { RoleLookupComponent } from './components/role-lookup/role-lookup.component';
import { SecurityRoleFormComponent } from './components/security-role-form/security-role-form.component';



@NgModule({
  declarations: [
    RoleLookupComponent,
    SecurityRoleFormComponent
  ],
  exports: [
    RoleLookupComponent,
    SecurityRoleFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class AdminSharedModule { }
