import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private _currentExperience$ = new BehaviorSubject<string>('');
  get currentExperience$() { return this._currentExperience$.asObservable(); }

  setCurrentExperience(experience: string) {
    this._currentExperience$.next(experience);
  }
}
