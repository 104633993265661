import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafrOverviewAccessListService } from '../services/safr-overview-access-list.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { SafrOverviewAccessApiService } from '../services/safr-overview-access-api.service';
import { OverviewAccessTableComponent } from '../../../overview-access/components/overview-access-table.component';
import { SafrOverviewAccess } from '../models/safr-overview-access';
import { AccessMapping } from '../../../overview-access/models/access-mapping';

@Component({
  selector: 'wre-safr-overview-access-table',
  templateUrl: './safr-overview-access-table.component.html',
  styleUrls: ['./safr-overview-access-table.component.sass']
})
export class SafrOverviewAccessTableComponent extends OverviewAccessTableComponent<SafrOverviewAccess> implements OnInit, OnDestroy {

  constructor(public listService: SafrOverviewAccessListService,
              public apiService: SafrOverviewAccessApiService,
              public confirmationService: ConfirmationService) {
    super(listService, apiService, confirmationService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getTeams(item: AccessMapping<SafrOverviewAccess>): string[] {
    return item.access.teams.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  getReportingGroups(item: AccessMapping<SafrOverviewAccess>): string[] {
    return item.access.reportingGroups.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

}
