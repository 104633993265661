import { SafrOverviewAccessListService } from './services/safr-overview-access-list.service';
import { Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { TabTitles } from '../../admin/models/refresh-data-type';
import { AccessMapping } from '../../overview-access/models/access-mapping';
import { SafrOverviewAccess } from './models/safr-overview-access';

@Component({
  selector: 'wre-safr-overview-wrapper',
  templateUrl: './safr-overview-wrapper.component.html',
  styleUrls: ['./safr-overview-wrapper.component.sass']
})
export class SafrOverviewWrapperComponent implements OnInit, OnDestroy {

  @ViewChildren(RouterOutlet)
  private outlets!: QueryList<RouterOutlet>;

  accessMappings: AccessMapping<SafrOverviewAccess>[] = [];
  total = 0;
  loading = true;
  error: Error;

  get total$(): Observable<number> {
    return of (this.total);
  }

  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  @Output() setExperienceTitle: EventEmitter<any> = new EventEmitter();

  isSearchAvailable = true;
  termSearched: string;
  searchedTerm: string;

  private dataSubscription: Subscription;
  private routerSubscription: Subscription;

  constructor(public safrOverviewListService: SafrOverviewAccessListService,
              private _router: Router) {

    this.routerSubscription = this._router.events.subscribe(e => {
      if (e instanceof ActivationStart) {
        let activatedOutlet;
        if (e.snapshot.outlet === 'modal') {
          activatedOutlet = this.outlets.find(o => o.isActivated);
          if (activatedOutlet) { activatedOutlet.deactivate(); }
        }
      }
    });
  }

  ngOnInit(): void {
    this.setExperienceTitle.emit(TabTitles.OverviewAccess);
    this.setTitle.emit('SAFR Admin');

    this.dataSubscription = this.safrOverviewListService.searchResult$.subscribe(searchResult => {
      this.accessMappings = searchResult.overviewAccess;
      this.total = searchResult.total;
      this.loading = searchResult.loading;
      this.error = searchResult.error;
    });

    this.applySearch('');
  }

  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  applySearch(searchTerm: string) {
    this.safrOverviewListService.searchTerm = searchTerm;
  }

}
