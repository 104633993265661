export class LookupUser {
    name: string;
    email: string;
    id?: string;
    active: boolean;

    constructor(name: string, email: string, id?: string, active: boolean = true) {
        this.name = name;
        this.email = email;
        this.active = active;
        if (id) {
            this.id = id;
        }
    }
}
