import { UserInfo } from './../models/user-Info';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RoleSummaryView } from '../models/role-summary-view';
import { environment } from 'src/environments/environment';
import { SecurityWorkspace } from '../models/security-workspace';
import { retryWhen } from 'rxjs/operators';
import { PermissionGroup } from '../models/permission-group';
import { RoleDetailsView } from '../models/role-details-view';
import { ExternalUser } from '../models/external-user';
import { UserGroup } from '../models/user-group';
import { User } from '../models/users';
import { RoleAudit } from '../models/role-audit';
import { HttpService } from '../../../services/http.service';

/* Service to make calls to Authorisation Service */

@Injectable({
  providedIn: 'root'
})
export class SecurityManagementService extends HttpService {
  private authorisationUrl = `${ environment.authorisationUrl }`;
  private gatewayUrl = `${ environment.gatewayApiUrl }`;

  constructor(http: HttpClient) {
    super(http);
  }

  async getApplications(): Promise<HttpResponse<SecurityWorkspace[]>> {
    const url = `${ this.gatewayUrl }admin/security/applications/wre-gateway/details`;

    return this.http.get<SecurityWorkspace[]>(url, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async getRoleAudit(id: number, workspace: SecurityWorkspace): Promise<HttpResponse<RoleAudit>> {
    const url = `${ this.gatewayUrl }admin/security/applications/${ workspace.applicationName }/roles/${ id }/audit`;

    return this.http.get<RoleAudit>(url, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async getRoles(workspace: SecurityWorkspace): Promise<HttpResponse<RoleSummaryView[]>> {
    const url = `${ this.gatewayUrl }admin/security/applications/${ workspace.applicationName }/roles`;

    return this.http.get<RoleSummaryView[]>(url, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async getUsers(): Promise<HttpResponse<UserGroup[]>> {
    const url = `${ this.gatewayUrl }admin/security/users`;

    return this.http.get<UserGroup[]>(url, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async getUserById(id: string): Promise<HttpResponse<User[]>> {
    const url = `${ this.gatewayUrl }admin/security/users/${ id }`;

    return this.http.get<User[]>(url, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async updateUserAsync(id: string, user: UserInfo): Promise<any> {
    const url = `${this.authorisationUrl}users/${id}`;

    return this.http.put(url, user, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async getPermissionGroups(workspace: SecurityWorkspace): Promise<HttpResponse<PermissionGroup[]>> {
    const url = `${ this.gatewayUrl }admin/security/${ workspace.applicationName }/permission-groups`;

    return this.http.get<PermissionGroup[]>(url, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async getRole(workspace: SecurityWorkspace, roleId: number): Promise<HttpResponse<RoleDetailsView>> {
    const url = `${ this.gatewayUrl }admin/security/applications/${ workspace.applicationName }/roles/${ roleId }`;

    return this.http.get<RoleDetailsView>(url, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async deleteRole(workspace: SecurityWorkspace, roleId: number): Promise<HttpResponse<any>> {
    const url = `${ this.gatewayUrl }admin/security/${ workspace.applicationName }/roles/${ roleId }`;

    return this.http.delete(url, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async createRole(role: RoleDetailsView): Promise<HttpResponse<RoleDetailsView>> {
    const url = `${ this.gatewayUrl }admin/security/roles`;
    return this.http.post<RoleDetailsView>(url, role, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async editRole(role: RoleDetailsView): Promise<HttpResponse<RoleDetailsView>> {
    const url = `${ this.gatewayUrl }admin/security/${ role.applicationName }/roles/${ role.id }`;
    return this.http.put<RoleDetailsView>(url, role, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async updateRoleStatus(workspace: SecurityWorkspace, roleId: number, active: boolean): Promise<HttpResponse<any>> {
    const url = `${ this.gatewayUrl }admin/security/${ workspace.applicationName }/roles/${roleId}/toggle`;

    return this.http.put<RoleDetailsView>(url, null, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async createUserAsync(workspace: SecurityWorkspace, user: UserInfo): Promise<HttpResponse<UserGroup>> {
    const url = `${ this.authorisationUrl }users/${ workspace.applicationName }`;
    return this.http.post<UserGroup>(url, user, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  async checkRoleExists(role: RoleDetailsView): Promise<HttpResponse<Boolean>> {
    const url = `${ this.gatewayUrl }admin/security/${ role.applicationName }/role-exists?roleName=${ role.name }`;
    return this.http.get<Boolean>(url, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }
}
