<div class="twitter-container">
  <div class="twitter-resize">
    <!-- <ngx-twitter-timeline #twitterHost watchDomTree (dom-changed)="handleDomChange($event)" [data]="{ sourceType: 'url', url: 'https://twitter.com/Willis_Re' }" [opts]="{ tweetLimit: 5, height: 100, chrome: ['noheader'] }">
    </ngx-twitter-timeline> -->
    <!-- <ngx-twitter-timeline #twitterHost watchDomTree (dom-changed)="handleDomChange($event)" [data]="{ sourceType: 'url', url: 'https://twitter.com/GallagherRe_' }" [opts]="{ tweetLimit: 5, chrome: ['noheader'] }">
    </ngx-twitter-timeline> -->

  </div>
</div>

<wre-toolkit-loader *ngIf="isLoading"></wre-toolkit-loader>

<div *ngIf="noTwitterFeed && !isLoading" class="pt-4">
    <wre-not-available serviceName="Twitter" [errorOccured]="noTwitterFeed"></wre-not-available>
</div>
