/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

declare var DebugOn: Function;
declare var DebugOff: Function;

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

if (environment.environment === 'Prod' || environment.environment === 'Uat') {
  DebugOff();
} else {
  DebugOn();
}
console.log(`Environment: ${ environment.environment }`);

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));




