<wre-toolkit-modal-popup [title]="title">
  <div>
    Do you want to exit, all changes will be lost?
  </div>

  <div footer>
    <div class="row">
      <div class="col d-flex justify-content-end">
        <button type="button" class="btn wre-btn btn-secondary" (click)="cancelClose()">
          No
        </button>

        <button type="button" class="btn wre-btn btn-error ms-3" (click)="confirmClose()">
          Yes
        </button>
      </div>
    </div>
  </div>
</wre-toolkit-modal-popup>
