<div class="row justify-content-center" [hidden]="!isloaderVisible">
    <div class="col-12">
        <div class="card">
            <div class="d-flex align-items-center justify-content-center" style="height:110px;">
                <div class="loading"></div>
            </div>
        </div>
    </div>
</div>
<div class="row justify-content-center" *ngIf="!isloaderVisible">
    <div class="col-12">
        <div *ngIf="errorOccured === false" class="card">
            <div *ngFor="let item of roadmapsData">
                <div class="card-body" style="padding: 2px;">
                    <div *ngIf="item.roadmapUrl !== '' " class="wre-gateway-web-roadmaps-padding">
                        <div class="justify-content-center wrap-element" *ngIf="item.roadmapUrl !== '' ">
                            <iframe [src]="item.roadmapUrl | sanitizeUrl" scrolling="yes" frameborder='0' target="_parent"></iframe >
                        </div>
                      </div>
                      <div  *ngIf="item.roadmapUrl === ''">
                        <p style="font-size: 1.1rem;">Coming Soon</p>
                      </div>
                </div>
              </div>
          </div>
          <div *ngIf="errorOccured === true" class="card pt-3 pb-3 ps-3 pe-3">
            <wre-not-available serviceName="Roadmaps" [errorOccured]="errorOccured"></wre-not-available>
          </div>
      </div>
</div>
