<div class="row justify-content-center" [hidden]="!this.isloaderVisible">
    <div class="col-lg-8 col-md-12">
      <div style="height:70px;" class="d-flex align-items-center justify-content-center">
        <div class="loading"></div>
      </div>
    </div>
  </div>
  <div [hidden]="this.isloaderVisible">
    <div #tableContainer class="table-responsive">
      <table class="table wre-table fixed-column mb-0" >
        <thead>
          <tr>
            <th class="fixed-column action">&nbsp;</th>
            <th class="active text-center">Active</th>
            <th class="app-name">App Name</th>
            <th class="type">View Type</th>
            <th class="route-name">Route Name</th>
            <th class="order text-center">Order No.</th>
            <th class="anchor-id">Anchor Id</th>
            <th class="title">Title</th>
            <th class="content">Content</th>
          </tr>
        </thead>
        <tbody class="mb-0 pb-0">
          <tr *ngFor="let item of TourStepsList">
            <td class="fixed-column action">

              <div ngbDropdown container="body">
                <button class="btn btn-secondary" ngbDropdownToggle>Action</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem (click)="onEdit(item.id,item.title)" *ngIf="hasEditPermission">Edit</button>
                  <button ngbDropdownItem (click)="onDelete(item.id,item.title)" *ngIf="hasDeletePermission" class="delete">Delete</button>
                </div>
              </div>

              <!-- <div class="dropdown">
                  <button
                  style="z-index:0;"
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>Action</span> <span class="caret"></span>
                </button>
                <div class="dropdown-menu" >
                  <a class="dropdown-item" href="javascript:;" (click)="onEdit(item.id,item.title)" *ngIf="hasEditPermission">Edit</a>
                  <a class="dropdown-item" href="javascript:;" (click)="onDelete(item.id,item.title)" *ngIf="hasDeletePermission">Delete</a>
                </div>
              </div> -->
            </td>
            <td class="active text-center">
              <i class="fa fa-check wre-gateway-web-tour-steps-color-icon-fa-check" aria-hidden="true" *ngIf="item.isActive"></i>
              <i class="fa fa-times wre-gateway-web-tour-steps-color-icon-fa-times" aria-hidden="true" *ngIf="!item.isActive"></i>
            </td>
            <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="app-name">{{ item.appName }}</td>
            <td class="type">
              <span *ngIf="item.isMobile">Mobile View</span>
              <span *ngIf="!item.isMobile">Normal View</span>
            </td>
            <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="route-name">{{ item.routeName }}</td>
            <td class="order text-center">{{ item.stepId }}</td>
            <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="anchor-id">{{ item.anchorId }}</td>
            <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="title">{{ item.title }}</td>
            <td class="content"><a href="javascript:;" title="{{item.content}}" (click)="onViewCentent(item.title,item.content)">View Content</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
