import { AdminExperience, UserPermissions } from '../models/admin';

export class ExperienceTab {
  label: string;
  link: string;
  id: string;
  permission: string;
}

export const TABS = new Map<AdminExperience, ExperienceTab[]>([
  [AdminExperience.Gateway, [
    { label: 'Workspaces', link: 'gateway/workspaces', id: 'tab-workspaces', permission: UserPermissions.GatewayWorkspacesAdd},
    { label: 'Tools', link: 'gateway/tools', id: 'tab-tools', permission: UserPermissions.GatewayToolsRead },
    { label: 'Inline Training', link: 'gateway/inlinetraining', id: 'tab-inlinetraining', permission: UserPermissions.GatewayInlineTrainingRead }
  ]],
  [AdminExperience.Reporting, [
    { label: 'Categories', link: 'reporting/categories', id: 'tab-categories', permission: UserPermissions.InteractiveReportsCategoriesSettingsView },
    { label: 'Reports', link: 'reporting/reports', id: 'tab-reports', permission: UserPermissions.InteractiveReportsReportsSettingsView },
  ]],
  [AdminExperience.Safr, [
    { label: 'Field Management', link: 'safr/field-management', id: 'tab-field-management', permission: UserPermissions.SafrFieldManagementSettingsView },
    { label: 'Overview Access', link: 'safr/overview-access', id: 'tab-overview-access', permission: UserPermissions.SafrFieldManagementSettingsView }
  ]],
  [AdminExperience.Security, [
    { label: 'Users', link: 'security/users', id: 'tab-users', permission: UserPermissions.GatewaySecurityUsersTabView },
    { label: 'Access', link: 'security/access', id: 'tab-access', permission: UserPermissions.GatewayExperienceSecurityView }
  ]],
  [AdminExperience.Sanctions, [
    { label: 'Database', link: 'sanctions/database', id: 'tab-sanctions-db', permission: UserPermissions.SanctionsDatabaseEdit }
  ]],
  [AdminExperience.SRT, [
    { label: 'Field Management', link: 'sanctions-referral/field-management', id: 'tab-field-management',
      permission: UserPermissions.SrtFieldManagementSettingsView },
    { label: 'Overview Access', link: 'sanctions-referral/overview-access', id: 'tab-srt-overview-access',
      permission: UserPermissions.SrtOverviewAccessRead }
  ]]
]);
