import { Component, Inject, OnInit } from '@angular/core';
import { SecurityWorkspaceService } from '../../services/security-workspace.service';
import { AuthorisationService } from 'wre-authlib';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'wre-security-admin-wrapper',
  templateUrl: './security-admin-wrapper.component.html',
})
export class SecurityAdminWrapperComponent implements OnInit {

  constructor(
    private securityWorkspaceService: SecurityWorkspaceService,
    private authService: AuthorisationService
  ) {}

  async ngOnInit() {
    const userRoles = await firstValueFrom(this.authService.getUserApplicationRolesWithPermissions());
    this.securityWorkspaceService.findAuthorizedWorkspaces(userRoles);
  }

}
