import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EditFieldModalComponent } from './edit-field-modal/edit-field-modal.component';

@Component({
  selector: 'wre-edit-field-modal-wrapper',
  template: ``,
  styleUrls: []
})
export class EditFieldModalWrapperComponent implements OnInit {

  fieldId: string;

  constructor(private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.fieldId = params.get('id');
    });

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true,
      scrollable: true
    };

    const modalRef = this.modalService.open(
      EditFieldModalComponent,
      modalOptions
    );

    if (this.fieldId) {
      modalRef.componentInstance.fieldId = this.fieldId;
    }

    const redirectUrl = 'admin/sanctions-referral/field-management';

    modalRef.result.then(
      (resolved) => this.router.navigate([redirectUrl]),
      (rejected) => this.router.navigate([redirectUrl])
    );
  }

}
