import { LookupUser } from '../../../models/lookup-user';

export class User {

  id?: string;
  displayName?: string;
  email?: string;

  constructor(id?: string, displayName?: string, email?: string) {
    this.id = id;
    this.displayName = displayName;
    this.email = email;
  }

  static fromLookupUser(lookupUser: LookupUser): User {
    const user = new User();
    user.id = lookupUser.id;
    user.email = lookupUser.email;
    user.displayName = lookupUser.name.replace('(AJ Gallagher)', '').trim();

    return user;
  }

  clone(): User {
    const user = new User();
    user.id = this.id;
    user.displayName = this.displayName;
    user.email = this.email;
    return user;
  }

  equal(user: User) {
    return user.id === this.id &&
      user.displayName === this.displayName &&
      user.email === this.email;
  }
}
