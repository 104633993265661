import { Injectable } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
import { delay, mergeMap, retryWhen, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AdvocateListResponse } from '../models/advocate-list-response';


@Injectable({
    providedIn: 'root'
})
export class ClientAdvocacyService {

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    advocateListUrl = environment.gatewayApiUrl + 'ClientAdvocacy/advocate-list';

    constructor(private http: HttpClient) { }

    getAdvocateList(): Observable<HttpResponse<AdvocateListResponse>> {
        return this.http.get<AdvocateListResponse>(this.advocateListUrl, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    handleRetry(errors: Observable<HttpResponse<any>>) {
        return errors
            .pipe(mergeMap((error: HttpResponse<any>) => {
                if (error.status !== undefined && error.status.toString().indexOf('4') === 0) {
                    // All 4xx status codes we do not retry
                    return throwError(error);
                } else {
                    return of(error).pipe(delay(1000)).pipe(take(2));
                }
            }));
    }
}
