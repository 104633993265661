<div class="form-group mb-0">
  <div class="row mb-3">
    <div class="col">
      <wre-toolkit-text-field class="w-100"
                              labelText="Link"
                              inputId="link"
                              placeholder="https://"
                              [control]="newReportForm.get('link')">
      </wre-toolkit-text-field>
      <wre-toolkit-validation-error-message
          [control]="newReportForm.get('link')"
          errorName="pattern"
          message="Please enter valid URL.">
      </wre-toolkit-validation-error-message>
      <wre-toolkit-validation-error-message
          [control]="newReportForm.get('link')"
          errorName="required"
          message="Please provide an URL.">
      </wre-toolkit-validation-error-message>
    </div>
  </div>

  <div role="alert" class="alert alert-info">
    Including "&lt;useremail&gt;" in the URL will pass through the active user's UPN email address
    to the target report when they select the report link
  </div>
</div>
