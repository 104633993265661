import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap'
import { AppInsightsService } from 'wre-core-lib'
import { TourStepApps, TourStepRoutes } from 'src/app/models/admin'
import { AdminService } from 'src/app/services/admin.service'
import { throwError } from 'rxjs'
import { NgForm } from '@angular/forms'
import { AdminDeleteComponent } from '../../admin/delete/delete.component'

@Component({
  selector: 'wre-tour-step-clear-history',
  templateUrl: './tour-step-clear-history.component.html',
  styleUrls: [
    './tour-step-clear-history.component.scss'
  ]
})
export class TourStepClearHistoryComponent implements OnInit {
  newTourStepApps: TourStepApps;
  newTourStepRoutes: TourStepRoutes;
  isloaderVisible = false
  noContentMessage: string = 'There is no data available.';
  isNoContentMessageVisible = false;
  
  constructor (
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private appInsightsService: AppInsightsService,
    private adminService: AdminService
  ) {
    this.appInsightsService.componentName = 'TourStepClearHistoryComponent'
  }

  ngOnInit () {
    this.newTourStepApps = new TourStepApps();
    this.newTourStepRoutes = new TourStepRoutes();        
    this.newTourStepRoutes.routeName = null;
    this.getInlineTrainingApps()
  }

  getInlineTrainingApps () {
    this.isNoContentMessageVisible = false;
    this.isloaderVisible = true
    this.appInsightsService.methodName = 'getInlineTrainingApps'
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    )
    try {
      this.adminService.getInlineTrainingApps().subscribe(httpResponseData => {
        this.newTourStepApps = httpResponseData.body;
        this.newTourStepApps.appName = null;
        this.isloaderVisible = false;  
        if(httpResponseData.body.length <= 0)              
        {
          this.isNoContentMessageVisible = true;
        }
        this.appInsightsService.trackTrace(
          this.appInsightsService.msgMethodsuccess
        )
      },error => {        
        this.isloaderVisible = false;
        
        
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error)
      throwError(error)
    }
  }

  getInlineTrainingAppRoutes () {
    if (this.newTourStepApps.appName === null) {      
      this.newTourStepRoutes = new TourStepRoutes();
      this.newTourStepRoutes.routeName = null;
      return;
    }
    this.isloaderVisible = true
    this.appInsightsService.methodName = 'getInlineTrainingAppRoutes'
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName  
    )
    try {
      this.adminService
        .getInlineTrainingAppRoutes(this.newTourStepApps.appName)
        .subscribe(httpResponseData => {
          this.newTourStepRoutes = httpResponseData.body;
          this.newTourStepRoutes.routeName = null;
          this.isloaderVisible = false;
          this.appInsightsService.trackTrace(
            this.appInsightsService.msgMethodsuccess
          )
        })
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error)
      throwError(error)
    }
  }

  onClearHistory (newInlineTrainingClearHistoryForm: NgForm) {
    if (newInlineTrainingClearHistoryForm.invalid) {
      return;
    }

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true
    }

    const modalRef = this.modalService.open(AdminDeleteComponent, modalOptions)

    modalRef.componentInstance.isConfirmModal = true
    modalRef.componentInstance.entityName =
      this.newTourStepApps.appName + ':' + this.newTourStepRoutes.routeName
    modalRef.componentInstance.deleteEntityMessage_h5 = `Are you sure you want to clear history for`
    modalRef.componentInstance.deleteEntityMessage_p = 'This cannot be undone.'

    modalRef.componentInstance.deleteConfirmedChange.subscribe($e => {      
      this.clearViewedHistory(
        this.newTourStepApps.appName,
        this.newTourStepRoutes.routeName
      )
      
    })
  }
  clearViewedHistory(app: string, route: string) {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'clearViewedHistory';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );      
      this.adminService.deleteInlineTrainingViewedHistory(app,route).subscribe(httpResponse => {
        const result = httpResponse.body;
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);        
        this.closeModal();
        
      }, error => {
       console.log(error);
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  closeModal () {
    this.activeModal.close()
  }
}
