<div class="tool-title d-flex align-items-center">
    <h1 class="">Roadmaps</h1>
</div>
<div class="wre-roadmap-container wre-main-container">
    <div *ngIf="roadMapsData" class="btn-group me-auto noselect advocate-list-table-container" style="width: 100%;">

        <ul class="nav-pills nav" id="roadmap-menu1" role="tablist" style="width: 100%;">
            <li class="nav-item d-flex" *ngFor="let item of roadMapsData">
                <a class="nav-link" id="tab-{{item.name}}" routerLink="{{item.routerLink | lowercase}}" routerLinkActive="active">
                    <span>{{ item.name }} </span>
                </a>
            </li>
        </ul>
    </div>

    <div id="roadmap-list" class="container-fluid noselect px-0 mt-3">
        <router-outlet></router-outlet>
    </div>
</div>
