import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SlipcaseNewsService } from 'src/app/services/slipcase-news-service';
import { SlipcaseNewsItem, Topic } from 'src/app/models/slipcase-news-item';
import { AppInsightsService } from 'wre-core-lib';

@Component({
  selector: 'app-news-aggregator',
  templateUrl: './news-aggregator.component.html',
  styleUrls: ['./news-aggregator.component.sass']
})
export class NewsAggregatorComponent implements OnInit {

  newsData: SlipcaseNewsItem[] = [];
  topics: Topic[];
  categories = [];
  isLoading = true;
  currentOffset = 0;
  selectedTopics = [];
  filteredTopics = [];
  filterTopicString: string;
  includePaid = true;

  errorOccured: boolean;


  @Output() setTitle: EventEmitter<any> = new EventEmitter();

  constructor(private appInsightsService: AppInsightsService, private slipcaseNewsService: SlipcaseNewsService) {
    this.appInsightsService.componentName = 'NewsAggregatorComponent';
  }

  ngOnInit() {
    this.setTitle.emit('News Workspace');
    this.topics = this.slipcaseNewsService.getSlipcaseTopics();
    this.getCategories();

    // By default paid articles included
    this.getSlipcaseNewsItems(undefined, this.includePaid, undefined, this.currentOffset);
  }

  getCategories() {
    // Get categories from topics
    const mapped = this.topics.map(x => x.category);
    const res = mapped.filter((value, index, self) => self.indexOf(value) === index);

    // Set topics within category
    res.forEach(category => {
      this.categories.push(
        { 'name': category, topics: this.topics.filter((topic, index, self) => category === topic.category) });
    });
  }

  onCheckboxChange(topic: Topic, isChecked: boolean) {
    if (isChecked) {
      this.selectedTopics.push(topic);
    } else {
      this.removeTopicFromSelected(topic);
    }
  }

  clearFilter() {
    this.selectedTopics.length = 0;
    const checkboxes = document.getElementById('filter-checkboxes').getElementsByTagName('input');
    if (checkboxes !== undefined && checkboxes.length > 0) {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          checkboxes[i].checked = false;
        }
      }
    }
    this.includePaid = true;
  }

  removeTopicFromFilter(topic: Topic) {
    const checkbox = <HTMLInputElement> document.getElementById('filter_' + topic.id);
    checkbox.checked = false;
    this.removeTopicFromSelected(topic);
    this.applyFilter(false);
  }

  applyFilter(toggleCollapse: boolean = true) {
    if (toggleCollapse) {
      document.getElementById('filter-collapse-container').click();
    }
    this.filteredTopics.length = 0;
    this.selectedTopics.forEach((i) => this.filteredTopics.push(i));
    this.filterTopicString = this.filteredTopics.map(x => x.id).toString();

    this.newsData.length = 0;
    this.currentOffset = 0;

    this.getSlipcaseNewsItems(
      this.filterTopicString,
      this.includePaid,
      undefined,
      this.currentOffset);
  }

  fetchMoreNews() {
    // do not fetch more news if the initial fetch is being executed or more news is already being fetched but still awaiting the results
    if (this.isLoading) { return; }
    this.getSlipcaseNewsItems(this.filterTopicString, this.includePaid, undefined, this.currentOffset);
  }


  private removeTopicFromSelected(topic: Topic) {
    const index = this.selectedTopics.indexOf(topic);
    this.selectedTopics.splice(index, 1);
  }

  private handleNewsItems(newsItems, newsData) {
    newsItems.forEach(element => {
      if (element.featured_image_url !== undefined) {
        const lastindex = element.featured_image_url.lastIndexOf('/');
        element.featured_image_url = element.featured_image_url.slice(0, lastindex) + '/resize_626x500';
      }
      newsData.push(element);
    });
    this.currentOffset += newsItems.length;
  }

  private getSlipcaseNewsItems(includeTopics?, includePayWalled?, numberOfItems?, offset?) {
    this.isLoading = true;
    this.errorOccured = false;
    this.slipcaseNewsService.getSlipcaseNewsItems(includeTopics, includePayWalled, numberOfItems, offset).subscribe(
      newsItems => {
        this.handleNewsItems(newsItems.body, this.newsData);
        this.isLoading = false;
      },
      ex => {
        this.appInsightsService.trackException(ex);
        this.errorOccured = true;
        this.isLoading = false;
      },
      () => { this.isLoading = false; });
  }
}
