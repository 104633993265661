import { Injectable } from '@angular/core';
import { OverviewSearchService } from '../../../overview-access/services/overview-search.service';
import { SafrOverviewAccess } from '../models/safr-overview-access';
import { SafrOverviewAccessApiService } from './safr-overview-access-api.service';
import { AccessMapping } from '../../../overview-access/models/access-mapping';

@Injectable({
  providedIn: 'root'
})
export class SafrOverviewAccessListService extends OverviewSearchService<SafrOverviewAccess> {

  constructor(private apiService: SafrOverviewAccessApiService) {
    super(apiService);
  }

  matchesSearchTerm(overviewAccess: AccessMapping<SafrOverviewAccess>): boolean {
    const searchTerm = this.searchTerm.trim().toLocaleLowerCase();
    return overviewAccess.user.displayName?.toLowerCase().includes(searchTerm) ||
      overviewAccess.user.email.toLowerCase().includes(searchTerm) ||
      overviewAccess.access.teams.toString().toLowerCase().includes(searchTerm) ||
      overviewAccess.access.reportingGroups.toString().toLowerCase().includes(searchTerm);

  }

}
