import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-placement-excellence-content-card',
  templateUrl: './placement-excellence-content-card.component.html',
  styleUrls: ['./placement-excellence-content-card.component.css']
})
export class PlacementExcellenceContentCardComponent implements OnInit {

  @Input() coreStep: string;
  @Input() generalTask: string;
  @Input() actionDetails: string;
  @Input() responsibility: string;
  @Input() cemRequirement: string;
  @Input() guidanceReferenceLink: string;

  constructor () { }

  ngOnInit() {
  }

  getValidId(text: string) {
    return text.replace(/[^a-z0-9\-_:\.]|^[^a-z]+/gi, '');
  }

}
