<div class="form-group mb-0">
  <div class="row mb-3">
    <div class="col">
      <div class="row">
        <div class="col pe-0">
          <label class="form-control-label" *ngIf="!rlsRoleWithAASDataSource">PowerBI Role</label>
          <label class="form-control-label" *ngIf="rlsRoleWithAASDataSource">AAS Role</label>
          <div class="position-relative form-icon">
            <span class="text-field-icon d-flex align-items-center justify-content-center">
              <i class="far fa-file-chart-pie subtle-text"></i>
            </span>
            <wre-toolkit-text-field class="w-100" inputId="rls-name" placeholder="Enter PowerBI role name..."
              [control]="rlsRoleFormGroup.get('name')">
            </wre-toolkit-text-field>
          </div>
        </div>
        <div class="col-auto px-0 d-flex align-items-end" *ngIf="rlsRoleWithAASDataSource">
          <div class="form-middle d-flex align-items-center justify-content-center">
            <span class="middle-circle d-flex align-items-center justify-content-center">
              <i class="far fa-link"></i>
            </span>
          </div>
        </div>
        <div class="col pe-0" *ngIf="rlsRoleWithAASDataSource">
          <label class="form-control-label">Custom Data</label>
          <div class="position-relative form-icon">
            <span class="text-field-icon d-flex align-items-center justify-content-center">
              <i class="far fa-file-chart-pie subtle-text"></i>
            </span>
            <wre-toolkit-text-field class="w-100" inputId="rls-name" placeholder="Enter RLS custom data..."
              [control]="rlsRoleFormGroup.get('rlsCustomData')">
            </wre-toolkit-text-field>
          </div>
        </div>
        <div class="col-auto px-0 d-flex align-items-end">
          <div class="form-middle d-flex align-items-center justify-content-center">
            <span class="middle-circle d-flex align-items-center justify-content-center">
              <i class="far fa-link"></i>
            </span>
          </div>
        </div>
        <div class="col ps-0">
          <label class="form-control-label">Security Role</label>
          <div class="position-relative form-icon">
            <span class="text-field-icon d-flex align-items-center justify-content-center">
              <i class="far fa-lock subtle-text"></i>
            </span>
            <wre-role-lookup #roleLookup [securityRoleFormGroup]="rlsRoleFormGroup.get('securityRole')"
              [application]="application" placeholder="Enter security role name...">
            </wre-role-lookup>
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto ms-3 align-self-end">
      <button type="button" class="btn btn-primary" (click)="onAddRole(); roleLookup.clearForm()"
        [disabled]="addDisabled">
        <span>Add</span>
      </button>
    </div>
  </div>
</div>
