<div class="p-4">
  <div class="btn-group me-auto">

    <ul class="nav-pills nav" id="mytabApps" role="tablist">

      <li *ngFor="let frame of frames" class="nav-item d-flex">
        <a [routerLink]="[frame.id]" routerLinkActive="active" class="nav-link show" id="twitter-tab-categories" data-bs-toggle="tab" role="tab"
           aria-controls="tabCategories" aria-selected="true">
          <span>{{frame.name}}</span>
        </a>
      </li>

    </ul>

  </div>

  <div class="wfm-frame-wrapper">
      <router-outlet></router-outlet>
  </div>

</div>
