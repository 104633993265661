import { Component, OnInit, AfterViewInit } from '@angular/core';
import items from '../../../../../assets/json/placement-excellence/marketing-protocols-data-gathering.json';
import { ActivatedRoute } from '@angular/router'; 
import { PlacementExcellenceService } from 'src/app/services/placement-excellence.service';

@Component({
  selector: 'app-marketing-protocols-data-gathering',
  templateUrl: './marketing-protocols-data-gathering.component.html',
  styleUrls: ['./marketing-protocols-data-gathering.component.css']
})
export class MarketingProtocolsDataGatheringComponent implements AfterViewInit {

  tableItems = items;

  constructor (private route: ActivatedRoute, private placementExcellenceService: PlacementExcellenceService) { }

  ngAfterViewInit() {
    this.route.fragment.subscribe(f => this.placementExcellenceService.scroolToElementById(f));
  }

}
