import { Component, OnDestroy, OnInit } from '@angular/core';
import { PermissionsListService } from '../../../../../services/permissions-list.service';
import { combineLatest, Subscription } from 'rxjs';
import { RolesPermissionsGridService } from '../../../../../services/roles/roles-permissions-grid.service';

@Component({
  selector: 'wre-access-permissions',
  templateUrl: './access-permissions.component.html',
  styleUrls: ['./access-permissions.component.sass'],
  providers: [RolesPermissionsGridService]
})
export class AccessPermissionsComponent implements OnInit, OnDestroy {

  loadingSubscription: Subscription;
  loading = true;

  constructor(private permissionsService: PermissionsListService,
              public rolesService: RolesPermissionsGridService) {
  }

  ngOnInit() {
    this.loadingSubscription = combineLatest(this.permissionsService.loading$, this.rolesService.loading$).subscribe(
      ([loadingPermissions, loadingRoles]) => {
        this.loading = loadingPermissions || loadingRoles;
      }
    );
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

}
