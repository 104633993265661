import { Component, Input } from '@angular/core';
import { AuditData } from '../../../../../models/role-audit';
import { UserType } from '../../../../../models/user-type';

@Component({
  selector: 'wre-role-history-card',
  templateUrl: './role-history-card.component.html',
  styleUrls: ['./role-history-card.component.sass']
})
export class RoleHistoryCardComponent {

  @Input() audit: AuditData;
  @Input() title;

  get isRoleActiveUpdate(): boolean {
    return !!this.audit.role && !!this.audit.role.old && this.audit.role.old.isActive !== null;
  }

  get isRoleNameUpdate(): boolean {
    return !!this.audit.role && !!this.audit.role.old.name && !!this.audit.role.new.name;
  }

  get isRoleCreate(): boolean {
    const result = !!this.audit.role && !!this.audit.role.new && !this.audit.role.old.name && !!this.audit.role.new.name &&
    this.audit.type === 'new-role';
    return result;
  }

  get usersCount() {
    return !!this.audit.users ? this.audit.users.filter(u => u.type !== UserType.adGroup).length : 0;
  }

  get adGroupsCount() {
    return !!this.audit.users ? this.audit.users.filter(u => u.type === UserType.adGroup).length : 0;
  }

  get servicePrincipleCount() {
    return !!this.audit.users ? this.audit.users.filter(u => u.type === UserType.servicePrinciple).length : 0;
  }

  get adminsCount() {
    return !!this.audit.admins ? this.audit.admins.length : 0;
  }

  get permissionsCount() {
    return !!this.audit.permissions ? this.audit.permissions.length : 0;
  }

  getActiveId(audit: AuditData) {
    if (audit.admins && audit.admins.length > 0 || this.isRoleCreate) {
      return 'Authorised_Admins';
    } else if (audit.users && audit.users.length > 0) {
      return 'Users';
    } else {
      return 'Permissions';
    }
  }

}
