import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminAppsCreateUpdateComponent } from '../apps-create-update/apps-create-update.component';
import { AdminDeleteComponent } from '../../admin/delete/delete.component';
import { TabTitles } from '../../admin/models/refresh-data-type';
import { App } from '../../../models/app';
import { AppsService } from '../services/apps.service';
import { SecurityWorkspaceService } from '../../security-admin/services/security-workspace.service';
import { UserPermissions } from '../../../models/admin';

@Component({
  selector: 'wre-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.sass']
})
export class AdminAppsComponent implements OnInit, OnDestroy {

  get loading$(): Observable<boolean> {
    return this.appsService.loading$;
  }

  displayError: boolean;

  appsSub: Subscription;
  apps: App[];
  offset = '0';
  roles: string[] = [];

    @Output() setExperienceTitle: EventEmitter<any> = new EventEmitter();

    get hasEditPermission() {
        return this.roles.includes(UserPermissions.GatewayToolsEdit);
    }

    get hasDeletePermission() {
        return this.roles.includes(UserPermissions.GatewayToolsDelete);
    }

  constructor (private modalService: NgbModal,
      private appsService: AppsService,
      private securityWorkspaceService: SecurityWorkspaceService) {
      this.roles = this.securityWorkspaceService.currentPermissions;
  }

  ngOnInit() {
    this.setExperienceTitle.emit(TabTitles.Tools);
    this.appsSub = this.appsService.apps$.subscribe(
      apps => {
        this.displayError = false;
        this.apps = apps;
      },
      () => this.displayError = true
    );
    this.appsService.loadApps();
  }

  ngOnDestroy() {
    this.appsSub?.unsubscribe();
  }

  public onEnableDisableApp(app: App) {
    this.appsService.toggleActivationOfApp(app.id).subscribe();
  }

  public onDelete(app: App) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'xl'
    };

    const modalRef = this.modalService.open(
      AdminDeleteComponent,
      modalOptions
    );

    const componentInstance = modalRef.componentInstance as AdminDeleteComponent;

    componentInstance.isConfirmModal = true;
    componentInstance.entityName = app.name;
    componentInstance.deleteEntityMessage_h5 = 'Are you sure you want to delete tool';
    componentInstance.deleteEntityMessage_p = 'This cannot be undone.';
    componentInstance.closeOnConfirm = false;

    componentInstance.deleteConfirmedChange.subscribe(confirmed => {
      if (confirmed) {
        componentInstance.isLoading = true;
        this.appsService.deleteApp(app.id).subscribe(() => componentInstance.closeModal());
      }
    });
  }

  public onEdit(app: App) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };

    const modalRef = this.modalService.open(
      AdminAppsCreateUpdateComponent,
      modalOptions
    );

    modalRef.componentInstance.appId = app.id;
  }

}

