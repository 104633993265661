<div class="placement-excellence-container-fluid">
    <div class="row">
        <div *ngFor="let card of cueCards">
            <ng-container *ngFor="let item of card">
                <div class="col">
                    <app-placement-excellence-content-card coreStep="{{item.coreStep}}" generalTask="{{item.generalTask}}" actionDetails="{{item.actionDetails}}" responsibility="{{item.responsibility}}" cemRequirement="{{item.cemRequirement}}" guidanceReferenceLink="{{item.guidanceReferenceLink}}">
                    </app-placement-excellence-content-card>
                </div>
            </ng-container>
        </div>
    </div>
</div>