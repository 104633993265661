import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

export interface PaginationSettings {
  total$: Observable<number>;
  page: number;
  pageSize: number;
}

@Component({
  selector: 'wre-access-pagination',
  templateUrl: './access-pagination.component.html',
  styleUrls: ['./access-pagination.component.sass']
})
export class AccessPaginationComponent {
  @Input() total: number;

  private _pageSize: number;
  private _page: number;

  @Input()
  set pageSize(page: number) {
    this._pageSize = page;
    this.pageSizeChange.emit(this._pageSize);
  }
  get pageSize() {
    return this._pageSize;
  }

  @Input()
  set page(page: number) {
    this._page = page;
    this.pageChange.emit(this._page);
  }
  get page() {
    return this._page;
  }

  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() pageChange = new EventEmitter<number>();
}
