import { Component } from '@angular/core';
import { PermissionGroup } from '../../../../../models/permission-group';
import { RoleSummaryView } from '../../../../../models/role-summary-view';
import { PermissionsListService } from '../../../../../services/permissions-list.service';
import { Observable } from 'rxjs';
import { RolesPermissionsGridService } from '../../../../../services/roles/roles-permissions-grid.service';

@Component({
  selector: 'wre-roles-permissions-grid',
  templateUrl: './roles-permissions-grid.component.html',
  styleUrls: ['./roles-permissions-grid.component.sass']
})
export class RolesPermissionsGridComponent {
  permissions$: Observable<PermissionGroup[]>;
  roles$: Observable<RoleSummaryView[]>;

  offset = '0';

  hoveredColumn: number;
  hoveredRow: number;

  constructor(public rolesService: RolesPermissionsGridService,
              private permissionService: PermissionsListService) {
    this.permissions$ = this.permissionService.basePermissions$;
    this.roles$ = this.rolesService.roles$;
  }

  setHovered(col: number, row: number) {
    this.hoveredColumn = col;
    this.hoveredRow = row;
  }

  resetHovered() {
    this.hoveredColumn = null;
    this.hoveredRow = null;
  }

  checkRolePermission(role: RoleSummaryView, permission: PermissionGroup) {
    return role.permissions && role.permissions.findIndex(p => p.id === permission.id) >= 0;
  }

}
