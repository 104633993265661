import { Component, Input } from '@angular/core';

@Component({
  selector: 'wre-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: ['./not-available.component.sass']
})
export class ServiceNotAvailableComponent {
  @Input() serviceName: string;
  @Input() errorOccured: boolean;

}
