<div class="file-upload-container">
  <input #fileDrop id="file-upload" class="d-none" type="file"
         [disabled]="status === FileUploadStatus.Submitting"
         [accept]="supportedExtensions.toString()"
         (change)="onFileSelected($event)"
  />

  <div *ngIf="status === FileUploadStatus.Submitting" class="align-items-center justify-content-center loading-overlay">
    <wre-toolkit-loader></wre-toolkit-loader>
  </div>


  <div class="row">
    <div class="col d-flex justify-content-center">
      <div class="row">
        <div class="col-auto">
          <i *ngIf="status !== FileUploadStatus.Complete" class="fal fa-file-upload icon"></i>
          <i *ngIf="status === FileUploadStatus.Complete" class="fal fa-file-check icon upload-complete"></i>
        </div>

        <div class="col text-start">
          <div class="row d-flex flex-column">
            <div class="col">
              <div class="row d-flex flex-column">
                <div class="col d-flex flex-row align-items-center mb-1">
                  <h6 class="file-heading">
                    <span (click)="file == null && fileDrop.click()" [title]="heading"
                          [ngClass]="{
                            'awaiting-input': status === FileUploadStatus.AwaitingInput,
                            'upload-complete': status === FileUploadStatus.Complete,
                            'file-name': file != null}"
                    >
                      {{ heading }}
                    </span>
                  </h6>
                  <span *ngIf="file != null" (click)="fileDrop.click()" class="change-file ms-3">
                    Change File
                  </span>
                </div>

                <div class="col upload-conditions">
                  <span class="d-block subtle">Accepted file Types: .xlxs, .xls</span>
                  <span class="d-block subtle">Max file size: 10 MB</span>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
</div>
