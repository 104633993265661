import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppInsightsService } from 'wre-core-lib';
import { AdminService } from 'src/app/services/admin.service';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SecurityGroupData } from 'src/app/models/admin';
import { throwError } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AdminDeleteComponent } from '../../admin/delete/delete.component';


@Component({
  selector: 'app-security-groups-create-update',
  templateUrl: './security-groups-create-update.component.html',
  styleUrls: ['./security-groups-create-update.component.css']
})
export class AdminSecurityGroupsCreateUpdateComponent implements OnInit {


  newSecurityGroup: SecurityGroupData;
  isloaderVisible = false;
  noContentMessage: string;
  isNoContentMessageVisible = false;
  errorMessage: any;

  title: string;
  securityGroupId: number;
  buttonAction: string;

  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  @Output() refreshSecurityGroupData: EventEmitter<boolean> = new EventEmitter();

  constructor (
    public activeModal: NgbActiveModal,
    private appInsightsService: AppInsightsService,
    private adminService: AdminService,
    private modalService: NgbModal) {
    this.appInsightsService.componentName = 'SecurityGroupsCreateUpdateComponent';
  }

  ngOnInit() {
    this.setTitle.emit('Gallagher Re Gateway');
    if (this.securityGroupId !== undefined) {
      this.title = 'Update Security Group';
      this.buttonAction = 'Update';
      this.getSecurityGroup(this.securityGroupId);
    } else {
      this.title = 'New Security Group';
      this.buttonAction = 'Add';
    }
    this.newSecurityGroup = new SecurityGroupData();
  }

  onAdd(newSecurityGroupForm: NgForm) {
    if (newSecurityGroupForm.invalid) {
      this.noContentMessage = '';
      this.isNoContentMessageVisible = false;
      return;
    }
    this.newSecurityGroup = newSecurityGroupForm.value;
    if (this.buttonAction === 'Add') {
      this.addSecurityGroup(this.newSecurityGroup);
    } else {
      this.newSecurityGroup.id = this.securityGroupId;
      this.editSecurityGroup(this.newSecurityGroup);
    }

  }
  private editSecurityGroup(securityGroupData: SecurityGroupData) {
    this.isloaderVisible = true;
    this.isNoContentMessageVisible = false;
    this.refreshSecurityGroupData.emit(false);
    this.appInsightsService.methodName = 'editApps';
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      securityGroupData.name = securityGroupData.name.trim();
      securityGroupData.domain = securityGroupData.domain.trim();
      this.adminService.editSecurityGroup(securityGroupData).subscribe(result => {
        if (result.status === 201) {
          this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
          this.closeModal();
          this.refreshSecurityGroupData.emit(true);
          this.isloaderVisible = false;
        }

      }, error => {
        if (error.status === 409) {
          this.isNoContentMessageVisible = true;
          this.noContentMessage = 'A Security Group with the name ' + this.newSecurityGroup.name + ' already exists in the system.';
        } else {
          this.appInsightsService.trackTrace(this.appInsightsService.msgMethodError);
        }
        this.isloaderVisible = false;
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }



  private addSecurityGroup(securityGroup: SecurityGroupData) {
    this.isloaderVisible = true;
    this.isNoContentMessageVisible = false;
    this.refreshSecurityGroupData.emit(false);
    this.appInsightsService.methodName = 'addApps';
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      securityGroup.name = securityGroup.name.trim();
      securityGroup.domain = securityGroup.domain.trim();
      this.adminService.addSecurityGroup(securityGroup).subscribe(result => {
        if (result.status === 201) {
          this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
          this.closeModal();
          this.refreshSecurityGroupData.emit(true);
          this.isloaderVisible = false;
        }
      }, error => {
        if (error.status === 409) {
          this.isNoContentMessageVisible = true;
          this.noContentMessage = 'A Security Group with the name ' + this.newSecurityGroup.name + ' already exists in the system.';
        } else {
          this.appInsightsService.trackTrace(this.appInsightsService.msgMethodError);
        }
        this.isloaderVisible = false;
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  getSecurityGroup(id: number) {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'getSecurityGroups';
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.adminService.getSecurityGroup(id).subscribe(data => {
        this.newSecurityGroup.name = data.body.name;
        this.newSecurityGroup.domain = data.body.domain;
        this.isloaderVisible = false;
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  open() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true
    };

    const modalRef = this.modalService.open(
      AdminDeleteComponent,
      modalOptions
    );

    modalRef.componentInstance.isConfirmModal = false;
    modalRef.componentInstance.entityName = '';

    modalRef.componentInstance.deleteEntityMessage_h5 = 'Security Group Info';

    if (this.buttonAction === 'Add') {
      modalRef.componentInstance.deleteEntityMessage_p = this.newSecurityGroup.name + ' added successfully.';
    } else {
      modalRef.componentInstance.deleteEntityMessage_p = this.newSecurityGroup.name + ' updated successfully.';
    }
  }
}
