import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientAdvocacyHomeComponent } from './client-advocacy-home/client-advocacy-home.component';
import { ClientAdvocacyAdvocateListComponent } from './client-advocacy-advocate-list/client-advocacy-advocate-list.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { ClientAdvocacyComponent } from './client-advocacy.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ClientAdvocacyHomeComponent,
    ClientAdvocacyAdvocateListComponent,
    ClientAdvocacyComponent],
  imports: [
    CommonModule, RouterModule, 
    TourMatMenuModule,
  ]
})
export class ClientAdvocacyModule { }
