import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminService } from '../../../services/admin.service';
import { App } from '../../../models/app';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppsService {

  private _apps$ = new BehaviorSubject<App[]>([]);
  private _loading$ = new BehaviorSubject<boolean>(false);

  private _apps: App[] = [];

  get apps$(): Observable<App[]> {
    if (this._apps$.getValue().length === 0) {
      this.loadApps();
    }
    return this._apps$.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  constructor(private adminService: AdminService) { }

  loadApps(): void {
    if (this._loading$.getValue()) {
      return;
    }
    console.log('Loading applications (tools)');
    this._loading$.next(true);
    this.adminService.getApps().subscribe(
      response => {
        console.log('Applications (tools) loaded successfully');
        this._apps = response.body;
        this._apps$.next(this._apps);
        this._loading$.next(false);
      }, error => {
        this._apps$.error(error);
        this._loading$.next(false);
    });
  }

  deleteApp(id: number): Observable<boolean> {
    console.log(`Deleting application ${id}`);
    return this.adminService.deleteApp(id).pipe(
      map(response => {
        if (response) {
          console.log(`Successfully deleted application ${id}`);
          const idx = this._apps.findIndex(a => a.id === id);
          this._apps.splice(idx, 1);
          this._apps$.next(this._apps);
        }
        return response.body;
      }));
  }

  toggleActivationOfApp(id: number): Observable<boolean> {
    console.log(`Toggling activation state of application ${id}`);
    return this.adminService.enableDisableApps(id).pipe(
      map(response => {
        console.log(`Application ${id} is now ${response.body ? 'enabled' : 'disabled'}`);
        const app = this._apps.find(a => a.id === id);
        app.isActive = response.body;
        this._apps$.next(this._apps);
        return app.isActive;
      }));
  }

  editApp(app: App): Observable<App> {
    console.log(`Editing application ${app.id}`);
    return this.adminService.editApp(app).pipe(
      map(response => {
        console.log(`Successfully edited application ${app.id}`);
        const idx = this._apps.findIndex(a => a.id === app.id);
        this._apps[idx] = response.body;
        this._apps$.next(this._apps);
        return response.body;
      }));
  }

  addApp(app: App): Observable<App> {
    console.log(`Creating new application`);
    return this.adminService.addApp(app).pipe(
      map(response => {
        const newApp = response.body;
        console.log(`New application created successfully with id ${newApp.id}`);
        this._apps.push(newApp);
        this._apps.sort((a, b) => {
          const categoryDiff = a.category.orderNo - b.category.orderNo;
          return categoryDiff !== 0 ? categoryDiff : a.orderNo - b.orderNo;
        });
        this._apps$.next(this._apps);
        return newApp;
      }));
  }

}
