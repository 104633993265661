import { Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { TabTitles } from '../../admin/models/refresh-data-type';
import { SrtOverviewAccessListService } from '../services/srt-overview-access-list.service';
import { AccessMapping } from '../../overview-access/models/access-mapping';
import { SrtOverviewAccess } from '../models/srt-overview-access';

@Component({
  selector: 'wre-srt-overview-wrapper',
  templateUrl: './srt-overview-wrapper.component.html',
  styleUrls: ['./srt-overview-wrapper.component.sass']
})
export class SrtOverviewWrapperComponent implements OnInit, OnDestroy {

  @ViewChildren(RouterOutlet)
  private outlets!: QueryList<RouterOutlet>;

  accessMappings: AccessMapping<SrtOverviewAccess>[] = [];
  total = 0;
  loading = true;
  error: Error;

  get total$(): Observable<number> {
    return of (this.total);
  }

  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  @Output() setExperienceTitle: EventEmitter<any> = new EventEmitter();

  isSearchAvailable = true;
  searchedTerm: string;

  private dataSubscription: Subscription;
  private routerSubscription: Subscription;

  constructor(public srtOverviewListService: SrtOverviewAccessListService,
              private router: Router) {

    this.routerSubscription = this.router.events.subscribe(e => {
      if (e instanceof ActivationStart) {
        let activatedOutlet;
        if (e.snapshot.outlet === 'modal') {
          activatedOutlet = this.outlets.find(o => o.isActivated);
          if (activatedOutlet) { activatedOutlet.deactivate(); }
        }
      }
    });
  }

  ngOnInit(): void {
    this.setExperienceTitle.emit(TabTitles.OverviewAccess);
    this.setTitle.emit('Sanctions Referral Admin');

    this.dataSubscription = this.srtOverviewListService.searchResult$.subscribe(searchResult => {
      this.accessMappings = searchResult.overviewAccess;
      this.total = searchResult.total;
      this.loading = searchResult.loading;
      this.error = searchResult.error;
    });

    this.applySearch('');

  }

  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  applySearch(searchTerm: string): void {
    this.srtOverviewListService.searchTerm = searchTerm;
  }

}
