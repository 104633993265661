export class AppsCategory {
  id: number;
  name: string;
  isValid: boolean;
  orderNo: number;

  constructor(id?: number, name?: string, isValid?: boolean, orderNo?: number ) {
    this.id = id;
    this.name = name;
    this.isValid = isValid;
    this.orderNo = orderNo;
  }

  equal(appCategory: AppsCategory) {
    return this.id === appCategory.id &&
      this.name === appCategory.name &&
      this.isValid === appCategory.isValid &&
      this.orderNo === appCategory.orderNo;
  }
}
