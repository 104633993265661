import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewAccessTableComponent } from './components/overview-access-table.component';
import { OverviewAccessModalBaseComponent } from './components/overview-access-modal-base.component';



@NgModule({
  declarations: [
    OverviewAccessTableComponent,
    OverviewAccessModalBaseComponent
  ],
  imports: [
    CommonModule
  ]
})
export class OverviewAccessModule { }
