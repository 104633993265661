<div class="form-group mb-0">
  <div class="row mb-3">
    <div class="col">
      <wre-role-lookup #roleLookup
                       [securityRoleFormGroup]="securityRoleFormGroup"
                       [application]="application"
                       [label]="displayDropdownLabel ? 'Role' : null"
      >
      </wre-role-lookup>
    </div>
    <div class="col-auto align-self-end">
      <button type="button" class="btn btn-primary ms-3"
              (click)="onAddRole(); roleLookup.clearForm()"
              [disabled]="securityRoleFormGroup.invalid">
        <span>Add</span>
      </button>
    </div>
  </div>
</div>

<div class="form-group mb-0">
  <div class="row">
    <div class="col-12">
      <label class="form-control-label">Selected Roles</label>
      <div class="card">
        <div class="card-body p-0">
          <div class="d-flex justify-content-between role-entry" *ngFor="let role of securityRoles; index as idx">
            {{ role.name }}
            <div class="px-2 delete-button" title="Delete role" (click)="deleteRole(idx)">
              <i class="fa fa-times"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
