import { Component, OnInit } from '@angular/core';
import { WreCoreMyAppService } from 'wre-core-lib';
import { BehaviorSubject } from 'rxjs';
import { AppsCategory } from 'wre-core-lib/lib/models/apps-category';
import { App } from 'wre-core-lib/lib/models/app';

@Component({
  selector: 'wre-quickaccess',
  templateUrl: './quickaccess.component.html',
  styleUrls: ['./quickaccess.component.sass']
})
export class QuickaccessComponent implements OnInit {

  private _apps$ = new BehaviorSubject<AppsCategory[]>([]);
  get apps() { return this._apps$.asObservable(); }

  isLoading = true;

  currentTabId: string;

  constructor(private myAppService: WreCoreMyAppService) { }

  async ngOnInit() {
    this.myAppService.getUserAppsDetail().then((res) => {
      this._apps$.next(res);
      this.isLoading = false;
    });
  }

  getTarget(item: App) {
    return item.openInNewTab ? '_blank' : '_self';
  }

  getIconClass(workspace: string) {
    switch (workspace) {
      case 'Modelling': return 'fa-tornado';
      case 'Sales': return 'fa-handshake';
      case 'Finance': return 'fa-coins';
      case 'Reporting': return 'fa-file-chart-line';
      case 'RQM': return 'fa-user-chart';
      case 'General': return 'fa-puzzle-piece';
      case 'Portals': return 'fa-galaxy';
      case 'Legacy Apps': return 'fa-history';
      default: return 'fa-file';
    }
  }
}
