import { Subscription } from 'rxjs';
import { SecurityWorkspace } from '../../../../models/security-workspace';
import { SecurityWorkspaceService } from '../../../../services/security-workspace.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wre-roles-sidebar-wrapper',
  templateUrl: './roles-sidebar-wrapper.component.html',
  styleUrls: ['./roles-sidebar-wrapper.component.sass']
})
export class RolesSidebarWrapperComponent implements OnInit, OnDestroy {

  roleId: string;
  isOpen = false;
  workspace: SecurityWorkspace;
  subscription: Subscription;

  constructor(private router: Router,
    private _securityWorkspaceService: SecurityWorkspaceService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.isOpen = true;
      this.roleId = params.get('id');
    });

    this.subscription = this._securityWorkspaceService.currentWorkspace$.subscribe(workspace => this.workspace = workspace);
  }

  closeSidebar() {
    this.isOpen = false;
    this.router.navigate([`admin/security/access/${this.workspace.url}/roles`]);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}
