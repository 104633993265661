import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RoadmapsService } from '../services/roadmaps.service';
import { AppInsightsService } from 'wre-core-lib';
import { throwError } from 'rxjs';

@Component({
  selector: 'wre-road-map',
  templateUrl: './road-map.component.html',
  styleUrls: ['./road-map.component.sass']
})
export class RoadMapComponent implements OnInit {

  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  roadmapUrl = 'Workspaces';
  contextmenu = false;
  roadMapsData: any[];
  constructor(private roadmapsService: RoadmapsService, private appInsightsService: AppInsightsService) { }

  ngOnInit() {
    this.setTitle.emit('Gallagher Re Gateway');
    this.getRoadmapsContents();
  }

  getRoadmapsContents() {
    this.appInsightsService.methodName = 'getRoadmapsContents';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.roadmapsService.getRoadmapsContents().subscribe(result => {
        this.roadMapsData = (result.body['workspaces'] as { id: string}[]).sort((a, b) => +a.id - +b.id);
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
      });
    } catch (error) {
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

}
