import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { RouterOutlet, Router, ActivationStart } from '@angular/router';
import { TabTitles } from '../../admin/models/refresh-data-type';

@Component({
  selector: 'wre-fields-wrapper',
  templateUrl: './fields-wrapper.component.html'
})
export class FieldsWrapperComponent implements OnInit {

  @Output() setExperienceTitle: EventEmitter<any> = new EventEmitter();

  @ViewChildren(RouterOutlet)
  private outlets!: QueryList<RouterOutlet>;

  constructor(private _router: Router) {
    const subscription3$ = this._router.events.subscribe(e => {
      if (e instanceof ActivationStart) {
        let activatedOutlet;
        if (e.snapshot.outlet === 'modal') {
          activatedOutlet = this.outlets.find(o => o.isActivated);
          if (activatedOutlet) { activatedOutlet.deactivate(); }
        }
      }
    });
  }

  ngOnInit(): void {
    this.setExperienceTitle.emit(TabTitles.FieldManagement);
  }

}
