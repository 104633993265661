import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { User } from 'src/app/admin-portal/security-admin/models/users';
import { SecurityManagementService } from 'src/app/admin-portal/security-admin/services/security-management.service';
import { Applications } from 'src/app/models/admin';

@Component({
  selector: 'wre-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.sass']
})
export class UserDetailsComponent implements OnChanges {

  @Input() userId = null;
  user: User = new User();
  errorMessageInfo: string;
  errorMessage = false;
  loading = false;
  error = null;
  reportingApplications = [];
  rqmApplications = [];
  gatewayApplications = [];
  sanctionsApplications = [];

  constructor(private dataService: SecurityManagementService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId']) {
      this.loading = true;
      this.dataService.getUserById(this.userId)
        .then(response => {
          if (response.status === 200) {
            this.user = Object.assign(this.user, response.body);
            this.reportingApplications = this.user.applications.filter(a => a.applicationName === Applications.WreReporting);
            this.rqmApplications = this.user.applications.filter(a => a.applicationName === Applications.WreSafr);
            this.gatewayApplications = this.user.applications.filter(a => a.applicationName === Applications.WreGateway);
            this.sanctionsApplications = this.user.applications.filter(a =>
              a.applicationName === Applications.WreSanctions ||
              a.applicationName === Applications.WreReferral).sort((a, b) => (a.applicationId > b.applicationId) ? 1 : -1);
            this.loading = false;
          } else {
            this.errorMessageInfo = 'An error has occured. Contact Administrator';
            this.errorMessage = true;
          }
        })
        .catch(error => {
          this.error = error;
          console.error(error);
          this.errorMessageInfo = 'An error has occured. Contact Administrator';
          this.errorMessage = true;
        });
    }
  }

}
