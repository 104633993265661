<div *ngIf="loading" class="row h-100">
  <div class="col-md-12 align-self-center">
      <wre-toolkit-loader></wre-toolkit-loader>
  </div>
</div>

<ng-container *ngIf="!loading">
  <div *ngIf="errorMessageInfo" class="row text-center">
    <div class="col">
      <span class="wre-form-error">
        {{ errorMessageInfo }}
      </span>
    </div>
  </div>

  <ng-container *ngIf="!errorMessageInfo && role">
    <div class="row">
      <div class="col-md-12">
          <div class="summary-bar border-bottom">
              <label class="form-control-label mb-0 me-2">
                  Role Name:
              </label>
              <span class="summary-detail">
                  <span *ngIf="!role.name">
                      &#x2015;
                  </span>
                  <span *ngIf="!!role.name">
                      <strong>{{ role.name }}</strong>
                  </span>
              </span>

              <label class="form-control-label border-start ms-3 ps-3 mb-0">
                  Active:
              </label>
              <span class="summary-detail ms-2" *ngIf="!!role.name">
                  <i class="far" [ngClass]=" {'fa-check': role.isActive, 'fa-times': !role.isActive}"></i>
              </span>
          </div>
      </div>
    </div>

    <div class="details-cont">
    <ul ngbNav #nav="ngbNav" activeId="Review" class="nav-tabs">
      <li ngbNavItem="Review">
        <a ngbNavLink>Review</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col">
              <div class="wre-tab-container">
                <wre-details [role]="role"></wre-details>
              </div>
            </div>
          </div>
        </ng-template>
      </li>

      <li ngbNavItem="History">
        <a ngbNavLink>History</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col">
              <div class="wre-tab-container">
                <wre-role-history [roleId]="roleId"></wre-role-history>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
  </ng-container>
</ng-container>
