<div class="row">
  <div class="col">
    <div *ngIf="status === FileUploadStatus.Error" role="alert" class="alert alert-danger p-3">
      An error has occurred, sanctions database not updated.
    </div>

    <div *ngIf="status === FileUploadStatus.Complete" role="alert" class="alert alert-success p-3">
      Sanctions database updated successfully.
      Modified {{ uploadResult.sanctionedEntitiesAffected }} sanctioned entities and {{ uploadResult.redFlagsAffected }} red flags.
    </div>
  </div>
</div>

<wre-file-upload></wre-file-upload>

<div class="row mt-3">
  <div class="col d-flex justify-content-center">
    <button type="button" class="btn wre-btn btn-primary"
            (click)="onFileSubmitted()"
            [disabled]="status !== FileUploadStatus.FileSelected">
      Submit
    </button>
  </div>
</div>
