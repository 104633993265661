import { Injectable } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import { AppsCategory } from '../../../models/apps-category';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppCategoriesService {

  private _appCategories$ = new BehaviorSubject<AppsCategory[]>([]);
  private _loading$ = new BehaviorSubject<boolean>(false);

  get appCategories$(): Observable<AppsCategory[]> {
    if (this._appCategories$.getValue().length === 0) {
      this.loadAppCategories();
    }
    return this._appCategories$.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  constructor(private adminService: AdminService) { }

  loadAppCategories(): void {
    if (this._loading$.getValue()) {
      return;
    }
    console.log('Loading app categories (workspaces)');
    this._loading$.next(true);
    this.adminService.getAppsCategories().subscribe(
      response => {
        console.log('App categories (workspaces) loaded successfully');
        this._appCategories$.next(response.body);
        this._loading$.next(false);
    }, error => {
        this._appCategories$.error(error);
        this._loading$.next(false);
    });
  }
}
