import { Component, OnInit } from '@angular/core';
import { SafrManagementService } from '../../../admin/services/safr-management.service';

@Component({
  selector: 'wre-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.sass']
})
export class FieldsComponent implements OnInit {
  offset = '0';

  get isLoading() { return this._safrManagementService.loading$; }
  get errorMessageInfo() { return this._safrManagementService.errorMessage$; }
  get fields() { return this._safrManagementService.fields$; }

  constructor(private _safrManagementService: SafrManagementService) { }

  ngOnInit(): void {
    this._safrManagementService.safrOption$.subscribe(option => {
      if (option !== null) {
        this._safrManagementService.loadFields();
      }
    });
  }
}
