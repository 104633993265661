import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlacementExcellenceService {

  constructor() { }
  scroolToElementById(id: string, yOffset = -140) {
    setTimeout(() => {
      const container = document.querySelector('.placement-excellence-container-fluid');

      if (id === undefined || id === '') {
        container.scrollTo(0, 0);
        return;
      }

      const element = document.querySelector('#' + id);
      if (element) {
        const yCoordinate = element.getBoundingClientRect().top + (container as HTMLElement).scrollTop;

        container.scrollTo({
          top: yCoordinate + yOffset,
          behavior: 'smooth'
        });
      }
    }, 1);
  }
}
