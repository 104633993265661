import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PermissionGroup } from '../../models/permission-group';
import { UserGroup } from '../../models/user-group';

@Component({
  selector: 'wre-role-review-step',
  templateUrl: './role-review-step.component.html',
  styleUrls: ['./role-review-step.component.sass']
})
export class RoleReviewStepComponent {

  @Input() form: UntypedFormGroup = null;
  @Input() userCount: number;
  @Input() adGroupCount: number;
  @Input() servicePrincipleCount: number;
  @Input() admins: UserGroup[];
  @Input() users;
  @Input() permissions: PermissionGroup[];
  @Input() isReview = true;
  @Input() isDetailsView = false;

  get noOfActivePermissions(): number {
    return this.permissionList.length;
  }

  get permissionList(): PermissionGroup[]{
    return !!this.form ? this.form.get('permissions').value : this.permissions;
  }

}
