import { Component, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { AppInsightsService, UserRecentlyViewedService, RecentlyViewedItemRequest } from 'wre-core-lib';
import items from '../../../assets/json/placement-excellence-items.json';

@Component({
  selector: 'app-placement-excellence',
  templateUrl: './placement-excellence.component.html',
  styleUrls: ['./placement-excellence.component.sass']
})


export class PlacementExcellenceComponent implements OnInit, AfterViewInit {
  itemCollection: object[] = items;
  isOpenCoreSteps = false;
  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  header = 'Placement Gallagher Excellence Model';

  constructor (
    private recentlyViewedService: UserRecentlyViewedService,
    private appInsightsService: AppInsightsService) {

    this.appInsightsService.componentName = 'PlacementExcellenceComponent';
  }


  getTitle() {
    const menu = document.getElementById('pem-navigation').getElementsByClassName('active');
    if (menu !== undefined && menu.length > 0) {
      var headerTitle = this.itemCollection.find(i => i['title'] === menu[0].textContent);
      if (headerTitle === undefined) {
        if(menu[0].textContent.toLowerCase() === this.header.toLowerCase()) {
          return menu[0].textContent;
        } else {
          return this.header + ' - ' + menu[0].textContent;
        }
      } else {
        return headerTitle['headerTitle'];
      }
    }
    return '';
  }

  ngOnInit() {
    this.setTitle.emit('RQM Workspace');
    this.appInsightsService.methodName = 'ngOnInit';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    this.appInsightsService.trackTrace(
      this.appInsightsService.msgMethodsuccess
    );
  }

  ngAfterViewInit() {
    this.appInsightsService.methodName = 'ngAfterViewInit';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );

    this.integrateWithRecentlyViewed();

    this.appInsightsService.trackTrace(
      this.appInsightsService.msgMethodsuccess
    );
  }

  integrateWithRecentlyViewed() {
    const linksToRegister = document.getElementById('placement-excellence-toolbar').getElementsByTagName('a');

    if (linksToRegister !== undefined && linksToRegister.length > 0) {
      for (let i = 0; i < linksToRegister.length; i++) {
        linksToRegister[i].addEventListener('mouseup', (event) => {
          if (event.button === 0 || event.button === 1) {
            this.recentlyViewedService.createRecentlyViewedItem(
              // tslint:disable-next-line: max-line-length
              new RecentlyViewedItemRequest('RQM', 'Placement CEM', linksToRegister[i].href, linksToRegister[i].text, new Date(Date.now()), false)
            ).subscribe();
          }
        });
      }
    }
  }



  isOpenCoreStepsChanged($event) {
    this.isOpenCoreSteps = $event;
  }

  toggleCoreSteps() {
    this.isOpenCoreSteps = !this.isOpenCoreSteps;
  }
}
