import { Component, Input } from '@angular/core';

@Component({
  selector: 'wre-user-app-details',
  templateUrl: './user-app-details.component.html',
  styleUrls: ['./user-app-details.component.sass']
})
export class UserAppDetailsComponent {

  @Input() application = null;
  @Input() displayName;

  get roles() {
    return this.application.roles.sort((a, b) => (a.roleName < b.roleName ? -1 : 1));
  }

  get roleCount() {
    if (this.application.roles) {
      return this.application.roles.length;
    }
    return 0;
  }

}
