export class OverviewAccessListState {
  page: number;
  pageSize: number;
  searchTerm: string;

  constructor(defaultPageSize: number) {
    this.page = 1;
    this.pageSize = defaultPageSize;
    this.searchTerm = '';
  }
}
