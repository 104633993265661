<div id="placement-excellence-toolbar" class="placement-excellence-toolbar tool-title btn-toolbar d-flex align-items-center">
    <h1 class="h4 me-auto mb-0 pe-3">{{getTitle()}}</h1>

    <div class="d-flex" role="group">
        <button type="button" routerLink="quick-reference" class="btn btn-secondary me-3 quick-reference">Quick Ref</button>
        <button type="button" aria-haspopup="true" aria-expanded="false" (click)="this.toggleCoreSteps()" class="btn btn-secondary me-3 core-steps">Core Steps</button>
        <div class="dropdown">
            <button id="btnGroupDrop1" type="button" tourAnchor="cueCardSelector.anchor" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <span class="d-none d-md-inline-block">Cue Cards &nbsp;</span>
                        <span class="caret d-none d-md-inline-block"></span></button>
            <div id="pem-navigation" class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1" x-placement="bottom-end" style="position: absolute; transform: translate3d(-26px, 33px, 0px); top: 0px; left: 0px; will-change: transform;">
                <a class="dropdown-item" routerLink="home" routerLinkActive="active">Placement Gallagher Excellence Model</a>
                <a *ngFor="let item of itemCollection" class="dropdown-item" routerLink="{{item.route}}" routerLinkActive="active">{{item.title}}</a>
                <a class="dropdown-item" routerLink="glossary" routerLinkActive="active">Glossary</a>
                <!-- <a class="dropdown-item" routerLink="legal-document-revenue-impact" routerLinkActive="active">Legal Documents & Revenue Impact</a> -->
                <a class="dropdown-item" routerLink="classic-view" routerLinkActive="active">Classic View</a>
                <a style="display: none;" class="dropdown-item" routerLink="quick-reference" routerLinkActive="active">Quick Ref</a>
                <a class="dropdown-item" href="https://ajg0.sharepoint.com/sites/gre-Gateway/_layouts/15/download.aspx?SourceUrl=https://ajg0.sharepoint.com/sites/gre-Gateway/Content/Center/RQM/CEM/Gateway/E-filing%20Requirements.pdf">E-filing Guidance</a>
                <a class="dropdown-item" href="https://ajg0.sharepoint.com/sites/gre-Gateway/_layouts/15/download.aspx?UniqueId=bf13ae02%2D929d%2D457a%2D8077%2Dfb1e7d3de547">Placement GEM Quick List</a>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>

<app-core-steps [isOpenCoreSteps]="this.isOpenCoreSteps" (isOpenCoreStepsChanged)="this.isOpenCoreStepsChanged($event)"></app-core-steps>
