import { AfterViewInit, OnDestroy, Directive } from '@angular/core';
import { AuthorisationService } from 'wre-authlib';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Directive()
export abstract class ProtectedBaseComponent implements AfterViewInit, OnDestroy {

  public componentAvailable = false;
  public componentAvailableSub = new BehaviorSubject(false);
  private allowedRoles: string[];

  constructor(protected authService: AuthorisationService) {}

  // Override default roles provided by app role configuration.
  async setRoles(allowedRoles: string[]) {
    this.allowedRoles = allowedRoles;
    await this.checkRoles();
  }

  async checkRoles() {
    if (this.authService && this.allowedRoles && this.allowedRoles.length > 0) {
      this.componentAvailable = await firstValueFrom(this.authService.checkUserHasPermissions(this.allowedRoles));
    } else {
      this.componentAvailable = true;
    }

    this.componentAvailableSub.next(this.componentAvailable);
    return this.componentAvailable;
  }

  async ngAfterViewInit() {
    await this.checkRoles();
  }

  ngOnDestroy(): void {
    this.componentAvailableSub.unsubscribe();
  }
}
