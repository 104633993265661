import { Component, AfterViewInit } from '@angular/core';
import items from '../../../../../assets/json/placement-excellence/contract-documentation.json';
import { ActivatedRoute } from '@angular/router'; 
import { PlacementExcellenceService } from 'src/app/services/placement-excellence.service';

@Component({
  selector: 'app-contract-documentation',
  templateUrl: './contract-documentation.component.html',
  styleUrls: ['./contract-documentation.component.css']
})
export class ContractDocumentationComponent implements AfterViewInit {

  tableItems = items;

  constructor (private route: ActivatedRoute, private placementExcellenceService: PlacementExcellenceService) { }

  ngAfterViewInit() {
    this.route.fragment.subscribe(f => this.placementExcellenceService.scroolToElementById(f, -300));
  }

}
