import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  modalRef: NgbModalRef;
  constructor(private _modalService: NgbModal) { }

  openModal(title: string, message: string, confirmationText: string,
            cancelText: string = 'No', entityName: string = ''): Observable<boolean> {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'alertModal'
    };

    this.modalRef = this._modalService.open(
      ConfirmationComponent,
      modalOptions
    );

    this.modalRef.componentInstance.title = title;
    this.modalRef.componentInstance.message = message;
    this.modalRef.componentInstance.confirmationText = confirmationText;
    this.modalRef.componentInstance.cancelText = cancelText;
    this.modalRef.componentInstance.entityName = entityName;

    return this.modalRef.componentInstance.confirmEvent;
  }

  closeModal() {
    this.modalRef.componentInstance.closeModal();
  }

  setLoading(loading: boolean): void {
    this.modalRef.componentInstance.setLoading(loading);
  }
}
