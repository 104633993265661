import { Component, OnInit, Inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthorisationService, UserProfile } from 'wre-authlib';
import { RolesConfig } from 'wre-core-lib';

@Component({
  selector: 'wre-home-welcome',
  templateUrl: './home-welcome.component.html',
  styleUrls: ['./home-welcome.component.sass']
})
export class HomeWelcomeComponent implements OnInit {

  user: UserProfile;
  searchAvailable: boolean;

  constructor(private authService: AuthorisationService, private rolesConfig: RolesConfig) { }

  async ngOnInit() {
    const checkUserHasRolesPromise = firstValueFrom(this.authService.checkUserHasPermissions(this.rolesConfig.searchExperience));
    const userPromise = firstValueFrom(this.authService.getUser());
    this.searchAvailable = await checkUserHasRolesPromise;
    this.user = await userPromise;
    console.log('Is External User : ', this.user.isExternal);
  }

}
