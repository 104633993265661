import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppInsightsService } from 'wre-core-lib';
import { RoadmapsService } from 'src/app/services/roadmaps.service';
import { throwError } from 'rxjs';

@Component({
  selector: 'wre-road-map-contents',
  templateUrl: './road-map-contents.component.html',
  styleUrls: ['./road-map-contents.component.scss', './road-map-contents.component.sass']
})
export class RoadMapContentsComponent implements OnInit {
  showCard = true;
  currentUrl: string;
  workspaceName: string;
  roadmapsData: any[];
  isloaderVisible = false;
  errorOccured = false;


  constructor(
    router: Router, private appInsightsService: AppInsightsService, private roadmapsService: RoadmapsService) {
    this.currentUrl = router.url;
  }

  ngOnInit() {
    this.workspaceName = this.currentUrl.substring(this.currentUrl.toString().lastIndexOf('/') + 1);
    this.getRoadmapsContents();
  }


  getRoadmapsContents() {
    this.isloaderVisible = true;
    this.errorOccured = false;
    this.appInsightsService.methodName = 'getRoadmapsContents';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    this.appInsightsService.trackTrace(
      this.appInsightsService.msgMethodStart
    );
    this.roadmapsService.getRoadmapsContents().subscribe(result => {
      const rdmapsData = result.body['workspaces'].filter(tr => tr.routerLink.toLowerCase() === this.workspaceName.toLowerCase())[0];
      if (rdmapsData !== undefined) {
        this.roadmapsData = (rdmapsData.experiences as { id: string }[]).sort((a, b) => +a.id - +b.id);
      }

      this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
      this.isloaderVisible = false;
    }, error => {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      this.errorOccured = true;
      throwError(error);
      this.isloaderVisible = false;
    },
      () => { this.isloaderVisible = false; });
  }
}
