import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthorisationGraphGroup, AuthorisationService, DirectoryDetail } from 'wre-authlib';
import { LookupUser } from '../../models/lookup-user';
import { User } from '../../models/user';

@Component({
  selector: 'wre-user-lookup-multiselect',
  templateUrl: './user-lookup-multiselect.component.html',
  styleUrls: ['./user-lookup-multiselect.component.sass'],
})
export class UserLookupMultiselectComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() inputId: string;
  @Input() placeholderValue = 'Search user...';
  @Input() allowOnlyInternalUsers = false;
  @Input() distributionListsIncluded = false;

  @Input() isInvalid: boolean;

  @Input() labelText: string;

  @Input() inputTabIndex: string;
  @Input() inputName: string;
  @Input() useFriendlyDisplayName: boolean;

  get name(): string {
    return this.inputName ? this.inputName : this.inputId;
  }

  get users() {
    return this.control.value;
  }

  dropdownKey: string;
  searchTerm = '';
  private subject: Subject<string> = new Subject();

  searchedUsers: DirectoryDetail[] | AuthorisationGraphGroup[] = [];
  selectedUsers: LookupUser[] = [];

  existingUsers: LookupUser[] = [];

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  @HostListener('keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.code === 'Tab' || (event.code === 'Tab' && event.shiftKey)) {
      if (this.dropdown.isOpen()) {
        this.dropdown.close();
      }
    }
  }

  isNewUser(user: LookupUser): boolean {
    return !this.existingUsers.includes(user);
  }

  constructor(private authService: AuthorisationService) {}

  ngOnInit() {
    this.existingUsers = [...this.control.value];
    this.dropdownKey = this.inputId + '-adUsersList';
    this.subject.pipe(debounceTime(200)).subscribe(() => {
      this.searchUsers();
    });
  }

  searchUsers() {
    const searchTerm = this.searchTerm.trim();
    if (searchTerm.length > 2) {
      const promises = [];
      promises.push(firstValueFrom(this.authService.getUsersStartsWith(searchTerm)));

      // Search also for email distribution lists
      if (this.distributionListsIncluded) {
        promises.push(firstValueFrom(this.authService.getUserGroupsStartsWith(searchTerm)));
      }

      Promise.all(promises).then((values) => {
        if (this.searchTerm) {
          const users = this.getValidUsers(values[0]);
          const allItems: DirectoryDetail[] | AuthorisationGraphGroup[] = users.concat(
            ...values.slice(1),
          );
          this.searchedUsers = allItems.sort((a, b) => a.displayName.localeCompare(b.displayName));
          if (this.searchedUsers.length === 0) {
            const user = new User();
            user.displayName = this.searchTerm;
            user.mail = 'SRT-namenotfound@gallagherre.com';
            this.searchedUsers.push(user);
          }
          this.dropdown.open();
        }
      });
    }
    return;
  }

  onBlur() {
    this.searchTerm = '';
    this.control.markAllAsTouched();
  }

  onKeyUp(event) {
    switch (event.key) {
      case 'Escape': {
        this.dropdown.close();
        break;
      }
      case 'ArrowDown': {
        this.selectItemInDropdownList(event.key);
        break;
      }
      case 'ArrowUp': {
        this.selectItemInDropdownList(event.key);
        break;
      }
      case 'Enter': {
        this.selectItemInDropdownList(event.key);
        break;
      }
      default: {
        this.subject.next(undefined);
        this.dropdown.close();
        break;
      }
    }
  }

  selectItemInDropdownList(eventKey: string) {
    const list = document.getElementById(this.inputId + '-adUsersList');
    const selected = list.querySelector('.selected');
    const element = document.getElementById(this.inputId);
    const scrollHeight = element === null ? 0 : element.getBoundingClientRect().height;
    if (selected) {
      switch (eventKey) {
        case 'ArrowUp': {
          const prevElement = selected.previousElementSibling;
          if (prevElement) {
            selected.classList.remove('selected');
            prevElement.classList.add('selected');
            list.scrollTo({ top: (list.scrollTop -= scrollHeight), behavior: 'smooth' });
          }
          break;
        }
        case 'ArrowDown': {
          const nextElement = selected.nextElementSibling;
          if (nextElement) {
            selected.classList.remove('selected');
            nextElement.classList.add('selected');
            list.scrollTo({ top: (list.scrollTop += scrollHeight), behavior: 'smooth' });
          }
          break;
        }
        case 'Enter': {
          (selected as HTMLElement).click();
        }
      }
    } else {
      const subjectToSelect = list.querySelector('a');
      if (subjectToSelect) {
        subjectToSelect.classList.add('selected');
      }
    }
  }

  selectUser(user: DirectoryDetail) {
    const displayName =
      !!user.givenName && this.useFriendlyDisplayName
        ? user.givenName + ' ' + user.surname
        : user.displayName;
    const lookupUser = new LookupUser(displayName, user.mail, user.id);
    const currentValue = this.control.value !== null ? this.control.value : [];

    if (
      currentValue.filter(
        (v) =>
          v.email !== 'SRT-namenotfound@willistowerswatson.com' && v.email === lookupUser.email,
      ).length === 0
    ) {
      currentValue.push(lookupUser);
      this.control.setValue(currentValue);
      this.control.markAsDirty();
    }

    this.searchTerm = '';
    this.dropdown.close();
    document.getElementById(this.inputId).focus();
  }

  removeItem(item: LookupUser) {
    if (item !== undefined) {
      const currentControlValue = this.control.value as LookupUser[];
      this.control.setValue(currentControlValue.filter((v) => v !== item));
      this.control.markAsDirty();
    }
  }

  private getValidUsers(users: DirectoryDetail[]): DirectoryDetail[] {
    const validCompanies = ['Gallagher Global Brokerage-Re', 'Home Office Division','GGB Re'];
    // Keep this in case if we want to add exceptions in future
    const exceptions = [];
    return users.filter(
      (u) =>
        (validCompanies.includes(u.companyName) && u.mail !== null) ||
        exceptions.includes(u.id) || // to include users not belonging to IRR
        /^tpsinttest\d+@gallagherre\.com$/i.test(u.userPrincipalName), // to include test accounts
    );
  }
}
