<div cdkDropList
     [cdkDropListDisabled]="orderDisabled"
     class="drag-and-drop-list container"
     (cdkDropListDropped)="dropItem($event)"
     cdkDropListLockAxis="y"
     #list
>
  <div *ngFor="let option of allOptions"
       cdkDrag
       class="row drag-and-drop-item"
       [class.new-option]="isNewOption(option)"
       [cdkDragBoundary]="list">
    <div *ngIf="!orderDisabled" class="col-auto pe-0" cdkDragHandle>
      <i class="far fa-bars reorder-icon"></i>
    </div>

    <div class="col" cdkDragHandle>
      {{ isUserType ? option.user.displayName : option.value }}
    </div>

    <div *ngIf="!option.isLocked"
         class="col-auto remove-icon"
         (click)="removeOption(option)"
         ngbTooltip="Remove option"
         container="body"
    >
      <i class="far fa-times"></i>
    </div>

    <div *ngIf="option.isLocked" class="col-auto lock-icon"
         ngbTooltip="Option cannot be removed" container="body"
    >
      <i class="far fa-lock-alt"></i>
    </div>
  </div>
</div>
