import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SecurityWorkspace } from '../models/security-workspace';
import { Applications, UserPermissions } from '../../../models/admin';
import { filter } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { SecurityManagementService } from './security-management.service';


/* Service to store global information about the current workspace of the Gateway Security */

@Injectable({
  providedIn: 'root'
})
export class SecurityWorkspaceService {

  private rolesWithPermissions = new BehaviorSubject(null);
  sharedRolesWithPermissions = this.rolesWithPermissions.asObservable();
  currentPermissions: string[] = [];
  private gatewayUrl = `${ environment.gatewayApiUrl }`;
  SECURITY_WORKSPACES: SecurityWorkspace[];

  constructor(private _securityManagementService: SecurityManagementService) {
  }

  private _currentWorkspace$ = new BehaviorSubject<SecurityWorkspace>(null);
  get currentWorkspace$(): Observable<SecurityWorkspace> {
    return this._currentWorkspace$.pipe(filter(workspace => workspace != null));
  }

  get currentWorkspace(): SecurityWorkspace {
    return this._currentWorkspace$.getValue();
  }

  private _authorizedWorkspaces$ = new BehaviorSubject<SecurityWorkspace[]>(null);
  get authorizedWorkspaces$(): Observable<SecurityWorkspace[]> {
    return this._authorizedWorkspaces$.asObservable();
  }

  setWorkspace(workspace: string) {
    const securityWorkspace = this.SECURITY_WORKSPACES.find(w => w.url.toLocaleLowerCase() === workspace.toLocaleLowerCase());
    if (securityWorkspace) {
      this._currentWorkspace$.next(securityWorkspace);
    }
  }

  findAuthorizedWorkspaces(roles: string[]) {
    this._securityManagementService.getApplications().then(apps => {
      this.SECURITY_WORKSPACES = apps.body;
      this.SECURITY_WORKSPACES = this.SECURITY_WORKSPACES.filter(w => w.isReady);

      const authorizedWorkspaces = this.SECURITY_WORKSPACES.filter(w => roles.includes(w.requiredPermission));

      const gatewayWorkspace = authorizedWorkspaces.find(w => w.applicationName === Applications.WreGateway);

      if (gatewayWorkspace !== undefined) {
        const index = authorizedWorkspaces.indexOf(gatewayWorkspace);
        authorizedWorkspaces.splice(index, 1);
        authorizedWorkspaces.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : -1);
        authorizedWorkspaces.splice(0, 0, gatewayWorkspace);
      } else {
        authorizedWorkspaces.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : -1);
      }

      this._authorizedWorkspaces$.next(authorizedWorkspaces);
    });
  }

  setRolesWithPermissions(rolesWithPermissions: string[]) {
    this.currentPermissions = rolesWithPermissions;
    this.rolesWithPermissions.next(rolesWithPermissions);
  }

  hasRequiredPermission(permission: string): boolean {
    const role = `${this.currentWorkspace?.permissionPrefix}.${permission}`;
    return this.currentPermissions.includes(role);
  }

  canViewAllReadOnly(): boolean {
    return  this.hasRequiredPermission(UserPermissions.RolesRead) &&
      !this.hasRequiredPermission(UserPermissions.RolesEdit) &&
      !this.hasRequiredPermission(UserPermissions.AuthorisedRolesEdit);
  }
}
