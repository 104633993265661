import { environmentBase, ReplaceEnvironmentWildcard } from './environment-base';

export const protectedResourceMap = new Map(
  [
    ...environmentBase.protectedResourceMap,
    // GATEWAY API
    ['https://gatewaydev.gallagherre.com/gatewayapi/api/', ['api://82851acc-fe29-478d-8c38-e3d688349580/accessuser']],
    ['https://gatewaydev.gallagherre.com/timesurveyapi/api', ['api://82851acc-fe29-478d-8c38-e3d688349580/accessuser']],
    // REPORTING API
    ['https://reportingapidev.gallagherre.com/api/usergroups', ['Directory.Read.All']],
    ['https://reportingapidev.gallagherre.com/api/', ['api://6e6b5691-2ab4-4ad1-95bb-4264260b2933/accessuser']],
    // AUTHORISATION API
    ['https://authorizationapidev.gallagherre.com/api/', ['api://35a3b1d2-e4fe-45f6-a8d2-f6e8c2fdc48f/user_impersonation']],
    // SAFR API
    ['https://safrapi.dev.ajgco.com/api/usergroups/', ['Directory.Read.all']],
    ['https://safrapi.dev.ajgco.com/api/', ['api://0db9b7a7-fbcd-4655-829a-86e67afa7c58/GallagherRe-SAFR-Api-Dev']],
    // SANCTIONS API
    ['https://sanctionsidentificationapi.dev.ajgco.com/api/', ['api://96f6d4d9-2146-4c0a-a706-c01cbdb54147/GallagherRe-Sanction-Api-Dev']],
    // SANCTIONS REFERRAL API
    ['https://sanctionsreferralapi.dev.ajgco.com/api/', ['api://97c200b4-e6f5-48b1-8ee9-bfdf7e49551f/GallagherRe-SanctionReferral-Api-Dev']]
  ]);


export const b2cProtectedResourceMap = new Map([
  // GATEWAY API
  ['https://gatewaydev.gallagherre.com/gatewayapi/api/', ['https://ajgb2c.onmicrosoft.com/1b747f3b-a7c1-4514-9e74-f8ee3da048d8/user_impersonation']],
  ['https://gatewaydev.gallagherre.com/timesurveyapi/api', ['https://ajgb2c.onmicrosoft.com/1b747f3b-a7c1-4514-9e74-f8ee3da048d8/user_impersonation']],
  // REPORTING API
  ['https://reportingapidev.gallagherre.com/api/', ['https://ajgb2c.onmicrosoft.com/1b747f3b-a7c1-4514-9e74-f8ee3da048d8/user_impersonation']],
  // AUTHORISATION API
  ['https://authorizationapidev.gallagherre.com/api/', ['https://ajgb2c.onmicrosoft.com/1b747f3b-a7c1-4514-9e74-f8ee3da048d8/user_impersonation']]
]);

export const environment = {
  ...environmentBase,
  environment: 'Dev',
  production: false,

  // APP SETTINGS
  clientId: '970c4c3b-d855-4541-bf2c-af16361e02a1',
  rolesCacheSeconds: 60,
  authenticationCacheSeconds: 60,
  protectedResourceMap: protectedResourceMap,
  // 3rd PARTY CONFIG
  appInsightsIntrumentationKey: 'c12e122d-dc67-4973-848e-52debd642dd6',
  gtmScriptRelativePath: '../../assets/js/googleTagManager.js',
  googleTagManagerIframeSrc: 'https://www.googletagmanager.com/ns.html?id=GTM-NHDM375&gtm_auth=d03_-lQGQTg_m_slo98syA&gtm_preview=env-3&gtm_cookies_win=x',
  b2cClientId: '7d69aa3a-f0da-4302-96c7-13433c3d4b9c',
  b2cProtectedResourceMap: b2cProtectedResourceMap
};

ReplaceEnvironmentWildcard(environment, environment.environment.toLowerCase());
