<div class="d-flex align-items-center" >
  <label class="form-control-label mb-0 me-2" *ngIf="label" for="securityGroup">{{label}}</label>
  <div class="dropdown w-100">
    <input type="text"
           class="form-control"
           id="securityGroup"
           style="width: 100%;"
           name="securityGroup"
           autocomplete="off"
           [(ngModel)]="searchTerm"
           (keyup)="onKeyUp($event)"
           [placeholder]="placeholder"
    >
    <div id="adGroupList"
         class="dropdown-menu shadow"
         [class.show]="displayDropdown"
         style="min-height: 40px; max-height: 200px; overflow: auto;">
      <a *ngFor="let role of searchedRoles"
         class="dropdown-item"
         (click)="selectRole(role)">
        {{ role.roleName }}
      </a>
      <a *ngIf="searchedRoles.length === 0"
         class="dropdown-item" style="pointer-events: none;" tabindex="-1">
        No role found for search term : <b>"{{ searchTerm }}"</b>
      </a>
    </div>
  </div>
</div>
