import { confirmationMessages, confirmationTexts, confirmationTitles } from './../../../shared/enums/confirmation';
import { ConfirmationService } from './../../../shared/services/confirmation.service';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { Applications } from '../../../models/admin';
import { AppFormService } from '../services/app-form.service';
import { App } from '../../../models/app';
import { HttpErrorResponse } from '@angular/common/http';
import { AppCategoriesService } from '../services/app-categories.service';
import { AppsService } from '../services/apps.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wre-apps-create-update',
  templateUrl: './apps-create-update.component.html',
  styleUrls: ['./apps-create-update.component.sass']
})
export class AdminAppsCreateUpdateComponent implements OnInit, OnDestroy {

  @Input() appId: number;

  @Output() refreshAppData = new EventEmitter();

  appForm: UntypedFormGroup;
  app: App;
  newApp: App;
  oldApp: App;
  isLoaderVisible = false;
  errorMessage: string;
  title: string;
  buttonAction: string;
  subscription: Subscription;
  gatewayApplication = Applications.WreGateway;

  get isEditMode() {
    return this.appId != null;
  }

  constructor (
    public activeModal: NgbActiveModal,
    private adminService: AdminService,
    private _confirmationService: ConfirmationService,
    private appFormService: AppFormService,
    public appCategoriesService: AppCategoriesService,
    private appsService: AppsService) {
  }

  ngOnInit(): void {
    this.appCategoriesService.loadAppCategories();
    this.appForm = this.appFormService.createAppFormGroup();
    if (this.isEditMode) {
      this.title = 'Update Tool';
      this.buttonAction = 'Update';
      this.getAppData();
    } else {
      this.buttonAction = 'Add';
      this.title = 'New Tool';
    }
  }

  getAppData(): void {
    this.isLoaderVisible = true;
    this.resetError();
    this.adminService.getAppData(this.appId).subscribe(
      response => {
        this.app = Object.assign(new App(), response.body);
        this.oldApp = Object.assign(new App(), response.body);
        this.appFormService.assignAppToFormGroup(this.app, this.appForm);
        this.isLoaderVisible = false;
    }, () => {
        this.errorMessage = 'Error has occurred while retrieving an app';
        this.isLoaderVisible = false;
    });
  }

  onSubmit(): void {
    if (this.appForm.invalid) {
      return;
    }
    this.newApp = new App();
    this.appFormService.assignFormGroupToApp(this.appForm, this.newApp);

    this.isLoaderVisible = true;
    this.resetError();

    if (this.isEditMode) {
      this.newApp.id = this.appId;
      this.appsService.editApp(this.newApp).subscribe(this.handleSuccess, this.handleError);
    } else {
      this.appsService.addApp(this.newApp).subscribe(this.handleSuccess, this.handleError);
    }
  }

  confirmClose() {
    this.newApp = new App();
    this.newApp.id = this.appId;
    this.appFormService.assignFormGroupToApp(this.appForm, this.newApp);
    if ((this.isEditMode && this.newApp.equal(this.oldApp)) || (!this.isEditMode && !this.appForm.valid)) {
      this.closeModal();
      return;
    }

    this.subscription = this._confirmationService.openModal(confirmationTitles.defaultTitle, confirmationMessages.defaultMessage, confirmationTexts.defaultConfirmationText)
      .subscribe((close: boolean) => {
        if (close) {
          this._confirmationService.closeModal();
          this.closeModal();
        }
      });
  }

  private closeModal() {
    this.activeModal.close();
  }

  resetError() {
    this.errorMessage = null;
  }

  private handleSuccess = () => {
    this.closeModal();
    this.isLoaderVisible = false;
  }

  private handleError = (error: HttpErrorResponse) => {
    if (error.status === 409) {
      this.errorMessage = 'Tool with the name ' + this.newApp.name + ' already exists in the system.';
    } else {
      this.errorMessage = `Error has occurred while ${this.isEditMode ? 'updating' : 'adding'} an app`;
    }
    this.isLoaderVisible = false;
  }

  ngOnDestroy() { this.subscription?.unsubscribe(); }
}
