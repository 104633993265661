<div class="slipcase-item-cont">
    <div class="row">
        <div class="col-3 d-flex align-items-center" *ngIf="newsItem.featured_image_url !== undefined">
            <a [attr.href]="newsItem.web_url" rel="noopener" target="_blank" (mouseup)="newsItemClicked($event)" class="news-feed-featured-img">
                <picture>
                    <img [attr.src]="newsItem.featured_image_url" [attr.alt]="newsItem.heading" [attr.title]="newsItem.heading" onerror="this.style.display='none'" />
                </picture>
            </a>
        </div>
        <div class="col d-flex align-items-center">
            <div [ngClass]="{'pt-0 pb-0': !isMostPopular, 'pt-0 pb-0 most-popular': isMostPopular}" class="">
                <h5 class="pb-2 mb-0"><i *ngIf="newsItem.paywalled" class="pe-2 far fa-lock padlock"></i>
                    <a [attr.href]="newsItem.web_url" rel="noopener" target="_blank" (mouseup)="newsItemClicked($event)">
              {{newsItem.heading}}
            </a>
                </h5>
                <p class="pb-2 mb-0">
                    {{newsItem.excerpt}}
                </p>
                <div *ngIf="showTopics || showItemHostname || showPublishDate" class="mb-0 pb-0">
                    <div *ngIf="showTopics" class="topics form-control-label pb-1">
                        <ng-container *ngFor="let topic of newsItem.topics; let last=last">
                            <span>{{topic.name}}</span>
                            <span *ngIf="!last">; </span>
                        </ng-container>
                    </div>
                    <div *ngIf="showItemHostname">
                        <a [href]="newsItem.web_url" class="source" style="text-decoration: none;" rel="noopener" target="_blank" (mouseup)="newsItemClicked($event)">
                {{hostnameUrl}}
              </a>
                    </div>
                    <div *ngIf="showPublishDate" class="date"> {{ getDate(newsItem.date) | timeAgo }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
