<wre-toolkit-modal-popup [title]="title" (closeClicked)="confirmClose()" [preventDefaultClose]="true">
    <wre-toolkit-loader *ngIf="isLoaderVisible"></wre-toolkit-loader>

    <div *ngIf="errorMessage && !isLoaderVisible" class="mb-3 pb-3 border-bottom">
        <div class="alert alert-danger">
            <i class="fas fa-exclamation pe-2"></i>
            {{ errorMessage }}
            <i class="far fa-times ms-auto" (click)="resetError()"></i>
        </div>
    </div>

    <form id="appForm" *ngIf="!isLoaderVisible" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-12 col-lg-6">
                <wre-toolkit-text-field class="w-100" labelText="Name" inputId="name" [control]="appForm.get('name')">
                </wre-toolkit-text-field>
                <wre-toolkit-validation-error-message [control]="appForm.get('name')"
                    errorName="required" message="Please enter tool name.">
                </wre-toolkit-validation-error-message>
            </div>
            <div class="col-12 col-lg-6 mt-3 mt-lg-0">
                <wre-toolkit-text-field class="w-100"
                                        labelText="Link"
                                        inputId="url"
                                        placeholder="https://"
                                        [control]="appForm.get('url')">
                </wre-toolkit-text-field>
                <wre-toolkit-validation-error-message [control]="appForm.get('url')" errorName="pattern" message="Please enter valid URL.">
                </wre-toolkit-validation-error-message>
                <wre-toolkit-validation-error-message [control]="appForm.get('url')" errorName="required" message="Please provide an URL.">
                </wre-toolkit-validation-error-message>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12 col-lg-6">
                <label for="appsCategoryId" class="form-control-label">Workspaces</label>
                <select class="form-control w-100" id="appsCategoryId" [formControl]="appForm.get('appsCategoryId')">
                    <option [ngValue]="null" disabled selected hidden>
                        {{ (appCategoriesService.appCategories$ | async)?.length <= 0 ? 'Loading Workspaces...' : 'Select Workspace' }}
                    </option>
                    <option *ngFor="let workspace of (appCategoriesService.appCategories$ | async)" [value]="workspace.id">{{ workspace.name }}
                    </option>
                </select>
                <wre-toolkit-validation-error-message [control]="appForm.get('appsCategoryId')"
                    errorName="required" message="Please select workspace.">
                </wre-toolkit-validation-error-message>
            </div>
            <div class="col-12 col-lg-6 mt-3 mt-lg-0">
                <wre-toolkit-number-field
                    labelText="Order No"
                    (keydown.e)="$event.preventDefault()"
                    inputId="order-no"
                    [min]="1"
                    [step]="1"
                    [control]="appForm.get('orderNo')"
                >
                </wre-toolkit-number-field>
                <wre-toolkit-validation-error-message [control]="appForm.get('orderNo')"
                                                      errorName="required" message="Please provide order number.">
                </wre-toolkit-validation-error-message>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-lg-auto">
                <div class="row">
                    <div class="col-12 col-sm-4 col-lg-auto">
                        <wre-toolkit-toggle-switch name="isActive" labelText="Active" [control]="appForm.get('isActive')">
                        </wre-toolkit-toggle-switch>
                    </div>
                    <div class="col-12 col-sm-8 col-lg-auto mt-3 mt-sm-0">
                        <wre-toolkit-toggle-switch name="isNew" labelText="Is New Tool" [control]="appForm.get('isNew')">
                        </wre-toolkit-toggle-switch>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-auto mt-3 mt-lg-0">
                <div class="row">
                    <div class="col-12 col-sm-4 col-lg-auto">
                        <wre-toolkit-toggle-switch name="isTool" labelText="Is Tool" [control]="appForm.get('isTool')">
                        </wre-toolkit-toggle-switch>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-auto mt-3 mt-sm-0">
                        <wre-toolkit-toggle-switch name="openInNewTab" labelText="Open in new tab" [control]="appForm.get('openInNewTab')">
                        </wre-toolkit-toggle-switch>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-auto mt-3 mt-sm-0">
                      <wre-toolkit-toggle-switch name="isAvailableOffVpn" labelText="Is Available Off VPN" [control]="appForm.get('isAvailableOffVpn')">
                      </wre-toolkit-toggle-switch>
                  </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col">
                <wre-security-role-form [securityRolesForm]="appForm.get('securityRoles')"
                                        [application]="gatewayApplication"
                                        [displayDropdownLabel]="true"
                >
                </wre-security-role-form>
            </div>
        </div>
    </form>

    <div footer>
        <div class="row">
            <div class="col-sm-12 d-flex justify-content-end">
                <button type="submit"
                        form="appForm"
                        class="btn wre-btn btn-primary ms-3"
                        [disabled]="appForm.invalid || isLoaderVisible"
                >
                    {{ buttonAction }}
                </button>
            </div>
        </div>
    </div>
</wre-toolkit-modal-popup>
