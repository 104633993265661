import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminFormService } from '../../admin-shared/services/admin-form.service';
import { TourStepData } from '../../../models/admin';

@Injectable({
  providedIn: 'root'
})
export class TourStepFormService {

  constructor(private fb: UntypedFormBuilder,
              private adminFormService: AdminFormService) { }

  /** Create empty FormGroup for Tour Step */
  createTourStepFormGroup(): UntypedFormGroup {
    return this.fb.group({
      appName: ['', [Validators.required, this.adminFormService.noWhitespaceValidator, Validators.maxLength(200)]],
      routeName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^\/\S*$/i)]],
      anchorId: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^[\w.-]+\.anchor$/i)]],
      isMobile: [null, Validators.required],
      title: ['', [Validators.required, this.adminFormService.noWhitespaceValidator,
        Validators.maxLength(75), Validators.pattern(/^[-\w ]+$/i)]],
      stepId: ['', [Validators.required, Validators.min(1)]],
      content: ['', [Validators.required, this.adminFormService.noWhitespaceValidator]],
      isActive: [true, Validators.required]
    });
  }

  /** Assign tour step properties to form group */
  assignTourStepToFormGroup(tourStep: TourStepData, tourStepForm: UntypedFormGroup): void {
    tourStepForm.patchValue(tourStep);
  }

  /** Assign data from form group to tour step object */
  assignFormGroupToFormStep(tourStepForm: UntypedFormGroup, tourStep: TourStepData): void {
    Object.assign(tourStep, tourStepForm.value);
    tourStep.appName = tourStep.appName.trim();
    tourStep.routeName = tourStep.routeName.trim();
    tourStep.anchorId = tourStep.anchorId.trim();
    tourStep.title = tourStep.title.trim();
    tourStep.content = tourStep.content.trim();
  }

}
