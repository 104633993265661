import { Component, OnInit, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { AppInsightsService } from 'wre-core-lib';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SlipcaseFeedComponent } from 'src/app/news/slipcase-feed/slipcase-feed.component';


@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: [
    './home-dashboard.component.css',
    './home-dashboard.component.sass'
  ]
})
export class HomeDashboardComponent implements OnInit, AfterViewInit {

  @Output() setTitle: EventEmitter<any> = new EventEmitter();

  notifications = [];

  @ViewChild(SlipcaseFeedComponent) slipcaseFeedComponent: SlipcaseFeedComponent;


  slipcaseAvailable = true;


  constructor(
    private appInsightsService: AppInsightsService,
    private notificationsService: NotificationsService,
  ) {
    this.appInsightsService.componentName = 'HomeDashboardComponent';
  }

  async ngAfterViewInit(): Promise<void> {
    const slipcaseFeedPrimse = this.slipcaseFeedComponent.checkRoles();
    const notificationsPromse = this.notificationsService.getNotifications().toPromise();
    this.slipcaseAvailable = await slipcaseFeedPrimse;
    const nostificationsResponse = await notificationsPromse;
    this.notifications.push(...nostificationsResponse.body);
  }

  async ngOnInit() {

    this.setTitle.emit('Gallagher Re Gateway');
    this.appInsightsService.methodName = 'ngOnInit';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    this.appInsightsService.trackTrace(
      this.appInsightsService.msgMethodsuccess
    );
  }
}
