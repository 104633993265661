import { Component, Input, OnInit } from '@angular/core';
import { PermissionGroup } from '../../models/permission-group';
import { PermissionsListService } from '../../services/permissions-list.service';
import { Observable } from 'rxjs';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { PermissionType } from '../../models/permission-type';

@Component({
  selector: 'wre-role-permissions-step',
  templateUrl: './role-permissions-step.component.html',
  styleUrls: ['./role-permissions-step.component.sass']
})
export class RolePermissionsStepComponent implements OnInit {

  availablePermissions$: Observable<PermissionGroup[]>;

  PermissionType = PermissionType;
  selectedType = PermissionType.Security;

  @Input() permissionsArray = new UntypedFormArray([]);
  @Input() hasEnablePermissions = false;
  get selectedPermissions(): PermissionGroup[] {
    return this.permissionsArray.value as PermissionGroup[];
  }

  get isDisabled(): boolean {
    return !this.hasEnablePermissions || this.permissionsArray.disabled;
  }

  get allSelected(): boolean {
    return this.selectedPermissions.filter(p => p.type === this.selectedType).length ===
      this.permissionsListService.basePermissions.filter(p => p.type === this.selectedType).length;
  }

  get noneSelected(): boolean {
    return this.selectedPermissions.filter(p => p.type === this.selectedType).length === 0;
  }

  constructor(private permissionsListService: PermissionsListService) { }

  ngOnInit() {
    this.availablePermissions$ = this.permissionsListService.basePermissions$;
  }

  togglePermission(permission: PermissionGroup) {
    if (!this.isDisabled) {
      if (this.isSelected(permission)) {
        this.removePermission(permission);
      } else {
        this.addPermission(permission);
      }
    }
  }

  findIndex(id: number): number {
    return this.permissionsArray.controls.findIndex(p => (p.value as PermissionGroup).id === id);
  }

  isSelected(permission: PermissionGroup): boolean {
    return this.findIndex(permission.id) >= 0;
  }

  selectAll() {
    if (!this.isDisabled) {
      if (this.allSelected) { return; }
      this.permissionsListService.basePermissions.filter(p => p.type === this.selectedType).forEach(p => {
        if (!this.isSelected(p)) {
          this.addPermission(p);
        }
      });
    }
  }

  deselectAll() {
    if (!this.isDisabled) {
      if (this.noneSelected) { return; }
      this.selectedPermissions.filter(p => p.type === this.selectedType).forEach(p => this.removePermission(p));
    }
  }

  selectType(type: PermissionType) {
    this.selectedType = type;
  }

  private addPermission(permission: PermissionGroup) {
    this.permissionsArray.push(new UntypedFormControl(permission));
  }

  private removePermission(permission: PermissionGroup) {
    this.permissionsArray.removeAt(this.findIndex(permission.id));
  }

}
