<div id="core-steps" class="wtw-toolkit-slideout" [class.open]="this.isOpenCoreSteps" [ngStyle]="this.isOpenCoreSteps ? { 'width.px': 600 } : { 'width.px': 0 }">
    <div class="slideout-header d-flex align-items-center">
        <h3 class="slideout-title">Core Steps</h3>
        <div class="slideout-options ms-auto">
            <button (click)="this.closeCoreSteps()" type="button" class="btn btn-icon">
                <i class="fal fa-times"></i>
            </button>
        </div>
    </div>
    <div class="slideout-body overflow-auto">
        <ng-container *ngFor="let card of cards; let i = index">
            <div class="card mb-3">
                <div data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseCard_'+i" aria-expanded="true" aria-controls="collapseExample" class="btn-toolbar align-items-center" role="toolbar" aria-label="Toolbar Actions">
                    <div class="btn-group card-header d-flex align-items-center w-100">
                        <h4 class="card-title"><a>{{card.title}}</a></h4>
                        <div class="card-options ms-auto me-2">
                            <i class="far fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
                <div class="collapse show" [attr.id]="'collapseCard_'+i">
                    <div class="card-body">
                        <ul>
                            <ng-container *ngFor="let coreStep of card.coreSteps">
                                <li><a [routerLink]="card.route" fragment="{{this.getValidId(coreStep)}}">{{coreStep}}</a></li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
