import { Component, OnInit } from '@angular/core';
import { FieldOrderType } from '../../models/field-order-type';
import { ReferralsFieldManagementService } from '../../services/referrals-field-management.service';

@Component({
  selector: 'wre-referrals-fields',
  templateUrl: './referrals-fields.component.html',
  styleUrls: ['./referrals-fields.component.sass']
})
export class ReferralsFieldsComponent implements OnInit {

  offset = '0';
  errorMessageInfo: string = '';

  FieldOrderType = FieldOrderType;

  get isLoading() { return this._referralsFieldManagementService.loading$; }
  get fields() { return this._referralsFieldManagementService.field$; }

  constructor(private _referralsFieldManagementService: ReferralsFieldManagementService) { }

  ngOnInit(): void {
    const referralType = this._referralsFieldManagementService.currentReferralType;
    this._referralsFieldManagementService.loadFields(referralType);
  }
}
