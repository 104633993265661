<ng-container *ngIf="componentAvailableSub.getValue() === true">
    <ng-container *ngIf="errorOccured === false">
        <div style="height:100px;" *ngIf="isloaderVisible" class="d-flex align-items-center justify-content-center">
            <div class="loading"></div>
        </div>
        <div class="container-fluid px-0">
            <div class="row" *ngFor="let newsItem of newsData; let first=first; let last=last" [ngClass]="{ 'border-top': !first }">
                <div class="col">
                    <app-slipcase-item-content [showItemHostname]="showItemHostname" [showPublishDate]="showPublishDate" [showTopics]="showTopics" [newsItem]="newsItem" [isCompact]="true" [isMostPopular]="useInputFeed"></app-slipcase-item-content>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="errorOccured === true">
        <div class="container-fluid px-0 pt-4">
            <div class="row">
                <div class="col">
                    <wre-not-available serviceName="Slipcase" [errorOccured]="errorOccured"></wre-not-available>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>