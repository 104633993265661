import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { AppInsightsService } from 'wre-core-lib';
import { Subscription, throwError } from 'rxjs';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminDeleteComponent } from '../../admin/delete/delete.component';
import { ReportsCreateUpdateComponent } from '../reports-create-update/reports-create-update.component';
import { Report, PowerBiConfig, UserPermissions } from 'src/app/models/admin';
import { ToggleService } from '../../../services/toggle.service';
import { ReportType, ReportTypeUtils } from '../models/report-type.enum';
import { AdminDataRefreshService } from '../../admin/services/admin-data-refresh.service';
import { filter } from 'rxjs/operators';
import { RefreshDataType, TabTitles } from '../../admin/models/refresh-data-type';
import { InfoModalComponent } from '../../admin/info-modal/info-modal.component';
import { ReportingService } from '../services/reporting.service';
import { SecurityWorkspaceService } from '../../security-admin/services/security-workspace.service';
import { SecurityRole } from '../../../models/security-role';

@Component({
  selector: 'wre-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss', './reports.component.sass']
})
export class ReportsComponent implements OnInit, OnDestroy {
  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  @Output() setExperienceTitle: EventEmitter<any> = new EventEmitter();
  errorMessage: any;
  reports: Report[];
  isloaderVisible = false;
  report: any;
  offset = '0';
  reportData: Report;
  reportId: number;
  isConfirmModal = true;
  isDeleteConfirm = false;
  isViewSummary = false;
  deleteReportMessage_h5: string;
  deleteReportMessage_p: string;
  margin = 321;
  tableHeight = window.innerHeight - this.margin;
  showInActiveReports = false;
  reportName: string;
  showDeleteAlert = false;
  deleteConflict = false;
  isSearchAvailable = true;
  searchResultReports: Report[];
  termSearched: string;
  searchedTerm: string;

  private dataRefreshSubscription: Subscription;

  ReportType = ReportType;

  @Input() set refreshReportData(value: boolean) {
    if (value === true) {
      this.getReports();
    }
  }

  get reportsList() { return this.searchResultReports; }
  get noSearchResult(): boolean { return this.termSearched?.length > 0 && this.reportsList?.length === 0; }

  roles: string[] = [];
  hasEditPermission: boolean = false;
  hasDeletePermission: boolean = false;

  constructor(private appInsightsService: AppInsightsService,
    private reportingService: ReportingService,
    private adminDataRefreshService: AdminDataRefreshService,
    private modalService: NgbModal,
    private toggleService: ToggleService,
    private securityWorkspaceService: SecurityWorkspaceService) {
    this.roles = this.securityWorkspaceService.currentPermissions;
    this.hasEditPermission = this.hasPermission(UserPermissions.InteractiveReportsReportsEdit);
    this.hasDeletePermission = this.hasPermission(UserPermissions.InteractiveReportsReportsDelete);
  }

  hasPermission(permission: UserPermissions): boolean {
    return this.roles.includes(permission);
  }

  onDelete(id: number, name: string) {
    this.isConfirmModal = true;
    this.reportId = id;
    this.reportName = name;
    this.open(false);
  }

  open(successInfo: boolean) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(
      AdminDeleteComponent,
      modalOptions
    );

    this.deleteReportMessage_h5 = 'Are you sure you want to delete report';
    this.deleteReportMessage_p = 'This cannot be undone.';

    modalRef.componentInstance.isConfirmModal = this.isConfirmModal;

    if (successInfo === false) {
      modalRef.componentInstance.entityName = this.reportName;
    } else {
      modalRef.componentInstance.entityName = '';
    }

    modalRef.componentInstance.deleteEntityMessage_h5 = this.deleteReportMessage_h5;
    modalRef.componentInstance.deleteEntityMessage_p = this.deleteReportMessage_p;

    modalRef.componentInstance.deleteConfirmedChange.subscribe(($e) => {
      this.isDeleteConfirm = $e;
      if (this.isDeleteConfirm === true) {
        this.deleteReport(this.reportId, this.reportName);
      }
    });
  }

  async deleteReport(id: number, name: string) {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'deleteReport';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.showDeleteAlert = false;
      this.deleteConflict = false;
      (await this.reportingService.deletePowerBiReport(id)).subscribe(httpResponse => {

        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);

        this.deleteReportMessage_h5 = 'Report Info';
        this.deleteReportMessage_p = this.reportName + ' deleted successfully.';
        this.getReports();
        this.isConfirmModal = false;
        this.reportName = '';
        this.isloaderVisible = false;
      }, error => {
        this.isloaderVisible = false;
        this.appInsightsService.trackException(error);
        this.isloaderVisible = false;
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  ngOnInit() {
    this.setExperienceTitle.emit(TabTitles.Reports);
    this.setTitle.emit('Gallagher Re Gateway');
    this.getReports();
    this.toggleService.currentMessage.subscribe(value => this.showInActiveReports = value);
    window.onresize = (e) => {
      if (this.tableHeight !== window.innerHeight - this.margin) {
        this.resizeTable();
      }
    };

    this.dataRefreshSubscription = this.adminDataRefreshService.refreshData$
      .pipe(filter(r => r === RefreshDataType.ReportingReports))
      .subscribe(() => this.getReports());
  }

  ngOnDestroy() {
    this.dataRefreshSubscription.unsubscribe();
  }

  resizeTable() {
    this.tableHeight = window.innerHeight - this.margin;
  }

  private buildReportPostObject(report: Report) {
    this.reportData = new Report();

    this.reportData.id = report.id;
    this.reportData.name = report.name;
    this.reportData.summary = report.summary;
    this.reportData.link = report.link;
    this.reportData.areaId = report.areaId;
    this.reportData.modelId = report.modelId;
    this.reportData.isActive = !report.isActive;
    this.reportData.type = report.type;

    if (report.type === ReportType.PowerBI) {
      this.reportData.powerBiConfig = new PowerBiConfig(
        report.powerBiConfig.groupExternalGuid,
        report.powerBiConfig.reportExternalGuid,
        report.powerBiConfig.defaultSection,
        report.powerBiConfig.sharedBookmarksVisible,
        report.powerBiConfig.rowLevelSecurity,
        report.powerBiConfig.rlsRoles);
    }

    this.reportData.permissions = [];
    report.permissions.forEach(element => {
      this.reportData.permissions.push(new SecurityRole(element.guid, element.name));
    });
  }

  async getReports() {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'getReports';
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.reportingService.getReports().then(reports => {
        this.reports = reports;
        this.isloaderVisible = false;
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
        this.applySearch(this.searchedTerm);
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  async editReportStatus(report: Report) {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'editReport';
    try {
      this.appInsightsService.trackTrace(this.appInsightsService.msgMethodStart);
      this.buildReportPostObject(report);
      await this.reportingService.editReport(this.reportData);
      this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
      this.getReports();
    } catch (error) {
      this.appInsightsService.trackException(error);
      throwError(error);
    }
    this.isloaderVisible = false;
  }

  onEdit(report: any) {
    this.report = report;
    this.editReport(this.report);
  }

  onStatusChange(report: any) {
    this.report = report;
    this.editReportStatus(this.report);
  }

  onViewSummary(viewSummaryTitle: string, viewSummaryData: string) {
    this.isViewSummary = true;
    const modalOptions: NgbModalOptions = {
      centered: true
    };

    modalOptions.size = 'sm';
    const modalRef = this.modalService.open(
      InfoModalComponent,
      modalOptions
    );
    (modalRef.componentInstance as InfoModalComponent).title = viewSummaryTitle;
    (modalRef.componentInstance as InfoModalComponent).message = viewSummaryData;
  }

  public editReport(report: any) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true
    };

    const modalRef = this.modalService.open(
      ReportsCreateUpdateComponent,
      modalOptions
    );

    modalRef.componentInstance.report = this.report;

    modalRef.componentInstance.refreshReportData.subscribe(($e) => {
      this.refreshReportData = $e;
      if (this.refreshReportData === true) {
        this.getReports();
      }
    });
  }

  getTypeLabel(type: ReportType): string {
    return ReportTypeUtils.toString(type);
  }

  applySearch(search?: string) {

    if (!search || search.trim().length === 0) {
      this.termSearched = '';
      this.searchResultReports = this.reports;
    }

    if (this.isSearchTermValid(search)) {
      this.termSearched = search.trim().toLowerCase();
      this.searchResultReports = this.reports.filter((record) => {
        const reports = record as Report;
        return reports.area.name.toLowerCase().includes(this.termSearched) ||
          reports.name.toLowerCase().includes(this.termSearched) ||
          this.getTypeLabel(reports.type).toLowerCase().includes(this.termSearched) ||
          reports.permissions.map(p => p.name).toString().toLowerCase().includes(this.termSearched)
      });
    }
  }

  private isSearchTermValid(searchTerm: string): boolean {
    return !!searchTerm && searchTerm.trim().length > 1;
  }

}
