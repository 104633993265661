import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListService } from 'src/app/services/base-list.service';
import { ServicePrincipal } from '../models/user-group';
import { SecurityManagementService } from './security-management.service';

@Injectable({
    providedIn: 'root'
})

export class ServicePrincipalsListService extends BaseListService<ServicePrincipal> {

    constructor(private securityService: SecurityManagementService) {
        super();
    }

    matches(item: ServicePrincipal, term: string) {
        return item.mail?.toLowerCase().includes(term.toLowerCase())
            || item.surname.toLowerCase().includes(term.toLowerCase());
    }

    async getAllItems(): Promise<ServicePrincipal[] | HttpResponse<ServicePrincipal[]>> {
        const users = await this.securityService.getUsers();
        return users.body.filter(f => f.type === 'Service Principle') as ServicePrincipal[];

    }

    async load() {
        this.loadData(null,
            { sortColumn: 'surname', pageSize: this.pageSize });
    }



}

