import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    url = environment.gatewayApiUrl + 'notifications/';

    constructor(private http: HttpClient) { }

    getNotifications(): Observable<HttpResponse<any[]>> {
        return this.http.get<any>(this.url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }


    handleRetry(errors: Observable<HttpResponse<any>>) {
        return errors
            .pipe(mergeMap((error: HttpResponse<any>) => {
                if (error.status !== undefined && error.status.toString().indexOf('4') === 0) {
                    // All 4xx status codes we do not retry
                    return throwError(error);
                } else {
                    return of(error).pipe(delay(1000)).pipe(take(2));
                }
            }));
    }

}
