<div class="modal-header d-flex align-items-center">
  <h5 class="modal-title mb-0">
    {{ title }}
  </h5>
  <div class="modal-options">
      <button class="btn btn-icon" type="button" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()" tabindex="-1">
        <i class="fal fa-times"></i>
      </button>
  </div>
</div>

<form novalidate #newSecurityGroupForm="ngForm" (ngSubmit)="onAdd(newSecurityGroupForm)">
  <div *ngIf="this.isloaderVisible">
    <div class="d-flex align-items-center justify-content-center" style="height: 70px;">
      <div class="loading"></div>
    </div>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-md-12 col-lg-6">
        <label class="form-control-label" for="name">Name</label>
          <input type="text" class="form-control" style="width: 100%;" id="name" name="name" [(ngModel)]="newSecurityGroup.name" required (change)="newSecurityGroup.name=newSecurityGroup.name.trim()"
            #name="ngModel"
            [ngClass]="{'invalid': ((name.touched || name.dirty) && !name.valid) || (!name.valid && newSecurityGroupForm.submitted) }" style="width:100%">
          <span class="invalid-feedback">
            <span *ngIf="name.errors?.required">
              Please enter Security Group name.
            </span>
          </span>
      </div>
      <div class="col-md-12 col-lg-6 mt-md-3 mt-lg-0">
        <label class="form-control-label" for="domain">Domain</label>
            <input type="text" class="form-control" style="width: 100%;" id="domain" name="domain" [(ngModel)]="newSecurityGroup.domain" (change)="newSecurityGroup.domain=newSecurityGroup.domain.trim()"
              required #domain="ngModel"
              [ngClass]="{'is-invalid': ((domain.touched || domain.dirty) && !domain.valid) || (!domain.valid && newSecurityGroupForm.submitted) }" style="width:100%">
            <span class="invalid-feedback">
              <span *ngIf="domain.errors?.required">
                Please enter Domain.
              </span>
            </span>
      </div>
    </div>
    <div class="form-group">
      <div class="col-12">
        <div [hidden]="!isNoContentMessageVisible" [ngClass]="isNoContentMessageVisible ? 'alert alert-warning' : ''">
          <strong>{{ noContentMessage }}</strong>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-top">
    <button type="submit" class="btn btn-primary ms-3">
      <span>{{ buttonAction }}</span>
    </button>
  </div>
</form>
