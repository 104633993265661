<div class="wre-table-container">
    <div *ngIf="!isNoSearchResult" #tableContainer class="table-responsive">
        <table class="table wre-table fixed-column table-hover mb-0">
            <thead class="text-center">
                <tr>
                    <th class="text-nowrap column-action fixed-column">&nbsp;</th>
                    <th class="text-nowrap col-ellipsis column-users">Name</th>
                    <th class="text-nowrap col-ellipsis column-name" *ngIf="!isAdGroup">Email Address</th>
                    <th class="text-nowrap col-ellipsis column-domain text-center">App Count</th>
                    <th class="text-nowrap col-ellipsis column-domain text-center">Role Count</th>
                    <th class="text-nowrap col-ellipsis column-domain text-center" *ngIf="!isAdGroup">Domain</th>
                </tr>
            </thead>
            <tbody class="">

                <wre-core-app-loading [isLoading]="isLoading"></wre-core-app-loading>
                <ng-container *ngIf="!isLoading">
                    <tr *ngFor="let user of users">
                        <td class="text-nowrap sticky-column column-action fixed-column">
                            <div class="dropdown" ngbDropdown container="body">
                                <button class="btn wre-btn btn-secondary dropdown-toggle" type="button" ngbDropdownToggle>
                  <span>Action</span> <span class="caret"></span>
                </button>
                                <div class="dropdown-menu" ngbDropdownMenu>
                                    <button class="dropdown-item" routerLinkActive #rla="routerLinkActive" [routerLink]="[user.id, {outlets: { sidebar: 'details' } } ]">
                    Details
                </button>
                                    <button class="dropdown-item" routerLinkActive *ngIf="user.type === UserType.externalUser && hasEditPermission" [routerLink]="[user.id, {outlets: { modal: 'edit' } } ]">
                    Edit
              </button>
                                </div>
                            </div>
                        </td>
                        <td class="text-nowrap col-ellipsis column-users">
                            <span *ngIf="user.displayName && user.displayName.trim() === ','" class="ps-3">&#x2015;</span>
                            <ngb-highlight *ngIf="user.displayName && user.displayName.trim() !== ','" [result]="user.displayName" [term]="searchTerm"></ngb-highlight>
                        </td>
                        <td class="text-nowrap col-ellipsis column-name" *ngIf="!isAdGroup">
                            <ngb-highlight [result]="user.mail" [term]="searchTerm"></ngb-highlight>
                        </td>
                        <td class="text-nowrap col-ellipsis column-domain text-center">
                            <span *ngIf="user.appCount < 10">0</span>{{ user.appCount }}
                        </td>
                        <td class="text-nowrap col-ellipsis column-domain text-center">
                            <span *ngIf="user.roleCount < 10">0</span>{{ user.roleCount }}
                        </td>
                        <td class="text-nowrap col-ellipsis column-domain text-center">
                            <span *ngIf="user.type === UserType.internalUser" class="badge bg-warning">INT</span>
                            <span *ngIf="user.type === UserType.externalUser" class="badge bg-secondary">EXT</span>
                        </td>
                    </tr>
                </ng-container>

            </tbody>
        </table>
    </div>
    <div *ngIf="isNoSearchResult">
      <div class="no-results">
        No items were found based on your search criteria. Please try again.
      </div>
    </div>
</div>
