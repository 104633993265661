import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retryWhen } from 'rxjs/operators';
import { SlipcaseNewsItem, Topic } from '../models/slipcase-news-item';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})

export class SlipcaseNewsService extends HttpService {
  slipcaseFeedUrl = environment.gatewayApiUrl + 'news/slipcase';
  slipcaseRecommendedFeedUrl = environment.gatewayApiUrl + 'news/slipcase/recommended';
  slipcaseMostPopularFeedUrl = environment.gatewayApiUrl + 'news/slipcase/mostpopular';
  recordNewsVisitUrl = environment.gatewayApiUrl + 'news/slipcase/visit';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(http: HttpClient) {
    super(http);
  }

  getSlipcaseNewsItems(includeTopics?, includePayWalled?, numberOfItems?, offset?): Observable<HttpResponse<SlipcaseNewsItem[]>> {
    let params = new HttpParams();
    if (includeTopics !== undefined) { params = params.append('includeTopics', includeTopics); }
    if (includePayWalled !== undefined) { params = params.append('includePayWalled', includePayWalled); }
    if (numberOfItems !== undefined) { params = params.append('numberOfItems', numberOfItems); }
    if (offset !== undefined) { params = params.append('offset', offset); }

    return this.http.get<SlipcaseNewsItem[]>(this.slipcaseFeedUrl, { params: params, observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  getRecommendedNewsItems(numberOfItems?): Observable<HttpResponse<SlipcaseNewsItem[]>> {
    let params = new HttpParams();
    if (numberOfItems !== undefined) { params = params.append('numberOfItems', numberOfItems); }

    return this.http.get<SlipcaseNewsItem[]>(this.slipcaseRecommendedFeedUrl,
      { params: params, observe: 'response' }).pipe(
        retryWhen((errors) => this.handleRetry(errors))
      );
  }

  getMostPopularNewsItems(numberOfItems = 10, monthAgo = 1): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('numberOfItems', numberOfItems.toString());

    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - monthAgo);

    params = params.append('fromDate', fromDate.toISOString());
    params = params.append('toDate', new Date().toISOString());

    return this.http.get<any>(this.slipcaseMostPopularFeedUrl,
      { params: params, observe: 'response' }).pipe(
        retryWhen((errors) => this.handleRetry(errors))
      );
  }

  recordNewsVisit(newsData: SlipcaseNewsItem) {
    let params = new HttpParams();
    params = params.append('id', newsData.id.toString());

    return this.http.post(this.recordNewsVisitUrl, newsData, { params: params, observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  getSlipcaseTopics(): Topic[] {
    const category1 = 'Speciality Lines';
    const category2 = 'Interest Areas';

    const topics: Topic[] = [
      { id: 4, name: 'Law', category: category2, slug: '' },
      { id: 5, name: 'Accident & Health / Contingency', category: category1, slug: '' },
      { id: 6, name: 'Marine Liability', category: category1, slug: '' },
      { id: 7, name: 'Alternative Capital', category: category2, slug: '' },
      { id: 8, name: 'Ratings', category: category2, slug: '' },
      { id: 9, name: 'Aerospace', category: category1, slug: '' },
      { id: 11, name: 'Marine Cargo', category: category1, slug: '' },
      { id: 12, name: 'Casualty', category: category1, slug: '' },
      { id: 13, name: 'Claims & Losses', category: category2, slug: '' },
      { id: 14, name: 'Construction', category: category1, slug: '' },
      { id: 16, name: 'Cyber', category: category1, slug: '' },
      { id: 17, name: 'Directors & Officers', category: category1, slug: '' },
      { id: 19, name: 'Financial Results', category: category2, slug: '' },
      { id: 20, name: 'Private Client', category: category1, slug: '' },
      { id: 21, name: 'Property - International', category: category1, slug: '' },
      { id: 23, name: 'Kidnap & Ransom', category: category1, slug: '' },
      { id: 24, name: 'Marine Hull', category: category1, slug: '' },
      { id: 25, name: 'Mergers & Acquisitions', category: category2, slug: '' },
      { id: 26, name: 'Mining', category: category1, slug: '' },
      { id: 27, name: 'Motor (Commercial)', category: category1, slug: '' },
      { id: 28, name: 'Market Moves', category: category2, slug: '' },
      { id: 29, name: 'Property - North America', category: category1, slug: '' },
      { id: 30, name: 'Offshore Energy', category: category1, slug: '' },
      { id: 31, name: 'Onshore Energy', category: category1, slug: '' },
      { id: 32, name: 'Political Risk & War', category: category1, slug: '' },
      { id: 33, name: 'Product Recall', category: category1, slug: '' },
      { id: 34, name: 'P I (E&O)', category: category1, slug: '' },
      { id: 35, name: 'Renewables', category: category1, slug: '' },
      { id: 36, name: 'Solvency II', category: category2, slug: '' },
      { id: 37, name: 'Terrorism Risk', category: category1, slug: '' },
      { id: 38, name: 'Topical Trends', category: category2, slug: '' },
      { id: 39, name: 'Property - UK', category: category1, slug: '' },
      { id: 40, name: 'Technology', category: category1, slug: '' },
      { id: 47, name: 'Risk Management', category: category2, slug: '' },
      { id: 48, name: 'Charity & Sponsorship', category: category2, slug: '' },
      { id: 50, name: 'Launches/New Products', category: category2, slug: '' },
      { id: 51, name: 'Emerging Markets', category: category2, slug: '' },
      { id: 52, name: 'Marketing & Comms', category: category2, slug: '' },
      { id: 54, name: 'Awards', category: category2, slug: '' },
      { id: 61, name: 'Regulation & Compliance', category: category2, slug: '' },
      { id: 62, name: 'Strategy', category: category2, slug: '' },
      { id: 67, name: 'Environment & Climate', category: category2, slug: '' },
      { id: 71, name: 'Diversity & Inclusion', category: category2, slug: '' },
      { id: 73, name: 'Captives', category: category2, slug: '' },
      { id: 76, name: 'Monte Carlo Rendez-Vous', category: category2, slug: '' },
      { id: 77, name: 'Superyachts', category: category1, slug: '' },
      { id: 88, name: 'Takaful', category: category1, slug: '' },
      { id: 89, name: 'InsurTech', category: category2, slug: '' },
      { id: 90, name: 'Rates', category: category2, slug: '' },
      { id: 91, name: 'Trade Credit', category: category1, slug: '' },
      { id: 92, name: 'Agriculture', category: category1, slug: '' },
      { id: 93, name: 'Risk Modelling', category: category2, slug: '' },
      { id: 94, name: 'Emerging Risks', category: category2, slug: '' },
      { id: 95, name: 'Supply Chain / Business Interruption', category: category1, slug: '' },
      { id: 96, name: 'Parametrics', category: category2, slug: '' },
      { id: 97, name: 'Financial Institutions', category: category1, slug: '' },
      { id: 160, name: 'MGAs', category: category2, slug: '' }
    ];
    return topics;
  }
}
