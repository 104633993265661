import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleModalComponent } from '../role-modal/role-modal.component';
import { RolesDataService } from '../services/roles/roles-data.service';
import { Subscription } from 'rxjs';
import { SecurityWorkspaceService } from '../services/security-workspace.service';
import { SecurityWorkspace } from '../models/security-workspace';

@Component({
  selector: 'wre-role-modal-wrapper',
  template: ``
})
export class RoleModalWrapperComponent implements OnInit, AfterViewInit, OnDestroy {

  id: number;
  paramSubscription: Subscription;
  roleModalRef: NgbModalRef;
  workspace: SecurityWorkspace;
  subscription: Subscription;

  get modalComponent(): RoleModalComponent {
    return this.roleModalRef?.componentInstance as RoleModalComponent;
  }

  constructor(private modalService: NgbModal,
    private rolesListService: RolesDataService,
    private _securityWorkspaceService: SecurityWorkspaceService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngAfterViewInit(): void {
    // Timeout used to fix the modal rendering bug when modal opens on page load #60243
    // 1 sec will allow to finish the parent component elements dom rendering.
    setTimeout(() => {
      const modalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        size: 'xl',
        centered: true
      };

      this.roleModalRef = this.modalService.open(
        RoleModalComponent,
        modalOptions
      );

      if (this.id) {
        this.modalComponent.roleId = this.id;
      }

      this.roleModalRef.result.then(
        (resolved) => {
          this.router.navigate([`/admin/security/access/${ this.workspace.url }/roles`]);
          this.rolesListService.loadRoles();
        },
        (rejected) => this.router.navigate([`/admin/security/access/${ this.workspace.url }/roles`])
      );
    }, 1000);

  }

  ngOnInit() {
    this.paramSubscription = this.route.paramMap.subscribe(params => {
      const idParam = params.get('id');
      if (idParam != null) {
        this.id = +idParam;
        if (this.modalComponent) {
          this.modalComponent.roleId = this.id;
        }
      }
    });

    this.subscription = this._securityWorkspaceService.currentWorkspace$.subscribe(workspace => this.workspace = workspace);
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
    if (!!this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
