<wre-toolkit-loader *ngIf="loading"></wre-toolkit-loader>

<div *ngIf="!loading" class="overview-table-conatiner">
  <div #tableContainer class="table-responsive">
    <table class="table wre-table fixed-column">
      <thead>
        <tr>
          <th class="fixed-column action">&nbsp;</th>
          <th class="name">Name</th>
          <th class="email">Email Address</th>
          <th class="teams">Teams</th>
          <th class="reporting-groups">Reporting Groups</th>
          <th class="last-updated">Last Updated</th>
        </tr>
      </thead>
      <tbody class="mb-0 pb-0">
        <tr *ngFor="let item of accessMappings">
          <td class="fixed-column action">
            <div class="dropdown" ngbDropdown container="body">
              <button class="btn btn-secondary dropdown-toggle dropdown" type="button" ngbDropdownToggle>
                <span>Action</span> <span class="caret"></span>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button class="dropdown-item" [routerLink]="[item.user.id, {outlets: { modal: 'edit' } } ]">Edit</button>
                <button class="dropdown-item delete" (click)="deleteUserMappings(item.user.id, item.user.displayName)" >Delete</button>
              </div>
            </div>
          </td>
          <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="name">
            <ngb-highlight [result]="item.user.displayName" [term]="highlightTerm"></ngb-highlight>
          </td>
          <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="email">
            <ngb-highlight [result]="item.user.email" [term]="highlightTerm"></ngb-highlight>
          </td>
          <td class="teams">
            <span *ngIf="item.access.teams.length === 0"> &#x2015; </span>
            <span *ngIf="item.access.teams.length !== 0">
              <span class="team" *ngFor="let team of getTeams(item);let last = last;">
                <ngb-highlight [result]="team" [term]="highlightTerm"></ngb-highlight><span *ngIf="!last">,&nbsp; </span>
              </span>
            </span>
          </td>
          <td class="reporting-groups">
            <span *ngIf="item.access.reportingGroups.length === 0"> &#x2015; </span>
            <span *ngIf="item.access.reportingGroups.length !== 0">
              <span class="reportingGroup" *ngFor="let group of getReportingGroups(item);let last = last;">
                <ngb-highlight [result]="group" [term]="highlightTerm"></ngb-highlight><span *ngIf="!last">,&nbsp; </span>
              </span>
            </span>
          </td>
          <td class="last-updated">
            <span *ngIf="!item.lastUpdateDate"> &#x2015; </span>
            <span *ngIf="item.lastUpdateDate"> {{ item.lastUpdateDate | date :'dd/MMM/yyyy hh:mm:ss a' }} </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
