import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppInsightsService } from 'wre-core-lib';
import { AdminService } from 'src/app/services/admin.service';
import { Subscription, throwError } from 'rxjs';
import { TourStepsCreateUpdateComponent } from '../tour-steps-create-update/tour-steps-create-update.component';
import { AdminDeleteComponent } from '../../admin/delete/delete.component';
import { AdminDataRefreshService } from '../../admin/services/admin-data-refresh.service';
import { filter } from 'rxjs/operators';
import { RefreshDataType, TabTitles } from '../../admin/models/refresh-data-type';
import { SecurityWorkspaceService } from '../../security-admin/services/security-workspace.service';
import { UserPermissions } from './../../../models/admin';

@Component({
  selector: 'wre-tour-steps',
  templateUrl: './tour-steps.component.html',
  styleUrls: ['./tour-steps.component.css', './tour-steps.component.sass']
})
export class TourStepsComponent implements OnInit, OnDestroy {
  offset = '0';
  TourStepsList: any[];
  isloaderVisible = false;
  TourStepId: number;
  TourStepName: string;
  deleteTourStepMessage_h5: string;
  deleteTourStepMessage_p: string;
  isConfirmModal = true;
  isViewContent = false;
  margin = 321;
  tableHeight = window.innerHeight - this.margin;
  roles: string[] = [];

  private dataRefreshSubscription: Subscription;

  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  @Output() setExperienceTitle: EventEmitter<any> = new EventEmitter();

  @Input() set refreshTourStepData(value: boolean) {
    if (value === true) {
      this.getTourSteps();
    }
  }

  get hasEditPermission() {
    return this.roles.includes(UserPermissions.GatewayInlineTrainingEdit);
  }

  get hasDeletePermission() {
    return this.roles.includes(UserPermissions.GatewayInlineTrainingDelete);
  }


  constructor (
    public activeModal: NgbActiveModal,
    private appInsightsService: AppInsightsService,
    private adminService: AdminService,
    private adminDataRefreshService: AdminDataRefreshService,
    private modalService: NgbModal,
    private securityWorkspaceService: SecurityWorkspaceService) {
    this.appInsightsService.componentName = 'TourStepsComponent';
    this.roles = this.securityWorkspaceService.currentPermissions;
  }

  ngOnInit() {
    this.setExperienceTitle.emit(TabTitles.InlineTraining);
    this.setTitle.emit('Gallagher Re Gateway');
    this.getTourSteps();
    window.onresize = (e) => {
      if (this.tableHeight !== window.innerHeight - this.margin) {
        this.resizeTable();
      }
    };

    this.dataRefreshSubscription = this.adminDataRefreshService.refreshData$
      .pipe(filter(r => r === RefreshDataType.GatewayInlineTraining))
      .subscribe(() => this.getTourSteps());
  }

  ngOnDestroy() {
    this.dataRefreshSubscription.unsubscribe();
  }

  resizeTable() {
    this.tableHeight = window.innerHeight - this.margin;
  }

  getTourSteps() {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'getTourSteps';
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.adminService.getInlineTrainings().subscribe(data => {
        this.TourStepsList = data.body;
        this.isloaderVisible = false;
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }
  onEdit(id: number) {
    this.isViewContent = false;
    this.editTourStep(id);
  }

  onDelete(id: number, name: string) {
    this.isViewContent = false;
    this.isConfirmModal = true;
    this.TourStepId = id;
    this.TourStepName = name;
    this.deleteTourStepMessage_h5 = 'Are you sure you want to delete Inline Training ';
    this.deleteTourStepMessage_p = 'This cannot be undone.';
    this.isConfirmModal = true;
    this.open(this.TourStepName);
  }

  open(entityName: string) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    const modalRef = this.modalService.open(
      AdminDeleteComponent,
      modalOptions
    );

    modalRef.componentInstance.isConfirmModal = this.isConfirmModal;
    modalRef.componentInstance.entityName = entityName;
    modalRef.componentInstance.deleteEntityMessage_h5 = this.deleteTourStepMessage_h5;
    modalRef.componentInstance.deleteEntityMessage_p = this.deleteTourStepMessage_p;

    modalRef.componentInstance.deleteConfirmedChange.subscribe(($e) => {
      if ($e === true) {
        this.deleteTourStep(this.TourStepId);
      }
    });
  }

  public editTourStep(id: number) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true
    };

    const modalRef = this.modalService.open(
      TourStepsCreateUpdateComponent,
      modalOptions
    );

    modalRef.componentInstance.tourStepId = id;
    modalRef.componentInstance.isViewContent = this.isViewContent;
    modalRef.componentInstance.refreshTourStepData.subscribe(($e) => {
      this.refreshTourStepData = $e;
    });
  }

  deleteTourStep(id: number) {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'deleteTourStep';
    this.appInsightsService.startTrace(
      this.appInsightsService.componentName,
      this.appInsightsService.methodName
    );
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.adminService.deleteInlineTraining(id).subscribe(result => {
        this.isloaderVisible = false;
        this.getTourSteps();
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
        this.deleteTourStepMessage_h5 = 'Inline Training Info';
        this.deleteTourStepMessage_p = this.TourStepName + ' deleted successfully.';
        this.isConfirmModal = false;

      }, error => {
        if (error.status === 409) {
          console.log('Inline Training delete error. Please contact system administrator');
        }
        this.isloaderVisible = false;
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }
  onViewCentent(viewContentTitle: string, viewContentData: string)
  {
    this.isViewContent = true;
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    modalOptions.size = 'sm';
    const modalRef = this.modalService.open(
      TourStepsCreateUpdateComponent,
      modalOptions
    );
    modalRef.componentInstance.isViewContent = this.isViewContent;
    modalRef.componentInstance.viewContentTitle = viewContentTitle;
    modalRef.componentInstance.viewContentData = viewContentData;

  }

}
