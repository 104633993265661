<div *ngIf="componentAvailable === true" class="wre-admin-panel-container wre-main-container" id="placement-excellence-home-container">
    <div *ngIf="isLoaderVisible" class="row justify-content-center">
        <div class="col-lg-8 col-md-12 pb-4">
            <div style="height:70px;" class="d-flex align-items-center justify-content-center">
                <div class="loading"></div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!isLoaderVisible">
        <div class="row">
            <div class="col-lg-12">
                <div class="row wre-gateway-web-admin-div-row-width">
                    <div class="col">
                        <ul class="nav-pills nav" id="tabs" role="tablist">
                            <li *ngFor="let tab of tabs" class="nav-item d-flex">
                                <a class="nav-link" [id]="tab.id" [routerLink]="tab.link" routerLinkActive="active">
                                    <span>{{ tab.label }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-auto d-flex align-items-center justify-content-end">
                        <div class="btn-group wre-gateway-web-admin-dropdown-div">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle dropdown" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ selectedExperience }}
                                    <span class="caret"></span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a *ngFor="let experience of experiences" class="nav-link dropdown-item" id="tab-experiences" routerLink="{{experience.url}}" routerLinkActive="active">
                                        {{ experience.name }}
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </ng-container>
