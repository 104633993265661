import { Component, AfterViewInit } from '@angular/core';
import items from '../../../../../assets/json/placement-excellence/client-confirms-firm-order.json';
import { ActivatedRoute } from '@angular/router';
import { PlacementExcellenceService } from 'src/app/services/placement-excellence.service';


@Component({
  selector: 'app-client-confirms-firm-order',
  templateUrl: './client-confirms-firm-order.component.html',
  styleUrls: ['./client-confirms-firm-order.component.css']
})
export class ClientConfirmsFirmOrderComponent implements AfterViewInit {

  tableItems = items;

  constructor (private route: ActivatedRoute, private placementExcellenceService: PlacementExcellenceService) { }

  ngAfterViewInit() {
    this.route.fragment.subscribe(f => this.placementExcellenceService.scroolToElementById(f));
  }

}
