<div class="btn-toolbar d-flex align-items-center noselect tool-title">
  <h1 class="me-auto">Dashboard</h1>
</div>

<div class="wre-home-dashboard-container wre-main-container">
  <div class="row home-dash-welcome">
    <div class="col-sm-12">
      <wre-home-welcome></wre-home-welcome>
    </div>
  </div>
  <div class="row home-dash-cont px-3">
    <!-- hide the news container if the user is external -->
    <div class="col-sm-12 col-lg-6" *ngIf="slipcaseAvailable === true">
      <div class="card card-md" id="news-card">
        <div class="btn-toolbar border-bottom align-items-center card-header">
          <h5 class="mb-0 me-auto card-title">
            <span>News</span>
          </h5>
          <a
            *ngIf="slipcaseAvailable === true"
            routerLink="/news-aggregator"
            style="text-decoration: none"
            title="Open News Workspace">
            <button class="btn btn-primary">View all news</button>
          </a>
        </div>
        <div class="card-body">
          <div class="news-tabs">
            <div class="btn-group w-100">
              <ul class="nav-pills nav card-tabs border-0" id="myTab2" role="tablist">
                <li *ngIf="slipcaseAvailable === true" class="nav-item d-flex">
                  <a
                    class="nav-link show"
                    tourAnchor="slipcaseRecommended.anchor"
                    [ngClass]="{ active: slipcaseAvailable === true }"
                    id="slipcase-tab"
                    data-bs-toggle="tab"
                    href="#tab1"
                    role="tab"
                    aria-controls="tab1"
                    aria-selected="true">
                    <span>Slipcase</span>
                  </a>
                </li>
                <!-- Commented because of the embeded twitter feed stopped working and waiting for twitter to fix it  -->
                <!-- <li class="nav-item d-flex">
                  <a
                    class="nav-link show"
                    [ngClass]="{ active: slipcaseAvailable === false }"
                    id="twitter-tab"
                    data-bs-toggle="tab"
                    href="#tab2"
                    role="tab"
                    aria-controls="tab2"
                    aria-selected="true">
                    <span>Twitter</span>
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="container-fluid px-0">
            <div class="tab-content" id="myTabContent">
              <div
                *ngIf="slipcaseAvailable === true"
                class="tab-pane fade"
                [ngClass]="{ 'active show': slipcaseAvailable === true }"
                id="tab1"
                role="tabpanel">
                <app-slipcase-feed></app-slipcase-feed>
              </div>
              <div
                class="tab-pane fade"
                [ngClass]="{ 'active show': slipcaseAvailable === false }"
                id="tab2"
                role="tabpanel">
                <div class="wre-gateway-home-twitter-feed-cont">
                  <div class="wre-gateway-home-twitter-feed-resize">
                    <app-twitter-feed></app-twitter-feed>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-6 mt-md-3 mt-lg-0">
      <div *ngIf="notifications.length != 0" class="row wre-gateway-notifications-row mb-4">
        <div class="col-sm-12">
          <div class="card wre-gateway-alert">
            <div class="card-body d-flex">
              <div class="wre-gateway-alert-icon d-flex">
                <i class="fas fa-bell"></i>
              </div>
              <div class="wre-gateway-alert-content" *ngFor="let item of notifications">
                <h4>{{ item.title }}</h4>
                <p>{{ item.text }}</p>
                <button type="button" role="button" class="btn btn-primary align-content-center">
                  <a href="{{ item.link }}" target="_blank"
                    ><i class="fa fa-chevron-right" aria-hidden="true"></i
                  ></a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <wre-quickaccess></wre-quickaccess>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-sm-12">
          <wre-most-viewed-news></wre-most-viewed-news>
        </div>
      </div>
    </div>
  </div>
</div>
