<div id="alertDanger" class="modal fade show" tabindex="-1" role="dialog" style="display: block;" aria-modal="true">
  <div style="max-width:450px;" class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center">
        <h3 class="modal-title">{{title}}</h3>
        <div class="modal-options">
          <button [disabled]="loading" class="btn btn-icon" type="button" data-bs-dismiss="modal"
                  aria-label="Close" (click)="closeModal()" tabindex="-1"
          >
            <i class="fal fa-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <wre-toolkit-loader *ngIf="loading; else contentBlock"></wre-toolkit-loader>

        <ng-template #contentBlock>
          <div class="d-flex row">
            <div class="col-lg-12">
              {{ message }} <strong>{{entityName}}</strong>
            </div>
          </div>
        </ng-template>

      </div>
      <div class="modal-footer">
        <button [disabled]="loading" type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">
          {{ defaultNoText }}
        </button>
        <button [disabled]="loading" type="button" class="btn ms-3 btn-error" (click)="confirm()">
          {{ confirmationText }}
        </button>
      </div>
    </div>
  </div>
</div>
