import { Component, OnDestroy, OnInit } from '@angular/core';
import { SrtOverviewAccessListService } from '../../services/srt-overview-access-list.service';
import { SrtOverviewAccess } from '../../models/srt-overview-access';
import { AccessMapping } from '../../../overview-access/models/access-mapping';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { SrtOverviewAccessApiService } from '../../services/srt-overview-access-api.service';
import { OverviewAccessTableComponent } from '../../../overview-access/components/overview-access-table.component';

@Component({
  selector: 'wre-srt-access-overview-table',
  templateUrl: './srt-access-overview-table.component.html',
  styleUrls: ['./srt-access-overview-table.component.sass']
})
export class SrtAccessOverviewTableComponent extends OverviewAccessTableComponent<SrtOverviewAccess> implements OnInit, OnDestroy {
  constructor(public listService: SrtOverviewAccessListService,
              public apiService: SrtOverviewAccessApiService,
              public confirmationService: ConfirmationService) {
    super(listService, apiService, confirmationService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getTeams(item: AccessMapping<SrtOverviewAccess>): string[] {
    return item.access.teams.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

}
