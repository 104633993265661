import { WreToolkitLibModule } from 'wre-toolkit-lib';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldsComponent } from './fields-wrapper/fields/fields.component';
import { FieldsWrapperComponent } from './fields-wrapper/fields-wrapper.component';
import { EditModalWrapperComponent } from './fields-wrapper/edit-modal-wrapper/edit-modal-wrapper.component';
import { EditModalComponent } from './fields-wrapper/edit-modal-wrapper/edit-modal/edit-modal.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SafrOverviewWrapperComponent } from './safr-overview-wrapper/safr-overview-wrapper.component';
import { SafrOverviewModalWrapperComponent } from './safr-overview-wrapper/safr-overview-modal-wrapper/safr-overview-modal-wrapper.component';
import { SafrOverviewAccessModalComponent } from './safr-overview-wrapper/safr-overview-modal-wrapper/safr-overview-access-modal/safr-overview-access-modal.component';
import { SafrOverviewAccessTableComponent } from './safr-overview-wrapper/safr-overview-access-table/safr-overview-access-table.component';
import { OverviewAccessModule } from '../overview-access/overview-access.module';


@NgModule({
  declarations: [
    FieldsComponent,
    FieldsWrapperComponent,
    EditModalWrapperComponent,
    EditModalComponent,
    SafrOverviewWrapperComponent,
    SafrOverviewModalWrapperComponent,
    SafrOverviewAccessModalComponent,
    SafrOverviewAccessTableComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    WreToolkitLibModule,
    NgbModule,
    OverviewAccessModule
  ],
  exports: [
    FieldsWrapperComponent,
    FieldsComponent
  ]
})
export class SafrAdminModule { }
