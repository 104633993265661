import { UsersListService } from './../../../services/users-list.service';
import { UserPermissions } from 'src/app/models/admin';
import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserGroup } from '../../../models/user-group';
import { UserType } from '../../../models/user-type';
import { SecurityWorkspaceService } from '../../../services/security-workspace.service';

@Component({
  selector: 'wre-users-table-admin',
  templateUrl: './users-table-admin.component.html',
  styleUrls: ['./users-table-admin.component.sass']
})
export class UsersTableAdminComponent {

  @Input() users: UserGroup[];
  @Input() isLoading: boolean;
  @Input() isAdGroup = false;
  @Input() searchTerm;
  @Input() isNoSearchResult;
  UserType = UserType;
  offset = '0';
  subscription: Subscription;
  roles = [];

  get hasEditPermission() { return this.roles.includes(UserPermissions.GatewaySecurityUsersTabEdit); }

  constructor(private securityWorkspaceService: SecurityWorkspaceService) {
    this.roles = this.securityWorkspaceService.currentPermissions;
  }

}
