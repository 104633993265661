export class SecurityRole {
  guid: string;
  name: string;

  constructor(
    guid = '',
    name = ''
  ) {
    this.guid = guid;
    this.name = name;
  }
}
