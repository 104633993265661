<div class="placement-excellence-container-fluid">
    <div class="row justify-content-center">
        <ng-container *ngFor="let item of tableItems">
            <div class="col-sm-12 col-md-6 col-lg-3 mb-3 quick-ref-card-container">
                <div class="card">
                    <div class="btn-toolbar card-header" role="toolbar" aria-label="Toolbar Actions">
                        <h6 class="card-title">{{item.category}}</h6>
                    </div>
                    <div class="card-body">
                        <ul>
                            <ng-container *ngFor="let i of item.items">
                                <li>
                                    {{i}}
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>