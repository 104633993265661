import { RolesDataService } from './../../../../../services/roles/roles-data.service';
import { SecurityManagementService } from '../../../../../services/security-management.service';
import { SecurityWorkspaceService } from '../../../../../services/security-workspace.service';
import { RoleDetailsView } from '../../../../../models/role-details-view';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wre-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent implements OnInit, OnDestroy {

  private _roleId: number;
  get roleId(): number {
    return this._roleId;
  }
  @Input() set roleId(value: number) {
    this._roleId = value;
    if (value != null) {
      this.loadRole();
    }
  }

  loading = false;
  errorMessageInfo: string;
  role: RoleDetailsView;

  workspaceSub: Subscription;
  reloadRoleSub: Subscription;

  constructor(private _securityWorkspaceService: SecurityWorkspaceService,
    private _securityManagementService: SecurityManagementService,
    private _roleDataService: RolesDataService) { }

  ngOnInit(): void {
    this.workspaceSub = this._securityWorkspaceService.currentWorkspace$.subscribe(_ => this.loadRole());
    this.reloadRoleSub = this._roleDataService.roles$.subscribe(_ => {
      if (this.roleId == this._roleDataService.toggledRoleId) {
        this.loadRole();
      }
    });
  }

  ngOnDestroy(): void {
    this.workspaceSub?.unsubscribe();
    this.reloadRoleSub?.unsubscribe();
  }

  loadRole(): void {
    const workspace = this._securityWorkspaceService.currentWorkspace;
    if (workspace == null || this.roleId == null) {
      return;
    }

    this.resetError();
    this.loading = true;
    this._securityManagementService.getRole(workspace, this.roleId)
      .then(async role => {
        this.role = role.body;
      })
      .catch(this.handleError)
      .finally(() => this.loading = false);
  }

  private handleError = (err) => {
    this.errorMessageInfo = err?.err || 'Error has occurred while retrieving the role.';
  }

  private resetError = () => {
    this.errorMessageInfo = null;
  }

}
