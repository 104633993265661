import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import cueCards from '../../../../assets/json/placement-excellence-items.json';
import items1 from '../../../../assets/json/placement-excellence/client-onboarding-and-status-disclosure.json';
import items2 from '../../../../assets/json/placement-excellence/client-due-diligence.json';
import items3 from '../../../../assets/json/placement-excellence/marketing-protocols-data-gathering.json';
import items4 from '../../../../assets/json/placement-excellence/obtain-quotations.json';
import items5 from '../../../../assets/json/placement-excellence/receives-quotations.json';
import items6 from '../../../../assets/json/placement-excellence/client-confirms-firm-order.json';
import items7 from '../../../../assets/json/placement-excellence/placement-status.json';
import items8 from '../../../../assets/json/placement-excellence/contract-documentation.json';


@Component({
  selector: 'app-core-steps',
  templateUrl: './core-steps.component.html',
  styleUrls: ['./core-steps.component.css']
})
export class CoreStepsComponent implements OnInit {

  cards = cueCards;
  items = [items1, items2, items3, items4, items5, items6, items7, items8];

  @Input() isOpenCoreSteps = false;
  @Output() isOpenCoreStepsChanged = new EventEmitter<boolean>();

  constructor () { }

  ngOnInit() {
    for (let i = 0; i < this.cards.length; i++) {
      const mapped = this.items[i].map(x => x.coreStep);
      const res = mapped.filter((value, index, self) => self.indexOf(value) === index);

      this.cards[i]['coreSteps'] = res;
    }
  }

  getValidId(text: string) {
    return text.replace(/[^a-z0-9\-_:\.]|^[^a-z]+/gi, '');
  }
  closeCoreSteps() {
    this.isOpenCoreSteps = false;
    this.isOpenCoreStepsChanged.emit(false);
  }

}
