import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreasComponent } from './areas/areas.component';
import { ReportsComponent } from './reports/reports.component';
import { AreasCreateUpdateComponent } from './areas-create-update/areas-create-update.component';
import { ReportsCreateUpdateComponent } from './reports-create-update/reports-create-update.component';
import { ReportsRlsRoleFormComponent } from './reports-create-update/reports-rls-role-form/reports-rls-role-form.component';
import { ReportsRlsRolesListComponent } from './reports-create-update/reports-rls-roles-list/reports-rls-roles-list.component';
import { ReportWizardDetailsComponent } from './reports-create-update/wizard-steps/report-wizard-details/report-wizard-details.component';
import { ReportWizardConfigNonPowerbiComponent } from './reports-create-update/wizard-steps/report-wizard-config-non-powerbi/report-wizard-config-non-powerbi.component';
import { ReportWizardConfigPowerbiComponent } from './reports-create-update/wizard-steps/report-wizard-config-powerbi/report-wizard-config-powerbi.component';
import { WreToolkitLibModule } from 'wre-toolkit-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { TypeEnumToArrayPipe } from './models/report-type.enum';
import { AdminSharedModule } from '../admin-shared/admin-shared.module';



@NgModule({
  declarations: [
    AreasComponent,
    ReportsComponent,
    AreasCreateUpdateComponent,
    ReportsCreateUpdateComponent,
    ReportsRlsRoleFormComponent,
    ReportsRlsRolesListComponent,
    ReportWizardDetailsComponent,
    ReportWizardConfigNonPowerbiComponent,
    ReportWizardConfigPowerbiComponent,
    TypeEnumToArrayPipe
  ],
  imports: [
    CommonModule,
    WreToolkitLibModule,
    FormsModule,
    NgbModule,
    CdkStepperModule,
    ReactiveFormsModule,
    AdminSharedModule
  ]
})
export class ReportingAdminModule { }
