import { Component, ElementRef, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AdminService } from './services/admin.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  // Used to pass pageIsLoading value from shell component
  constructor(public adminService: AdminService, private _elementRef: ElementRef) {  }
  ngOnInit() {

    this._elementRef.nativeElement.removeAttribute('ng-version');
  }
}
