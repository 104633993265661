<div class="d-flex justify-content-center align-items-center w-100" style="height: 80vh;" *ngIf="loading">
    <wre-toolkit-loader></wre-toolkit-loader>
</div>

<div *ngIf='errorMessage' class="row text-center">
    <div class="col">
        <span class="wre-form-error">
            {{ errorMessageInfo }}
        </span>
    </div>
</div>

<div class="wre-timeline-container" *ngIf="!loading && roleAudit">
    <div class="row">
        <div class="col-12 col wre-timeline-toggle d-flex justify-content-end">
            <a (click)="reverseHistoryData()"
                [ngClass]="{'sortIconDesc': !this.sortToggle, 'sortIconAsc': this.sortToggle }">
                Date
                <span *ngIf="this.sortToggle">
                    Ascending
                </span>
                <span *ngIf="!this.sortToggle">
                    Descending
                </span>
            </a>
        </div>
    </div>
    <div class="wre-timeline">
        <div class="row">
            <div class="col-12 col">
                <ul class="mt-3 wre-timeline-line">
                    <li class="wre-timeline-group" *ngFor='let data of historyData'
                        [ngClass]=" {'hideIt' : data.value.length === 0}">
                        <div data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + data.title" aria-expanded="true"
                            [attr.aria-controls]="'collapse' + data.title">
                            <span class="badge bg-dark">{{ data.displayName }} </span>
                        </div>
                        <ul [attr.id]="'collapse' + data.title" class="collapse show">
                            <li class="no-info" *ngFor="let audit of data.value;let i = index">
                                <div class="card w-100">
                                    <wre-role-history-card [audit]="audit" [title]="data.title + i"></wre-role-history-card>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
