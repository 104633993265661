import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-client-advocacy',
  templateUrl: './client-advocacy.component.html',
  styleUrls: ['./client-advocacy.component.sass', 'client-advocacy.component.scss']
})
export class ClientAdvocacyComponent implements OnInit {

  @Output() setTitle: EventEmitter<any> = new EventEmitter();

  contextmenu = false;

  constructor() { }

  ngOnInit() {
    this.setTitle.emit('Sales Workspace');
  }
  disableContextMenu() {
    this.contextmenu = false;
  }
}
