import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TabTitles } from '../../admin/models/refresh-data-type';
import { FileSelectService } from '../services/file-select.service';
import { FileUploadStatus } from '../models/file-upload-status';
import { DbUpdateResult } from '../models/db-update-result';

@Component({
  selector: 'wre-sanctions-database',
  templateUrl: './sanctions-database.component.html',
  styleUrls: ['./sanctions-database.component.sass']
})
export class SanctionsDatabaseComponent implements OnInit {

  @Output() setExperienceTitle: EventEmitter<any> = new EventEmitter();

  get status(): FileUploadStatus {
    return this.fileSelectService.status;
  }

  get uploadResult(): DbUpdateResult {
    return this.fileSelectService.uploadResult;
  }

  FileUploadStatus = FileUploadStatus;

  constructor(private fileSelectService: FileSelectService) { }

  ngOnInit(): void {
    this.setExperienceTitle.emit(TabTitles.Database);
    this.fileSelectService.reset();
  }

  onFileSubmitted(): void {
    this.fileSelectService.submitFile();
  }

}
