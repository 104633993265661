<div class="row justify-content-center" [hidden]="!this.isloaderVisible">
  <div class="col-lg-8 col-md-12 pb-4">
    <div style="height:70px;" class="d-flex align-items-center justify-content-center">
      <div class="loading"></div>
    </div>
  </div>
</div>
<div [hidden]="isloaderVisible" class="table_container">
  <div *ngIf="!noSearchResult" #tableContainer class="table-responsive">
    <table class="table wre-table fixed-column mb-0">
      <thead>
        <tr>
          <th class="fixed-column action">&nbsp;</th>
          <th class="active">Active</th>
          <th class="category">Category</th>
          <th class="report">Report</th>
          <th class="type">Type</th>
          <th class="model">Model</th>
          <th class="external">External</th>
          <th class="link">Link</th>
          <th class="roles">Roles</th>
          <th class="summary">Summary</th>
        </tr>
      </thead>
      <tbody class="mb-0 pb-0">
        <tr *ngFor="let report of reportsList" [hidden]="!showInActiveReports && !report.isActive">
          <td class="fixed-column action">
            <div class="dropdown" ngbDropdown container="body">
              <button class="btn btn-secondary dropdown-toggle dropdown" type="button" ngbDropdownToggle
                      [disabled]="!(hasEditPermission || hasDeletePermission)">
                <span>Action</span> <span class="caret"></span>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu *ngIf="hasEditPermission || hasDeletePermission">
                <button class="dropdown-item" (click)="onEdit(report)" *ngIf="hasEditPermission">Edit</button>
                <button class="dropdown-item" (click)="onStatusChange(report)" *ngIf="hasEditPermission">
                  {{ report.isActive ? 'Disable' : 'Enable' }}
                </button>
                <button class="dropdown-item delete" (click)="onDelete(report.id, report.name)" *ngIf="hasDeletePermission">Delete</button>
              </div>
            </div>
          </td>
          <td class="active text-center">
            <i class="fas fa-check" aria-hidden="true" *ngIf="report.isActive"></i>
            <i class="fas fa-times" aria-hidden="true" *ngIf="!report.isActive"></i>
          </td>
          <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="category">
            <ngb-highlight [result]="report.area.name" [term]="termSearched"></ngb-highlight>
          </td>
          <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="report">
            <ngb-highlight [result]="report.name" [term]="termSearched"></ngb-highlight>
          </td>
          <td class="type">
            <ngb-highlight [result]="getTypeLabel(report.type)" [term]="termSearched"></ngb-highlight>
          </td>
          <td class="model text-center">
            <i *ngIf="report.type === ReportType.PowerBI && report.modelId" class='fas fa-check'></i>
            <i *ngIf="report.type === ReportType.PowerBI && !report.modelId" class='fas fa-times'></i>
            <span *ngIf="report.type !== ReportType.PowerBI">N/A</span>
          </td>
          <td class="external text-center">
            <i *ngIf="report.isExternal" class='fas fa-check'></i>
            <i *ngIf="!report.isExternal" class='fas fa-times'></i>
          </td>
          <td class="link text-center">
            <a *ngIf="report.link" target="_blank" rel="noopener noreferrer" title="{{ report.link }}"
              href="{{ report.link }}">
              <i class="fas fa-external-link-alt"></i>
            </a>
          </td>
          <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="roles">
            <span *ngFor="let group of report.permissions; let first = first;">
              <span [ngClass]= "{'ms-2 ps-2 border-start': !first}">
                <ngb-highlight [result]="group.name" [term]="termSearched"></ngb-highlight>
              </span>
            </span>
          </td>
          <td class="summary">
            <a title="{{ report.summary }}" (click)="onViewSummary(report.name, report.summary)">
              View Summary
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="noSearchResult">
    <div class="no-results">
      No items were found based on your search criteria. Please try again.
    </div>
</div>
</div>
