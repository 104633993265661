export class WreNotification {
    type: string;
    message: string;
  
    constructor(type?: string, message?: string) {
      this.type = type;
      this.message = message;
    }
  }
  
  
  export enum WreNotificationType {
    error = 'error',
    warning = 'warning',
    success = 'success'
  }
  