import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ReportingLinkBuilderService, ReportingLinkInfo } from '../../services/reporting-link-builder.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-client-advocacy-home',
  templateUrl: './client-advocacy-home.component.html'
})
export class ClientAdvocacyHomeComponent implements OnInit {

  @Output() setTitle: EventEmitter<any> = new EventEmitter();

  contextmenu = false;

  now: number;

  constructor(private reportingLinkBuilder: ReportingLinkBuilderService) {
    this.now = (new Date()).getTime();
  }

  ngOnInit() {
    this.setTitle.emit('Sales Workspace');
  }

  openClientAdvocateList() {
    document.getElementById('tab-advocate-list').click();
    document.getElementById('advocateListSearchText').focus();
  }

  disableContextMenu() {
    this.contextmenu = false;
  }

  // This will generate non cachable link
  get clientAdvocacyLink() {
    return `https://ajg0.sharepoint.com/sites/gre-Gateway/_layouts/15/download.aspx?SourceUrl=https://ajg0.sharepoint.com/sites/gre-Gateway/Content/Center/Sales/Client%20Advocacy/WRe%20Client%20Management%20Templates.pptx?d=wad1e4a28c79e4d15b66e55a11fa09e1f&csf=1&web=1&e=YXsLct&${this.now}`;
  }

  get salesReportsLink(): string {
    const salesReportsLinkInfo = environment.reports.sales as ReportingLinkInfo;
    return this.reportingLinkBuilder.generateLink(salesReportsLinkInfo);
  }
}
