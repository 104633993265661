import { Component, OnDestroy, OnInit } from '@angular/core';
import { Field, FieldType } from '../../../../admin/models/safr-type';
import { SafrOverviewFormService } from '../../services/safr-overview-form.service';
import { SafrOverviewFieldsService } from '../../services/safr-overview-fields.service';
import { SafrOverviewAccessApiService } from '../../services/safr-overview-access-api.service';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SafrOverviewAccess } from '../../models/safr-overview-access';
import { UserTypeFlag } from 'wre-authlib';
import { OverviewAccessModalBaseComponent } from '../../../../overview-access/components/overview-access-modal-base.component';
import { SafrOverviewAccessListService } from '../../services/safr-overview-access-list.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wre-safr-overview-access-modal',
  templateUrl: './safr-overview-access-modal.component.html',
  styleUrls: ['./safr-overview-access-modal.component.sass']
})
export class SafrOverviewAccessModalComponent extends OverviewAccessModalBaseComponent<SafrOverviewAccess> implements OnInit, OnDestroy {

  userTypes = UserTypeFlag.InternalUsers;

  teams: string[] = [];
  reportingGroups: string[] = [];

  get selectedTeams(): string[] {
    return this.form?.get('access').get('teams').value ?? [];
  }

  get selectedReportingGroups(): string[] {
    return this.form?.get('access').get('reportingGroups').value ?? [];
  }

  fieldSubscription: Subscription;

  constructor(protected formService: SafrOverviewFormService,
              protected fieldService: SafrOverviewFieldsService,
              protected listService: SafrOverviewAccessListService,
              protected apiService: SafrOverviewAccessApiService,
              protected confirmationService: ConfirmationService,
              protected activeModal: NgbActiveModal) {
    super(activeModal, confirmationService, apiService, listService, formService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.fieldSubscription = this.fieldService.fields$.subscribe(fields => {
        this.teams = this.getFieldOptions(fields, FieldType.Team);
        this.reportingGroups = this.getFieldOptions(fields, FieldType.ReportingGroup);
      });

    this.fieldService.loadFields();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.fieldSubscription?.unsubscribe();
  }

  private getFieldOptions(fields: Field[], fieldType: FieldType): string[] {
    const names = fields
      .filter(f => f.name === fieldType)
      .reduce((a, b) => [...a, ...b.options.map(o => o.name)], []);

    const uniqueNames = [...new Set(names)];
    return uniqueNames.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

}
