import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ValidationErrors, AbstractControl } from '@angular/forms';
import { OverviewAccessFormService } from '../../../overview-access/services/overview-access-form.service';
import { SafrOverviewAccess } from '../models/safr-overview-access';
import { AccessMapping } from '../../../overview-access/models/access-mapping';
import { LookupUser } from '../../../../models/lookup-user';
import { OverviewValidators } from '../../../overview-access/overview-validators';

@Injectable({
  providedIn: 'root'
})
export class SafrOverviewFormService extends OverviewAccessFormService<SafrOverviewAccess> {

  overviewAccessForm: UntypedFormGroup;

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  createOverviewForm(): UntypedFormGroup {
    this.overviewAccessForm = this.fb.group({
      user: [new LookupUser(null, null, null), { validators: OverviewValidators.userLookupValidator() }],
      access: this.fb.group({
        teams: [[]],
        reportingGroups: [[]]
      }, {validators: this.accessOptionRequired})
    });
    return this.overviewAccessForm;
  }

  patchFormWithAccessMapping(form: UntypedFormGroup, accessMapping: AccessMapping<SafrOverviewAccess>): void {
    form.get('access').patchValue(accessMapping.access);
    form.get('user').patchValue(new LookupUser(accessMapping.user.displayName, accessMapping.user.email, accessMapping.user.id));
    form.get('user').disable();
  }

  /**
   * Custom Validators
   */

  // Validate if at least one Team or Reporting Group is provided
  accessOptionRequired(control: AbstractControl): ValidationErrors | null {
    const teams = control.get('teams').value;
    const reportingGroups = control.get('reportingGroups').value;

    const noOptionProvided = teams?.length === 0 && reportingGroups?.length === 0;
    return noOptionProvided ? { accessOptionMissing: true } : null;
  }

}
