import { AuditEntry } from './audit-entry';
import { PermissionGroup } from './permission-group';

export class RoleSummaryView {
  id: number;
  name: string;
  applicationName?: string;
  isActive: boolean;
  isSystem: boolean;
  userCount: number;
  adGroupCount: number;
  servicePrincipalCount: number;
  adminCount: number;
  permissions: PermissionGroup[];
  createdBy: string;
  createdDate: Date;
  latestAudit: AuditEntry;
  domain: {
    internal: boolean;
    external: boolean;
  };
  canEditRole: boolean;

  constructor(name?: string,
              applicationName?: string,
              isActive?: boolean,
              userCount?: number,
              adGroupCount?: number,
              servicePrincipalCount?: number,
              isSystem?: boolean) {
    this.name = name;
    this.applicationName = applicationName;
    this.isActive = isActive;
    this.userCount = userCount;
    this.adGroupCount = adGroupCount;
    this.servicePrincipalCount = servicePrincipalCount;
    this.isSystem = isSystem;
  }
}

export enum ErrorMessages {
  duplicateRoleError = 'Role name already exists.',
  duplicateOverviewMappingError = 'Overview mapping for this user already exists.',
  duplicateExternalUserError = 'Email address already exists.',
  duplicateServicePrinciple = 'Service principle with this id already exists.',
  OverviewMappingNotFoundError = 'User no longer exists.',
  genericError = 'An error has occurred. Please contact administrator.'
}
