<wre-toolkit-modal-popup [title]="this.title" [preventDefaultClose]="true" (closeClicked)="confirmCloseModal()">

  <wre-error-message [loading]='loading' [errorMessage]='errorMessage' [errorMessageInfo]='errorMessageInfo'>
  </wre-error-message>

  <form [formGroup]="userForm" *ngIf="!loading">
    <div class="row">
      <div class="col-lg-6">
        <wre-toolkit-text-field class="w-100" inputId="lastName" labelText="Name" [control]="userForm.get('lastName')">
        </wre-toolkit-text-field>
      </div>
      <div class="col-lg-6">
        <wre-toolkit-text-field class="w-100" inputId="mail" labelText="Id" [control]="userForm.get('mail')">
        </wre-toolkit-text-field>
      </div>
    </div>
  </form>

  <div footer style="width: 100%;">
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-end">
        <button type="button" [disabled]="loading || userForm.invalid || !isUserInfoChanged"
          class="btn wre-btn btn-primary ms-3" (click)="saveChanges()">
          Save Changes
        </button>
      </div>
    </div>
  </div>

</wre-toolkit-modal-popup>
