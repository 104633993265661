import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { throwError } from 'rxjs';
import { AppInsightsService } from 'wre-core-lib';
import { ExcelModel } from '../../../../../models/reporting/excel-model';
import { ReportType } from '../../../models/report-type.enum';
import { ReportingService } from '../../../services/reporting.service';

@Component({
  selector: 'wre-report-wizard-config-powerbi',
  templateUrl: './report-wizard-config-powerbi.component.html',
  styleUrls: ['./report-wizard-config-powerbi.component.sass']
})
export class ReportWizardConfigPowerbiComponent implements OnInit {

  @Input() newReportForm: UntypedFormGroup;

  ReportType = ReportType;

  isLoaderVisible = false;
  models: ExcelModel[];

  constructor(private appInsightsService: AppInsightsService,
              private reportingService: ReportingService) { }

  async ngOnInit() {
    await this.getModels();
  }

  async getModels() {
    this.isLoaderVisible = true;
    this.appInsightsService.methodName = 'getApps';
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.models = (await this.reportingService.getPowerBiModels()).sort((a, b) => (a.fileName > b.fileName ? 1 : -1) );
      this.isLoaderVisible = false;
      this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
    } catch (error) {
      this.isLoaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

}
