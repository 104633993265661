export enum confirmationTitles {
  defaultTitle = 'Are you sure you want to exit?',
  deleteTitle = 'Delete'
}

export enum confirmationMessages {
  defaultMessage = 'Do you want to exit, all changes will be lost?',
  deleteUserMappingMessage = 'Are you sure you want to delete mapping'
}

export enum confirmationTexts {
  defaultConfirmationText = 'Yes',
  deleteConfirmationText = 'Yes, Delete'
}

export enum cancelTexts {
  defaultNoText = 'No',
  cancelText = 'Cancel'
}
