import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { WreCoreHeaderConfiguration } from 'wre-core-lib';
import { environment } from '../../../environments/environment';

// To support Google Analytics' dataLayer object which stores custom data
declare global {
  interface Window { dataLayer: any[]; }
}

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html'
})
export class ShellComponent implements OnInit {

  headerConfig: WreCoreHeaderConfiguration;
  pageIsLoading = true;
  userDetails: any;

  constructor(
    private _adminService: AdminService) {

    this.headerConfig = {
      title: 'Gallagher Re Gateway',
      logoPath: './assets/images/gateway-logo-new.svg',
      appName: environment.applicationName
    };
  }

  ngOnInit() {
    this._adminService.pageIsLoading = false;
    this.pageIsLoading = false;
  }

  onActivate(componentReference) {

    if (componentReference.setTitle) {
      // componentReference.anyFunction(); You can call any function of the child component
      componentReference.setTitle.subscribe((data) => {
        if (data !== undefined && data !== '') {
          this.headerConfig.title = data;
        }
      });
    }
  }

}
