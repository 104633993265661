import { Component, OnDestroy } from '@angular/core';
import { RoleSummaryView } from '../../../../../models/role-summary-view';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdminDeleteComponent } from '../../../../../../admin/delete/delete.component';
import { SecurityManagementService } from '../../../../../services/security-management.service';
import { SecurityWorkspaceService } from '../../../../../services/security-workspace.service';
import { Observable, Subscription } from 'rxjs';
import { RolesMainListService } from '../../../../../services/roles/roles-main-list.service';
import { UserPermissions } from 'src/app/models/admin';

@Component({
  selector: 'wre-roles-table',
  templateUrl: './roles-table.component.html',
  styleUrls: ['./roles-table.component.sass']
})
export class RolesTableComponent implements OnDestroy {

  offset = '0';

  modalContent = {
    mainMessage: '',
    entityName: '',
    sideNote: '',
    isConfirm: false,
  };

  modalRef: NgbModalRef;
  modalSubscription: Subscription;

  get roles$(): Observable<RoleSummaryView[]> {
    return this.rolesService.roles$;
  }

  get canDeleteRole(): boolean {
    return this.securityWorkspaceService.hasRequiredPermission(UserPermissions.RolesDelete);
  }

  get canDisableRole(): boolean {
    return this.securityWorkspaceService.hasRequiredPermission(UserPermissions.RolesAdd);
  }

  get canEditRole(): boolean {
    return this.securityWorkspaceService.hasRequiredPermission(UserPermissions.RolesEdit) ||
            this.securityWorkspaceService.hasRequiredPermission(UserPermissions.AuthorisedRolesEdit);
  }

  get canViewRoleDetails(): boolean {
    return this.securityWorkspaceService.hasRequiredPermission(UserPermissions.RolesDetails);
  }

  constructor(public rolesService: RolesMainListService,
              private modalService: NgbModal,
              private securityWorkspaceService: SecurityWorkspaceService,
              private securityService: SecurityManagementService) { }

  ngOnDestroy() {
    if (this.modalSubscription) { this.modalSubscription.unsubscribe(); }
  }

  openDeleteWindow(role: RoleSummaryView) {
    this.openModal();
    this.setDeleteConfirmModalContent(role);

    (this.modalRef.componentInstance as AdminDeleteComponent).deleteConfirmedChange.subscribe(
      roleDeleteConfirmed => roleDeleteConfirmed && this.deleteRole(role.id)
    );
  }

  isRoleDisabled(role) {
    return !((this.canEditRole && role.canEditRole) || this.canViewRoleDetails);
  }

  toggleRoleActive() { }

  private openModal() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true
    };

    this.modalRef = this.modalService.open(
      AdminDeleteComponent,
      modalOptions
    );
    (this.modalRef.componentInstance as AdminDeleteComponent).closeOnConfirm = false;
  }

  private deleteRole(roleId: number) {
    (this.modalRef.componentInstance as AdminDeleteComponent).isLoading = true;
    this.securityService.deleteRole(this.securityWorkspaceService.currentWorkspace, roleId).then(
      _ => {
        this.modalRef.close();
        this.rolesService.reload();
      },
      _ => {
        this.setErrorModalContent();
      }
    );
  }

  toggleRoleStatus(role: RoleSummaryView) {
    this.rolesService.toggleRoleStatus(role);
  }

  private setDeleteConfirmModalContent(role: RoleSummaryView) {
    const component = this.modalRef.componentInstance as AdminDeleteComponent;
    component.isConfirmModal = true;
    component.deleteEntityMessage_h5 = 'Are you sure you want to delete role';
    component.entityName = role.name;
    component.deleteEntityMessage_p = 'This cannot be undone.';
  }

  private setErrorModalContent() {
    const component = this.modalRef.componentInstance as AdminDeleteComponent;
    component.isLoading = false;
    component.isConfirmModal = false;
    component.deleteEntityMessage_h5 = 'Error has occurred when deleting a role';
    component.entityName = '';
    component.deleteEntityMessage_p = '';
    component.setToInfoMode();
  }

}
