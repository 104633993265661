<div class="card">
  <div class="row">
    <div class="col-auto pe-0 d-flex">
      <div class="left-panel">
        <ul>
          <li >
            <div routerLink="roles" routerLinkActive="active" ngbTooltip="Roles" placement="right" [openDelay]="700">
              <button class="btn btn-icon" role="button" type="button">
                <i class="far fa-user-friends"></i>
              </button>
            </div>
          </li>
          <li>
            <div routerLink="permissions" routerLinkActive="active" ngbTooltip="Permissions" placement="right" [openDelay]="700">
              <button class="btn btn-icon" role="button" type="button">
                <i class="far fa-key"></i>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col ps-0 main-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
