<div class="btn-group mb-3">
    <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle dropdown" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{ (currentWorkspace$ | async)?.displayName }}
      <span class="caret"></span>
    </button>
        <div class="dropdown-menu dropdown-menu-right">
            <a *ngFor="let experience of authorizedWorkspaces$ | async" class="nav-link dropdown-item" id="tab-experiences" [routerLink]="['/admin/security/access', experience.url]" routerLinkActive="active">
        {{ experience.displayName }}
      </a>
        </div>
    </div>
</div>

<wre-access-tab></wre-access-tab>
