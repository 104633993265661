import { UserModalComponent } from './user-modal/user-modal.component';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserType } from '../../../models/user-type';
import { ServicePrincipleModalComponent } from './service-principle-modal/service-principle-modal.component';
import { UserModalBaseComponent } from './user-modal-base.component';

@Component({
  selector: 'wre-user-modal-wrapper',
  template: ``,
  styles: []
})
export class UserModalWrapperComponent implements OnInit, OnDestroy {

  @Output() isUpdatedChange = new EventEmitter<boolean>();
  id: string;
  paramSubscription: Subscription;
  userModalRef: NgbModalRef;

  userType: UserType;

  get modalComponent(): UserModalBaseComponent {
    return this.userModalRef?.componentInstance as UserModalBaseComponent;
  }

  constructor(private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.paramSubscription = this.route.paramMap.subscribe(params => {

      const idParam = params.get('id');
      if (idParam != null) {
        this.id = idParam;
        if (this.modalComponent) {
          this.modalComponent.userId = this.id;
        }
      }
      const userTypeParam = params.get('userType');
      this.userType = userTypeParam ? userTypeParam as UserType : null;
    });

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true
    };

    // TODO: Refactor user and ad groups grid. Use switch based on a type.
    if (this.userType) {
      this.userModalRef = this.modalService.open(
        ServicePrincipleModalComponent,
        modalOptions
      );
    } else {
      this.userModalRef = this.modalService.open(
        UserModalComponent,
        modalOptions
      );
    }

    if (this.id) {
      this.modalComponent.userId = this.id;
    }

    this.userModalRef.componentInstance.isUpdatedChange.subscribe(() => {
      this.isUpdatedChange.emit();
    });

    this.userModalRef.result.then(
      _ => this.router.navigate(['/admin/security/users']),
      _ => this.router.navigate(['/admin/security/users'])
    );
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }

}
