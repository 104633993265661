import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wre-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.sass']
})
export class InfoModalComponent {

  @Input() title: string;
  @Input() message: string;

  constructor (public activeModal: NgbActiveModal) { }

  closeModal() {
    this.activeModal.close();
  }

}
