import { SafrOverviewUser } from './safr-overview-user';
import { AccessMapping } from '../../../overview-access/models/access-mapping';
import { User } from '../../../overview-access/models/user';
import { SafrOverviewAccess } from './safr-overview-access';

export class SafrAccessMapping {
  user: SafrOverviewUser;
  access: SafrOverviewAccess;
  lastUpdated: Date;

  public static toSafrAccessMapping(accessMapping: AccessMapping<SafrOverviewAccess>): SafrAccessMapping {
    const safrAccessMapping = new SafrAccessMapping();
    safrAccessMapping.user = new SafrOverviewUser(accessMapping.user.id, accessMapping.user.displayName, accessMapping.user.email);
    safrAccessMapping.access = accessMapping.access;
    safrAccessMapping.lastUpdated = accessMapping.lastUpdateDate;

    return safrAccessMapping;
  }

  public static toAccessMapping(safrAccessMapping: SafrAccessMapping): AccessMapping<SafrOverviewAccess> {
    const accessMapping = new AccessMapping<SafrOverviewAccess>();
    accessMapping.user = new User(safrAccessMapping.user.id, safrAccessMapping.user.name, safrAccessMapping.user.email);
    accessMapping.access = safrAccessMapping.access;
    accessMapping.lastUpdateDate = safrAccessMapping.lastUpdated;

    return accessMapping;
  }
}
