import { SecurityWorkspaceService } from './../../../services/security-workspace.service';
import { ConfirmationService } from './../../../../../shared/services/confirmation.service';
import { confirmationMessages, confirmationTexts, confirmationTitles } from './../../../../../shared/enums/confirmation';
import { SecurityManagementService } from '../../../services/security-management.service';
import { UserInfo } from '../../../models/user-Info';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserType } from '../../../models/user-type';
import { UserGroup } from '../../../models/user-group';

@Component({
  selector: 'wre-user-modal-base',
  template: '',
  styleUrls: []
})
export abstract class UserModalBaseComponent implements OnInit, OnDestroy {

  @Input() userId;
  @Output() isUpdatedChange = new EventEmitter<boolean>();
  @Output() userAddedEvent = new EventEmitter<UserGroup>();

  protected originalUser: UserInfo;

  errorMessageInfo: string;
  errorMessage: any;
  loading = true;
  userForm: UntypedFormGroup;
  title = 'Edit User';
  userInfo: UserInfo = new UserInfo();
  closeConfirmSubscription: Subscription;
  isUserInfoChanged = false;
  userType: UserType;

  constructor(private activeModal: NgbActiveModal,
    protected fb: UntypedFormBuilder,
    private _confirmationService: ConfirmationService,
    private _securityWorkspaceService: SecurityWorkspaceService,
    private securityManagementService: SecurityManagementService) { }

  ngOnInit() {
    this.SetValidation();
    if (this.userId) {
      this.loadUser();
    } else {
      this.loading = false;
    }

    this.userForm.valueChanges.subscribe(() => {
      this.assignUserFormToInfo();
      this.isUserInfoChanged = !this.userInfo.equals(this.originalUser);
    });
  }

  loadUser() {
    this.loading = true;
    this.securityManagementService.getUserById(this.userId).then(role => {
      this.mapResponseIntoUserInfoObject(role.body);
      this.patchOriginalUserIntoForm();
      this.userForm.markAllAsTouched();
      this.loading = false;
    });
  }

  mapResponseIntoUserInfoObject(res) {
    const user = res;
    this.originalUser = new UserInfo(user.firstName, user.lastName, user.email, this.userType);
  }

  patchOriginalUserIntoForm() { this.userForm.patchValue(this.originalUser); }

  saveChanges() {
    this.assignUserFormToInfo();
    this.loading = true;
    if (this.userId) {
      this.securityManagementService.updateUserAsync(this.userId, this.userInfo)
      .then(() => { this.isUpdatedChange.emit(); this.dismissModal(); }, (err) => this.handleSubmissionError(err))
      .finally(() => this.loading = false);
    } else {
      const workspace = this._securityWorkspaceService.currentWorkspace;
      this.securityManagementService.createUserAsync(workspace, this.userInfo)
      .then((res) => { this.userAddedEvent.emit(res.body); this.dismissModal(); }, (err) => this.handleSubmissionError(err))
      .finally(() => this.loading = false);
    }

  }

  confirmCloseModal() {
    this.assignUserFormToInfo();
    if (!this.displayConfirmCloseModal()) {
      this.dismissModal();
      return;
    }

    this.closeConfirmSubscription = this._confirmationService.openModal(confirmationTitles.defaultTitle, confirmationMessages.defaultMessage, confirmationTexts.defaultConfirmationText)
      .subscribe( _ => {
        this._confirmationService.closeModal();
        this.dismissModal();
      });
  }

  private assignUserFormToInfo() { Object.assign(this.userInfo, this.userForm.getRawValue()); this.userInfo.type = this.userType; }

  private dismissModal = () => { this.activeModal.dismiss(); }

  private displayConfirmCloseModal(): boolean {

    if (!this.userId)
      return this.userForm.valid;

    return !this.userInfo.equals(this.originalUser);
  }

  ngOnDestroy() { this.closeConfirmSubscription?.unsubscribe(); }

  abstract SetValidation();
  abstract handleSubmissionError(err);

}
