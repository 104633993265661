<div class="row">
  <div id="filter-checkboxes" class="col-sm-12 col-lg order-sm-2 order-lg-1 wre-filters-container">
    <div class="wre-filters">
      <div class="row">
        <div class="col-auto mb-3">
          <wre-toolkit-toggle-switch
            [(ngModel)]="filter.onlyActive"
            inputId="filterActive"
            labelText="Active">
          </wre-toolkit-toggle-switch>
        </div>
        <div class="col-auto mb-3">
          <wre-toolkit-toggle-switch
            [(ngModel)]="filter.onlyAuthorised"
            inputId="filterAuthorised"
            labelText="Authorised to manage only">
          </wre-toolkit-toggle-switch>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-auto">
          <label class="form-control-label d-block">Domain</label>
          <wre-toolkit-checkbox
            class="me-3"
            [label]="domains.internal.label"
            [id]="'filter-domain-int' + domains.internal.key"
            [value]="domains.internal.key">
          </wre-toolkit-checkbox>
          <wre-toolkit-checkbox
            class="me-3"
            [label]="domains.external.label"
            [id]="'filter-domain-int' + domains.external.key"
            [value]="domains.external.key">
          </wre-toolkit-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-auto date-range-col">
          <label class="form-control-label">Created Date</label>
          <wre-toolkit-date-range-picker-us
            inputId="createdDateRange"
            [inputValue]="filter.createdDate"
            (inputValueChange)="createdDateChange($event)"></wre-toolkit-date-range-picker-us>
        </div>
        <div class="col-auto date-range-col">
          <label class="form-control-label">Last Update Date</label>
          <wre-toolkit-date-range-picker-us
            inputId="lastUpdateDateRange"
            [inputValue]="filter.lastUpdateDate"
            (inputValueChange)="lastUpdateDateChange($event)"></wre-toolkit-date-range-picker-us>
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-lg-auto ms-auto order-sm-1 order-lg-2 mb-3">
    <div class="filters-actions">
      <button (click)="clearFilter()" class="btn wre-btn btn-secondary">Clear Filter</button>
      <button (click)="applyFilter()" class="btn wre-btn btn-primary ms-3">Apply</button>
    </div>
  </div>
</div>
