<div class="form-group mb-0">
  <div class="row">
    <div class="col-sm-6 pe-3 mb-3">
      <wre-toolkit-text-field class="w-100"
                              labelText="Group External Guid"
                              inputId="groupExternalGuid"
                              [control]="newReportForm.get('powerBiConfig.groupExternalGuid')">
      </wre-toolkit-text-field>
      <wre-toolkit-validation-error-message
          [control]="newReportForm.get('powerBiConfig.groupExternalGuid')"
          errorName="required"
          message="Please enter group external guid.">
      </wre-toolkit-validation-error-message>
      <wre-toolkit-validation-error-message
          [control]="newReportForm.get('powerBiConfig.groupExternalGuid')"
          errorName="pattern"
          message="Please enter valid group external guid.">
      </wre-toolkit-validation-error-message>
    </div>
    <div class="col-sm-6 ps-3 mb-3">
      <wre-toolkit-text-field class="w-100"
                              labelText="Report External Guid"
                              inputId="reportExternalGuid"
                              [control]="newReportForm.get('powerBiConfig.reportExternalGuid')">
      </wre-toolkit-text-field>
      <wre-toolkit-validation-error-message
          [control]="newReportForm.get('powerBiConfig.reportExternalGuid')"
          errorName="required"
          message="Please enter report external guid.">
      </wre-toolkit-validation-error-message>
      <wre-toolkit-validation-error-message
          [control]="newReportForm.get('powerBiConfig.reportExternalGuid')"
          errorName="pattern"
          message="Please enter valid report external guid.">
      </wre-toolkit-validation-error-message>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6 pe-3 mb-3">
      <label for="model" class="form-control-label">Model</label>
      <select class="form-control w-100"
              id="model"
              [formControl]="newReportForm.get('modelId')"
      >
        <option [ngValue]="null" selected>{{newReportForm.get('modelId').value == null ? 'Select Model' : 'Remove Model' }}</option>
        <option *ngFor="let model of models" [value]="model.id">{{ model.fileName }}</option>
      </select>
    </div>
    <div class="col-sm-6 ps-3 mb-3">
      <wre-toolkit-text-field class="w-100"
                              labelText="Default Section"
                              inputId="defaultSection"
                              [control]="newReportForm.get('powerBiConfig.defaultSection')">
      </wre-toolkit-text-field>
    </div>
  </div>

  <div class="row mb-3 mt-3">
    <div class="col-12">
      <ng-container *ngIf="+newReportForm.get('type').value === ReportType.PowerBI">
        <wre-toolkit-toggle-switch name="rls" class="me-3" labelText="Row level Security"
                                    [control]="newReportForm.get('powerBiConfig.rowLevelSecurity')"
        >
        </wre-toolkit-toggle-switch>
        <wre-toolkit-toggle-switch name="aas" class="me-3" labelText="AAS Datasource"
                                    ngbTooltip="Azure Analysis Services" container="body"
                                    [control]="newReportForm.get('powerBiConfig.azureAnalysisServices')"
        >
        </wre-toolkit-toggle-switch>
        <wre-toolkit-toggle-switch name="sharedBookmarksVisible" labelText="Shared Bookmarks Visible"
                                    [control]="newReportForm.get('powerBiConfig.sharedBookmarksVisible')"
        >
        </wre-toolkit-toggle-switch>
      </ng-container>
    </div>
  </div>

  <div *ngIf="newReportForm.get('powerBiConfig.azureAnalysisServices').value === true"
       role="alert" class="alert alert-info mb-0">
    Service principles need to be added to the datasource to use Azure Analysis Services.
  </div>
</div>
