import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserCacheLocation } from '@azure/msal-browser';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { AuthConfig, WreAuthlibModule } from 'wre-authlib';
import { WreCoreLibModule } from 'wre-core-lib';
import { WreToolkitLibModule } from 'wre-toolkit-lib';
import { environment } from '../environments/environment';
import { AdminModule } from './admin-portal/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientAdvocacyModule } from './client-advocacy/client-advocacy.module';
import { MaintenancepageComponent } from './components/holding-pages/maintenancepage/maintenancepage.component';
import { SharedComponentsModule } from './components/shared/shared-components.module';
import { ShellComponent } from './components/shell/shell.component';
import { WorkFlowManagerModule } from './embed/work-flow-manager/work-flow-manager.module';
import { HomeModule } from './home/home.module';
import { RolesConfig } from './models/roles-config';
import { NewsModule } from './news/news.module';
import { PlacementExellenceModelModule } from './placement-exellence-model/placement-exellence-model.module';
import { RoadmapModule } from './road-map/road-map.module';
import { UserTypeSelectionModule } from './user-type-selection/user-type-selection.module';

const internalAuthConfig = {
  protectedResourceMap: environment.protectedResourceMap,
  customScopes: environment.customScopes,
  auth: {
    clientId: environment.clientId,
    authority: environment.authority,
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  homePageUrl: '/dashboard',
  unauthorisedPageUrl: '/unauthorized',
  applicationName: environment.applicationName,
  cacheTimeInSeconds: environment.rolesCacheSeconds,
  authenticationCacheSeconds: environment.authenticationCacheSeconds,
  authorisationUrl: environment.authorisationUrl,
} as AuthConfig;

const externalAuthConfig = {
  protectedResourceMap: environment.b2cProtectedResourceMap,
  customScopes: environment.b2cCustomScopes,
  auth: {
    clientId: environment.b2cClientId,
    authority: environment.b2cAuthority,
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.postLogoutRedirectUri,
    knownAuthorities: environment.b2cKnownAuthorities,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  homePageUrl: '/dashboard',
  unauthorisedPageUrl: '/unauthorized',
  applicationName: environment.applicationName,
  cacheTimeInSeconds: environment.rolesCacheSeconds,
  authenticationCacheSeconds: environment.authenticationCacheSeconds,
  authorisationUrl: environment.authorisationUrl,
  resetPasswordAuthority: environment.b2cResetPasswordAuthority,
} as AuthConfig;

@NgModule({
  declarations: [AppComponent, ShellComponent, MaintenancepageComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    UserTypeSelectionModule,
    WreAuthlibModule.forRoot(
      internalAuthConfig,
      externalAuthConfig,
      environment.loggingEnabled,
      environment.logLevel,
    ),
    WreCoreLibModule.forRoot(
      environment.gatewayUrl,
      environment.gatewayApiUrl,
      environment.appInsightsIntrumentationKey,
      environment.applicationName,
      environment.timesurveyApiURI,
      environment.intranetUri,
      environment.hideNavbarSearchExperienceForPathNames,
      environment.adminGroups,
      environment.applicationName,
      environment.rolesConfig,
      environment.googleTagManagerIframeSrc,
      environment.gtmScriptRelativePath,
      environment.cookie,
      environment.isInternalApp,
    ),
    TourMatMenuModule,
    WreToolkitLibModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    PlacementExellenceModelModule,
    ClientAdvocacyModule,
    RoadmapModule,
    HomeModule,
    NewsModule,
    SharedComponentsModule,
    FormsModule,
    AdminModule,
    WorkFlowManagerModule,
  ],
  providers: [NgbActiveModal, { provide: RolesConfig, useValue: new RolesConfig() }],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
