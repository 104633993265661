import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wre-user-details-wrapper',
  templateUrl: './user-details-wrapper.component.html',
  styleUrls: ['./user-details-wrapper.component.sass']
})
export class UserDetailsWrapperComponent implements OnInit {

  userId: string;
  isOpen = false;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.isOpen = true;
      this.userId = params.get('id');
    });
  }

  closeSidebar() {
    this.isOpen = false;
    this.router.navigate(['admin/security/users']);
  }

}
