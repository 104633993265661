<div *ngIf='errorMessage' class="row text-center">
  <div class="col">
    <span class="wre-form-error">
      {{ errorMessageInfo }}
    </span>
  </div>
</div>

<div class="row" *ngIf="!loading && user">
  <div class="col-md-12">
    <div class="summary-bar border-bottom d-flex align-items-center">
      <label class="form-control-label">
        User Name:
      </label>
      <span class="summary-detail" wreToolkitTitleWhenTruncated ngbTooltip container="body" placement="bottom">
        <span *ngIf="user.displayName && user.displayName.trim() === ','">
          &#x2015;
        </span>
        <span *ngIf="user.displayName && user.displayName.trim() !== ','">
          {{ user.displayName }}
        </span>
      </span>

      <label class="form-control-label border-start ms-3 ps-3" *ngIf="user.email">
        Email Address:
      </label>
      <span class="summary-detail" *ngIf="user.email" wreToolkitTitleWhenTruncated ngbTooltip container="body" placement="bottom">
        {{ user.email }}
      </span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12" style="height: 90vh;" *ngIf="loading">
    <div class="d-flex justify-content-center align-items-center h-100 w-100">
      <div class="loading" [hidden]='errorMessage'></div>
    </div>
  </div>
</div>

<div class="user-details-cont" *ngIf="!loading && user">
  <ul ngbNav #nav="ngbNav" activeId="Gateway" class="nav-tabs">

    <li ngbNavItem="Gateway">
      <a ngbNavLink>Gateway</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col">
            <div class="wre-tab-container">
              <div *ngIf="gatewayApplications && gatewayApplications.length > 0">
                <div *ngFor="let application of gatewayApplications">
                  <wre-user-app-details [application]="application" displayName="Gateway"></wre-user-app-details>
                </div>
              </div>

              <div *ngIf="gatewayApplications && gatewayApplications.length === 0" class="pt-3">
                No Roles assigned.
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="Reporting">
      <a ngbNavLink>Reporting</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col">
            <div class="wre-tab-container">
              <div *ngIf="reportingApplications && reportingApplications.length > 0">
                <div *ngFor="let application of reportingApplications">
                  <wre-user-app-details [application]="application" displayName="Interactive Reports">
                  </wre-user-app-details>
                </div>
              </div>

              <div *ngIf="reportingApplications && reportingApplications.length === 0" class="pt-3">
                No Roles assigned.
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="Quality">
      <a ngbNavLink>Quality</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col">
            <div class="wre-tab-container">
              <div *ngIf="rqmApplications && rqmApplications.length > 0">
                <div *ngFor="let application of rqmApplications">
                  <wre-user-app-details [application]="application" displayName="SAFR"></wre-user-app-details>
                </div>
              </div>

              <div *ngIf="rqmApplications && rqmApplications.length === 0" class="pt-3">
                No Roles assigned.
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="Compliance">
      <a ngbNavLink>Compliance</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col">
            <div class="wre-tab-container">
              <div *ngIf="sanctionsApplications?.length > 0; else noRoles">
                <div *ngFor="let application of sanctionsApplications">
                  <wre-user-app-details *ngIf="application.applicationName === 'wre-sanctions'" [application]="application" displayName="Sanctions Identification Tool"></wre-user-app-details>

                  <wre-user-app-details *ngIf="application.applicationName === 'wre-referral'" [application]="application" displayName="Sanctions Referral Tool"></wre-user-app-details>
                </div>
              </div>

              <ng-template #noRoles>
                <div *ngIf="rqmApplications && rqmApplications.length === 0" class="pt-3">
                  No Roles assigned.
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-3"></div>
</div>
