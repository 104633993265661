import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccessMapping } from '../models/access-mapping';
import { Subscription } from 'rxjs';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { cancelTexts, confirmationMessages, confirmationTexts, confirmationTitles } from '../../../shared/enums/confirmation';
import { filter, take } from 'rxjs/operators';
import { OverviewSearchService } from '../services/overview-search.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'wre-overview-access-table-base',
  template: ''
})
export class OverviewAccessTableComponent<T> implements OnInit, OnDestroy {
  offset = '0';
  margin = 430;
  tableHeight = window.innerHeight - this.margin;

  accessMappings: AccessMapping<T>[] = [];
  total = 0;
  loading = true;
  error: Error;

  get highlightTerm(): string { return this.listService.searchTerm; }

  private subscription = new Subscription();

  constructor(public listService: OverviewSearchService<T>,
              public apiService: ApiService<T>,
              public confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    window.onresize = () => {
      if (this.tableHeight !== window.innerHeight - this.margin) {
        this.resizeTable();
      }
    };

    this.subscription.add(
      this.listService.searchResult$.subscribe(searchResult => {
        this.accessMappings = searchResult.overviewAccess;
        this.total = searchResult.total;
        this.loading = searchResult.loading;
        this.error = searchResult.error;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  deleteUserMappings(userId: string, userName: string): void {
    this.subscription.add(
      this.confirmationService
        .openModal(confirmationTitles.deleteTitle, confirmationMessages.deleteUserMappingMessage,
          confirmationTexts.deleteConfirmationText, cancelTexts.cancelText, userName)
        .pipe(filter(close => close), take(1))
        .subscribe(() => {
          this.confirmationService.setLoading(true);
          this.apiService.deleteAccessMapping(userId).subscribe(() => {
            this.confirmationService.setLoading(false);
            this.listService.loadOverviewAccess();
            this.confirmationService.closeModal();
          });
        })
    );
  }

  private resizeTable() {
    this.tableHeight = window.innerHeight - this.margin;
  }
}
