import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbDropdown, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wre-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.sass']
})
export class MultiselectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() inputId: string;
  @Input() placeholder = 'Search user...';
  @Input() isInvalid: boolean;
  @Input() inputTabIndex: string;
  @Input() inputName: string;
  @Input() list = [];
  @Input() labelText = 'label';
  @Input() disabled: boolean;

  get name(): string { return this.inputName ? this.inputName : this.inputId; }
  get selected() { return document.getElementById(this.inputId + '-item').querySelector('.selected'); }

  selectedItems = [];
  existingItems = [];

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    switch (event.code) {
      case 'Tab':
        // on tabbing away from dropdown close the dropdown if open and remove selected class if exists.
        if (this.dropdown.isOpen()) {
          this.dropdown.close();
          if (!!this.selected) {
            this.selected.classList.remove('selected');
          }
        }
        break;
      case 'Space': {
        event.preventDefault(); // prevent default scroll on spacebar
        if (!this.dropdown.isOpen()) {
          // open dropdown if not open and select the first dropdown value
          this.dropdown.open();
          this.selectItemInDropdownList('ArrowDown');
        } else {
          if (!!this.selected) {
            // on spacebar reusing enter event to add dropdown item to list
            this.selectItemInDropdownList('Enter');
          }
        }
      }
    }
  }

  constructor(config: NgbDropdownConfig) {
    config.autoClose = 'outside'; // close dropdown only when click event happens outside dropdown
  }

  ngOnInit() {
    this.selectedItems = this.control.value.length > 0 ? this.control.value : [];
    this.existingItems = [...this.selectedItems];
  }

  onKeyUp(event) {
    switch (event.key) {
      case 'Escape': {
        this.selected.classList.remove('selected');
        break;
      }
      case 'ArrowDown': {
        this.selectItemInDropdownList(event.key);
        break;
      }
      case 'ArrowUp': {
        this.selectItemInDropdownList(event.key);
        break;
      }
      case 'Enter': {
        this.selectItemInDropdownList(event.key);
        break;
      }
    }
  }

  selectItemInDropdownList(eventKey: string) {
    const list = document.getElementById(this.inputId + '-item');
    const selected = list.querySelector('.selected');
    const element = document.getElementById(this.inputId);
    const scrollHeight = element === null ? 0 : element.getBoundingClientRect().height;
    if (selected) {
      switch (eventKey) {
        case 'ArrowUp': {
          const prevElement = selected.previousElementSibling;
          if (prevElement) {
            selected.classList.remove('selected');
            prevElement.classList.add('selected');
            list.scrollTo({ top: list.scrollTop -= scrollHeight, behavior: 'smooth' });
          }
          break;
        }
        case 'ArrowDown': {
          const nextElement = selected.nextElementSibling;
          if (nextElement) {
            selected.classList.remove('selected');
            nextElement.classList.add('selected');
            list.scrollTo({ top: list.scrollTop += scrollHeight, behavior: 'smooth' });
          }
          break;
        }
        case 'Enter': {
          const item = (selected as HTMLElement).innerText.trim();
          this.toggle(item);
          break;
        }
      }
    } else {
      const subjectToSelect = list.querySelector('a');
      if (subjectToSelect) {
        subjectToSelect.classList.add('selected');
      }
    }
  }

  toggle(item: string) {
    if (!this.selectedItems.includes(item)) {
      this.addItem(item);
    } else {
      this.removeItem(item);
    }
  }

  private addItem(item: string) {
    this.selectedItems.push(item);
    this.PatchFormControl();
  }

  private removeItem(item) {
    if (item !== undefined) {
      let index = this.selectedItems.indexOf(item);
      this.selectedItems.splice(index, 1);
      index = this.existingItems.indexOf(item);
      this.existingItems.splice(index, 1);
      this.PatchFormControl();
    }
  }

  private PatchFormControl() {
    this.control.setValue(this.selectedItems);
    this.control.markAsDirty();
  }

}
