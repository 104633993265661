import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { retryWhen } from 'rxjs/operators';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class RoadmapsService extends HttpService {

    roadmapUrl = environment.gatewayApiUrl + 'roadmaps/';

    constructor(http: HttpClient) {
        super(http);
    }


    getRoadmapsContents(): Observable<HttpResponse<any[]>> {
        return this.http.get<any>(this.roadmapUrl, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

}
