import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-placement-excellence-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.sass']
})
export class CardComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() cardImage: string;
  @Input() route: string;

  cardImageSpecified: boolean;

  constructor () { }

  ngOnInit() { }

}
