import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Applications} from '../../../../models/admin';
import { AdminFormService } from '../../services/admin-form.service';
import { SecurityRole } from '../../../../models/security-role';

@Component({
  selector: 'wre-security-role-form',
  templateUrl: './security-role-form.component.html',
  styleUrls: ['./security-role-form.component.sass']
})
export class SecurityRoleFormComponent implements OnInit {

  @Input() securityRolesForm: UntypedFormControl;
  @Input() application: Applications;
  @Input() displayDropdownLabel = false;

  get securityRoles(): SecurityRole[] {
    return this.securityRolesForm.value as SecurityRole[];
  }

  securityRoleFormGroup: UntypedFormGroup;

  constructor(private adminFormService: AdminFormService) { }

  ngOnInit() {
    this.securityRoleFormGroup = this.adminFormService.createSecurityRoleFormGroup();
  }

  onAddRole() {
    const selectedRole = this.securityRoleFormGroup.value as SecurityRole;
    if (this.securityRoles.find(s => s.guid === selectedRole.guid) === undefined) {
      this.securityRoles.push(selectedRole);
    }
    this.securityRoleFormGroup.reset();
  }

  deleteRole(idx: number) {
    this.securityRoles.splice(idx, 1);
  }

}
