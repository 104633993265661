import { Component, Input } from '@angular/core';
import { UserGroup } from '../../../models/user-group';
import { UntypedFormControl } from '@angular/forms';
import { UserType } from '../../../models/user-type';

@Component({
  selector: 'wre-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.sass']
})
export class UsersTableComponent {

  UserType = UserType;

  @Input() control: UntypedFormControl;
  @Input() users: UserGroup[];
  @Input() reviewStep = false;
  @Input() hasMemberPermissions: boolean;

  offset = '0';
  get usersAndAdGroups(): UserGroup[] {
    const list = !!this.control ? this.control.value : this.users;
    return !!list ? list.sort(
      (a, b) => {
        let atype = (a.type === UserType.externalUser || a.type === UserType.internalUser) ? UserType.users : a.type;
        let btype = (b.type === UserType.externalUser || b.type === UserType.internalUser) ? UserType.users : b.type;
        if(atype === UserType.servicePrinciple) { atype = 'z'+atype }
        if(btype === UserType.servicePrinciple) { btype = 'z'+btype }

        if (atype !== btype) {
          return atype > btype ? 1 : -1;
        } else {
          return (a.displayName as string).toLowerCase() > (b.displayName as string).toLowerCase() ? 1 : -1;
        }
      }) : [];
  }

  remove(item: UserGroup) {
    if (item !== undefined && this.hasMemberPermissions) {
      const currentControlValue = this.control.value as UserGroup[];
      this.control.setValue(currentControlValue.filter(v => v.id !== item.id));
      this.control.markAsDirty();
    }
  }

}
