<div class="d-flex justify-content-center" *ngIf="(isLoading | async)">
	<div class="d-flex align-items-center" style="height: 70px;">
		<div class="loading"></div>
	</div>
</div>
<div *ngIf="errorMessageInfo" class="row text-center">
	<div class="col">
		<span class="wre-form-error">
			{{ errorMessageInfo }}
		</span>
	</div>
</div>

<div class="wre-table-container" *ngIf="!(isLoading | async) && !errorMessageInfo">
	<div #tableContainer class="table-responsive">
		<table class="table wre-table fixed-column table-hover">
			<thead>
				<tr>
					<th class="text-nowrap column-action fixed-column">&nbsp;</th>
					<th class="text-nowrap col-ellipsis column-name">Field Name</th>
					<th class="text-nowrap col-ellipsis column-order-type">Order Type</th>
					<th class="text-nowrap col-ellipsis column-options text-center">Options</th>
					<th class="text-nowrap col-ellipsis column-last-updated">Last Updated</th>
					<th class="text-nowrap col-ellipsis column-last-updated-by">Last Updated By</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let field of (fields | async)">
					<td class="text-nowrap sticky-column column-action fixed-column">
						<div ngbDropdown container="body">
							<button class="btn btn-secondary" ngbDropdownToggle>Action</button>
							<div ngbDropdownMenu>
								<button ngbDropdownItem [routerLink]="[field.id, {outlets: { modal: 'edit' } } ]">Edit</button>
							</div>
						</div>
					</td>
					<td class="text-nowrap col-ellipsis column-name"
							[ngbTooltip]="!!field.tooltip ? field.tooltip : ''" container="body">{{ field.name }}
					</td>
					<td class="text-nowrap col-ellipsis column-order-type">{{ FieldOrderType[field.order] }}</td>
					<td class="text-nowrap col-ellipsis column-options text-center">{{ field.optionsCount }}</td>
					<td class="text-nowrap col-ellipsis column-last-updated">{{ field.lastUpdatedDate != null
						? (field.lastUpdatedDate | date:'dd/MMM/yyyy' : 'UTC')
						: '&#x2015;' }}</td>
					<td class="text-nowrap col-ellipsis column-last-updated-by">{{ field.lastUpdatedBy.displayName }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
