<div class="card-header">
    <h6 class="card-title me-auto">Permissions</h6>
    <div class="card-options">
        <div class="input-group prepend">
            <div class="d-flex">
                <label for="rolesSearchText"></label>
                <input tourAnchor="permissionsSearch.anchor" [(ngModel)]="rolesService.searchTerm" [disabled]="(rolesService.roles$ | async)?.length === 0 && !rolesService.isSearchMode" class="form-control" id="rolesSearchText" maxlength="50" name="searchTerm" autocomplete="off"
                    placeholder="Search...">
                <span class="input-group-text" id="basic-addon2"><i class="far fa-search"></i></span>
            </div>
        </div>
    </div>
</div>

<div class="card-body">
    <div class="row">
        <div class="col-12">

            <ng-container *ngIf="loading; then loadingBlock else tableBlock"></ng-container>

            <ng-template #loadingBlock>
                <div class="d-flex justify-content-center">
                    <div class="d-flex align-items-center" style="height: 70px;">
                        <div class="loading"></div>
                    </div>
                </div>
            </ng-template>

            <ng-template #tableBlock>
                <ng-container *ngIf="(rolesService.error$ | async); then errorBlock else rolesTableBlock"></ng-container>
                <ng-template #errorBlock>
                    <div class="no-results">
                        Failed to retrieve the roles.
                    </div>
                </ng-template>

                <ng-template #rolesTableBlock>

                    <ng-container *ngIf="(rolesService.roles$ | async)?.length === 0; then noRecordResult else ResultBlock">
                    </ng-container>

                    <ng-template #noRecordResult>
                        <ng-container *ngIf="rolesService.isSearchMode; then noSearchResult else noRecordResult"></ng-container>

                        <ng-template #noSearchResult>
                            <div class="no-results">
                                No items were found based on your search criteria. Please try again.
                            </div>
                        </ng-template>

                        <ng-template #noRecordResult>
                            <div class="no-results">
                                No records to display
                            </div>
                        </ng-template>
                    </ng-template>

                    <ng-template #ResultBlock>
                        <wre-roles-permissions-grid></wre-roles-permissions-grid>
                        <wre-access-pagination [total]="rolesService.total$ | async" [(pageSize)]="rolesService.pageSize" [(page)]="rolesService.page">
                        </wre-access-pagination>
                    </ng-template>
                </ng-template>
            </ng-template>
        </div>
    </div>
</div>
