import { Component, OnInit } from '@angular/core';
import { Tabulator } from 'tabulator-tables';
import { ClientAdvocacyService } from 'src/app/services/client-advocacy.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-client-advocacy-advocate-list',
  templateUrl: './client-advocacy-advocate-list.component.html',
  styleUrls: ['./client-advocacy-advocate-list.component.sass']
})
export class ClientAdvocacyAdvocateListComponent implements OnInit {

  columnNames: any[];
  advocateListTable: Tabulator;
  tableItems: any[];
  flatternItems: any[];
  tableHeight = window.innerHeight - 323;
  dataTreeStartExpanded = false;
  searchTerm: string;
  listUpdated: Date;

  isLoading = true;

  // Use to hook keyup event for the search input
  public subject: Subject<string> = new Subject();

  constructor(private service: ClientAdvocacyService) { }

  ngOnInit() {
    this.loadData();

    this.subject.pipe(
      debounceTime(200)
    ).subscribe(() => {
      this.isLoading = true;
      this.search();
    });

    // Redraw table
    window.onresize = (e) => {
      if (this.tableHeight !== window.innerHeight - 367) {
        this.resizeTable();
      }
    };
  }

  resizeTable() {
    this.isLoading = true;
    this.tableHeight = window.innerHeight - 367;
    this.setTableHeaders();
    this.advocateListTable.setData(this.tableItems);
    this.search();
  }

  loadData() {
    this.setTableHeaders();
    this.service.getAdvocateList()
      .subscribe(res => {
        this.tableItems = res.body.data;
        this.listUpdated = res.body.time_stamp;
        this.advocateListTable.setData(this.tableItems);
        this.isLoading = false;
        // this.flatternItems = this.flatternCollection(res.body.data);
      });
  }

  // flatternCollection(tableItems: any[]): any[] {
  //   const _flatten = (acc, arr: any[]): any[] =>
  //     arr.reduce((acc, item) => acc.concat(Array.isArray(item._children) ? _flatten([], item._children) : item), acc);

  //   return _flatten([], tableItems);
  // }


  anyChildContainsRecord(collection, filter, self): boolean {
    if (collection === undefined || collection === null || collection.length === 0) {
      return false;
    }

    return collection.filter((record) => {
      return self(record._children, filter, self) ||
        record.Company.toLowerCase().indexOf(filter) !== -1 ||
        record.AdvocatePartner.toLowerCase().indexOf(filter) !== -1 ||
        record.ClientAdvocate.toLowerCase().indexOf(filter) !== -1 ||
        record.SpecialtyAdvocate.toLowerCase().indexOf(filter) !== -1 ||
        record.InternationalAdvocate.toLowerCase().indexOf(filter) !== -1 ||
        record.NorthAmericaAdvocate.toLowerCase().indexOf(filter) !== -1;
    }).length > 0;
  }

  customFilter(data, filterParams) {
    return data.Company.toLowerCase().indexOf(filterParams.filter) !== -1 ||
      data.AdvocatePartner.toLowerCase().indexOf(filterParams.filter) !== -1 ||
      data.ClientAdvocate.toLowerCase().indexOf(filterParams.filter) !== -1 ||
      data.SpecialtyAdvocate.toLowerCase().indexOf(filterParams.filter) !== -1 ||
      data.InternationalAdvocate.toLowerCase().indexOf(filterParams.filter) !== -1 ||
      data.NorthAmericaAdvocate.toLowerCase().indexOf(filterParams.filter) !== -1 ||
      filterParams.recursiveFunc(data._children, filterParams.filter, filterParams.recursiveFunc);
  }

  search() {
    if (this.searchTerm == null || this.searchTerm.trim() === '') {
      this.dataTreeStartExpanded = false;
      this.setTableHeaders();
      this.advocateListTable.setData(this.tableItems);
      this.isLoading = false;
      return;
    }

    if (this.searchTerm.trim().length >= 2) {
      this.dataTreeStartExpanded = true;
      this.setTableHeaders();
      this.advocateListTable.setData(this.tableItems);

      this.advocateListTable.setFilter(this.customFilter,
        {
          filter: this.searchTerm.trim().toLowerCase(),
          recursiveFunc: this.anyChildContainsRecord
        });
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  formatTableCell(cell, formatterParams) {
    const element = cell.getElement();
    element.style.heigth = '100%';
    return cell.getValue();
  }

  formatPartnerTableValue(value, data, type, params, component) {
    // value - original value of the cell
    // data - the data for the row
    // type - the type of mutation occurring  (data|edit)
    // params - the mutatorParams object from the column definition
    // component - when the "type" argument is "edit", this contains the cell component for the edited cell,
    //             otherwise it is the column component for the column

    if (value === '' || value.indexOf('mailto:') !== -1) { return value; }

    let emailAddress = '';
    if (params.field === 'AdvocatePartner') { emailAddress = data.AdvocatePartner_Email; }
    if (params.field === 'ClientAdvocate') { emailAddress = data.ClientAdvocate_Email; }
    if (params.field === 'SpecialtyAdvocate') { emailAddress = data.SpecialtyAdvocate_Email; }
    if (params.field === 'InternationalAdvocate') { emailAddress = data.InternationalAdvocate_Email; }
    if (params.field === 'NorthAmericaAdvocate') { emailAddress = data.NorthAmericaAdvocate_Email; }

    return '<a href="mailto:' + emailAddress + '" title="' + value + '">' + value + '</a>'; // return the new value for the cell data.
  }

  setTableHeaders() {
    this.columnNames = [
      {
        title: 'Company <span class="fw-normal">(links to CRM company record)</span>',
        field: 'Company',
        widthGrow: 2,
        cssClass: 'pe-3',
        formatter: this.formatTableCell,

        mutator: function (value, data, type, params, component) {
          if (value.indexOf('<a href=') !== -1) { return value; }

          return '<a href="' + data.CompanyUri + '" target="_blank"  title="' + value + '">' + value + '</a>';
        },
        variableHeight: true,
        tooltip: false,
        resizable: false,
        sorterParams: { alignEmptyValues: 'bottom' }
      },
      {
        title: 'Type',
        field: 'CustomerTypeCode',
        width: '95',
        cssClass: 'pe-3',
        formatter: 'textarea',
        resizable: false,

        sorterParams: { alignEmptyValues: 'bottom' }
      },
      {
        title: 'Advocate Partner',
        field: 'AdvocatePartner',
        formatter: this.formatTableCell,
        mutator: this.formatPartnerTableValue,
        mutatorParams: { field: 'AdvocatePartner' },
        tooltip: false,
        headerTooltip: true,
        resizable: false,

        sorterParams: { alignEmptyValues: 'bottom' }
      },
      {
        title: 'WRe Client Advocate',
        field: 'ClientAdvocate',
        headerTooltip: true,
        formatter: this.formatTableCell,
        mutator: this.formatPartnerTableValue,
        mutatorParams: { field: 'ClientAdvocate' },

        tooltip: false,
        resizable: false,

        sorterParams: { alignEmptyValues: 'bottom' }
      },
      {
        title: 'Specialty Advocate',
        field: 'SpecialtyAdvocate',
        headerTooltip: true,
        formatter: this.formatTableCell,
        mutator: this.formatPartnerTableValue,
        mutatorParams: { field: 'SpecialtyAdvocate' },
        tooltip: false,
        resizable: false,

        sorterParams: { alignEmptyValues: 'bottom' }
      },
      {
        title: 'International Advocate',
        field: 'InternationalAdvocate',
        headerTooltip: true,
        formatter: this.formatTableCell,
        mutator: this.formatPartnerTableValue,
        mutatorParams: { field: 'InternationalAdvocate' },
        tooltip: false,
        resizable: false,

        sorterParams: { alignEmptyValues: 'bottom' }
      },
      {
        title: 'North America Advocate',
        field: 'NorthAmericaAdvocate',
        headerTooltip: true,
        formatter: this.formatTableCell,
        mutator: this.formatPartnerTableValue,
        mutatorParams: { field: 'NorthAmericaAdvocate' },
        tooltip: false,
        resizable: false,

        sorterParams: { alignEmptyValues: 'bottom' }
      }
    ];

    // reference id of div where table is to be displayed (prepend #)
    this.advocateListTable = new Tabulator('#advocate-list-table', {

      dataTree: true,
      dataTreeStartExpanded: this.dataTreeStartExpanded,
      dataTreeExpandElement: '<i class="far fa-plus-square pe-2 dataTreeControl"></i>',
      dataTreeCollapseElement: '<i class="far fa-minus-square pe-2 dataTreeControl"></i>',

      layout: 'fitColumns',
      groupToggleElement: 'header',
      tooltipGenerationMode: 'hover',

      // ---- Performance improvement for a big data - Start
      height: this.tableHeight, // Height required, Important.

      virtualDomBuffer: 25,
      // progressiveRender: true,
      // progressiveRenderSize: 5,
      // progressiveRenderMargin: 100,
      // ----- Performance improvement for a big data - End

      // initialSort: [
      //   { column: 'ParentCompany', dir: 'asc' },
      //   { column: 'Company', dir: 'asc' }
      // ]
    });

    this.advocateListTable.setColumns(this.columnNames);
  }
}
