<div class="wre-table-container">
  <div #tableContainer
       class="table-responsive"
  >
    <table class="table wre-table fixed-column">
      <thead>
        <tr>
          <th class="sticky-column column-name fixed-column">Role Name</th>
          <th *ngFor="let permission of (permissions$ | async); index as colIdx"
              class="column-permission text-center"
              [class.highlighted]="colIdx === hoveredColumn"
              (mouseenter)="setHovered(colIdx, null)"
              (mouseleave)="resetHovered()"
          >
            {{permission.name}}
          </th>
        </tr>
      </thead>
      <tbody class="border-bottom">
        <tr *ngFor="let role of (roles$ | async); index as rowIdx">
          <td class="sticky-column column-name fixed-column"
              wreToolkitTitleWhenTruncated
              ngbTooltip
              container="body"
              [class.highlighted]="rowIdx === hoveredRow"
              (mouseenter)="setHovered(null, rowIdx)"
              (mouseleave)="resetHovered()"
          >
            <ngb-highlight [result]="role.name" [term]="rolesService.searchTerm"></ngb-highlight>
          </td>
          <td *ngFor="let permission of (permissions$ | async); index as colIdx"
              (mouseenter)="setHovered(colIdx, rowIdx)"
              (mouseleave)="resetHovered()"
              class="column-permission text-center"
              [class.highlighted]="colIdx === hoveredColumn || rowIdx === hoveredRow"
              [class.main-highlighted]="colIdx === hoveredColumn && rowIdx === hoveredRow"
          >
            <ng-container *ngIf="checkRolePermission(role, permission); then checkBlock else timesBlock"></ng-container>
            <ng-template #checkBlock><i class="fa fa-check" aria-hidden="true"></i></ng-template>
            <ng-template #timesBlock><i #timesBlock class="fa fa-times" aria-hidden="true"></i></ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
