import { PermissionGroup } from './permission-group';
import { UserGroup } from './user-group';

export class RoleDetailsView {
  id?: number;
  name: string;
  applicationName?: string;
  isActive: boolean;
  isSystem: boolean;
  users: UserGroup[];
  admins: UserGroup[];
  permissions: PermissionGroup[];
  canEditRole: boolean;

  public equals(role: RoleDetailsView): boolean {
    return this.name === role.name &&
      this.isActive === role.isActive &&
      this.permissions.length === role.permissions.length &&
      this.users.length === role.users.length &&
      this.admins.length === role.admins.length &&
      this.permissions.map(p => p.id).every(i => role.permissions.map(p => p.id).includes(i)) &&
      this.users.map(u => u.id).every(i => role.users.map(u => u.id).includes(i)) &&
      this.admins.map(a => a.id).every(i => role.admins.map(a => a.id).includes(i));
  }
}
