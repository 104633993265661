import { DateRange } from './date-range';

export class RoleFilter {
  onlyActive: boolean;
  onlyAuthorised: boolean;
  domain: {
    internal: boolean;
    external: boolean;
  };
  createdDate: DateRange;
  lastUpdateDate: DateRange;

  constructor(filter?: {
    onlyActive: boolean;
    onlyAuthorised: boolean;
    domain: {
      internal: boolean;
      external: boolean;
    };
    createdDate: DateRange;
    lastUpdateDate: DateRange
  }) {
    if (!filter) {
      // Default constructor
      this.onlyActive = false;
      this.onlyAuthorised = true;
      this.domain = {
        internal: false,
        external: false
      };
      this.createdDate = new DateRange(null, null);
      this.lastUpdateDate = new DateRange(null, null);
    } else {
      this.onlyActive = filter.onlyActive;
      this.onlyAuthorised = filter.onlyAuthorised;
      this.domain = {
        internal: filter.domain.internal,
        external: filter.domain.external
      };
      this.createdDate = filter.createdDate;
      this.lastUpdateDate = filter.lastUpdateDate;
    }
  }

  public equal(filter: RoleFilter): boolean {
    return this.onlyActive === filter.onlyActive &&
      this.onlyAuthorised === filter.onlyAuthorised &&
      this.domain.internal === this.domain.internal &&
      this.domain.external === this.domain.external &&
      this.createdDate.equal(filter.createdDate) &&
      this.lastUpdateDate.equal(filter.lastUpdateDate);
  }

  public copy(): RoleFilter {
    const newFilter = new RoleFilter();
    newFilter.onlyActive = this.onlyActive;
    newFilter.onlyAuthorised = this.onlyAuthorised;
    newFilter.domain = {
      internal: this.domain.internal,
      external: this.domain.external
    };
    newFilter.createdDate = new DateRange(this.createdDate.begin, this.createdDate.end);
    newFilter.lastUpdateDate = new DateRange(this.lastUpdateDate.begin, this.lastUpdateDate.end);

    return newFilter;
  }
}
