import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DateRange } from 'src/app/admin-portal/security-admin/models/date-range';
import { CheckboxComponent } from 'wre-toolkit-lib';
import { RoleFilter } from '../../../../../models/role-filter';
import { RoleFilterItem, RoleFilterProperty } from '../../../../../models/role-filter-item';
import { RolesMainListService } from '../../../../../services/roles/roles-main-list.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'wre-roles-filter-container',
  templateUrl: './roles-filter-container.component.html',
  styleUrls: ['./roles-filter-container.component.sass'],
})
export class RolesFilterContainerComponent implements OnInit, OnDestroy {
  @ViewChildren(CheckboxComponent) domainCheckboxes!: QueryList<CheckboxComponent>;

  @Output() collapseFilterPane = new EventEmitter();

  defaultFilterSub$: Subscription;
  defaultFilter: RoleFilter;

  filter: RoleFilter;
  domains = {
    internal: {
      label: 'Internal',
      key: 'int',
    },
    external: {
      label: 'External',
      key: 'ext',
    },
  };

  selectedFilters: RoleFilterItem[] = [];

  constructor(private rolesService: RolesMainListService) {}

  ngOnInit() {
    this.defaultFilterSub$ = this.rolesService.defaultFilter$.subscribe((defaultFilter) => {
      this.defaultFilter = defaultFilter.copy();
      this.clearFilter();
      this.populateSelectedFilters();
    });
  }

  ngOnDestroy() {
    this.defaultFilterSub$?.unsubscribe();
  }

  applyFilter(emitEvent: boolean = true) {
    this.assignDomainValues();
    this.rolesService.filter = this.filter.copy();
    this.populateSelectedFilters();
    if (emitEvent) {
      this.collapseFilterPane.emit();
    }
  }

  clearFilter() {
    this.filter = this.defaultFilter.copy();
    this.setCheckboxesToDefault();
  }

  populateSelectedFilters() {
    this.selectedFilters = [];

    if (this.filter.onlyActive !== this.defaultFilter.onlyActive) {
      this.selectedFilters.push(new RoleFilterItem(RoleFilterProperty.OnlyActive));
    }

    if (this.filter.onlyAuthorised === this.defaultFilter.onlyAuthorised) {
      this.selectedFilters.push(new RoleFilterItem(RoleFilterProperty.OnlyAuthorised));
    }

    if (this.filter.domain.internal !== this.defaultFilter.domain.internal) {
      this.selectedFilters.push(new RoleFilterItem(RoleFilterProperty.DomainInternal));
    }

    if (this.filter.domain.external !== this.defaultFilter.domain.external) {
      this.selectedFilters.push(new RoleFilterItem(RoleFilterProperty.DomainExternal));
    }

    if (!this.filter.createdDate.equal(this.defaultFilter.createdDate)) {
      this.selectedFilters.push(
        new RoleFilterItem(RoleFilterProperty.CreatedDate, this.filter.createdDate),
      );
    }

    if (!this.filter.lastUpdateDate.equal(this.defaultFilter.lastUpdateDate)) {
      this.selectedFilters.push(
        new RoleFilterItem(RoleFilterProperty.LastUpdateDate, this.filter.lastUpdateDate),
      );
    }
  }

  removeFilter(filterItem: RoleFilterItem) {
    this.defaultProperty(filterItem);
    this.applyFilter(false);
  }

  defaultProperty(filterItem: RoleFilterItem) {
    switch (filterItem.property) {
      case RoleFilterProperty.OnlyActive:
        this.filter.onlyActive = this.defaultFilter.onlyActive;
        return;

      case RoleFilterProperty.OnlyAuthorised:
        this.filter.onlyAuthorised = false;
        return;

      case RoleFilterProperty.DomainInternal:
        this.domainCheckboxes
          .find((c) => c.label === this.domains.internal.label)
          .writeValue(false);
        return;

      case RoleFilterProperty.DomainExternal:
        this.domainCheckboxes
          .find((c) => c.label === this.domains.external.label)
          .writeValue(false);
        return;

      case RoleFilterProperty.CreatedDate:
        this.filter.createdDate = this.defaultFilter.createdDate.copy();
        return;

      case RoleFilterProperty.LastUpdateDate:
        this.filter.lastUpdateDate = this.defaultFilter.lastUpdateDate.copy();
        return;
    }
  }

  private assignDomainValues() {
    const domainsSelected = this.domainCheckboxes
      .filter((c) => c.input.nativeElement.checked === true)
      .map((c) => c.value);
    this.filter.domain.internal = domainsSelected.includes(this.domains.internal.key);
    this.filter.domain.external = domainsSelected.includes(this.domains.external.key);
  }

  private setCheckboxesToDefault() {
    if (this.domainCheckboxes == null) {
      return;
    }

    const intCheckbox = this.domainCheckboxes.find((c) => c.value === this.domains.internal.key);
    intCheckbox.writeValue(this.defaultFilter.domain.internal);

    const extCheckbox = this.domainCheckboxes.find((c) => c.value === this.domains.external.key);
    extCheckbox.writeValue(this.defaultFilter.domain.external);
  }

  createdDateChange(event: { begin: Date; end: Date }) {
    this.filter.createdDate = new DateRange(event.begin, event.end);
  }

  lastUpdateDateChange(event: { begin: Date; end: Date }) {
    this.filter.lastUpdateDate = new DateRange(event.begin, event.end);
  }
}
