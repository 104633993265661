import { Component, Inject, Input, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subject, firstValueFrom } from 'rxjs';
import { Applications} from '../../../../models/admin';
import { AuthorisationRole, AuthorisationService } from 'wre-authlib';
import { UntypedFormGroup } from '@angular/forms';
import { AdminFormService } from '../../services/admin-form.service';
import { SecurityRole } from '../../../../models/security-role';

@Component({
  selector: 'wre-role-lookup',
  templateUrl: './role-lookup.component.html'
})
export class RoleLookupComponent implements OnInit {

  @Input() label: string;
  @Input() placeholder = 'Enter role name...';
  @Input() securityRoleFormGroup: UntypedFormGroup;
  @Input() application: Applications;

  searchTerm = '';
  searchedRoles: AuthorisationRole[] = [];
  private subject: Subject<string> = new Subject();

  displayDropdown = false;

  constructor(private authService: AuthorisationService,
              adminFormService: AdminFormService) {
    this.securityRoleFormGroup = adminFormService.createSecurityRoleFormGroup();
  }

  ngOnInit() {
    this.subject.pipe(
      debounceTime(200)
    ).subscribe(() => {
      this.search();
    });
  }

  onKeyUp(event) {
    if (event.key === 'Escape') {
      this.closeDropdown();
    }
    setTimeout(() => {
      this.subject.next(undefined);
      this.closeDropdown();
    }, 500);
  }

  async search() {
    if (this.searchTerm && this.searchTerm.length > 2) {
      await firstValueFrom(this.authService.getApplicationRoles(this.searchTerm, this.application)).then(roles => {
        this.searchedRoles = roles;
        this.openDropdown();
      });
    }
    return;
  }

  selectRole(role: AuthorisationRole) {
    const selectedRole = new SecurityRole(role.roleGuid, role.roleName);
    this.searchTerm = role.roleName;
    this.securityRoleFormGroup.setValue(selectedRole);
    this.closeDropdown();
  }

  clearForm() {
    this.searchTerm = '';
    this.closeDropdown();
  }

  private openDropdown() {
    this.displayDropdown = true;
  }

  private closeDropdown() {
    this.displayDropdown = false;
  }

}
