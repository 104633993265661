import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ProtectedBaseComponent } from 'src/app/components/shared/protected-base/protected-base.component';
import { AdminExperience, Roles, Experience, UserPermissions } from 'src/app/models/admin';
import { RolesConfig } from './../../models/roles-config';
import { AuthorisationService } from 'wre-authlib';
import { ExperienceTab, TABS } from '../experience-tabs';
import { SecurityWorkspaceService } from '../security-admin/services/security-workspace.service';
import { SafrManagementService } from './services/safr-management.service';
import { SafrAdminService } from './services/safr-admin.service';
import { AdminService } from './services/admin.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.sass']
})

export class AdminComponent extends ProtectedBaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() setTitle: EventEmitter<any> = new EventEmitter();

  permissionList: string[] = [];
  experiences: Experience[] = [];
  selectedExperience = AdminExperience.Gateway;

  get tabs(): ExperienceTab[] {
    if (this.selectedExperience != null) {
      const tabsData = TABS.get(this.selectedExperience);
      return tabsData.filter(tab => this.permissionList.includes(tab.permission));
    }

    return [];
  }

  currentUrl: string;
  isLoaderVisible = true;
  private routeSubscription: Subscription;
  runOnce = false;

  constructor(private router: Router,
    protected authService: AuthorisationService,
    rolesConfig: RolesConfig,
    private securityWorkspaceService: SecurityWorkspaceService,
    private safrManagementService: SafrManagementService,
    private safrAdminService: SafrAdminService,
    private adminService: AdminService) {
    // Security
    super(authService);
    this.setRoles(rolesConfig.adminArea);
    this.routeSubscription = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(async (event: NavigationEnd) => {
        this.currentUrl = event.url;
        if (event.url === '/admin') {
          this.setExperiences();
        }
        this.runOnce = true;
        this.currentUrl = event.url;
        this.setExperienceView();
      });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  async ngAfterViewInit() {
    await super.ngAfterViewInit();
    this.currentUrl = this.router.url;
    this.setExperiences();
    this.currentUrl = this.router.url;
    this.runOnce = false;
  }

  async ngOnInit() {
    this.setTitle.emit('Gallagher Re Gateway');
  }

  async setExperiences() {
    this.isLoaderVisible = true;
    this.setCurrentExperience();
    const roles = [Roles.gatewayAdmin.toString(), Roles.reportingAdmin.toString()];
    this.permissionList = await firstValueFrom(this.authService.getUserApplicationRolesWithPermissions());
    this.securityWorkspaceService.setRolesWithPermissions(this.permissionList);

    this.safrAdminService.getSafrTypes().then(response => {
      this.safrManagementService.setSafrTypes(response.body);
    });

    // const matchingRoles = this.permissionList.filter(userRole => roles.includes(userRole));

    this.experiences = [];

    if (this.permissionList.includes(UserPermissions.GatewayExperienceSecurityView)) {
      this.experiences.push(({
        name: AdminExperience.Security,
        url: '/admin/security',
      }));
    }
    if (this.permissionList.includes(UserPermissions.GatewayWorkspacesAdd) &&
      this.experiences.find(exp => exp.name === AdminExperience.Gateway) === undefined) {
      this.experiences.push({
        name: AdminExperience.Gateway,
        url: '/admin/gateway/workspaces',
      });
    }
    if (this.permissionList.includes(UserPermissions.GatewayToolsAdd) &&
      this.experiences.find(exp => exp.name === AdminExperience.Gateway) === undefined) {
      this.experiences.push({
        name: AdminExperience.Gateway,
        url: '/admin/gateway/tools',
      });
    }
    if (this.permissionList.includes(UserPermissions.GatewayInlineTrainingAdd) &&
      this.experiences.find(exp => exp.name === AdminExperience.Gateway) === undefined) {
      this.experiences.push({
        name: AdminExperience.Gateway,
        url: '/admin/gateway/inlinetraining',
      });
    }
    if (this.permissionList.includes(UserPermissions.InteractiveReportsCategoriesSettingsView) &&
      this.experiences.find(exp => exp.name === AdminExperience.Reporting) === undefined) {
      this.experiences.push(({
        name: AdminExperience.Reporting,
        url: '/admin/reporting/categories',
      }));
    }
    if (this.permissionList.includes(UserPermissions.InteractiveReportsReportsSettingsView) &&
      this.experiences.find(exp => exp.name === AdminExperience.Reporting) === undefined) {
      this.experiences.push(({
        name: AdminExperience.Reporting,
        url: '/admin/reporting/reports',
      }));
    }
    if (this.permissionList.includes(UserPermissions.GatewayExperienceSafrView) &&
      this.experiences.find(exp => exp.name === AdminExperience.Safr) === undefined) {
      this.experiences.push(({
        name: AdminExperience.Safr,
        url: '/admin/safr/field-management',
      }));
    }
    if (this.permissionList.includes(UserPermissions.GatewayExperienceSafrView) &&
      this.experiences.find(exp => exp.name === AdminExperience.Safr) === undefined) {
      this.experiences.push(({
        name: AdminExperience.Safr,
        url: '/admin/safr/overview-access',
      }));
    }
    if (this.permissionList.includes(UserPermissions.GatewayExperienceSanctionsView) &&
      this.experiences.find(exp => exp.name === AdminExperience.Sanctions) === undefined) {
      this.experiences.push(({
        name: AdminExperience.Sanctions,
        url: '/admin/sanctions/database'
      }));
    }
    if (this.permissionList.includes(UserPermissions.SrtFieldManagementSettingsView) &&
      this.experiences.find(exp => exp.name === AdminExperience.SRT) === undefined) {
      this.experiences.push(({
        name: AdminExperience.SRT,
        url: '/admin/sanctions-referral/field-management'
      }));
    }
    if (this.permissionList.includes(UserPermissions.SrtOverviewAccessRead) &&
      this.experiences.find(exp => exp.name === AdminExperience.SRT) === undefined) {
      this.experiences.push(({
        name: AdminExperience.SRT,
        url: '/admin/sanctions-referral/overview-access'
      }));
    }
    // if /admin hit, redirect to first available workspace
    if (this.experiences.length > 0 && this.currentUrl.toLowerCase() === '/admin') {

      const permissionExperience = this.experiences.find(e => e.name === AdminExperience.Security);
      if (permissionExperience !== undefined) {
        const index = this.experiences.indexOf(permissionExperience);
        this.experiences.splice(index, 1);
        this.experiences.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        this.experiences.splice(0, 0, permissionExperience);
      } else {
        this.experiences.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
      }

      this.router.navigateByUrl(this.experiences[0].url);
    }

    this.setExperienceView();
    this.isLoaderVisible = false;
  }

  async setCurrentExperience() {
    const subroutes = this.router.url.split('/');
    const experience = subroutes[2];

    switch (experience) {
      case 'gateway': this.selectedExperience = AdminExperience.Gateway; break;
      case 'safr': this.selectedExperience = AdminExperience.Safr; break;
      case 'reporting': this.selectedExperience = AdminExperience.Reporting; break;
      case 'security': this.selectedExperience = AdminExperience.Security; break;
      case 'sanctions': this.selectedExperience = AdminExperience.Sanctions; break;
      case 'sanctions-referral': this.selectedExperience = AdminExperience.SRT; break;
      default: this.selectedExperience = AdminExperience.Gateway; break;
    }
  }

  setExperienceView() {
    // Compare only experience parts of the URL
    const currentExp = this.experiences.find(e => this.currentUrl.split('/', )[2] === e.url.split('/')[2]);
    if (currentExp) {
      this.selectedExperience = currentExp.name;
      this.adminService.setCurrentExperience(currentExp.name);
    }
  }

}
