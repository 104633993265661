import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export class ReportingLinkInfo {
  categoryId: number;
  reportId: number;
}

@Injectable({
  providedIn: 'root'
})
export class ReportingLinkBuilderService {

  private reportingUrl = environment.reportingUrl;
  private dashboardPath = 'dashboard';
  private reportsPath = 'reports';

  constructor() { }

  generateLink(info: ReportingLinkInfo): string {
    let link = this.reportingUrl + this.dashboardPath + '/' + info.categoryId;
    if (info.reportId) {
      link += link + '/' + this.reportsPath + '/' + info.reportId;
    }
    return link;
  }
}
