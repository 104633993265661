import { UserType } from './../../../../models/user-type';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { UserModalBaseComponent } from '../user-modal-base.component';
import { ErrorMessages } from 'src/app/admin-portal/security-admin/models/role-summary-view';

@Component({
  selector: 'wre-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: []
})
export class UserModalComponent extends UserModalBaseComponent {

  userForm: UntypedFormGroup;
  title = this.userId ? 'Edit User' : 'Add User';
  userType = UserType.externalUser;

  SetValidation() {
    const validationPattern = '^[a-zA-Z0-9 _-]*$';
    this.userForm = this.fb.group({
      firstName: new UntypedFormControl('', [Validators.required, Validators.pattern(validationPattern)]),
      lastName: new UntypedFormControl('', [Validators.required, Validators.pattern(validationPattern)]),
      mail: new UntypedFormControl('', [Validators.required, Validators.email])
    });
  }

  handleSubmissionError = (err) => {
    this.errorMessage = true;
    this.errorMessageInfo = err.status === 409 ? ErrorMessages.duplicateExternalUserError : ErrorMessages.genericError;
  }

}
