<div class="row justify-content-end mt-3">
  <div class="col-auto d-flex justify-content-end align-items-center wre-pagination">
    <label for="pageSize" class="col-form-label">Page Size</label>
    <select id="pageSize" name="pageSize" [(ngModel)]="pageSize">
      <option [value]="5">5</option>
      <option [value]="10">10</option>
      <option [value]="20">20</option>
      <option [value]="50">50</option>
    </select>
    <ngb-pagination [collectionSize]="total"
                    [(page)]="page"
                    [pageSize]="pageSize"
                    [boundaryLinks]="true">
    </ngb-pagination>
  </div>
</div>
