import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PermissionsListService } from '../../../services/permissions-list.service';
import { PermissionGroup } from '../../../models/permission-group';
import { PermissionType } from '../../../models/permission-type';

@Component({
  selector: 'wre-permissions-table',
  templateUrl: './permissions-table.component.html',
  styleUrls: ['./permissions-table.component.sass']
})
export class PermissionsTableComponent implements OnDestroy {

  @Input() selectedPermissions: PermissionGroup[];
  @Input() isDetailsView = false;

  _availablePermissions: PermissionGroup[];
  subscription: Subscription;

  PermissionType = PermissionType;

  get permissions() {
    // return sorted list in order : selected, type, name
    return this._availablePermissions.sort((x, y) => {
      // sort with selected first
      if (this.isSelected(x.id) !== this.isSelected(y.id)) {
        if (this.isSelected(x.id)) {
          return -1;
        } else {
          return 1;
        }
      }

      // Security type permissions go before Application type
      if (x.type === PermissionType.Security && y.type === PermissionType.Application) { return -1; }
      if (x.type === PermissionType.Application && y.type === PermissionType.Security) { return 1; }

      // sort with name if both selected or not selected
      if (x.name < y.name) { return -1; }
      if (x.name > y.name) { return 1; }
      return 0;
    });
  }

  constructor(private permissionsListService: PermissionsListService) {
    // get permission list
    this.subscription =  this.permissionsListService.basePermissions$
      .subscribe(permissions => {
        this._availablePermissions = [...permissions];  // shallow copy is enough not to change the order of elements in the original array
      });
  }

  isSelected(id): boolean {
    return this.selectedPermissions.filter(p => p.id === id).length > 0;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
