import { FieldOrderType } from './field-order-type';
import { User } from '../../overview-access/models/user';
import { DynamicFieldType } from '../enums/dynamic-field-type.enum';

export class ReferralField {
  id: string;
  name: string;
  tooltip: string;
  optionsCount: number;
  options: Option[];
  lastUpdatedDate: Date;
  lastUpdatedBy: User;
  order: FieldOrderType;
  fieldType: DynamicFieldType;
}

export class Option {
  user?: User;
  value: string;
  order: number;
  isLocked: boolean;
  active: boolean;

  constructor(option?: Partial<Option>) {
    this.user = option?.user != null ? new User(option.user.id, option.user.displayName, option.user.email) : null;
    this.value = option?.value;
    this.order = option?.order ?? 0;
    this.isLocked = option?.isLocked ?? false;
    this.active = option?.active ?? true;
  }

  equal(option: Option): boolean {
    return ((this.user == null && option.user == null) || this.user.equal(option.user)) &&
      this.value === option.value &&
      this.order === option.order &&
      this.isLocked === option.isLocked &&
      this.active === option.active;
  }

  equalValue(option: Option, isUserType: boolean = false): boolean {
    return isUserType
      ? this.user != null && option.user != null && this.user.equal(option.user)
      : this.value === option.value;
  }
}

export class OptionsRequest {
  options: Option[];

  constructor(options?: Option[]) {
    this.options = options;
  }
}
