import { SecurityRole } from './security-role';
import { AppsCategory } from './apps-category';

export class App {
  id: number;
  name: string;
  url: string;
  orderNo: number;
  isActive: boolean;
  isNew: boolean;
  isTool: boolean;
  isAvailableOffVpn: boolean;
  openInNewTab: boolean;
  category: AppsCategory = new AppsCategory();
  securityRoles: SecurityRole[] = [];

  constructor() { }

  equal(app: App): boolean {
    return this.id === app.id &&
      this.name === app.name &&
      this.url === app.url &&
      this.orderNo === app.orderNo &&
      this.isActive === app.isActive &&
      this.isNew === app.isNew &&
      this.isTool === app.isTool &&
      this.isAvailableOffVpn === app.isAvailableOffVpn &&
      this.openInNewTab === app.openInNewTab &&
      this.category.id === app.category.id &&
      JSON.stringify(this.securityRoles) === JSON.stringify(app.securityRoles);
  }
}
