import { Component, Inject, Input } from '@angular/core';
import { AppInsightsService } from 'wre-core-lib';
import { SlipcaseNewsItem, Topic } from '../../models/slipcase-news-item';
import { SlipcaseNewsService } from '../../services/slipcase-news-service';
import { ProtectedBaseComponent } from 'src/app/components/shared/protected-base/protected-base.component';
import { AuthorisationService } from 'wre-authlib';
import { RolesConfig } from 'src/app/models/roles-config';

@Component({
  selector: 'app-slipcase-feed',
  templateUrl: './slipcase-feed.component.html',
  styleUrls: ['./slipcase-feed.component.css']
})
export class SlipcaseFeedComponent extends ProtectedBaseComponent {

  @Input() showPublishDate = true;
  @Input() showItemHostname = true;
  @Input() showTopics = true;
  @Input() newsData: SlipcaseNewsItem[];
  @Input() useInputFeed = false;
  topics: Topic[];
  categories: string[];
  @Input() isloaderVisible = true;

  errorOccured: boolean;

  constructor(
    private appInsightsService: AppInsightsService,
    private slipcaseNewsService: SlipcaseNewsService,
    protected authService: AuthorisationService,
    rolesConfig: RolesConfig,
  ) {
    // Security
    super(authService);
    this.setRoles(rolesConfig.slipcaseFeed);
    // ----

    this.appInsightsService.componentName = 'SlipcaseFeedComponent';
    this.componentAvailableSub.subscribe(x => this.load(x));
  }


  private load(isAvailable: boolean) {
    this.errorOccured = false;
    if (isAvailable === false) {
      return;
    }

    this.isloaderVisible = true;
    this.topics = this.slipcaseNewsService.getSlipcaseTopics();
    this.getCategories();

    if (!this.useInputFeed) {
      this.slipcaseNewsService.getRecommendedNewsItems().subscribe(
        newsItemsResponse => {
          newsItemsResponse.body.forEach(element => {
            if (element.featured_image_url !== undefined) {
              const lastindex = element.featured_image_url.lastIndexOf('/');
              element.featured_image_url = element.featured_image_url.slice(0, lastindex) + '/resize_626x500';
            }
          });
          this.newsData = newsItemsResponse.body;
          this.isloaderVisible = false;
        },
        ex => {
          this.appInsightsService.trackException(ex);
          this.errorOccured = true;
          this.isloaderVisible = false;
        },
        () => {
          this.isloaderVisible = false;
        }
      );
    }
  }
  getCategories() {
    const mapped = this.topics.map(x => x.category);
    const res = mapped.filter((value, index, self) => self.indexOf(value) === index);
    this.categories = res;
  }
}
