<div class="row justify-content-center">
  <div class="col-12 ps-0 pe-0">
      <div class="p-3">
          <div class="card client-advocacy-container shadow-sm bg-white">
              <div class="card-body">
                  <p style="font-size: 1.1rem;">
                    Coming Soon
                  </p>
              </div>
          </div>
      </div>
  </div>
</div>


