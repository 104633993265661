import {Pipe, PipeTransform} from '@angular/core';

export enum ReportType {
  PowerBI = 1,
  SSRS,
  Excel,
  SharePoint,
}

export enum InteractiveReportsAdminTabs {
  Category,
  Reports
}

export class ReportTypeUtils  {
  static toString(type: ReportType) {
    switch (type) {
      case ReportType.PowerBI:
        return 'PowerBI';

      case ReportType.SSRS:
        return 'SSRS';

      case ReportType.Excel:
        return 'Excel';

      case ReportType.SharePoint:
        return 'SharePoint Page';

      default:
        return 'Other';
    }
  }
}

@Pipe({name: 'typeEnumToArray'})
export class TypeEnumToArrayPipe implements PipeTransform {
  transform(value: ReportType): {key: ReportType, label: string}[] {
    return Object.keys(value)
      .filter(e => !isNaN(+e))
      .map(o => ({key: +o, label: ReportTypeUtils.toString(+o)}))
      .sort((a, b) => a.label.localeCompare(b.label));
  }
}
