import { UserType } from './../../../../../models/user-type';
import { UserGroup } from './../../../../../models/user-group';
import { RoleDetailsView } from './../../../../../models/role-details-view';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'wre-details',
  templateUrl: './details.component.html',
  styleUrls: []
})
export class DetailsComponent {
  @Input() role: RoleDetailsView;

  get usersCount() {
    return !!this.role && !!this.role.users ? (this.role.users as UserGroup[]).filter(u => u.type !== UserType.adGroup && u.type !== UserType.servicePrinciple).length : 0;
  }

  get adGroupCount(): number {
    return !!this.role && !!this.role.users ? (this.role.users as UserGroup[]).filter(u => u.type === UserType.adGroup).length : 0;
  }

  get servicePrincipleCount() {
    return !!this.role && !!this.role.users ? (this.role.users as UserGroup[]).filter(u => u.type === UserType.servicePrinciple).length : 0;
  }
}
