import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { NgPipesModule } from 'ngx-pipes';

@NgModule({
  declarations: [
    InfiniteScrollComponent
  ],
  imports: [
    CommonModule,
    NgPipesModule,
  ],
  exports: [
    InfiniteScrollComponent,
    NgPipesModule
  ]
})
export class SharedComponentsModule { }
