import { UserType } from './user-type';

export class UserGroup {
    id: string;
    mail?: string;
    givenName?: string;
    surname?: string;
    appCount?: number;
    roleCount?: number;
    type: string;

    private _displayName: string;
    get displayName(): string {
        return this._displayName || `${ this.surname }, ${ this.givenName }`;
    }
    set displayName(displayName: string) {
        this._displayName = displayName;
    }

    get isUserType(): boolean {
        return this.type === UserType.internalUser || this.type === UserType.externalUser;
    }

    constructor(id?: string, displayName?: string, type?: string, mail?: string,
        givenName?: string, surname?: string, appCount?: number, roleCount?: number) {
        this.id = id;
        this._displayName = displayName;
        this.type = type;
        this.mail = mail;
        this.givenName = givenName;
        this.surname = surname;
        this.appCount = appCount;
        this.roleCount = roleCount;
    }
}

export class ServicePrincipal extends UserGroup {

}
