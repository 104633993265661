import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminFormService } from '../../admin-shared/services/admin-form.service';
import { App } from '../../../models/app';

@Injectable({
  providedIn: 'root'
})
export class AppFormService {

  constructor(private fb: UntypedFormBuilder,
              private adminFormService: AdminFormService) { }

  /** Create empty FormGroup for Application */
  createAppFormGroup(): UntypedFormGroup {
    return this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100), this.adminFormService.noWhitespaceValidator]],
      url: ['', [Validators.required, Validators.pattern('https?://.+')]],
      appsCategoryId: [null, Validators.required],
      orderNo: [null, Validators.required],
      isActive: [true, Validators.required],
      isNew: [true, Validators.required],
      isTool: [true, Validators.required],
      isAvailableOffVpn: [false, Validators.required],
      openInNewTab: [true, Validators.required],
      securityRoles: [[]]
    });
  }

  /** Assign app properties to form group */
  assignAppToFormGroup(app: App, appForm: UntypedFormGroup): void {
    appForm.patchValue(app);

    appForm.get('appsCategoryId').setValue(app.category.id);

    // Copy array of security roles to prevent the form control from directly pointing to app security roles property
    const securityRoles = Object.assign([], app.securityRoles);
    appForm.get('securityRoles').setValue(securityRoles);

    console.log('App: ', app);
    console.log('App Form: ', appForm);
  }

  /** Assign data from form group to app object */
  assignFormGroupToApp(appForm: UntypedFormGroup, app: App): void {
    Object.assign(app, appForm.value);
    app.name = app.name.trim();
    app.url = app.url.trim();
    app.category.id = +appForm.get('appsCategoryId').value;
  }
}
