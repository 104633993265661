<div class="d-flex justify-content-center" *ngIf="isLoading | async">
  <div class="d-flex align-items-center" style="height: 70px;">
    <div class="loading"></div>
  </div>
</div>
<div *ngIf="errorMessageInfo | async" class="row text-center">
  <div class="col">
    <span class="wre-form-error">
      {{ errorMessageInfo | async }}
    </span>
  </div>
</div>

<div class="wre-table-container" *ngIf="!(isLoading | async) && !(errorMessageInfo | async)">
    <div #tableContainer
         class="table-responsive"
    >
      <table class="table wre-table fixed-column table-hover">
        <thead>
          <tr>
            <th class="text-nowrap column-action fixed-column">&nbsp;</th>
            <th class="text-nowrap col-ellipsis column-name">Field Name</th>
            <th class="text-nowrap col-ellipsis column-options text-center">Options</th>
            <th class="text-nowrap col-ellipsis column-last-updated">Last Updated</th>
            <th class="text-nowrap col-ellipsis column-last-updated-by">Last Updated By</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let field of fields | async;">
            <td class="text-nowrap sticky-column column-action fixed-column">
              <div ngbDropdown container="body">
                <button class="btn btn-secondary" ngbDropdownToggle>Action</button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem [routerLink]="[field.id, {outlets: { modal: 'edit' } } ]">Edit</button>
                </div>
              </div>
              <!-- <div class="dropdown">
                <button style="z-index:0;"
                        class="btn wre-btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                >
                  <span>Action</span> <span class="caret"></span>
                </button>
                <div class="dropdown-menu">
                  <div class="dropdown-item"
                    [routerLink]="[field.id, {outlets: { modal: 'edit' } } ]">
                    Edit
                </div>
                </div>
              </div> -->
            </td>
            <td class="text-nowrap col-ellipsis column-name">{{ field.name }}</td>
            <td class="text-nowrap col-ellipsis column-options text-center">{{ field.optionsCount }}</td>
            <td class="text-nowrap col-ellipsis column-last-updated">{{ field.lastUpdated | date :'dd/MMM/yyyy hh:mm:ss a' }}</td>
            <td class="text-nowrap col-ellipsis column-last-updated-by">{{ field.lastUpdatedBy }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
