export class UserInfo {
  firstName?: string;
  lastName?: string;
  mail?: string;
  type?: string;

  constructor(firstName?: string, lastName?: string, mail?: string, type?: string) {
    this.firstName = firstName ? firstName : undefined;
    this.lastName = lastName;
    this.mail = mail;
    this.type = type;
  }

  public equals(user: UserInfo): boolean {
    return user && this.firstName === user.firstName &&
      this.lastName === user.lastName &&
      this.mail === user.mail;
  }
}
