import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SafrAdminService } from '../../../admin/services/safr-admin.service';
import { Field } from 'src/app/admin-portal/admin/models/safr-type';

@Injectable({
  providedIn: 'root'
})
export class SafrOverviewFieldsService {

  _fields$ = new BehaviorSubject<Field[]>([]);
  get fields$() { return this._fields$.asObservable(); }
  get fields(): Field[] { return this._fields$.getValue(); }

  _loading$ = new BehaviorSubject<boolean>(true);
  get loading$() { return this._loading$.asObservable(); }
  get loading(): boolean { return this._loading$.getValue(); }

  constructor(private _safrAdminService: SafrAdminService) { }

  loadFields() {
    this._loading$.next(true);
    this._safrAdminService.getFields().subscribe(fields => {
      this._fields$.next(fields);
      this._loading$.next(false);
    });
  }
}
