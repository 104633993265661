import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wre-role-info-header',
  templateUrl: './role-info-header.component.html',
  styleUrls: ['./role-info-header.component.sass']
})
export class RoleInfoHeaderComponent {

  @Input() roleInfo: UntypedFormGroup;
  @Input() displayUsersStats = false;
  @Input() servicePrincipleCount: number;
  @Input() userCount: number;
  @Input() adGroupCount: number;
  @Input() adminCount: number;

  get isActive(): boolean {
    return this.roleInfo.get('isActive').value;
  }

  get roleName(): string {
    return this.roleInfo.get('name').value;
  }
}
