import { Injectable } from '@angular/core';
import { RoleSummaryView } from '../../models/role-summary-view';
import { RolesSearchService } from './roles-search.service';
import { RolesDataService } from './roles-data.service';
import { SecurityWorkspaceService } from '../security-workspace.service';

@Injectable()
export class RolesPermissionsGridService extends RolesSearchService {

  constructor(protected rolesListService: RolesDataService,
              protected securityWorkspaceService: SecurityWorkspaceService) {
    super(rolesListService, securityWorkspaceService);
  }

  matchesSearchTerm(role: RoleSummaryView): boolean {
    return role.name.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase());
  }
}
