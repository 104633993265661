import { Component, OnInit } from '@angular/core';
import { WfmFrame, WorkFlowManagerService } from '../../../services/work-flow-manager.service';

@Component({
  selector: 'wre-wfm-dashboard',
  templateUrl: './wfm-dashboard.component.html',
  styleUrls: ['./wfm-dashboard.component.scss']
})
export class WfmDashboardComponent implements OnInit {
  frames: WfmFrame[];

  constructor(private wfmService: WorkFlowManagerService) { }

  ngOnInit() {
    this.frames = this.wfmService.getFrames();
  }

}
