import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { RouterModule } from '@angular/router';
import { RoadMapComponent } from './road-map.component';
import { ReleaseNoteComponent } from './release-note/release-note.component';
import { RoadMapContentsComponent } from './road-map-contents/road-map-contents.component';
import { SanitizeUrlPipeComponent } from './sanitize-url-pipe/sanitize-url-pipe.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    RoadMapComponent,
    ReleaseNoteComponent,
    RoadMapContentsComponent,
    SanitizeUrlPipeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TourMatMenuModule,
    SharedModule
  ]
})
export class RoadmapModule { }
