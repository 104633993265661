<div class="wre-timeline-card" [ngClass]="[audit.type ? audit.type : '']">
  <div class="card-body">
    <div class="wre-timeline-time" *ngIf="audit.date">
      {{ audit.date + 'Z' | date : 'EEEE dd MMMM yyyy, hh:mm:ss a'}}
      <div *ngIf="audit.isCollapsable" style="float: right; cursor: pointer;" data-bs-toggle="collapse"
        [attr.data-bs-target]="'#collapse' + title + 'details'" aria-expanded="true"
        [attr.aria-controls]="'collapse' + title + 'details'" class="collapseIcon">
        <span class="collapseDetailsTitle me-1"> Collapse</span>
        <span class="collapseTitle me-1">Details</span>
        <i class='fas fa-chevron-down'></i>
      </div>
    </div>
    <div class="wre-timeline-action">

      <div *ngIf="isRoleCreate">
        <p class="mb-1">
          New role created <b> ({{ audit.role.new.name }}) </b>
        </p>
        <span>
          <span class="no-admin-text">
            Admins:
          </span>
          <span class="header-value pe-1 me-1 fw-bold border-end">
            <span *ngIf="adminsCount < 10">0</span>{{ adminsCount }}
          </span>
          <i class="far fa-user px-2"></i>
          <span class="no-admin-text">
            Users:
          </span>
          <span class="header-value pe-1 fw-bold">
            <span *ngIf="usersCount < 10">0</span>{{ usersCount }}
          </span>
          <i class="far fa-users px-2"></i>
          <span class="no-admin-text">
            AD Groups:
          </span>
          <span class="header-value fw-bold border-end pe-1 me-1">
            <span *ngIf="adGroupsCount < 10">0</span>{{ adGroupsCount }}
          </span>
          <i class="far fa-desktop px-2"></i>
          <span class="no-admin-text">
            SPs :
          </span>
          <span class="header-value pe-1 fw-bold border-end pe-1 me-1">
            <span *ngIf="servicePrincipleCount < 10">0</span>{{ servicePrincipleCount }}
          </span>
          <i class="far fa-key px-2"></i>
          <span class="no-admin-text">
            Permissions:
          </span>
          <span class="header-value fw-bold">
            <span *ngIf="permissionsCount < 10">0</span>{{ permissionsCount }}
          </span>
        </span>
      </div>

      <div *ngIf="isRoleNameUpdate" class="no-admin-text">
        Role name changed
        (<s> {{audit.role.old.name}} </s>
        <i class='far fa-long-arrow-alt-right mx-2' style='color:purple'></i>
        <b>{{audit.role.new.name}}</b>)
      </div>

      <div *ngIf="isRoleActiveUpdate">
        Role is set to <span
          [ngClass]="{'role-setTo-active': audit.role.new.isActive, 'role-setTo-inactive': !audit.role.new.isActive}">
          {{ audit.role.new.isActive ? 'Active' : 'InActive' }}
        </span>
      </div>

      <div *ngIf="!isRoleActiveUpdate && !isRoleNameUpdate && !isRoleCreate && audit.title">
        {{ audit.title }}
      </div>
    </div>
    <div class="wre-timeline-user" *ngIf="audit.actionBy">
      Actioned By: {{ audit.actionBy }}
    </div>

  </div>
</div>
<div *ngIf="audit.isCollapsable">
  <div [attr.id]="'collapse' + title + 'details'" class="collapse tabbed-content show border-top border-light"
    [ngClass]="{'hideTabList': !isRoleCreate}">

    <ul ngbNav #nav="ngbNav" [activeId]="getActiveId(audit)" class="nav-tabs">
      <li ngbNavItem="Authorised_Admins" *ngIf="audit.admins || isRoleCreate">
        <a ngbNavLink>Authorised Admins</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col">
              <div class="wre-tab-container badges">
                <ng-container *ngIf="audit.admins; else noAdmins">
                  <div *ngFor="let admin of audit.admins" class="badge bg-success me-3 mb-3"
                    [ngClass]="{'bg-danger': admin.isRemoved}">
                    <span class="border-end pe-2 me-2" *ngIf="admin.isAdded || admin.isRemoved">
                      <i class="far" [ngClass]="{'fa-plus': admin.isAdded, 'fa-minus': admin.isRemoved}"></i>
                    </span>
                    <span>
                      {{ admin.displayName }}
                    </span>
                  </div>
                </ng-container>
                <ng-template #noAdmins>
                  <div class="no-admin-text pb-3">No admins have been selected.</div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </li>

      <li ngbNavItem="Users" *ngIf="audit.users || isRoleCreate">
        <a ngbNavLink>Users</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col">
              <div class="wre-tab-container">
                <wre-role-history-data-table *ngIf="audit.users" [isUser]="true" [listData]="audit.users">
                </wre-role-history-data-table>
                <div class="no-admin-text" *ngIf="!audit.users">
                  No users have been selected.
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>

      <li ngbNavItem="Permissions" *ngIf="audit.permissions || isRoleCreate">
        <a ngbNavLink>Permissions</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col">
              <div class="wre-tab-container">
                <wre-role-history-data-table *ngIf="audit.permissions" [isUser]="false" [listData]="audit.permissions">
                </wre-role-history-data-table>
                <div class="no-admin-text" *ngIf="!audit.permissions">
                  No permissions have been selected.
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
