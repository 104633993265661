import { environment } from 'src/environments/environment';

// This is a mapper from configuration to DI
export class RolesConfig {
    searchExperience = environment.rolesConfig.searchExperience;
    slipcaseFeed = environment.rolesConfig.slipcaseFeed;
    submitIdea = environment.rolesConfig.submitIdea;
    roadmaps = environment.rolesConfig.roadmaps;
    timeSurvey = environment.rolesConfig.timeSurvey;
    adminArea = environment.rolesConfig.adminArea;
}

