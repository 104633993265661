<div class="permission-step-wrapper mt-1 border-top">
  <div class="quick-select-panel d-flex justify-content-between mb-3 border-bottom">
    <div class="type-tabs-wrapper d-inline-block">
      <ul class="nav-pills nav border-bottom-0" id="tabs" role="tablist">
        <li *ngFor="let type of [PermissionType.Security, PermissionType.Application]" class="nav-item d-flex">
          <a class="nav-link" (click)="selectType(type)" [class.active]="selectedType === type">
            <span>{{ type }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div>
      <div class="clickable-icon d-inline-block">
        <div class="btn btn-text quick-select-text pb-2"
             [class.disabled-quick-select]="allSelected || isDisabled"
             (click)="selectAll()">
          Select All
        </div>
      </div>
      <div class="clickable-icon d-inline-block border-start">
        <div class="btn btn-text quick-select-text pb-2"
             [class.disabled-quick-select]="noneSelected || isDisabled"
             (click)="deselectAll()">
          Deselect All
        </div>
      </div>
    </div>
  </div>
  <div class="header">Select Permissions:</div>
  <div class="permission-grid pt-2">
    <ng-container *ngFor="let permission of availablePermissions$ | async">
      <wre-permission-group-unit
        *ngIf="permission.type === selectedType"
        [permissionGroup]="permission"
        [isDisabled]="isDisabled"
        [isSelected]="isSelected(permission)"
        (click)="togglePermission(permission)">
      </wre-permission-group-unit>
    </ng-container>
  </div>
</div>
