import { Component, Input } from '@angular/core';
import { UserType } from '../../../../../../models/user-type';

@Component({
  selector: 'wre-user-audit-table',
  templateUrl: './user-audit-table.component.html',
  styleUrls: ['./user-audit-table.component.sass']
})
export class UserAuditTableComponent {

  @Input() list = [];
  @Input() isAddedorRemoved = false;
  offset = '0';
  hideCol = false;
  UserType = UserType;
}
