<div class="row justify-content-center" [hidden]="!this.isloaderVisible">
  <div class="col-lg-8 col-md-12">
    <div style="height:70px;" class="d-flex align-items-center justify-content-center">
      <div style="" class="loading"></div>
    </div>
  </div>
</div>
<div [hidden]="this.isloaderVisible">

  <!-- <div class="table-responsive" [ngStyle]="{'height': this.tableHeight + 'px'}"> -->
  <div #tableContainer class="table-responsive">
    <table class="table wre-table fixed-column mb-0">
      <thead>
        <tr>
          <th class="action fixed-column">&nbsp;</th>
          <th class="id" hidden="true">Id</th>
          <th class="order text-center">Order No.</th>
          <th class="active text-center">Active</th>
          <th class="name">Name</th>
        </tr>
      </thead>
      <tbody class="mb-0 pb-0">
        <tr *ngFor="let appCategory of appCategories">
          <td class="fixed-column action">
            <div ngbDropdown container="body">
              <button class="btn btn-secondary" ngbDropdownToggle>Action</button>
              <div ngbDropdownMenu>
                <button (click)="onEdit(appCategory.id)" *ngIf="hasEditPermission" ngbDropdownItem>Edit</button>
                <button (click)="onStatusChange(appCategory.id)" *ngIf="hasEditPermission && this.appCategory.isValid" ngbDropdownItem>Disable</button>
                <button (click)="onStatusChange(appCategory.id)" *ngIf="hasEditPermission && !this.appCategory.isValid" ngbDropdownItem>Enable</button>
                <button (click)="onDelete(appCategory.id, appCategory.name)" *ngIf="hasDeletePermission" class="delete" ngbDropdownItem>Delete</button>
              </div>
            </div>
            <!-- <div class="dropdown">
              <button
                style="z-index:0;"
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>Action</span> <span class="caret"></span>
              </button>
              <div class="dropdown-menu" >
                <a class="dropdown-item" href="javascript:;" (click)="onEdit(appCategory.id)" *ngIf="hasEditPermission">Edit</a>
                <a class="dropdown-item" href="javascript:;" (click)="onStatusChange(appCategory.id)" *ngIf="hasEditPermission && this.appCategory.isValid">Disable</a>
                <a class="dropdown-item" href="javascript:;" (click)="onStatusChange(appCategory.id)" *ngIf="hasEditPermission && !this.appCategory.isValid">Enable</a>
                <a class="dropdown-item" href="javascript:;" (click)="onDelete(appCategory.id, appCategory.name)" *ngIf="hasDeletePermission">Delete</a>
              </div>
            </div> -->
          </td>
          <td class="id" hidden="true">{{ appCategory.id }}</td>
          <td class="order text-center">{{ appCategory.orderNo }}</td>
          <td class="active text-center">
              <i class="fa fa-check" aria-hidden="true" *ngIf="this.appCategory.isValid"></i>
              <i class="fa fa-times" aria-hidden="true" *ngIf="!this.appCategory.isValid"></i>
            </td>
          <td class="name">{{ appCategory.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

