import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wre-report-wizard-config-non-powerbi',
  templateUrl: './report-wizard-config-non-powerbi.component.html',
  styleUrls: ['./report-wizard-config-non-powerbi.component.sass']
})
export class ReportWizardConfigNonPowerbiComponent {

  @Input() newReportForm: UntypedFormGroup;

}
