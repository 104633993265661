import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LookupUser } from 'src/app/models/lookup-user';
import { User } from 'src/app/models/user';
import { AuthorisationService, DirectoryDetail, UserTypeFlag } from 'wre-authlib';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'wre-user-lookup',
  templateUrl: './user-lookup.component.html',
  styleUrls: ['./user-lookup.component.sass'],
})
export class UserLookupComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() inputId: string;
  @Input() disabled: boolean;
  @Input() isInvalid: boolean;
  @Input() labelText: string;
  @Input() userTypes: UserTypeFlag = UserTypeFlag.All;

  @Input() placeholderValue = 'Search user...';

  @Output() userSelected = new EventEmitter<LookupUser>();

  dropdownKey: string;
  searchTerm = '';
  searchedUsers: User[] = [];
  private subject: Subject<string> = new Subject();
  enterClicked: boolean;

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  @Input() inputName: string;
  @Input() inputTabIndex: string;

  @HostListener('keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.code === 'Tab' || (event.code === 'Tab' && event.shiftKey)) {
      if (this.dropdown.isOpen) {
        this.dropdown.close();
      }
    }
  }

  get name(): string {
    return this.inputName ? this.inputName : this.inputId;
  }

  get inputValue(): string {
    return this.control.value ? this.control.value.name : null;
  }

  constructor(private authService: AuthorisationService) {}

  ngOnInit() {
    this.dropdownKey = this.inputId + '-adUsersList';

    this.subject.pipe(debounceTime(300)).subscribe(() => {
      this.searchUsers();
    });
  }

  searchUsers() {
    const promises = [];
    const searchTerm = this.searchTerm.trim();

    /* tslint:disable:no-bitwise */
    const includeInternal =
      (this.userTypes & UserTypeFlag.InternalUsers) === UserTypeFlag.InternalUsers;

    if (this.searchTerm.trim().length > 2) {
      if (includeInternal) {
        promises.push(firstValueFrom(this.authService.getUsersStartsWith(searchTerm)));
      }
      if ((this.userTypes & UserTypeFlag.ExternalUsers) === UserTypeFlag.ExternalUsers) {
        promises.push(
          firstValueFrom(
            this.authService.getUsersDetailsStartsWith(
              environment.applicationName,
              'External User',
              searchTerm,
            ),
          ),
        );
      }

      Promise.all(promises).then((results) => {
        if (includeInternal) {
          results[0] = this.getValidUsers(results[0]);
        }
        this.searchedUsers = [].concat(...(results as User[]));
        this.dropdown.open();
      });
    }
  }

  onKeyUp(event) {
    this.enterClicked = false;
    switch (event.key) {
      case 'Escape': {
        this.dropdown.close();
        break;
      }
      case 'ArrowDown': {
        this.selectItemInDropdownList(event.key);
        this.control.markAsDirty();
        break;
      }
      case 'ArrowUp': {
        this.selectItemInDropdownList(event.key);
        this.control.markAsDirty();
        break;
      }
      case 'Enter': {
        this.selectItemInDropdownList(event.key);
        this.control.markAsDirty();
        break;
      }
      default: {
        if (this.searchTerm !== '' && this.searchTerm !== this.inputValue) {
          this.subject.next(undefined);
          this.control.markAsDirty();
        } else if (this.searchTerm === '') {
          this.control.setValue(null);
        }
        break;
      }
    }
  }

  selectUser(user: any) {
    const lookupUser = new LookupUser(user.displayName, user.mail, user.id);
    this.control.setValue(lookupUser);
    this.control.markAsDirty();
    this.dropdown.close();

    this.userSelected.emit(lookupUser);
  }

  onFocus() {
    this.enterClicked = false;
    this.searchTerm = this.inputValue;
  }

  onBlur() {
    this.control.markAllAsTouched();
    // If user search completed but no correct value selected we reset field to null
    if (!this.enterClicked && this.control.dirty === true && !this.inputValue) {
      this.control.setValue(null);
    } else if (
      !this.enterClicked &&
      this.control.dirty === true &&
      this.searchTerm !== this.inputValue
    ) {
      this.control.setValue(null);
    }
  }

  selectItemInDropdownList(eventKey: string) {
    const list = document.getElementById(this.inputId + '-adUsersList');
    const selected = list.querySelector('.selected');
    const element = document.getElementById(this.inputId);
    const scrollHeight = element === null ? 0 : element.getBoundingClientRect().height;
    if (selected) {
      switch (eventKey) {
        case 'ArrowUp': {
          const nextElement = selected.previousElementSibling;
          if (nextElement) {
            selected.classList.remove('selected');
            nextElement.classList.add('selected');
            list.scrollTo({ top: (list.scrollTop -= scrollHeight), behavior: 'smooth' });
          }
          break;
        }
        case 'ArrowDown': {
          const nextElement = selected.nextElementSibling;
          if (nextElement) {
            selected.classList.remove('selected');
            nextElement.classList.add('selected');
            list.scrollTo({ top: (list.scrollTop += scrollHeight), behavior: 'smooth' });
          }
          break;
        }
        case 'Enter': {
          (selected as HTMLElement).click();
          this.enterClicked = true;
          break;
        }
      }
    } else {
      const subjectToSelect = list.querySelector('a');
      if (subjectToSelect) {
        subjectToSelect.classList.add('selected');
      }
    }
  }

  private getValidUsers(users: DirectoryDetail[]): DirectoryDetail[] {
    const validCompanies = ['Gallagher Global Brokerage-Re', 'Home Office Division', 'GGB Re'];
    // Keep this in case if we want to add exceptions in future
    const exceptions = [];
    return users.filter(
      (u) =>
        (validCompanies.includes(u.companyName) && u.mail !== null) ||
        exceptions.includes(u.id) || // to include users not belonging to IRR
        /^tpsinttest\d+@gallagherre\.com$/i.test(u.userPrincipalName), // to include test accounts
    );
  }
}
