<div class="placement-excellence-container-fluid">
    <div class="row justify-content-center">
        <ng-container *ngFor="let item of tableItems">
            <div class="col-sm-12 col-md-6 col-lg-3 glossary-card-container">
                <div class="card">
                    <div class="btn-toolbar card-header" role="toolbar" aria-label="Toolbar Actions">
                        <h6 class="">{{item.category}}</h6>
                    </div>
                    <div class="card-body">
                        <ng-container *ngFor="let i of item.items">
                            <div class="row">
                                <div class="col-4"><strong>{{i.abbreviation}}</strong></div>
                                <div class="col-8">{{i.description}}</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>