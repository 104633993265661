<router-outlet name="sidebar"></router-outlet>
<router-outlet name="modal" (activate)="onActivate($event)"></router-outlet>
<div class="card">
  <div class="row">
    <div class="col-auto pe-0 d-flex">
      <div class="left-panel">
        <ul>
          <li>
            <div (click)="toggle('users')" [ngClass]="{'active': _selectedType === 'users'}" ngbTooltip="Users"
              placement="right" [openDelay]="700">
              <button [disabled]="(loading$ | async)" class="btn btn-icon" role="button" type="button">
                <i class="far fa-user"></i>
              </button>
            </div>
          </li>
          <li>
            <div (click)="toggle('adGroup')" [ngClass]="{'active': _selectedType === 'adGroup'}" ngbTooltip="AD Groups"
              placement="right" [openDelay]="700">
              <button [disabled]="(loading$ | async)" class="btn btn-icon" role="button" type="button">
                <i class="far fa-users"></i>
              </button>
            </div>
          </li>
          <li>
            <div (click)="toggle('servicePrinciple')" [ngClass]="{'active': showServicePrincipalsTable}"
              ngbTooltip="Service Principles" placement="right" [openDelay]="700">
              <button [disabled]="(loading$ | async)" class="btn btn-icon" role="button" type="button">
                <i class="fas fa-desktop"></i>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col ps-0 main-container">
      <div class="card-header">
        <h6 class="card-title me-auto">{{ this.heading }}</h6>
        <div class="card-options d-flex align-items-center">
          <div class="input-group prepend">
            <input class="form-control" id="searchText" maxlength="50" name="searchText"
              (input)="onSearchTermChange($event)" [ngModel]="searchTerm" (keyup)="subject.next(undefined)"
              [disabled]="(loading$ | async)" type="text" placeholder="Search...">
            <span class="input-group-text" id="basic-addon2"><i class="far fa-search"></i></span>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12" *ngIf="(loading$ | async)">
            <wre-toolkit-loader></wre-toolkit-loader>
          </div>
          <div class="col-12" *ngIf="!(loading$ | async)">
            <ng-container *ngIf="!error">
              <wre-users-table-admin *ngIf="!showServicePrincipalsTable" [users]="items$ | async"
                [isAdGroup]="isAdGroup" [searchTerm]="searchTerm" [isLoading]="loading" [isNoSearchResult]="isNoSearchResult">
              </wre-users-table-admin>

              <wre-service-principals-table-admin *ngIf="showServicePrincipalsTable" [searchTerm]="searchTerm">
              </wre-service-principals-table-admin>
            </ng-container>
            <div *ngIf="error" class="p-3">
              Failed to retrieve the users.
            </div>
          </div>
        </div>

        <div class="row" *ngIf="(!showServicePrincipalsTable && !isNoSearchResult) && !(loading$ | async)">
          <div class="col">
            <div class="mt-3">
              <wre-toolkit-pagination [isLoading]="loading" [pageSize]="usersListService.pageSize"
                (pageSizeChange)="usersListService.pageSize=$event" [page]="usersListService.page"
                (pageChange)="usersListService.page=$event" [maxSize]="5" [boundaryLinks]="true" [total$]="total$">
              </wre-toolkit-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
