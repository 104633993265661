<div class="users-select">
  <div class="row pb-3">
    <div class="col-md">
      <span *ngFor="let item of availableUserTypes; let last = last;">
        <wre-toolkit-radio-button *ngIf="!item.hide" [class.me-3]="!last"
        [inputId]="item.id" inputName="userOption" [inputTabIndex]="1" [control]="selectedType"
        [inputValue]="item.value" [labelText]="item.displayName">
      </wre-toolkit-radio-button>
      </span>
    </div>
    <div class="col-md-auto d-flex justify-content-end">
      <div>
        <i class="far fa-user me-2"></i>
        <span class="header-label">
          Users :
        </span>
        <span class="header-value me-4">
          <strong>{{ userCount }}</strong>
        </span>

        <i class="far fa-users me-2"></i>
        <span class="header-label">
          AD Groups :
        </span>
        <span class="header-value me-4">
          <strong>{{ adGroupCount }}</strong>
        </span>

        <i class="far fa-desktop me-2"></i>
        <span class="header-label">
          SPs :
        </span>
        <span class="header-value">
          <strong>{{ servicePrincipleCount }}</strong>
        </span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 pe-0">
      <wtw-user-lookup-wrapper
        labelText="User lookup"
        inputId="role-user-userlookup"
        inputName="role-user-userlookup"
        [userTypes]="selectedType.value"
        [control]="form.get('users')"
        [placeholderValue]="placeholderText"
        [noSearchResultText]="noSearchResultText"
        [displayBadge]="false"
        [isInvalid]="false"
        [isDisabled]="!hasMemberPermissions">
      </wtw-user-lookup-wrapper>
    </div>

    <div class="col-md-2 ps-0" *ngIf="canAddNew">
      <div class="add-button-container">
        <div class="border-start ps-3 ms-3">
          <button type="button" class="btn wre-btn btn-primary" (click)="add()">
            <i class="far fa-plus"></i> &nbsp; New
          </button>
        </div>

      </div>
    </div>

  </div>

  <div class="user-grid border-top">
    <wre-users-table [control]="form.get('users')" [hasMemberPermissions]="hasMemberPermissions"></wre-users-table>
  </div>
</div>
