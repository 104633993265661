import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wre-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: []
})
export class ConfirmationComponent {

  @Output() confirmEvent: EventEmitter<boolean> = new EventEmitter();

  title: string = 'Confirm';
  message = 'Are you sure you want to proceed?';
  confirmationText = 'Yes';
  defaultNoText = 'No';
  entityName = '';

  loading: boolean;

  constructor(public activeModal: NgbActiveModal) { }

  confirm() {
    this.confirmEvent.emit(true);
  }

  closeModal() {
    if (this.loading) {
      return;
    }

    this.activeModal.close();
  }

  setLoading(loading: boolean): void {
    this.loading = loading;
  }

}
