import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgxTwitterWidgetsModule } from 'ngx-twitter-widgets';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { WreToolkitLibModule } from 'wre-toolkit-lib';
import { NewsModule } from '../news/news.module';
import { SharedModule } from '../shared/shared.module';
import { DomChangedDirective } from './dom-changed';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { HomeWelcomeComponent } from './home-welcome/home-welcome.component';
import { QuickaccessComponent } from './quickaccess/quickaccess.component';
import { SearchExperienceComponent } from './search-experience/search-experience.component';
import { TwitterFeedComponent } from './twitter-feed/twitter-feed.component';

const routes: Routes = [{ path: '', component: HomeDashboardComponent }];

@NgModule({
  declarations: [
    HomeDashboardComponent,
    TwitterFeedComponent,
    SearchExperienceComponent,
    QuickaccessComponent,
    HomeWelcomeComponent,
    DomChangedDirective,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    TourMatMenuModule,
    NewsModule,
    RouterModule.forChild(routes),
    WreToolkitLibModule,
    NgbNavModule,
    SharedModule,
    // NgxTwitterWidgetsModule,
  ],
  exports: [RouterModule],
})
export class HomeModule {}
