<div class="news-aggregator-toolbar tool-title noselect btn-toolbar">
    <h1 class="">Dashboard</h1>
</div>
<div class="wre-news-aggregator-container wre-main-container prevent-scroll-shift">
    <div class="row">
        <div class="order-lg-last order-last col-lg-5 col-md-12">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <wre-most-viewed-news></wre-most-viewed-news>
                </div>
            </div>
        </div>
        <div class="order-lg-first order-first col-lg-7 col-md-12">
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12 mb-3">
                    <div class="">
                        <div class="card shadow-sm bg-white">
                            <div class="btn-toolbar card-header" role="toolbar" aria-label="Toolbar Actions">
                                <h5 class="me-auto card-title">Slipcase</h5>
                                <div class="card-options">
                                    <button tourAnchor="slipcaseFilter.anchor" id="filter-collapse-container" class="btn btn-icon collapsed" data-bs-toggle="collapse" data-bs-target="#filter-container" aria-expanded="false" style="cursor: pointer;">
                                        <i class="far fa-filter"></i>
                                        <span class="ps-2">Filter</span>
                                    </button>
                                </div>
                            </div>
                            <div id="filter-container" class="card-body collapse filters-selector">
                                <div class="row">
                                    <div class="col d-flex align-items-center">
                                        <wre-toolkit-toggle-switch class="" name="include-paid" #includePaidRef="ngModel" [(ngModel)]="includePaid" labelText="Include Paid Subscriptions">
                                        </wre-toolkit-toggle-switch>
                                    </div>
                                    <div class="col-auto filters-actions d-flex">
                                        <div class="ms-auto">
                                            <button (click)="clearFilter()" class="btn">Clear Filter</button>
                                            <button (click)="applyFilter()" class="btn btn-primary ms-3">Apply</button>
                                        </div>
                                    </div>
                                </div>
                                <div id="filter-checkboxes" class="row mt-3">
                                    <div class="col">
                                        <ng-container *ngFor="let category of categories;">
                                            <div class="row pt-2 pb-2">
                                                <div class="col">
                                                    <h5 style="margin-bottom: 15px; font-size: 14px; font-weight: 600; letter-spacing: 1px; text-transform: uppercase;">{{category.name}}</h5>
                                                    <ng-container *ngFor="let topic of category.topics;">
                                                        <wre-toolkit-checkbox class="me-3 mb-3 d-inline-block" [label]="topic.name" (change)="onCheckboxChange(topic, $event.target.checked)" [id]="'filter_'+topic.id" [value]="topic.id">
                                                        </wre-toolkit-checkbox>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="filteredTopics.length> 0" class="filters-selected pt-2 ps-3 pe-3 pb-3">
                                <button (click)="removeTopicFromFilter(t)" *ngFor="let t of filteredTopics;" class="btn badge btn-success mt-2 me-3" type="button">
                                <span>{{t.name}}</span>
                                <span class="ps-3">×</span>
                        </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg col-md-12">
                    <app-infinite-scroll (scrolled)="fetchMoreNews()">
                        <ng-container *ngFor="let newsItem of newsData">
                            <div class="row justify-content-center mb-3">
                                <div class="col-lg-12 col-md-12">
                                    <app-slipcase-feed-item [newsItem]="newsItem"></app-slipcase-feed-item>
                                </div>
                            </div>
                        </ng-container>
                    </app-infinite-scroll>

                    <div class="row justify-content-center" *ngIf="isLoading">
                        <div class="col-lg-12 col-md-12 pb-4">
                            <div class="d-flex align-items-center justify-content-center" style="height:70px;">
                                <div class="loading"></div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="errorOccured === true" class="row justify-content-center">
                        <div class="col-lg-12 col-md-12 pb-4">
                            <div class="card shadow-sm bg-white pt-4 pb-4 ps-3 pe-3">
                                <wre-not-available serviceName="Slipcase" [errorOccured]="errorOccured"></wre-not-available>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
