import { Component, Inject, OnDestroy } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { RoleSummaryView } from '../../../../../models/role-summary-view';
import { SecurityWorkspaceService } from 'src/app/admin-portal/security-admin/services/security-workspace.service';
import { UserPermissions } from 'src/app/models/admin';
import { RolesMainListService } from '../../../../../services/roles/roles-main-list.service';
import { AuthorisationService } from 'wre-authlib';

@Component({
  selector: 'wre-access-roles',
  templateUrl: './access-roles.component.html',
  styleUrls: ['./access-roles.component.sass'],
  providers: [RolesMainListService]
})
export class AccessRolesComponent implements OnDestroy {
  roles$: Observable<RoleSummaryView[]>;
  loading$: Observable<boolean>;
  error$: Observable<Error>;
  subscription = new Subscription();

  hasRolePermission: boolean = false;

  constructor(
    private authService: AuthorisationService,
    public rolesService: RolesMainListService,
    private securityWorkspaceService: SecurityWorkspaceService) {
    this.roles$ = this.rolesService.roles$;
    this.loading$ = this.rolesService.loading$;
    this.error$ = this.rolesService.error$;
    this.subscription.add(
      combineLatest([this.securityWorkspaceService.currentWorkspace$, this.authService.getUserApplicationRolesWithPermissions()])
      .subscribe(([_, permissionList]) => {
        this.securityWorkspaceService.setRolesWithPermissions(permissionList);
        this.hasRolePermission = this.securityWorkspaceService.hasRequiredPermission(UserPermissions.RolesAdd);
        this.rolesService.reset();
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
