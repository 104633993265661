import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { tap, debounceTime, switchMap, mergeMap, delay } from 'rxjs/operators';
import { Observable, throwError, BehaviorSubject, Subject, of } from 'rxjs';


export interface DefaultGridConfig<T> {
  sortColumn?: SortColumn<T>;

  sortDirection?: 'desc' | 'asc';
  pageSize?: number;
}

interface SearchResult<T> {
  items: T[];
  total: number;
}

export interface State<T> {
  page: number;
  pageSize: number;
  defaultPageSize: number;
  searchTerm: string;
  sortColumn: SortColumn<T>;
  sortDirection: SortDirection;
  defaultSortDirection: SortDirection;
  defaultSortColumn: SortColumn<T>;
}

export type SortColumn<T> = keyof T | '';
export type SortDirection = 'asc' | 'desc' | '';


const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;


@Injectable({
  providedIn: 'root'
})
export abstract class BaseListService<T> {

  private itemResponse: T[] = [];

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _items$ = new BehaviorSubject<T[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State<T> = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: 'desc',
    defaultSortDirection: 'desc',
    defaultSortColumn: '',
    defaultPageSize: 10
  };

  constructor() {
    this._search$.pipe(
      debounceTime(400),
      tap(() => this._loading$.next(true)),
      switchMap(() => this._search())
    ).subscribe(result => {
      this._items$.next(result.items);
      this._total$.next(result.total);
      this._loading$.next(false);
    });
  }

  get items$() { return this._items$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  get items() { return this._items$.getValue(); }

  // tslint:disable-next-line: adjacent-overload-signatures
  set page(page: number) { this._set({ page }); }
  // tslint:disable-next-line: adjacent-overload-signatures
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  // tslint:disable-next-line: adjacent-overload-signatures
  set searchTerm(searchTerm: string) { this._set({ searchTerm }); }

  set sortColumn(sortColumn: SortColumn<T>) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  private _set(patch: Partial<State<T>>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult<T>> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let items = this.sort(this.itemResponse, sortColumn, sortDirection);

    // 2. filter
    items = items.filter(c => this.matches(c, searchTerm));
    const total = items.length;

    // 3. paginate
    items = items.slice((page - 1) * pageSize, page * pageSize);
    return of({ items, total });
  }

  handleRetry(errors: Observable<HttpResponse<any>>) {
    let retries = 2;
    return errors
      .pipe(mergeMap((error: HttpResponse<any>) =>
        // All 4xx status codes we do not retry
        ((error.status !== undefined && error.status.toString().indexOf('4') === 0)) || retries-- === 0 ?
          throwError(error) :
          of(error).pipe(delay(1000))));
  }

  // This method must be implemented by extender.
  // Used for search
  abstract matches(item: T, term: string): boolean;

  // This method must be implemented by extender.
  // Used to retrieve data from API
  abstract getAllItems(param?: any): Promise<HttpResponse<T[]> | T[]> ;

  // Call this method to get collection from API and apply it
  public async loadData(param?: any, defaultConfig?: DefaultGridConfig<T>) {
    this._loading$.next(true);
    if (defaultConfig) {
      this._state.defaultPageSize = defaultConfig.pageSize ? defaultConfig.pageSize : this._state.pageSize;
      this._state.defaultSortColumn = defaultConfig.sortColumn ? defaultConfig.sortColumn : '';
      this._state.defaultSortDirection = defaultConfig.sortDirection ? defaultConfig.sortDirection : 'desc';
    }

    this.resetStateToDefault();
    const response = await this.getAllItems(param);
    this.itemResponse = response instanceof Array ? response : await response.body;
    this._search$.next();
    this._loading$.next(false);
  }

  private resetStateToDefault(defaultConfig?: DefaultGridConfig<T>) {
    this._items$.next([]);
    this._total$.next(0);
    this._state.page = 1;
    this._state.pageSize = this._state.defaultPageSize;
    this._state.searchTerm = '';
    this._state.sortColumn = this._state.defaultSortColumn;
    this._state.sortDirection = this._state.defaultSortDirection;
  }

  public sort(items: T[], column: SortColumn<T>, direction: string): T[] {
    if (direction === '' || column === '') {
      return items;
    } else {

      // TODO: Make sorting showing nulls and empty always bottom of the list regardless sorting direction
      // const arr = [...items];
      // const undefinedAndNulls =
      //   arr.filter(val => val[column] === null || val[column] === undefined || val[column].toString().trim() === '');
      // const numbers = arr.filter(val => !isNaN(val[column as number]) && val[column] !== null);
      // const sortedNumbers = numbers.sort((a, b) => a[column as number] - b[column as number]);
      // const rest = arr.filter(val => val[column] && isNaN(val[column as number]));
      // const sortedRest = rest.sort((a, b) => {
      //   const val1 = a[column] || '';
      //   const val2 = b[column] || '';
      //   const valueA = val1.toString().trim();
      //   const valueB = val2.toString().trim();
      //   const res = valueA.localeCompare(valueB);
      //   return direction === 'asc' ? res : -res;
      // });
      // return [...sortedNumbers, ...sortedRest, ...undefinedAndNulls];

      return [...items].sort((a, b) => {
        // const res = compare(`${ a[column] }`, `${ b[column] }`);
        const res = a[column] === null ? -1 : b[column] === null ? 1 : a[column].toString().localeCompare(b[column].toString());

        return direction === 'asc' ? res : -res;
      });
    }
  }

}



