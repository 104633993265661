import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { confirmationMessages, confirmationTexts, confirmationTitles } from '../../../shared/enums/confirmation';
import { filter, take } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { ApiService } from '../services/api.service';
import { OverviewSearchService } from '../services/overview-search.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OverviewAccessFormService } from '../services/overview-access-form.service';

@Component({
  template: ``,
})
export class OverviewAccessModalBaseComponent<T> implements OnInit, OnDestroy {

  protected _userId: string;

  get userId(): string {
    return this._userId;
  }

  set userId(userId: string) {
    this._userId = userId;
    if (userId == null) {
      this.reset();
    } else {
      this.retrieveUserAccessMapping();
    }
  }

  form: UntypedFormGroup;

  get isDisabled(): boolean {
    return this.form.invalid || this.form.pristine;
  }

  loading: boolean;
  error: string;

  subscription: Subscription;

  constructor(protected activeModal: NgbActiveModal,
              protected confirmationService: ConfirmationService,
              protected apiService: ApiService<T>,
              protected dataService: OverviewSearchService<T>,
              protected formService: OverviewAccessFormService<T>) { }

  ngOnInit(): void {
    this.form = this.formService.createOverviewForm();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  confirmCloseModal(): void {
    if (this.isDisabled) {
      this.activeModal.close();
      return;
    }

    this.subscription = this.confirmationService
      .openModal(confirmationTitles.defaultTitle, confirmationMessages.defaultMessage, confirmationTexts.defaultConfirmationText)
      .pipe(filter(close => close), take(1))
      .subscribe(() => {
        this.confirmationService.closeModal();
        this.activeModal.close();
      });
  }

  submit(): void {
    const accessMapping = this.formService.getAccessMappingFromForm(this.form);
    this.loading = true;

    if (this.userId == null) {
      this.apiService.createAccessMapping(accessMapping).subscribe(this.successSubmission, this.errorSubmission);
    } else {
      this.apiService.updateAccessMapping(accessMapping).subscribe(this.successSubmission, this.errorSubmission);

    }
  }

  private retrieveUserAccessMapping(): void {
    this.loading = true;
    this.apiService.getAccessMapping(this.userId).subscribe(mapping => {
      this.formService.patchFormWithAccessMapping(this.form, mapping);
      this.loading = false;
    }, this.errorRetrieval);
  }

  private reset(): void {
    this.form?.reset();
    this.error = null;
  }

  private successSubmission = () => {
    this.loading = false;
    this.dataService.loadOverviewAccess();
    this.activeModal.close();
    this.reset();
  }

  private errorSubmission = (error: HttpErrorResponse) => {
    switch (error.status) {
      case 409:
        this.error = 'Overview mapping for this user already exists';
        break;

      case 404:
        this.error = 'Overview mapping for this user does not exist';
        break;

      default:
        this.error = 'Error has occurred while submitting the overview mapping';
    }

    setTimeout(() => this.error = null, 5000);
    this.loading = false;
  }

  private errorRetrieval = (error: HttpErrorResponse) => {
    switch (error.status) {
      case 404:
        this.error = 'Overview mapping for this user does not exist';
        break;

      default:
        this.error = 'Error has occurred while retrieving the overview mapping';
    }

    setTimeout(() => this.error = null, 5000);
    this.loading = false;
  }

}
