import { Injectable } from '@angular/core';
import { FileUploadStatus } from '../models/file-upload-status';
import { SanctionsAdminService } from './sanctions-admin.service';
import { DbUpdateResult } from '../models/db-update-result';

@Injectable({
  providedIn: 'root'
})
export class FileSelectService {

  file: File;
  status: FileUploadStatus = FileUploadStatus.AwaitingInput;
  uploadResult: DbUpdateResult;

  constructor(private sanctionsAdminService: SanctionsAdminService) { }

  public selectFile(file: File) {
    this.file = file;
    this.status = FileUploadStatus.FileSelected;
  }

  public submitFile(): void {
    this.status = FileUploadStatus.Submitting;
    this.sanctionsAdminService.uploadDatabaseFile(this.file).subscribe(
      uploadResult => {
        this.uploadResult = uploadResult;
        this.status = FileUploadStatus.Complete;
      },
      () => this.status = FileUploadStatus.Error
    );
  }

  public resetError(): void {
    if (this.status === FileUploadStatus.Error) {
      this.status = FileUploadStatus.FileSelected;
    }
  }

  public reset(): void {
    this.file = null;
    this.status = FileUploadStatus.AwaitingInput;
    this.uploadResult = null;
  }
}
