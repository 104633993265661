<div class="card">
    <div class="card-header">
        <h4 class="card-title">{{title}}</h4>
    </div>
    <div class="card-body">
        <p>{{description}}</p>
        <div class="btn-cont">
            <a class="btn btn-primary" routerLink="../{{route}}">Read More</a> 
        </div>
    </div>
</div>