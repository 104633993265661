<div class="d-flex align-items-center justify-content-center vh-100 vw-100 position-relative overflow-hidden b-container">
  <span class="background-image"></span>
  <div class="card wre-gateway-login-card">
    <div class="row">
      <div class="col-sm-5 d-none d-lg-block pe-0">
        <div class="base position-relative overflow-hidden">
          <span class="base-feature-1"></span>
          <span class="base-feature-2"></span>
        </div>
      </div>
      <div class="col-sm-12 col-lg-7 ps-0">
        <div class="wre-gateway-login-content">
          <div class="row">
            <div class="col">
              <div class="card-header d-block">
                <div class="row">
                  <div class="col-auto pt-3">
                    <span class="gateway-logo"><img src="../../assets/images/gateway-logo-new-ajg.svg" /></span>
                  </div>
                  <div class="col ps-0">
                    <span>Welcome to</span>
                    <h2>Gallagher Re Gateway</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="mb-0">Our digital ecosystem which contains all your tools. This seamless and unified ecosystem enables: </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <ul class="list-group border-bottom pb-2 mb-3">
                <li class="list-group-item">Rapid  <strong>innovation</strong> whilst reducing the time to market when delivering new services</li>
                <li class="list-group-item">Our <strong>on-the-go</strong> strategy by ensuring our services are available across multiple platforms including your desktop and tablet</li>
                <li class="list-group-item">Delivery of the best <strong>experience</strong> possible</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col text-center internal">
              <span class="d-block mb-3 title d-flex justify-content-center align-items-center"><i class="far fa-user me-2"></i> Employee</span>
              <button class="btn btn-primary w-100" (click)="navigateToInternalUserLoginPage()">Log in</button>
            </div>
            <div class="col-auto d-flex align-items-center divide position-relative">
              <span>or</span>
            </div>
            <div class="col text-center external">
              <span class="d-block mb-3 title d-flex justify-content-center align-items-center"><i class="far fa-user me-2"></i> Client</span>
              <button class="btn btn-tertiary w-100" (click)="navigateToExternalUserLoginPage()">Log in <i class="far fa-external-link-alt ms-2"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
