import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlipcaseFeedComponent } from './slipcase-feed/slipcase-feed.component';
import { SlipcaseCardComponent } from './slipcase-card/slipcase-card.component';
import { FormsModule } from '@angular/forms';
import { WreCoreLibModule } from 'wre-core-lib';
import { WreToolkitLibModule } from 'wre-toolkit-lib';
import { NewsAggregatorComponent } from './news-aggregator/news-aggregator.component';
import { SharedComponentsModule } from '../components/shared/shared-components.module';
import { SlipcaseItemContentComponent } from './slipcase-item-content/slipcase-item-content.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { MostViewedNewsComponent } from '../components/shared/most-viewed-news/most-viewed-news.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    NewsAggregatorComponent,
    SlipcaseFeedComponent,
    SlipcaseCardComponent,
    SlipcaseItemContentComponent,
    MostViewedNewsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    WreCoreLibModule,
    WreToolkitLibModule,
    SharedComponentsModule,
    TourMatMenuModule,
    SharedModule
  ],
  exports: [SlipcaseFeedComponent, MostViewedNewsComponent]
})
export class NewsModule { }
