<div [hidden]="!hasData">
    <div class="modal-header d-flex align-items-center">
        <h5 class="modal-title mb-0">
            {{ title }}
        </h5>
        <div class="modal-options">
            <button class="btn btn-icon" type="button" data-bs-dismiss="modal" aria-label="Close"  (click)="confirmClose()" tabindex="-1">
                <i class="fal fa-times"></i>
            </button>
        </div>
    </div>
    <wre-toolkit-loader *ngIf="loading"></wre-toolkit-loader>
    <form novalidate #newCategoryForm="ngForm" (ngSubmit)="onAdd(newCategoryForm)">
        <div *ngIf="!loading" class="modal-body p-4">
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <label class="form-control-label" for="name">Name</label>
                    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="newCategory.name" required #name="ngModel" placeholder [ngClass]="{'invalid': ((name.touched || name.dirty) && !name.valid) || (!name.valid && newCategoryForm.submitted) }">
                    <span class="invalid-feedback d-none">
                        <span *ngIf="name.errors?.required">
                            Please enter workspace name.
                        </span>
                    </span>
                </div>
                <div class="col-md-12 col-lg-6">
                    <label class="form-control-label" for="orderNo">Order No</label>
                    <input type="text" class="form-control" id="orderNo" name="orderNo" [(ngModel)]="newCategory.orderNo" required #orderNo="ngModel" placeholder [ngClass]="{'invalid': ((orderNo.touched || orderNo.dirty) && !orderNo.valid) || (!orderNo.valid && newCategoryForm.submitted) }">
                    <span class="invalid-feedback d-none">
                        <span *ngIf="orderNo.errors?.required">
                            Please enter order number.
                        </span>
                    </span>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col">
                    <wre-toolkit-toggle-switch name="isValid" #isValid="ngModel" labelText="Active" [(ngModel)]="newCategory.isValid">
                    </wre-toolkit-toggle-switch>
                </div>
            </div>
        </div>
        <div class="modal-footer border-top">
            <button type="submit" class="btn btn-primary ms-3" [disabled]="loading">
        <span>{{ buttonAction }}</span>
      </button>
        </div>
    </form>
</div>
