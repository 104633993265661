import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PowerBiConfig, Report } from '../../../models/admin';
import { ReportType } from '../models/report-type.enum';
import { Subscription } from 'rxjs';
import { AdminFormService } from '../../admin-shared/services/admin-form.service';

@Injectable({
  providedIn: 'root'
})
export class ReportFormService {

  private namePattern: RegExp = /^[a-zA-Z0-9 _-]*$/;

  constructor(private fb: UntypedFormBuilder, private adminFormService: AdminFormService) { }

  /**
   * Create custom Form Groups
   */

  // Create empty FormGroup for Report
  createReportFormGroup(): UntypedFormGroup {
    return this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100), this.adminFormService.noWhitespaceValidator]],
      type: [null, Validators.required],
      areaId: [null, Validators.required],
      link: ['', [Validators.required, Validators.pattern('https?://.+')]],
      powerBiConfig: this.createPowerBiConfig(),
      summary: ['', Validators.required],
      modelId: null,
      isActive: true,
      isExternal: false,
      permissions: [[]]
    });
  }

  // Create empty FormGroup for PowerBiConfig
  createPowerBiConfig(): UntypedFormGroup {
    return this.fb.group({
      groupExternalGuid: ['', [Validators.required, Validators.pattern(this.adminFormService.guidPattern)]],
      reportExternalGuid: ['', [Validators.required, Validators.pattern(this.adminFormService.guidPattern)]],
      modelId: null,
      defaultSection: '',
      sharedBookmarksVisible: false,
      rowLevelSecurity: false,
      azureAnalysisServices: false,
      rlsRoles: [[]]
    });
  }

  // Create empty FormGroup for RLS role
  createRLSRoleFormGroup(rlsRoleWithAASDataSource: boolean): UntypedFormGroup {
    return rlsRoleWithAASDataSource
      ? this.fb.group({
          name: ['', [Validators.required, Validators.pattern(this.namePattern),
          Validators.maxLength(100), this.adminFormService.noWhitespaceValidator]],
          rlsCustomData: ['', [Validators.required, Validators.pattern(this.namePattern),
            Validators.maxLength(100), this.adminFormService.noWhitespaceValidator]],
          securityRole: this.adminFormService.createSecurityRoleFormGroup()
        })
      : this.fb.group({
        name: ['', [Validators.required, Validators.pattern(this.namePattern),
        Validators.maxLength(100), this.adminFormService.noWhitespaceValidator]],
        securityRole: this.adminFormService.createSecurityRoleFormGroup()
      });
  }

  /**
   * Custom Validators
   */

  // Subscription to update validators when report type is changed
  reportTypeSubscription(form: UntypedFormGroup): Subscription {
    return form.get('type').valueChanges.subscribe(value => {
      if (+value === ReportType.PowerBI) {
        form.get('link').disable();
        form.get('powerBiConfig').enable();
      } else {
        form.get('link').enable();
        form.get('powerBiConfig').disable();
      }
    });
  }

  /**
   * Assignments of data to/from Report object from/to report Form Group
   */
  assignReportToFormGroup(report: Report, reportForm: UntypedFormGroup): void {

    // Need to create an empty object to support patching form with nested values
    if (!report.powerBiConfig) { report.powerBiConfig = new PowerBiConfig(); }

    reportForm.reset();
    reportForm.patchValue(report);

    // Copy array of permissions to prevent the form control from directly pointing to report permissions property
    const permissions = Object.assign([], report.permissions);
    reportForm.get('permissions').setValue(permissions);

    if (report.type === ReportType.PowerBI) {
      reportForm.get('type').disable();
    }
  }

  assignFormToReport(reportForm: UntypedFormGroup, report: Report): void {
    Object.assign(report, reportForm.getRawValue());
    if (report.type == null) {
      report.type = reportForm.get('type').value as ReportType;
    }
  }

}
