import { Component, Input, OnInit } from '@angular/core';
import { Option } from '../../../../models/referral-field';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'wre-options-drag-and-drop',
  templateUrl: './options-drag-and-drop.component.html',
  styleUrls: ['./options-drag-and-drop.component.sass']
})
export class OptionsDragAndDropComponent implements OnInit {

  @Input() existingOptions: Option[] = [];
  @Input() allOptions: Option[] = [];
  @Input() isUserType: boolean;
  @Input() orderDisabled: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  removeOption(option: Option) {
    if (!option.isLocked) {
      this.allOptions.splice(this.allOptions.indexOf(option), 1);
    }
  }

  isNewOption(option: Option): boolean {
    return this.existingOptions.findIndex(o => o.equalValue(option, this.isUserType)) < 0;
  }

  dropItem(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.allOptions, event.previousIndex, event.currentIndex);
    this.allOptions.forEach((o, idx) => o.order = idx);
  }

}
