import { ReportType } from '../admin-portal/reporting-admin/models/report-type.enum';
import { SecurityRole } from './security-role';

export class AreaData {
  id: number;
  name: string;
  isActive: boolean;

  constructor(
    id: number = 0,
    name: string = '',
    isActive: boolean = true
  ) { }
}

export class SecurityGroupData {
  id: number;
  name: string;
  domain: string;

  constructor(
    id: number = 0,
    name: string = '',
    domain: string = ''
  ) { }
}

export class TourStepData {
  id: number;
  routeName: string;
  stepId: number;
  anchorId: string;
  title: string;
  content: string;
  isActive: boolean;
  appName: string;
  isMobile: boolean;

  constructor(
    id: number = 0,
    routeName: string = '',
    stepId: number = 0,
    anchorId: string = '',
    title: string = '',
    content: string = '',
    isActive: boolean = true,
    appName: string = '',
    isMobile: boolean = true
  ) { }

  equal(tour: TourStepData): boolean {
    return this.id === tour.id &&
      this.routeName === tour.routeName &&
      this.stepId === tour.stepId &&
      this.anchorId === tour.anchorId &&
      this.title === tour.title &&
      this.content === tour.content &&
      this.isActive === tour.isActive &&
      this.appName === tour.appName &&
      this.isMobile === tour.isMobile;
  }

}

export class TourStepApps {
  appName: string;

  constructor(
    appName: string = '',
  ) { }
}

export class TourStepRoutes {

  routeName: string;

  constructor(
    routeName: string = '',
  ) { }
}

export class RLSRole {
  name: string;
  rlsCustomData: string;
  securityRole: SecurityRole;

  constructor(
    name = '',
    rlsCustomData = '',
    securityRole = new SecurityRole()
  ) {
    this.name = name;
    this.rlsCustomData = rlsCustomData;
    this.securityRole = securityRole;
  }
}

export class PowerBiConfig {
  groupExternalGuid?: string;
  reportExternalGuid?: string;
  defaultSection?: string;
  sharedBookmarksVisible?: boolean;
  rowLevelSecurity: boolean;
  rlsRoles: RLSRole[];
  azureAnalysisServices: boolean;

  constructor(
    groupExternalGuid = '',
    reportExternalGuid = '',
    defaultSection = '',
    sharedBookmarksVisible = false,
    rowLevelSecurity = false,
    rlsRoles = [],
    azureAnalysisServices = false
  ) {
    this.groupExternalGuid = groupExternalGuid;
    this.reportExternalGuid = reportExternalGuid;
    this.defaultSection = defaultSection;
    this.sharedBookmarksVisible = sharedBookmarksVisible;
    this.rowLevelSecurity = rowLevelSecurity;
    this.rlsRoles = this.rowLevelSecurity ? rlsRoles : null;
    this.azureAnalysisServices = azureAnalysisServices;
  }

  equal(config: PowerBiConfig): boolean {
    return config.groupExternalGuid?.trim() === this.groupExternalGuid?.trim() &&
      config.reportExternalGuid?.trim() === this.reportExternalGuid?.trim() &&
      config.defaultSection?.trim() === this.defaultSection?.trim() &&
      config.sharedBookmarksVisible === this.sharedBookmarksVisible &&
      config.rowLevelSecurity === this.rowLevelSecurity &&
      config.rlsRoles === this.rlsRoles &&
      config.azureAnalysisServices === this.azureAnalysisServices;
  }

  clone(): PowerBiConfig {
    const config = new PowerBiConfig();
    config.groupExternalGuid = this.groupExternalGuid;
    config.reportExternalGuid = this.reportExternalGuid;
    config.defaultSection = this.defaultSection;
    config.sharedBookmarksVisible = this.sharedBookmarksVisible;
    config.rowLevelSecurity = this.rowLevelSecurity;
    config.rlsRoles = this.rlsRoles;
    config.azureAnalysisServices = this.azureAnalysisServices;
    return config;
  }

}

export class Report {
  id?: number;
  name?: string;
  summary?: string;
  link?: string;
  area?: any;
  areaId?: number;
  type?: ReportType;
  modelId?: number;
  isActive?: boolean;
  isExternal?: boolean;
  securityGroup?: string;
  powerBiConfig?: PowerBiConfig;
  permissions?: SecurityRole[];

  constructor(
    id = 0,
    name = '',
    summary = '',
    link = '',
    areaId = 0,
    isActive = true,
    powerBiConfig = {},
    permissions = []
  ) { }

  equal(newReport: Report): boolean {
    return newReport.id === this.id &&
      newReport.name.trim() === this.name.trim() &&
      newReport.summary.trim() === this.summary.trim() &&
      newReport.link?.trim() === this.link?.trim() &&
      newReport.area === this.area &&
      newReport.areaId === this.areaId &&
      newReport.type === this.type &&
      newReport.modelId === this.modelId &&
      newReport.isActive === this.isActive &&
      newReport.isExternal === this.isExternal &&
      newReport.securityGroup?.trim() === this.securityGroup?.trim() &&
      newReport.powerBiConfig.equal(this.powerBiConfig) &&
      JSON.stringify(newReport.permissions).includes(JSON.stringify(this.permissions)) &&
      newReport.permissions.length === this.permissions.length;
  }

  clone(): Report {
    const newReport = new Report();
    const config = Object.assign(new PowerBiConfig(), this.powerBiConfig);
    newReport.id = this.id;
    newReport.name = this.name;
    newReport.summary = this.summary;
    newReport.link = this.link;
    newReport.area = this.area;
    newReport.areaId = this.areaId;
    newReport.type = this.type;
    newReport.modelId = this.modelId;
    newReport.isActive = this.isActive;
    newReport.isExternal = this.isExternal;
    newReport.securityGroup = this.securityGroup;
    newReport.powerBiConfig = config.clone();
    newReport.permissions = [...this.permissions];
    return newReport;
  }

}

export enum Roles {
  gatewayAdmin = 'w-wre-gateway-admin',
  reportingAdmin = 'w-wre-reporting-admin',
  timeSurveyAdmin = 'w-wre-time-survey-admin',
  internalUser = 'gateway-internal-user',
  externalUser = 'gateway-external-user'
}

export enum AdminExperience {
  Gateway = 'Gateway',
  Reporting = 'Interactive Reports',
  Security = 'Permissions',
  Safr = 'SAFR',
  Sanctions = 'Sanctions Identification',
  SRT = 'Sanctions Referrals'
}

export class Experience {
  name: AdminExperience;
  url: string;
}

export enum Environment {
  Dev = 'Dev',
  Qa = 'Qa',
  Uat = 'Uat',
  Prod = 'Prod'
}

export enum UserPermissions {
  GatewayAdminCogView = 'Gateway.AdminCog.View',
  GatewayExperienceSecurityView = 'Gateway.Experience.Security.View',
  GatewayExperienceReportingView = 'Gateway.Experience.Reporting.View',
  GatewayExperienceSafrView = 'Gateway.Experience.SAFR.View',
  GatewayExperienceGatewayView = 'Gateway.Experience.Gateway.View',
  GatewayExperienceSanctionsView = 'Gateway.Experience.Sanctions.View',
  GatewayExperienceSrtView = 'Gateway.Experience.SRT.View',
  GatewayRolesTabInteractiveReportsView = 'Gateway.RolesTab.InteractiveReports.View',
  GatewayRolesTabSAFRView = 'Gateway.RolesTab.SAFR.View',
  GatewayRolesTabGatewayView = 'Gateway.RolesTab.Gateway.View',
  GatewaySecurityUsersTabView = 'Gateway.Security.UsersTab.View',
  GatewaySecurityUsersTabEdit = 'Gateway.Security.UsersTab.Edit',

  GatewayWorkspacesRead = 'Gateway.Workspaces.Read',
  GatewayWorkspacesAdd = 'Gateway.Workspaces.Add',
  GatewayWorkspacesEdit = 'Gateway.Workspaces.Edit',
  GatewayWorkspacesDelete = 'Gateway.Workspaces.Delete',
  GatewayToolsRead = 'Gateway.Tools.Read',
  GatewayToolsAdd = 'Gateway.Tools.Add',
  GatewayToolsEdit = 'Gateway.Tools.Edit',
  GatewayToolsDelete = 'Gateway.Tools.Delete',
  GatewayInlineTrainingRead = 'Gateway.InlineTraining.Read',
  GatewayInlineTrainingAdd = 'Gateway.InlineTraining.Add',
  GatewayInlineTrainingEdit = 'Gateway.InlineTraining.Edit',
  GatewayInlineTrainingDelete = 'Gateway.InlineTraining.Delete',

  GatewaySearchView = 'Gateway.Search.View',
  GatewaySlipcaseView = 'Gateway.Slipcase.View',

  InteractiveReportsCategoryAdd = 'InteractiveReports.Category.Add',
  InteractiveReportsReportsAdd = 'InteractiveReports.Reports.Add',
  InteractiveReportsReportsEdit = 'InteractiveReports.Reports.Edit',
  InteractiveReportsReportsDelete = 'InteractiveReports.Reports.Delete',
  InteractiveReportsCategoryRead = 'InteractiveReports.Category.Read',
  InteractiveReportsRolesAdd = 'InteractiveReports.Roles.Add',
  InteractiveReportsRolesDelete = 'InteractiveReports.Roles.Delete',
  InteractiveReportsMembersAdd = 'InteractiveReports.Members.Add',
  InteractiveReportsPermissionEnable = 'InteractiveReports.Permission.Enable',
  InteractiveReportsRead = 'InteractiveReports.Reports.Read',
  InteractiveReportsRoleAdminsAdd = 'InteractiveReports.RoleAdmins.Add',
  InteractiveReportsAuthorisedMembersAdd = 'InteractiveReports.AuthorisedMembers.Add',
  InteractiveReportsAuthorisedRolesAdd = 'InteractiveReports.AuthorisedRoles.Add',
  InteractiveReportsReportsSettingsView = 'InteractiveReports.Reports.Settings.View',
  InteractiveReportsCategoriesSettingsView = 'InteractiveReports.Categories.Settings.View',

  SafrFieldManagementSettingsView = 'SAFR.FieldManagement.Settings.View',

  SanctionsDatabaseEdit = 'Sanctions.Database.Edit',

  SrtFieldManagementSettingsView = 'SRT.FieldManagement.Settings.View',
  SrtOverviewAccessRead = 'SRT.OverviewAccess.Read',
  SrtOverviewAccessAdd = 'SRT.OverviewAccess.Add',
  SrtOverviewAccessEdit = 'SRT.OverviewAccess.Edit',
  SrtOverviewAccessDelete = 'SRT.OverviewAccess.Delete',

  RolesDetails = 'Roles.Details',
  RolesRead = 'Roles.Read',
  RolesAdd = 'Roles.Add',
  RolesEdit = 'Roles.Edit',
  AuthorisedRolesEdit = 'AuthorisedRoles.Edit',
  AuthorisedMembersAdd = 'AuthorisedMembers.Add',
  MembersAdd = 'Members.Add',
  PermissionEnable = 'Permission.Enable',
  RoleAdminsAdd = 'RoleAdmins.Add',
  RolesDelete = 'Roles.Delete'
}

export enum Applications {
  WreReporting = 'wre-reporting',
  WreSafr = 'wre-safr',
  WreGateway = 'wre-gateway',
  WreSanctions = 'wre-sanctions',
  WreReferral = 'wre-referral'
}
