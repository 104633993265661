<div class="row">
    <div class="col-md-12">
        <div class="d-flex align-items-center role-summary border-top border-bottom">
            <div class="pe-3 me-3 border-end">
                <label class="form-control-label mb-0">
                    Role Name:
                </label>
                <span class="header-value">
                    <strong>{{ roleName }}</strong>
                </span>
            </div>
            <div class="me-3">
                <label class="form-control-label mb-0">
                    Active:
                </label>
                <span class="badge" [class.bg-success]="isActive" [class.bg-danger]="!isActive">
                    {{ isActive ? 'Active' : 'Inactive' }}
                </span>
            </div>
            <div class="ps-3 me-3 border-start" *ngIf="adminCount != null">
                <label class="form-control-label mb-0">Admins: </label>
                <span class="header-value fw-bold">{{ adminCount }}</span>
            </div>
            <div *ngIf="displayUsersStats" class="ps-3 border-start">
                <span>
                    <i class="far fa-user me-2"></i>
                    <span class="header-label">
                        Users :
                    </span>
                    <span class="header-value me-3 fw-bold">
                        {{ userCount }}
                    </span>
                    <i class="far fa-users me-2"></i>
                    <span class="header-label">
                        AD Groups :
                    </span>
                    <span class="header-value me-3 fw-bold">
                        {{ adGroupCount }}
                    </span>

                    <i class="far fa-desktop me-2"></i>
                    <span class="header-label">
                      SPs :
                    </span>
                    <span class="header-value fw-bold">
                        {{ servicePrincipleCount }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>
