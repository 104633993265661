import { confirmationTitles, confirmationMessages, confirmationTexts } from '../../../shared/enums/confirmation';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AppInsightsService } from 'wre-core-lib';
import { Applications, Report, RLSRole } from '../../../models/admin';
import { ReportType } from '../models/report-type.enum';
import { ReportFormService } from './report-form.service';
import { WizardComponent } from 'wre-toolkit-lib';
import { ReportingService } from '../services/reporting.service';

@Component({
  selector: 'wre-reports-create-update',
  templateUrl: './reports-create-update.component.html',
  styleUrls: ['./reports-create-update.component.sass']
})
export class ReportsCreateUpdateComponent implements OnInit, OnDestroy {

  newReport: Report;

  @ViewChild(WizardComponent)
  private wizard: WizardComponent;

  @Input() report: Report;  // If report is to be edited
  @Output() refreshReportData: EventEmitter<boolean> = new EventEmitter();

  isLoaderVisible = false;

  conflictName: string = null;
  errorMessage: string = null;

  ReportType = ReportType;
  newReportForm: UntypedFormGroup;
  formSubscription: Subscription;
  subscription: Subscription;
  modalRef: NgbModalRef;
  oldReport: Report; // To track changes

  reportingApplication = Applications.WreReporting;
  rlsRoleWithAASDataSourceAdded: boolean = false;

  get isCompleted(): boolean {
    return this.wizard && this.wizard.selected.completed;
  }

  get isEditMode() {
    return this.report !== undefined;
  }

  get title() {
    return this.isEditMode ? 'Update Report' : 'New Report';
  }

  get nextButtonText() {
    if (this.wizard?.selectedIndex === 2) {
      // Last step
      return this.isEditMode ? 'Update Report' : 'Create Report';
    }
    return 'Next';
  }

  get showBackButton() {
    return this.wizard?.selectedIndex !== 0;
  }

  get rlsRoleWithAASDataSource() {
    return this.newReportForm.get('powerBiConfig.azureAnalysisServices').value === true && 
      this.newReportForm.get('powerBiConfig.rowLevelSecurity').value === true
  }

  get isRLSRoleWithAASDataSourceAdded() {
    const rlsRoles = this.newReportForm.get('powerBiConfig.rlsRoles').value as RLSRole[];
    return this.rlsRoleWithAASDataSource && rlsRoles.length >= 1;
  }

  constructor(public activeModal: NgbActiveModal,
    private appInsightsService: AppInsightsService,
    private _confirmationService: ConfirmationService,
    private reportingService: ReportingService,
    private reportFormService: ReportFormService) {
    this.appInsightsService.componentName = 'ReportsCreateUpdateComponent';
  }

  ngOnInit() {
    this.newReportForm = this.reportFormService.createReportFormGroup();
    this.formSubscription = this.reportFormService.reportTypeSubscription(this.newReportForm);
    this.newReport = new Report();
    if (this.report !== undefined) {
      this.reportFormService.assignReportToFormGroup(this.report, this.newReportForm);
      Object.assign(this.newReport, this.report);
      this.oldReport = this.newReport.clone();
    }
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  resetErrors() {
    this.conflictName = null;
    this.errorMessage = null;
  }

  closeModal() {
    this.activeModal.close();
  }

  backStep() {
    this.resetErrors();
    this.wizard.previous();
  }

  async executeStep() {
    this.resetErrors();
    switch (this.wizard.selectedIndex) {
      case 0:
        await this.checkIfReportExists();
        break;

      case 2:
        await this.handleFormSubmission();
        break;

      default:
        this.wizard.next();
    }
  }

  async checkIfReportExists() {
    const formReportName = this.newReportForm.get('name').value;
    if (this.isEditMode && formReportName.toLocaleLowerCase().trim() === this.report.name.toLocaleLowerCase().trim()) {
      this.wizard.next();
      return;
    }
    try {
      this.isLoaderVisible = true;
      const reportExists = await this.reportingService.checkIfReportExists(formReportName);
      if (reportExists) {
        this.conflictName = formReportName;
      } else {
        this.wizard.next();
      }
    } catch (error) {
      this.errorMessage = `An error has occurred while checking for an existing report`;
    }
    this.isLoaderVisible = false;
  }

  async handleFormSubmission() {
    if (this.newReportForm.invalid) {
      return;
    }

    this.reportFormService.assignFormToReport(this.newReportForm, this.newReport);

    this.isLoaderVisible = true;
    this.refreshReportData.emit(false);
    this.appInsightsService.methodName = this.isEditMode ? 'editReport' : 'addReport';

    try {
      this.appInsightsService.trackTrace(this.appInsightsService.msgMethodStart);

      if (!this.isEditMode) {
        await this.reportingService.addReport(this.newReport);
      } else {
        this.newReport.id = this.report.id;
        await this.reportingService.editReport(this.newReport);
      }
      this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
      this.refreshReportData.emit(true);
      this.closeModal();
    } catch (error) {
      if (error.status === 409) {
        this.conflictName = this.newReport.name;
      } else {
        this.errorMessage = `An error has occurred while ${this.isEditMode ? 'updating' : 'adding'} the report`;
        this.appInsightsService.trackTrace(error);
      }
    }
    this.isLoaderVisible = false;
  }

  confirmClose() {
    // close for
    // a. New form with not all fields filled
    // b. Edit with no chnages in form
    this.reportFormService.assignFormToReport(this.newReportForm, this.newReport);
    if ((!this.isEditMode && !this.isNewReportFormDetailsValid()) || (this.isEditMode && this.newReport.equal(this.oldReport))) {
      this.closeModal();
      return;
    }

    this.subscription = this._confirmationService.openModal(confirmationTitles.defaultTitle, confirmationMessages.defaultMessage, confirmationTexts.defaultConfirmationText).subscribe((close: boolean) => {
      if (close) {
        this._confirmationService.closeModal();
        this.closeModal();
      }
    });
  }

  // is details form filled completely for newly created report.
  isNewReportFormDetailsValid(): boolean {
    return this.newReportForm
      && this.newReportForm.get('name').valid
      && (this.newReportForm.get('type').valid || this.newReportForm.get('type').disabled)
      && this.newReportForm.get('areaId').valid
      && this.newReportForm.get('summary').valid
      && this.newReportForm.get('isActive').valid;
  }

}
