import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { retryWhen } from 'rxjs/operators';
import { SecurityGroupData, TourStepData } from 'src/app/models/admin';
import { environment } from 'src/environments/environment';
import { InteractiveReportsAdminTabs } from '../admin-portal/reporting-admin/models/report-type.enum';
import { HttpService } from './http.service';
import { AppsCategory } from '../models/apps-category';
import { App } from '../models/app';

@Injectable({
    providedIn: 'root'
})
export class AdminService extends HttpService {
    currentTabSource = new BehaviorSubject(InteractiveReportsAdminTabs.Category);
    currentTab = this.currentTabSource.asObservable();
    pageIsLoading = true;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    categoriesUrl = environment.gatewayApiUrl + 'admin/categories/';
    appsUrl = environment.gatewayApiUrl + 'admin/apps/';
    securityGroupsUrl = environment.gatewayApiUrl + 'admin/securitygroups/';
    searchTabUrl = environment.gatewayApiUrl + 'apps/';
    inlineTrainingUrl = environment.gatewayApiUrl + 'admin/inlinetraining/';

    constructor(http: HttpClient) {
        super(http);
    }

    getAppsCategories(): Observable<HttpResponse<AppsCategory[]>> {

        return this.http.get<AppsCategory[]>(this.categoriesUrl, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    addAppsCategory(category: AppsCategory): Observable<HttpResponse<Object>> {
        return this.http.post(this.categoriesUrl, category, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    editAppsCategory(category: AppsCategory): Observable<HttpResponse<Object>> {
        return this.http.put(this.categoriesUrl, category, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    enableDisableAppsCategory(id: number): Observable<HttpResponse<Object>> {

        const url = this.categoriesUrl + id.toString();

        return this.http.put(url, null, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    enableDisableApps(id: number): Observable<HttpResponse<boolean>> {
        const url = this.appsUrl + id.toString() + '/toggle-activation';

        return this.http.get<boolean>(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    deleteAppsCategory(id: number): Observable<HttpResponse<Object>> {

        const url = this.categoriesUrl + id.toString();

        return this.http.delete(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    getAppsCategory(id: number): Observable<HttpResponse<AppsCategory>> {

        const url = this.categoriesUrl + id.toString();

        return this.http.get<AppsCategory>(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    getApps(): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.appsUrl, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );

    }

    getSecurityGroups(): Observable<HttpResponse<any>> {
        return this.http.get<SecurityGroupData>(this.securityGroupsUrl, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    getSecurityGroup(id: number): Observable<HttpResponse<any>> {
        if (id === undefined) {
            id = 0;
        }
        const url = this.securityGroupsUrl + id.toString();

        return this.http.get<SecurityGroupData>(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    addApp(app: any): Observable<HttpResponse<App>> {
        return this.http.post<App>(this.appsUrl, app, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    addSecurityGroup(securityGroup: any): Observable<HttpResponse<Object>> {
        return this.http.post(this.securityGroupsUrl, securityGroup, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    editApp(app: App): Observable<HttpResponse<App>> {
        const url = this.appsUrl + app.id.toString();

        return this.http.put<App>(url, app, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    editSecurityGroup(securityGroup: any): Observable<HttpResponse<Object>> {
        return this.http.put(this.securityGroupsUrl, securityGroup, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    deleteSecurityGroup(id: number): Observable<any> {
        const url = this.securityGroupsUrl + id.toString();

        return this.http.delete(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    getAppData(id: number): Observable<HttpResponse<App>> {
        const url = this.appsUrl + id.toString();

        return this.http.get<App>(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }


    getSecurityGroupData(id: number): Observable<HttpResponse<Object>> {
        if (id === undefined) {
            id = 0;
        }
        const url = this.securityGroupsUrl + id.toString();

        return this.http.get<any>(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    deleteApp(id: number): Observable<HttpResponse<boolean>> {
        const url = this.appsUrl + id.toString();

        return this.http.delete<boolean>(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    GetSearchBarTabsDetails(adgroup: string): Observable<HttpResponse<any[]>> {
        const url = this.searchTabUrl + 'searchbartabs';
        const data = { userAdGroup: adgroup, splitter: ',' };

        return this.http.post<any>(url, data, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    getInlineTrainings(): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.inlineTrainingUrl, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    addInlineTraining(inlineTraining: any): Observable<HttpResponse<Object>> {
        return this.http.post(this.inlineTrainingUrl, inlineTraining, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    getInlineTraining(id: number): Observable<HttpResponse<TourStepData>> {
        const url = this.inlineTrainingUrl + id.toString();
        return this.http.get<any>(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    editInlineTraining(inlineTraining: any): Observable<HttpResponse<Object>> {
        return this.http.put(this.inlineTrainingUrl, inlineTraining, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    deleteInlineTraining(id: number): Observable<any> {
        const url = this.inlineTrainingUrl + id.toString();
        return this.http.delete(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }


    getInlineTrainingApps(): Observable<HttpResponse<any>> {
        const url = `${ this.inlineTrainingUrl }apps`;
        return this.http.get<any>(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    getInlineTrainingAppRoutes(app: string): Observable<HttpResponse<any>> {
        const url = `${ this.inlineTrainingUrl }apps/${ app }/routes`;
        return this.http.get<any>(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    deleteInlineTrainingViewedHistory(app: string, route: string): Observable<HttpResponse<any>> {

        route = route.replace(new RegExp('/', 'g'), '~');
        const url = `${ this.inlineTrainingUrl }apps/${ app }/routes/${ route }`;
        return this.http.delete<any>(url, { observe: 'response' }).pipe(
            retryWhen((errors) => this.handleRetry(errors))
        );
    }

    changeTab(currentTab: InteractiveReportsAdminTabs) {
        this.currentTabSource.next(currentTab);
    }

}
