import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OverviewValidators } from '../../overview-access/overview-validators';
import { AccessMapping } from '../../overview-access/models/access-mapping';
import { SrtOverviewAccess } from '../models/srt-overview-access';
import { LookupUser } from '../../../models/lookup-user';
import { OverviewAccessFormService } from '../../overview-access/services/overview-access-form.service';

@Injectable({
  providedIn: 'root'
})
export class SrtOverviewAccessFormServiceService extends OverviewAccessFormService<SrtOverviewAccess> {

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  createOverviewForm(): UntypedFormGroup {
    this.overviewAccessForm = this.fb.group({
      user: [new LookupUser('', '', ''), { validators: OverviewValidators.userLookupValidator() }],
      access: this.fb.group({
        teams: [[], Validators.required]
      })
    });

    return this.overviewAccessForm;
  }

  patchFormWithAccessMapping(form: UntypedFormGroup, accessMapping: AccessMapping<SrtOverviewAccess>): void {
    form.get('access').patchValue(accessMapping.access);
    form.get('user').patchValue(new LookupUser(accessMapping.user.displayName, accessMapping.user.email, accessMapping.user.id));
    form.get('user').disable();
  }
}
