import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subscription, Subject, of, Observable } from 'rxjs';
import { UserGroup } from '../../models/user-group';
import { UserType } from '../../models/user-type';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { UserDetailsWrapperComponent } from './user-details-wrapper/user-details-wrapper.component';
import { UserModalWrapperComponent } from './user-modal-wrapper/user-modal-wrapper.component';
import { UsersListService } from '../../services/users-list.service';
import { ServicePrincipalsListService } from '../../services/service-principals-list.service';

@Component({
  selector: 'wre-users-tab',
  templateUrl: './users-tab.component.html',
  styleUrls: ['./users-tab.component.sass']
})
export class UsersTabComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  heading = 'Users';

  selectedType = UserType.users;

  get _selectedType() { return this.selectedType.toString(); }
  get isNoSearchResult(): boolean { return this.searchTerm.length > 0 && this.usersListService.items.length === 0; }
  get loading$(): Observable<boolean> { return this.usersListService.loading$; }

  error: Error;
  public subject: Subject<string> = new Subject();
  isAdGroup = false;
  routerSubscription: Subscription;
  modalActivationSubscription: Subscription;

  items$ = of<UserGroup[]>([]);
  total$ = of<number>();

  searchTerm = '';

  offset = '0';

  showServicePrincipalsTable = false;

  @ViewChildren(RouterOutlet)
  private outlets!: QueryList<RouterOutlet>;

  constructor(
    private router: Router,
    private usersListService: UsersListService,
    private principalsListService: ServicePrincipalsListService
  ) { }

  async ngOnInit() {
    this.items$ = this.usersListService.items$;
    this.total$ = this.usersListService.total$;

    this.loadUsersData();
    this.setRouterSubscriptions();
  }

  private setRouterSubscriptions() {
    this.routerSubscription = this.router.events.subscribe(e => {
      if (e instanceof ActivationStart) {
        let activatedOutlet;
        if (e.snapshot.outlet === 'modal') {
          activatedOutlet = this.outlets.find(o => o.isActivated);
          if (activatedOutlet) { activatedOutlet.deactivate(); }
        }
        if (e.snapshot.outlet === 'sidebar') {
          activatedOutlet = this.outlets.find(o => o.isActivated);
          if (activatedOutlet && !(activatedOutlet.component as UserDetailsWrapperComponent).isOpen) {
            activatedOutlet.deactivate();
          }
        }
      }
    });
  }

  onSearchTermChange($event) {
    this.searchTerm = $event.target.value;
    this.usersListService.searchTerm = this.searchTerm;
  }

  // getUserData from server
  private async loadUsersData() {
    if (!this.showServicePrincipalsTable) {
      await this.usersListService.load();
    } else {
      await this.principalsListService.load();
    }
  }

  // change selected type as per user action
  toggle(selectedType) {
    this.searchTerm = '';
    if (this.selectedType !== selectedType) {
      this.selectedType = selectedType;
      this.heading = UserType[this.selectedType];
      this.showServicePrincipalsTable = UserType[this.selectedType] === UserType.servicePrinciple;
      this.isAdGroup = UserType[this.selectedType] === UserType.adGroup;
      if (!this.showServicePrincipalsTable) {
        this.usersListService.userType = this.selectedType;
        this.loadUsersData();
      }
    }
  }

  onActivate(componentReference) {
    this.modalActivationSubscription = (componentReference as UserModalWrapperComponent).isUpdatedChange.subscribe(
      () => {
        this.loadUsersData(); this.searchTerm = '';
      }
    );
  }

  ngOnDestroy() {
    if (!!this.subscription) {
      this.subscription.unsubscribe();
    }
    if (!!this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (!!this.modalActivationSubscription) {
      this.modalActivationSubscription.unsubscribe();
    }
  }

}
