import { Injectable } from '@angular/core';
import { OverviewSearchService } from '../../overview-access/services/overview-search.service';
import { SrtOverviewAccess } from '../models/srt-overview-access';
import { AccessMapping } from '../../overview-access/models/access-mapping';
import { SrtOverviewAccessApiService } from './srt-overview-access-api.service';

@Injectable({
  providedIn: 'root'
})
export class SrtOverviewAccessListService extends OverviewSearchService<SrtOverviewAccess> {

  constructor(private apiService: SrtOverviewAccessApiService) {
    super(apiService);
  }

  matchesSearchTerm(overviewAccess: AccessMapping<SrtOverviewAccess>): boolean {
    const searchTerm = this.searchTerm.toLocaleLowerCase();
    return overviewAccess.user.displayName.toLowerCase().includes(searchTerm)
      || overviewAccess.user.email.toLowerCase().includes(searchTerm)
      || overviewAccess.access.teams.some(t => t.toLowerCase().includes(searchTerm));
  }
}
