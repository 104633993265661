import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EditModalComponent } from './edit-modal/edit-modal.component';

@Component({
  selector: 'wre-edit-modal-wrapper',
  template: ``,
  styles: []
})
export class EditModalWrapperComponent implements OnInit {

  fieldId: string;

  constructor(private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.fieldId = params.get('id');
    });

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true,
      scrollable: true
    };

    const modalRef = this.modalService.open(
      EditModalComponent,
      modalOptions
    );

    if (this.fieldId) {
      modalRef.componentInstance.fieldId = this.fieldId;
    }

    const redirectUrl = 'admin/safr/field-management';

    modalRef.result.then(
      (resolved) => this.router.navigate([redirectUrl]),
      (rejected) => this.router.navigate([redirectUrl])
    );
  }
}
