import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReferralType } from '../enums/referral-type';
import { ReferralField } from '../models/referral-field';
import { ReferralFieldService } from './referrals-field.service';

@Injectable({
  providedIn: 'root'
})
export class ReferralsFieldManagementService {

  private _referralType$ = new BehaviorSubject<ReferralType>(ReferralType.Reinsurance);
  private _fields$ = new BehaviorSubject<ReferralField[]>([]);
  private _loading$ = new BehaviorSubject<boolean>(false);

  get field$() { return this._fields$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get currentReferralType() { return this._referralType$.getValue(); }

  constructor(private _referralFieldService: ReferralFieldService) { }

  loadFields(referralType: ReferralType) {
    this._loading$.next(true);
    this._referralFieldService.loadFields(referralType).subscribe(fields => {
      this._fields$.next(fields);
      this._loading$.next(false);
    });
  }
}
