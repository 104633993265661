import { Component, Input } from '@angular/core';

@Component({
  selector: 'wre-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.sass']
})
export class ErrorMessageComponent {
  @Input() loading = true;
  @Input() errorMessageInfo: string;
  @Input() errorMessage: any;

}
