<router-outlet name="modal"></router-outlet>

<div class="row">
  <div class="col-12">
    <ng-container *ngIf="loading; then loadingBlock else tableBlock"></ng-container>

    <ng-template #loadingBlock>
      <wre-toolkit-loader></wre-toolkit-loader>
    </ng-template>

    <ng-template #tableBlock>
      <ng-container *ngIf="error; then errorBlock else resultBlock"></ng-container>
      <ng-template #errorBlock>
        <div class="p-3">
          Failed to retrieve the overview access users.
        </div>
      </ng-template>

      <ng-template #resultBlock>

        <ng-container *ngIf="accessMappings.length === 0; then noDataResult else DataResultBlock">
        </ng-container>

        <ng-template #noDataResult>
          <div class="col-12 text-start">
            <div class="fw-bold">
              No data to display.
            </div>
          </div>
        </ng-template>

        <ng-template #DataResultBlock>

          <ng-container
            *ngIf="safrOverviewListService.isNoSearchOrFilterResult; then noSearchOrFilterResult else overviewAccessTable">
          </ng-container>

          <ng-template #noSearchOrFilterResult>
            <div class="no-results">
              No items were found based on your search criteria. Please try again.
            </div>
          </ng-template>

          <ng-template #overviewAccessTable>
            <div *ngIf="total > 0">
              <wre-safr-overview-access-table></wre-safr-overview-access-table>
              <div class="custom-pagination">
                <wre-toolkit-pagination
                    [total$]="total$"
                    [(pageSize)]="safrOverviewListService.pageSize"
                    [(page)]="safrOverviewListService.page"
                    [isLoading]="loading">
                </wre-toolkit-pagination>
              </div>
            </div>
          </ng-template>

        </ng-template>

      </ng-template>
    </ng-template>
  </div>
</div>
