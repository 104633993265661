import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { UserModalBaseComponent } from '../user-modal-base.component';
import { UserType } from '../../../../models/user-type';
import { ErrorMessages } from '../../../../models/role-summary-view';

@Component({
  selector: 'wre-service-principle-modal',
  templateUrl: './service-principle-modal.component.html',
  styleUrls: []
})
export class ServicePrincipleModalComponent extends UserModalBaseComponent {

  userForm: UntypedFormGroup;
  title = this.userId ? 'Edit Service Principle': 'Add Service Principle';
  userType = UserType.servicePrinciple;

  SetValidation() {
    const nameValidationPattern = '^[a-zA-Z0-9 _-]*$';
    const idValidationPattern =
      '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$';
    this.userForm = this.fb.group({
      lastName: new UntypedFormControl('', [Validators.required, Validators.pattern(nameValidationPattern)]),
      mail: new UntypedFormControl('', [Validators.required, Validators.pattern(idValidationPattern)]),
      type: new UntypedFormControl(this.userType, [])
    });
  }

  handleSubmissionError = (err) => {
    this.errorMessage = true;
    this.errorMessageInfo = err.status === 409 ? ErrorMessages.duplicateServicePrinciple : ErrorMessages.genericError;
  }

}
