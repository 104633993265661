import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { HttpService } from '../../../services/http.service';
import { DbUpdateResult } from '../models/db-update-result';
import { Observable } from 'rxjs';
import { retryWhen } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SanctionsAdminService extends HttpService {
  private sanctionsUrl = environment.sanctionsUrl;

  constructor(http: HttpClient) {
    super(http);
  }

  public uploadDatabaseFile(file: File): Observable<DbUpdateResult> {
    const url = `${ this.sanctionsUrl }sanctions-database`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<DbUpdateResult>(url, formData).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }
}
