import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalConfigGuard, MsalLoginGuard, PermissionGuard, RoleGuard } from 'wre-authlib';
import { PageNotFoundComponent, UnauthorizedComponent } from 'wre-core-lib';
import { ClientAdvocacyAdvocateListComponent } from './client-advocacy/client-advocacy-advocate-list/client-advocacy-advocate-list.component';
import { ClientAdvocacyHomeComponent } from './client-advocacy/client-advocacy-home/client-advocacy-home.component';
import { ClientAdvocacyComponent } from './client-advocacy/client-advocacy.component';
import { MaintenancepageComponent } from './components/holding-pages/maintenancepage/maintenancepage.component';
import { ShellComponent } from './components/shell/shell.component';
import { WfmDashboardComponent } from './embed/work-flow-manager/wfm-dashboard/wfm-dashboard.component';
import { WfmFrameComponent } from './embed/work-flow-manager/wfm-frame/wfm-frame.component';
import { QuickaccessComponent } from './home/quickaccess/quickaccess.component';
import { Roles, UserPermissions } from './models/admin';
import { NewsAggregatorComponent } from './news/news-aggregator/news-aggregator.component';
import { ReleaseNoteComponent } from './road-map/release-note/release-note.component';
import { RoadMapContentsComponent } from './road-map/road-map-contents/road-map-contents.component';
import { RoadMapComponent } from './road-map/road-map.component';

const routes: Routes = [
  {
    path: 'login',
    component: MsalRedirectComponent,
    canActivate: [MsalLoginGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./user-type-selection/user-type-selection.module').then(
        (m) => m.UserTypeSelectionModule,
      ),
  },
  { path: 'maintenance', component: MaintenancepageComponent },
  {
    path: '',
    component: ShellComponent,
    canActivate: [MsalConfigGuard],
    children: [
      {
        path: '',
        canActivate: [MsalGuard],
        children: [
          {
            path: 'unauthorizedaccess',
            component: UnauthorizedComponent,
            data: { hideRequestAccessButton: true },
          },
          {
            path: '',
            canActivate: [RoleGuard],
            data: {
              roles: [Roles.internalUser, Roles.externalUser],
              redirectUrl: 'unauthorizedaccess',
            },
            children: [
              {
                path: 'quickaccess',
                component: QuickaccessComponent,
              },
              {
                path: 'dashboard',
                loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
              },
              {
                path: 'callback',
                loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
              },
              {
                path: 'news-aggregator',
                component: NewsAggregatorComponent,
                canActivate: [PermissionGuard],
                data: {
                  permissions: [UserPermissions.GatewaySlipcaseView],
                  redirectUrl: 'unauthorizedaccess',
                },
              },

              {
                path: 'sales-workspace',
                redirectTo: 'sales-workspace/introduction',
                pathMatch: 'full',
              },
              {
                path: 'client-advocacy',
                redirectTo: 'sales-workspace/client-advocates',
                pathMatch: 'full',
              },

              {
                path: 'admin',
                loadChildren: () =>
                  import('./admin-portal/admin.module').then((m) => m.AdminModule),
                canActivate: [PermissionGuard],
                data: {
                  permissions: [
                    UserPermissions.GatewayExperienceGatewayView,
                    UserPermissions.GatewayExperienceReportingView,
                    UserPermissions.GatewayExperienceSecurityView,
                    UserPermissions.GatewayExperienceSafrView,
                    UserPermissions.GatewayExperienceSanctionsView,
                    UserPermissions.GatewayExperienceSrtView,
                  ],
                  redirectUrl: 'unauthorizedaccess',
                },
              },
              {
                path: 'placement-client-excellence-model',
                loadChildren: () =>
                  import('./placement-exellence-model/placement-exellence-model.module').then(
                    (m) => m.PlacementExellenceModelModule,
                  ),
                canActivate: [RoleGuard],
                data: {
                  roles: [Roles.internalUser],
                  redirectUrl: 'unauthorizedaccess',
                  // toolName: 'Placement CEM'
                },
              },

              { path: 'roadmaps', redirectTo: 'roadmaps/general', pathMatch: 'full' },
              {
                path: 'roadmaps',
                component: RoadMapComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [Roles.internalUser],
                  redirectUrl: 'unauthorizedaccess',
                },
                children: [
                  { path: 'releasenote', component: ReleaseNoteComponent },
                  { path: 'modelling', component: RoadMapContentsComponent },
                  { path: 'general', component: RoadMapContentsComponent },
                  { path: 'rqm', component: RoadMapContentsComponent },
                  { path: 'reporting', component: RoadMapContentsComponent },
                  { path: 'finance', component: RoadMapContentsComponent },
                  { path: 'sales', component: RoadMapContentsComponent },
                  { path: 'compliance', component: RoadMapContentsComponent },
                ],
              },
              {
                path: 'sales-workspace',
                component: ClientAdvocacyComponent,
                canActivate: [RoleGuard],
                data: {
                  permissions: [Roles.internalUser],
                  redirectUrl: 'unauthorizedaccess',
                  // toolName: 'Client Advocacy'
                },
                children: [
                  { path: 'introduction', component: ClientAdvocacyHomeComponent },
                  { path: 'client-advocates', component: ClientAdvocacyAdvocateListComponent },
                ],
              },
              {
                path: 'embed/wfm',
                component: WfmDashboardComponent,
                canActivate: [RoleGuard],
                data: {
                  permissions: [Roles.internalUser],
                  redirectUrl: 'unauthorizedaccess',
                },
                children: [{ path: ':wfmFrameId', component: WfmFrameComponent }],
              },
              { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
