import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { debounce, switchMap, tap } from 'rxjs/operators';
import { AccessMapping } from '../models/access-mapping';
import { OverviewAccessListState } from '../models/overview-access-list-state';
import { ApiService } from './api.service';

export class OverviewSearchResult<T> {
  overviewAccess: AccessMapping<T>[];
  total: number;
  loading: boolean;
  error: Error;

  constructor(
    overviewAccess: AccessMapping<T>[],
    total: number,
    loading: boolean,
    error: Error = null,
  ) {
    this.overviewAccess = overviewAccess;
    this.total = total;
    this.loading = loading;
    this.error = error;
  }
}

const DEFAULT_PAGE_SIZE = 10;

// @Injectable({
//   providedIn: 'root'
// })
export abstract class OverviewSearchService<T> {
  private _search$ = new Subject<boolean>();

  private allAccessMappings: AccessMapping<T>[] = [];
  private accessMappingsToDisplay: AccessMapping<T>[] = [];
  private total = 0;

  private _searchResult = new BehaviorSubject<OverviewSearchResult<T>>(
    new OverviewSearchResult<T>([], 0, true),
  );
  public searchResult$ = this._searchResult.asObservable();

  private _state: OverviewAccessListState = new OverviewAccessListState(DEFAULT_PAGE_SIZE);

  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get isSearchMode(): boolean {
    return this.searchTerm !== '';
  }

  set page(page: number) {
    this.set({ page });
  }
  set pageSize(pageSize: number) {
    this.set({ pageSize: +pageSize });
  }
  set searchTerm(searchTerm: string) {
    if (
      searchTerm.trim().length === 1 ||
      (searchTerm.trim().length === 0 && this.searchTerm.length === 0)
    ) {
      return;
    }
    this.set({ page: 1, searchTerm });
  }

  isNoSearchOrFilterResult = false;

  protected constructor(protected overviewService: ApiService<T>) {
    this.initialiseSearchSubscription();
    this.loadOverviewAccess();
  }

  public loadOverviewAccess() {
    this.announceLoading();
    this.overviewService.getOverviewAccess().subscribe((overviewAccess) => {
      this.allAccessMappings = overviewAccess.mappings;
      this._search$.next(true);
    });
  }

  private initialiseSearchSubscription() {
    this._search$
      .pipe(
        debounce((instant) => (instant ? of(undefined) : timer(400))),
        tap(() => this.announceLoading()),
        switchMap(() => this.search()),
      )
      .subscribe(
        (result) => {
          this.isNoSearchOrFilterResult =
            result.total === 0 && this.isSearchMode && this.allAccessMappings.length !== 0;

          this.accessMappingsToDisplay = result.overviewAccess;
          this.total = result.total;
          this.announceAccessMappings();
        },
        () => {
          this.announceError();
        },
      );
  }

  private search(): Observable<OverviewSearchResult<T>> {
    // 1. Search
    let result = this.allAccessMappings.filter((r) => this.matchesSearchTerm(r));

    // 2. Sort
    result.sort((a: AccessMapping<T>, b: AccessMapping<T>) =>
      a.user.displayName.localeCompare(b.user.displayName),
    );

    // 3. Paginate
    const total = result.length;
    result = result.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize,
    );
    return of({ overviewAccess: result, total: total, loading: false, error: null });
  }

  private set(patch: Partial<OverviewAccessListState>) {
    Object.assign(this._state, patch);
    this._search$.next(false);
  }

  private announceLoading(): void {
    this._searchResult.next(
      new OverviewSearchResult<T>(this.accessMappingsToDisplay, this.total, true),
    );
  }

  private announceAccessMappings(): void {
    this._searchResult.next(
      new OverviewSearchResult<T>(this.accessMappingsToDisplay, this.total, false),
    );
  }

  private announceError(): void {
    this._searchResult.next(new OverviewSearchResult<T>([], 0, false, new Error('Error occurred')));
  }

  abstract matchesSearchTerm(overviewAccess: AccessMapping<T>): boolean;
}
