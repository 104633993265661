import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { confirmationMessages, confirmationTexts, confirmationTitles } from 'src/app/shared/enums/confirmation';
import { ConfirmationService } from '../../../../../shared/services/confirmation.service';
import { FieldOrderType } from '../../../models/field-order-type';
import { Option, OptionsRequest, ReferralField } from '../../../models/referral-field';
import { ReferralFieldService } from '../../../services/referrals-field.service';
import { ReferralsFieldManagementService } from '../../../services/referrals-field-management.service';
import { FieldNames } from '../../../enums/field-names';
import { LookupUser } from '../../../../../models/lookup-user';
import { User } from '../../../../overview-access/models/user';
import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { UserTypeFlag } from 'wre-authlib';

const DragConfig = {
  zIndex: 1100
};

@Component({
  selector: 'wre-edit-field-modal',
  templateUrl: './edit-field-modal.component.html',
  styleUrls: ['./edit-field-modal.component.sass'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }]
})
export class EditFieldModalComponent implements OnInit, OnDestroy {

  title = 'Edit Query Recipient(s)';
  optionControl: UntypedFormControl;
  fieldId: string;
  field: ReferralField;

  existingOptions: Option[] = [];
  allOptions: Option[] = [];

  subscription: Subscription;
  loading: boolean;

  UserTypeFlag = UserTypeFlag;

  get isDisabled() {
    return !this.field || this.allOptions.length === 0 || !this.isOptionsChanged();
  }

  get isOrderEnabled() {
    return !!this.field && this.field.order === FieldOrderType.Manual;
  }

  get isReviewer() {
    return !!this.field && this.field.name === FieldNames.Reviewer;
  }

  constructor(
    private _activeModal: NgbActiveModal,
    private _referralFieldService: ReferralFieldService,
    private _referralsFieldManagementService: ReferralsFieldManagementService,
    private _confirmationService: ConfirmationService,
    private _fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.loading = true;
    this.optionControl = this._fb.control('');

    this._referralFieldService.getField(this.fieldId).subscribe(field => {
      this.field = field;

      this.sortOptions(this.field.options);
      this.existingOptions = this.field.options.filter(o => o.active === true).map(o => new Option(o));
      this.allOptions = JSON.parse(JSON.stringify(this.existingOptions)).map(o => new Option(o)); // deep copy of existing options

      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  addOption() {
    const optionValue = this.optionControl.value;

    // check if option with this value already exists
    if (this.allOptions.findIndex(o => o.value.toLowerCase() === optionValue.toLowerCase()) < 0) {
      const option = new Option({
        value: optionValue,
        order: this.field.order === FieldOrderType.Alphabetical ? 0 : this.allOptions.length
      });

      this.allOptions.push(option);
      this.sortOptions(this.allOptions);
    }

    this.optionControl.reset();
  }

  addReviewerOption(lookupUser: LookupUser): void {
    const user = User.fromLookupUser(lookupUser);

    if (this.allOptions.findIndex(o => o.user.equal(user)) < 0) {
      const option = new Option({
        user: user,
        order: this.field.order === FieldOrderType.Alphabetical ? 0 : this.allOptions.length
      });

      this.allOptions.push(option);
      this.sortOptions(this.allOptions);
    }

    // rest() or setting null value doesn't clear out the input field
    this.optionControl.setValue(' ');
  }

  saveChanges() {
    this.loading = true;
    const optionRequest = new OptionsRequest(this.allOptions);

    this._referralFieldService.updateField(this.fieldId, optionRequest).subscribe(response => {
      if (response) {
        this._referralsFieldManagementService.loadFields(this._referralsFieldManagementService.currentReferralType);
        this._activeModal.close();
        this.loading = false;
      }
    });
  }

  confirmClose() {
    if (this.isDisabled) {
      this._activeModal.close();
      return;
    }

    this.subscription = this._confirmationService.openModal(
      confirmationTitles.defaultTitle, confirmationMessages.defaultMessage, confirmationTexts.defaultConfirmationText)
      .subscribe((close: boolean) => {
        if (close) {
          this._confirmationService.closeModal();
          this._activeModal.close();
        }
    });
  }

  private isOptionsChanged(): boolean {
    const optionsEqual = this.existingOptions.length === this.allOptions.length &&
      this.existingOptions?.every(o => this.allOptions.some(op => o.equal(op)));

    return !optionsEqual;
  }

  private sortOptions(options: Option[]): void {
    if (this.field.order === FieldOrderType.Alphabetical) {
      options.sort((a, b) =>
        (this.isReviewer ? a.user.displayName.localeCompare(b.user.displayName) : a.value.localeCompare(b.value)));
    } else {
      options.sort((a, b) => a.order - b.order);
    }
  }
}
