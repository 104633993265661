import { ConfirmationService } from './../../../shared/services/confirmation.service';
import { confirmationMessages, confirmationTexts, confirmationTitles } from './../../../shared/enums/confirmation';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppInsightsService } from 'wre-core-lib';
import { AreaData } from 'src/app/models/admin';
import { NgForm } from '@angular/forms';
import { ReportingService } from '../services/reporting.service';

@Component({
  selector: 'wre-areas-create-update',
  templateUrl: './areas-create-update.component.html',
  styleUrls: ['./areas-create-update.component.scss']
})
export class AreasCreateUpdateComponent implements OnInit, OnDestroy {

  title: string;
  errorMessage: any;
  isloaderVisible = false;
  noContentMessage: string;
  isNoContentMessageVisible = false;
  areaId: number;
  buttonAction: string;
  newArea: AreaData;
  oldArea: AreaData; // to check if value of area has changed
  subscription: Subscription;
  modalRef: NgbModalRef;

  areaName: string = '';

  @Output() setTitle: EventEmitter<any> = new EventEmitter();
  @Output() refreshAreasData: EventEmitter<boolean> = new EventEmitter();


  constructor(
    public activeModal: NgbActiveModal,
    private appInsightsService: AppInsightsService,
    private _confirmationService: ConfirmationService,
    private reportingService: ReportingService) {
    this.appInsightsService.componentName = 'AreasCreateUpdateComponent';
  }

  ngOnInit() {
    this.setTitle.emit('Gallagher Re Gateway');
    if (this.areaId !== undefined) {
      this.title = 'Update Category';
      this.buttonAction = 'Update';
      this.getAreasById();
    } else {
      this.buttonAction = 'Add';
      this.title = 'New Category';
    }
    this.newArea = new AreaData();
    this.newArea.isActive = true;
  }


  onAdd(newAreaForm: NgForm) {
    this.resetErrors();
    if (newAreaForm.invalid) {
      this.noContentMessage = '';
      this.isNoContentMessageVisible = false;
      return;
    }
    this.newArea = newAreaForm.value;
    if (this.buttonAction === 'Add') {
      this.addArea();
    } else {
      this.newArea.id = this.areaId;
      this.editArea();
    }
  }

  async getAreasById() {
    this.isloaderVisible = true;
    this.appInsightsService.methodName = 'getAreasById';
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );

      (await this.reportingService.getPowerBiAreasById(this.areaId)).subscribe(area => {
        this.newArea = area;
        this.oldArea = { ...area };
        this.isloaderVisible = false;
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  async addArea() {
    this.isloaderVisible = true;
    this.isNoContentMessageVisible = false;
    this.refreshAreasData.emit(false);
    this.appInsightsService.methodName = 'addArea';
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.newArea.name = this.newArea.name.trim();
      (await this.reportingService.addPowerBiAreas(this.newArea)).subscribe(result => {

        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
        this.closeModal();
        this.refreshAreasData.emit(true);
        this.isloaderVisible = false;
      }, error => {
        if (error.status === 409) {
          this.isNoContentMessageVisible = true;
          this.areaName = { ...this.newArea }.name;
          this.noContentMessage = 'A Category with the name ' + this.areaName + ' already exists in the system.';
          console.error(this.noContentMessage);
        } else {
          this.appInsightsService.trackTrace(this.appInsightsService.msgMethodError);
        }
        this.isloaderVisible = false;
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }


  async editArea() {
    this.isloaderVisible = true;
    this.isNoContentMessageVisible = false;
    this.refreshAreasData.emit(false);
    this.appInsightsService.methodName = 'editApps';
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );
      this.newArea.name = this.newArea.name.trim();

      (await this.reportingService.editPowerBiAreas(this.newArea)).subscribe(result => {
        this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
        this.closeModal();
        this.refreshAreasData.emit(true);
        this.isloaderVisible = false;
      }, error => {
        if (error.status === 409) {
          this.isNoContentMessageVisible = true;
          this.areaName = { ...this.newArea }.name;
          this.noContentMessage = 'A Category with the name ' + this.areaName + ' already exists in the system.';
        } else {
          this.appInsightsService.trackTrace(this.appInsightsService.msgMethodError);
        }
        this.isloaderVisible = false;
      });
    } catch (error) {
      this.isloaderVisible = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

  resetErrors() { this.errorMessage = null; this.isNoContentMessageVisible = false; this.noContentMessage = null }

  confirmClose() {
    if ((!this.areaId && !this.newArea.name) || (this.areaId && this.newArea.name.trim() === this.oldArea.name.trim() && this.newArea.isActive === this.oldArea.isActive)) {
      this.closeModal();
      return;
    }

    this.subscription = this._confirmationService.openModal(confirmationTitles.defaultTitle, confirmationMessages.defaultMessage, confirmationTexts.defaultConfirmationText).subscribe((close: boolean) => {
      if (close) {
        this._confirmationService.closeModal();
        this.closeModal();
      }
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy() { this.subscription?.unsubscribe(); }

}
