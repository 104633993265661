import { Component } from '@angular/core';
import { PreLoginComponent } from 'wre-authlib';

@Component({
  selector: 'wre-user-type-selection',
  templateUrl: './user-type-selection.component.html',
  styleUrls: ['./user-type-selection.component.sass']
})
export class UserTypeSelectionComponent extends PreLoginComponent {
}
