import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ReportType } from '../../../models/report-type.enum';
import { throwError } from 'rxjs';
import { AppInsightsService } from 'wre-core-lib';
import { AreaData, Report } from '../../../../../models/admin';
import { ReportingService } from '../../../services/reporting.service';

@Component({
  selector: 'wre-report-wizard-details',
  templateUrl: './report-wizard-details.component.html',
  styleUrls: ['./report-wizard-details.component.sass']
})
export class ReportWizardDetailsComponent implements OnInit {

  @Input() newReportForm: UntypedFormGroup = null;
  @Input() existingReport: Report;

  areas: AreaData[];
  ReportType = ReportType;

  areasLoading = false;

  get nextStepButtonEnabled(): boolean {
    return this.newReportForm
      && this.newReportForm.get('name').valid
      && (this.newReportForm.get('type').valid || this.newReportForm.get('type').disabled)
      && this.newReportForm.get('areaId').valid
      && this.newReportForm.get('summary').valid
      && this.newReportForm.get('isActive').valid;
  }

  constructor(private appInsightsService: AppInsightsService,
              private reportingService: ReportingService) { }

  async ngOnInit() {
    await this.getAreas();
  }

  async getAreas() {
    this.areasLoading = true;
    this.newReportForm.get('areaId').disable();
    this.appInsightsService.methodName = 'getApps';
    try {
      this.appInsightsService.trackTrace(
        this.appInsightsService.msgMethodStart
      );

      this.areas = (await this.reportingService.getPowerBiAreas())
        .sort((a, b) => a.name.localeCompare(b.name));
      this.areasLoading = false;
      this.newReportForm.get('areaId').enable();
      this.appInsightsService.trackTrace(this.appInsightsService.msgMethodsuccess);
    } catch (error) {
      this.areasLoading = false;
      this.appInsightsService.trackException(error);
      throwError(error);
    }
  }

}
