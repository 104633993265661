import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.sass']
})
export class AdminDeleteComponent implements OnInit {

  entityId: number;
  entityName: string;
  deleteEntityMessage_h5: string;
  deleteEntityMessage_p: string;
  isConfirmModal = true;
  buttonAction = 'Cancel';
  deleteConfirmed = false;
  punctuationMark = '?';
  title = 'Delete';

  isLoading = false;
  closeOnConfirm = true;

  @Output() deleteConfirmedChange: EventEmitter<boolean> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {

  }

  ngOnInit() {
    if (this.isConfirmModal !== true) {
      this.setToInfoMode();
    }
  }

  deleteEntityConfirm() {
    this.deleteConfirmed = true;
    this.deleteConfirmedChange.emit(this.deleteConfirmed);
    if (this.closeOnConfirm) { this.activeModal.close(); }
  }


  closeModal() {
    this.activeModal.close();
  }

  setToInfoMode() {
    this.buttonAction = 'OK';
    this.punctuationMark = '';
  }
}
