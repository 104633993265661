<div class="wre-table-container w-100">
    <div #tableContainer class="table-responsive" (scroll)="offset = tableContainer.scrollLeft + 'px'">
        <table class="table wre-table fixed-column table-hover p-0 m-0">
            <thead class="text-center p-0 m-0" [style.left]="'-' + offset">
                <tr class="t-header">
                    <th class="text-nowrap col-ellipsis column-selected" *ngIf="isAddedorRemoved"></th>
                    <th class="text-nowrap col-ellipsis column-name">Name</th>
                    <th class="text-nowrap col-ellipsis column-type">Type</th>
                </tr>
            </thead>

            <tbody class="p-0 m-0">
                <tr *ngFor="let permission of list ; last as isLast" class="tr-padding"
                    [ngClass]="{'border-bottom': isLast}">
                    <td class="text-nowrap col-ellipsis column-selected text-center" *ngIf="isAddedorRemoved">
                        <i class="far"
                            [ngClass]=" {'fa-plus': permission.isAdded, 'fa-minus': permission.isRemoved}"></i>

                    </td>
                    <td class="text-nowrap col-ellipsis column-name">
                        {{ permission.name }}
                    </td>
                    <td class="text-nowrap col-ellipsis column-type">
                        <span class="badge" [ngClass]="{
      'bg-warning': permission.type === PermissionType.Security,
      'bg-info': permission.type !== PermissionType.Security}">
                            <i class="me-1 far" [ngClass]="{
        'fa-lock': permission.type === PermissionType.Security,
        'fa-mouse-pointer': permission.type !== PermissionType.Security}"></i>
                            {{ permission.type === PermissionType.Security ? 'Security' : 'App' }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
