import { Component, Input } from '@angular/core';
import { AuditData, RoleAudit } from '../../../../../models/role-audit';
import moment, { Moment } from 'moment';
import { SecurityManagementService } from '../../../../../services/security-management.service';
import { SecurityWorkspaceService } from '../../../../../services/security-workspace.service';

@Component({
  selector: 'wre-role-history',
  templateUrl: './role-history.component.html',
  styleUrls: ['./role-history.component.sass']
})
export class RoleHistoryComponent {

  private _roleId: number;
  get roleId(): number {
    return this._roleId;
  }
  @Input() set roleId(value: number) {
    this._roleId = value;
    if (value != null) {
      this.getRoleAuditData();
    }
    this.assignDateValues();
  }

  roleAudit: RoleAudit;

  today: AuditData[] = [];
  yesterday: AuditData[] = [];
  thisWeek: AuditData[] = [];
  lastWeek: AuditData[] = [];
  twoWeeksAgo: AuditData[] = [];
  threeWeeksAgo: AuditData[] = [];
  lastMonth: AuditData[] = [];
  older: AuditData[] = [];

  todaysDate: Moment;
  yesterdaysDate: Moment;
  startDateOfWeek: Moment;
  endDateOfWeek: Moment;
  startDateOfLastWeek: Moment;
  endDateOfLastWeek: Moment;
  startDateOfTwoWeekAgo: Moment;
  endDateOfTwoWeekAgo: Moment;
  startDateOfThreeWeekAgo: Moment;
  endDateOfThreeWeekAgo: Moment;
  startDateOfLastMonth: Moment;
  endDateOfLastMonth: Moment;

  historyData = [
    {
      title: 'Today',
      displayName: 'Today',
      value: this.today
    },
    {
      title: 'yesterday',
      displayName: 'Yesterday',
      value: this.yesterday
    },
    {
      title: 'thisWeek',
      displayName: 'This Week',
      value: this.thisWeek
    },
    {
      title: 'lastWeek',
      displayName: 'Last Week',
      value: this.lastWeek
    },
    {
      title: 'twoWeeksAgo',
      displayName: 'Two Weeks Ago',
      value: this.twoWeeksAgo
    },
    {
      title: 'threeWeeksAgo',
      displayName: 'Three Weeks Ago',
      value: this.threeWeeksAgo
    },
    {
      title: 'lastMonth',
      displayName: 'Last Month',
      value: this.lastMonth
    },
    {
      title: 'older',
      displayName: 'Older',
      value: this.older
    }
  ];

  sortToggle = false;
  loading = true;
  errorMessage: boolean;
  errorMessageInfo: string;
  error: any;

  constructor(private securityService: SecurityManagementService, private securityWorkspaceService: SecurityWorkspaceService) { }

  getRoleAuditData() {
    this.loading = true;
    this.securityService.getRoleAudit(this.roleId, this.securityWorkspaceService.currentWorkspace).then((res) => {
      if (res.status === 200) {
        this.roleAudit = res.body;
        this.setHistoryInfo();
        this.loading = false;
      } else {
        this.errorMessageInfo = 'An error has occured. Contact Administrator';
        this.errorMessage = true;
        this.loading = false;
      }
    }).catch(error => {
      this.error = error;
      console.error(error);
      this.errorMessageInfo = 'An error has occured. Contact Administrator';
      this.errorMessage = true;
      this.loading = false;
    });
  }

  setHistoryInfo(): void {
    this.sort(this.roleAudit.auditData);
    this.groupTimeStamp(this.roleAudit.auditData);
  }

  private assignDateValues() {
    this.todaysDate = moment(moment().format('YYYY-MM-DDT00:00:00.000'));
    this.yesterdaysDate = moment(moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000'));
    this.startDateOfWeek = moment(moment().startOf('week').format('YYYY-MM-DDT00:00:00.000'));
    this.endDateOfWeek = moment(moment().endOf('week').format('YYYY-MM-DDT00:00:00.000'));
    this.startDateOfLastWeek = moment(moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DDT00:00:00.000'));
    this.endDateOfLastWeek = moment(moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DDT00:00:00.000'));
    this.startDateOfTwoWeekAgo = moment(moment().subtract(2, 'week').startOf('week').format('YYYY-MM-DDT00:00:00.000'));
    this.endDateOfTwoWeekAgo = moment(moment().subtract(2, 'week').endOf('week').format('YYYY-MM-DDT00:00:00.000'));
    this.startDateOfThreeWeekAgo = moment(moment().subtract(3, 'week').startOf('week').format('YYYY-MM-DDT00:00:00.000'));
    this.endDateOfThreeWeekAgo = moment(moment().subtract(3, 'week').endOf('week').format('YYYY-MM-DDT00:00:00.000'));
    this.startDateOfLastMonth = moment(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DDT00:00:00.000'));
    this.endDateOfLastMonth = moment(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DDT00:00:00.000'));
  }

  private sort(updateInfo: AuditData[]) {
    updateInfo.sort((a, b) => {
      return a.date > b.date ? -1 : 1;
    });
  }

  private groupTimeStamp(updateInfo: AuditData[]) {
    updateInfo.forEach(item => {
      const updatedDateTimeStamp = moment(moment(item.date).format('YYYY-MM-DDT00:00:00.000'));

      if (updatedDateTimeStamp.isSame(this.todaysDate)) {
        this.today.push(item);
      } else if (updatedDateTimeStamp.isSame(this.yesterdaysDate)) {
        this.yesterday.push(item);
      } else if (updatedDateTimeStamp >= this.startDateOfWeek && updatedDateTimeStamp <= this.endDateOfWeek) {
        this.thisWeek.push(item);
      } else if (updatedDateTimeStamp >= this.startDateOfLastWeek && updatedDateTimeStamp <= this.endDateOfLastWeek) {
        this.lastWeek.push(item);
      } else if (updatedDateTimeStamp >= this.startDateOfTwoWeekAgo && updatedDateTimeStamp <= this.endDateOfTwoWeekAgo) {
        this.twoWeeksAgo.push(item);
      } else if (updatedDateTimeStamp >= this.startDateOfThreeWeekAgo && updatedDateTimeStamp <= this.endDateOfThreeWeekAgo) {
        this.threeWeeksAgo.push(item);
      } else if (updatedDateTimeStamp >= this.startDateOfLastMonth && updatedDateTimeStamp <= this.endDateOfLastMonth) {
        this.lastMonth.push(item);
      } else {
        this.older.push(item);
      }
    });
  }

  reverseHistoryData() {
    this.historyData.reverse(); // Reverses accordian
    this.today.reverse();
    this.yesterday.reverse();
    this.thisWeek.reverse();
    this.lastWeek.reverse();
    this.twoWeeksAgo.reverse();
    this.threeWeeksAgo.reverse();
    this.lastMonth.reverse();
    this.older.reverse();
    this.sortToggle = !this.sortToggle;
  }

}
