import { Component, Input } from '@angular/core';
import { PermissionGroup } from '../../../../../models/permission-group';
import { UserAudit } from '../../../../../models/role-audit';
import { PermissionType } from '../../../../../models/permission-type';
import { UserType } from '../../../../../models/user-type';

@Component({
  selector: 'wre-role-history-data-table',
  templateUrl: './role-history-data-table.component.html',
  styleUrls: ['./role-history-data-table.component.sass']
})
export class RoleHistoryDataTableComponent {

  @Input() isUser = true;
  @Input() listData;

  get isAddedorRemoved(): boolean {
    return this.listData[0].isAdded || this.listData[0].isRemoved;
  }

  get list() {
    if (this.isUser) {
      let usersList: UserAudit[];
      usersList = this.listData;

      if (this.isAddedorRemoved) {
        usersList.sort((a, b) => {
          const atype = (a.type === UserType.externalUser || a.type === UserType.internalUser) ?
            UserType.users.toString() : a.type === UserType.servicePrinciple ? 'z'+UserType.servicePrinciple : UserType.adGroup;
          const btype = (b.type === UserType.externalUser || b.type === UserType.internalUser) ?
          UserType.users.toString() : b.type === UserType.servicePrinciple ? 'z'+UserType.servicePrinciple : UserType.adGroup;
          if (a.isAdded !== b.isAdded) {
            return a.isAdded ? -1 : 1;
          } else if (a.isRemoved !== b.isRemoved) {
            return a.isRemoved ? -1 : 1;
          } else if (atype !== btype) {
            return atype > btype ? 1 : -1;
          } else {
            return (a.displayName as string).toLowerCase() > (b.displayName as string).toLowerCase() ? 1 : -1;
          }
        });
      } else {
        usersList.sort((a, b) => {
          const atype = (a.type === UserType.externalUser || a.type === UserType.internalUser) ?
            UserType.users.toString() : a.type === UserType.servicePrinciple ? 'z'+UserType.servicePrinciple : UserType.adGroup;
          const btype = (b.type === UserType.externalUser || b.type === UserType.internalUser) ?
            UserType.users.toString() : b.type === UserType.servicePrinciple ? 'z'+UserType.servicePrinciple : UserType.adGroup;
          if (atype !== btype) {
            return atype > btype ? 1 : -1;
          } else {
            return (a.displayName as string).toLowerCase() > (b.displayName as string).toLowerCase() ? 1 : -1;
          }
        });
      }
      return usersList;
    } else {
      let permissionsList: PermissionGroup[];
      permissionsList = this.listData;

      if (this.isAddedorRemoved) {
        permissionsList.sort((a, b) => {
          if (a.isAdded !== b.isAdded) {
            return a.isAdded ? -1 : 1;
          } else if (a.isRemoved !== b.isRemoved) {
            return a.isRemoved ? -1 : 1;
          } else if (a.type !== b.type) {
            return b.type > a.type ? 1 : -1;
          } else {
            return a.name > b.name ? 1 : -1;
          }
        });
      } else {
        permissionsList.sort((a, b) => b.type.localeCompare(a.type) || a.name.localeCompare(b.name));
      }

      return permissionsList;
    }
  }

}
