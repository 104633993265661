<div class="row justify-content-center mt-3">
    <div class="col-md-12 col-lg-8">
        <div class="">
            <div class="card client-advocacy-container">
                <div class="card-header btn-toolbar" role="toolbar" aria-label="Toolbar Actions">
                    <h6 class="card-title">Reinforcing Client Advocacy</h6>
                </div>
                <div class="card-body">
                    <p>
                        Since formalising our Global Client Advocacy framework and with over 200 Gallagher Re colleagues now graduated from the Client Advocacy Development Program, we are confident that the true role of client advocacy and the main drivers of client value are broadly
                        understood, which we are able to build on in a number of ways:
                    </p>
                    <ol>
                        <li>
                            <p>There will now be full adoption of the Client Advocacy model and framework that has been developed and taught through the CADP. This is best practice client management and provides the soundest foundation for sales to both
                                existing and new clients. While our value proposition will be unique for each client and prospect, the underlying client management and core sales processes we have developed enables a repeatable framework for superior
                                client experience on a robust and coherent basis. A clear way of doing business also enables our newer colleagues to learn common best practices and standards quicker, facilitates transfer of knowledge and skills and, most
                                importantly, focuses us on what really matters – the client.
                            </p>
                            <p>
                                <strong>Accordingly there are three core client management tools that many people have been using which will now be mandatory for all client plans: </strong><a href="{{clientAdvocacyLink}}" target="_blank">(1) A Value Grid, (2) Engagement Ladder(s), (3) A Playbook</a>.
                                All Client Advocates are responsible for overseeing the implementation of these for each client although it is expected that everyone on the team will feed into the process to create and update them.
                            </p>
                            <p>
                                The Value Grid, Ladder and Playbook are dynamic planning tools which need to be assessed and updated continuously as a natural part of client management, and they are best practice reminders of how to do this consistently. They are taught within the CADP
                                and at an introductory level within the REACH Program (in London currently but to be rolled out more broadly for all colleagues). <strong>The adoption of these tools into client plans takes immediate effect and will
                                    be implemented likely with small variants within each BU and for each client, but always consistent with the principles.</strong>
                            </p>
                        </li>
                        <li>
                            <p><strong>Client Advocates will be fully responsible for revenue recognition and estimation on each client contract.</strong> Volatility in our revenue - both upwards and downwards - is a fact of life as estimates crystalize
                                into actual, but we amplify that volatility when we are slow to react to reality. We need to get as close to real-time estimates as possible. Notwithstanding huge efforts by our operations team to moderate this volatility
                                and we have made improvements, this has been an ongoing issue and particularly on proportional treaties. We often end up having to re-budget, sometimes several quarters after the issue was observable. As well as managing
                                and understanding the underlying price and volume trends, we also need to improve the precision with which we measure risk adjusted pricing trends of reinsurance covers.
                            </p>
                            <p>As the single point of coordination for all client activities regardless of BU, we are sure that Client Advocates are best positioned to own responsibility for these inputs into CRM. We also acknowledge that this process needs
                                to be aligned with finance, operations and other reporting environments. Chris Brook and each BU finance and Ops teams are working on the best processes to support Client Advocates in this endeavor, but we want to flag
                                the ultimate ownership responsibility now. We anticipate processes being in place for this to be fully executed in 2020.</p>
                        </li>
                        <li>
                            <p>
                                <strong>Client Advocates and Advocate Partners have been re/confirmed in line with the key tenets of and best practice for Client Advocacy. In addition and where applicable, a BU Client Advocate has also been identified. </strong>                                This does not change the role of the WRe Client Advocate who will remain responsible for the overall client relationship. Introducing BU Advocates will streamline internal client communication between regions and ensure
                                that all relevant BUs are included in planning client strategy to maximise production opportunities. As operating structures vary between clients it is the responsibility of the Client Advocate to agree with the BU Client
                                Advocate the most effective client communication strategy.
                            </p>
                            <p><strong>All Advocate Partners, Client Advocates and BU Advocates are <a href="javascript:void(0)" aria-haspopup="true" aria-expanded="false" (click)="openClientAdvocateList()">listed here</a>.</strong></p>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-lg-4 mt-md-3 mt-lg-0">
        <div class="">
            <div class="card shadow-sm bg-white advocate-list-table-container">
                <div class="card-header" role="toolbar" aria-label="Toolbar Actions">
                    <h6 class="card-title">Related Links</h6>
                </div>
                <div class="card-body related-links-container">
                    <ul>
                        <li><a id="advocateListLink" href="javascript:void(0)" aria-haspopup="true" aria-expanded="false" (click)="openClientAdvocateList()">
                            Client Advocate List
                            </a>
                        </li>
                        <li><a [href]="clientAdvocacyLink" target="_blank">Value Grid, Engagement Ladder, Playbook Templates</a></li>
                        <li><a [href]="salesReportsLink">Sales Reports</a></li>
                        <li><a href="https://gallagherre.lightning.force.com/" target="_blank">WIN System</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>