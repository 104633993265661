import { SafrOverviewModalWrapperComponent } from './safr-admin/safr-overview-wrapper/safr-overview-modal-wrapper/safr-overview-modal-wrapper.component';
import { SafrOverviewWrapperComponent } from './safr-admin/safr-overview-wrapper/safr-overview-wrapper.component';
import { SafrAdminModule } from './safr-admin/safr-admin.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin/admin.component';
import { AdminDeleteComponent } from './admin/delete/delete.component';
import { WreToolkitLibModule } from 'wre-toolkit-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AdminAppsCategoryCreateUpdateComponent } from './gateway-admin/apps-category-create-update/apps-category-create-update.component';
import { AdminAppsCreateUpdateComponent } from './gateway-admin/apps-create-update/apps-create-update.component';
import { AdminSecurityGroupsComponent } from './gateway-admin/security-groups/security-groups.component';
// tslint:disable-next-line: max-line-length
import { AdminSecurityGroupsCreateUpdateComponent } from './gateway-admin/security-groups-create-update/security-groups-create-update.component';
import { TourStepsCreateUpdateComponent } from './gateway-admin/tour-steps-create-update/tour-steps-create-update.component';
import { TourStepClearHistoryComponent } from './gateway-admin/tour-step-clear-history/tour-step-clear-history.component';
import { TourStepsComponent } from './gateway-admin/tour-steps/tour-steps.component';
import { AdminAppsCategoryComponent } from './gateway-admin/apps-category/apps-category.component';
import { AdminAppsComponent } from './gateway-admin/apps/apps.component';
import { AreasComponent } from './reporting-admin/areas/areas.component';
import { ReportsComponent } from './reporting-admin/reports/reports.component';
import { PermissionGuard } from 'wre-authlib';
import { UserPermissions } from '../models/admin';
import { SecurityAdminModule } from './security-admin/security-admin.module';
import { UsersTabComponent } from './security-admin/components/users-tab/users-tab.component';
import { AccessTabWrapperComponent } from './security-admin/components/access-wr/access-tab-wrapper.component';
import { AdminCardComponent } from './admin/admin-card/admin-card.component';
import { SecurityAdminWrapperComponent } from './security-admin/components/security-admin-wrapper/security-admin-wrapper.component';
// tslint:disable-next-line:max-line-length
import { AccessTabWorkspaceWrapperComponent } from './security-admin/components/access-wr/workspace-wr/access-tab-workspace-wrapper.component';
// tslint:disable-next-line:max-line-length
import { AccessRolesComponent } from './security-admin/components/access-wr/workspace-wr/access-tab/access-roles/access-roles.component';
// tslint:disable-next-line:max-line-length
import { AccessPermissionsComponent } from './security-admin/components/access-wr/workspace-wr/access-tab/access-permissions/access-permissions.component';
import { RoleModalWrapperComponent } from './security-admin/role-modal-wrapper/role-modal-wrapper.component';
import { RoleModalComponent } from './security-admin/role-modal/role-modal.component';
import { UserDetailsWrapperComponent } from './security-admin/components/users-tab/user-details-wrapper/user-details-wrapper.component';
import { RolesSidebarWrapperComponent } from './security-admin/components/access-wr/workspace-wr/roles-sidebar-wr/roles-sidebar-wrapper.component';
import { UserModalWrapperComponent } from './security-admin/components/users-tab/user-modal-wrapper/user-modal-wrapper.component';
import { UserModalComponent } from './security-admin/components/users-tab/user-modal-wrapper/user-modal/user-modal.component';
import { InfoModalComponent } from './admin/info-modal/info-modal.component';
import { ReportingAdminModule } from './reporting-admin/reporting-admin.module';
import { AdminSharedModule } from './admin-shared/admin-shared.module';
import { SharedModule } from '../shared/shared.module';
import { FieldsWrapperComponent } from './safr-admin/fields-wrapper/fields-wrapper.component';
import { EditModalWrapperComponent } from './safr-admin/fields-wrapper/edit-modal-wrapper/edit-modal-wrapper.component';
import { WreCoreLibModule } from 'wre-core-lib';
import { ServicePrincipleModalComponent } from './security-admin/components/users-tab/user-modal-wrapper/service-principle-modal/service-principle-modal.component';
import { SanctionsDatabaseComponent } from './sanctions-admin/sanctions-database/sanctions-database.component';
import { SanctionsAdminModule } from './sanctions-admin/sanctions-admin.module';
import { ReferralsFieldWrapperComponent } from './srt-admin/referrals-field-wrapper/referrals-field-wrapper.component';
import { SrtAdminModule } from './srt-admin/srt-admin.module';
import { EditFieldModalWrapperComponent } from './srt-admin/referrals-field-wrapper/edit-field-modal-wrapper/edit-field-modal-wrapper.component';
import { SrtOverviewWrapperComponent } from './srt-admin/srt-overview-wrapper/srt-overview-wrapper.component';
import { SrtOverviewAccessModalWrapperComponent } from './srt-admin/srt-overview-wrapper/srt-overview-access-modal-wrapper/srt-overview-access-modal-wrapper.component';

const routes: Routes = [
  {
    path: '', component: AdminComponent, children:
      [{
        path: 'security',
        canActivate: [PermissionGuard],
        data: {
          permissions: [UserPermissions.GatewayExperienceSecurityView],
          redirectUrl: 'unauthorizedaccess'
        },
        component: SecurityAdminWrapperComponent,
        children: [
          { path: '', redirectTo: 'access', pathMatch: 'full' },
          {
            path: 'access', component: AccessTabWrapperComponent, children: [
              {
                path: ':workspace', component: AccessTabWorkspaceWrapperComponent, children: [
                  { path: '', redirectTo: 'roles', pathMatch: 'full' },
                  {
                    path: 'roles', component: AccessRolesComponent, children: [
                      { path: 'new', component: RoleModalWrapperComponent, outlet: 'modal' },
                      { path: 'edit/:id', component: RoleModalWrapperComponent, outlet: 'modal' },
                      { path: 'details/:id', component: RolesSidebarWrapperComponent, outlet: 'sidebar' }
                    ]
                  },
                  { path: 'permissions', component: AccessPermissionsComponent }
                ]
              },
            ]
          },
          {
            path: 'users', component: UsersTabComponent, canActivate: [PermissionGuard],
            data: {
              permissions: [UserPermissions.GatewaySecurityUsersTabView],
              redirectUrl: 'unauthorizedaccess'
            },
            children: [
              {
                path: ':id', children: [
                  { path: 'details', component: UserDetailsWrapperComponent, outlet: 'sidebar' },
                  { path: 'edit', component: UserModalWrapperComponent, outlet: 'modal' },
                ]
              },
              {
                path: ':userType', children: [
                  {
                    path: ':id', children: [
                      { path: 'details', component: UserDetailsWrapperComponent, outlet: 'sidebar' },
                      { path: 'edit', component: UserModalWrapperComponent, outlet: 'modal' },
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '', component: AdminCardComponent, children: [
          {
            path: 'gateway',
            canActivate: [PermissionGuard],
            data: {
              permissions: [UserPermissions.GatewayExperienceGatewayView],
              redirectUrl: 'unauthorizedaccess'
            },
            children: [
              { path: '', redirectTo: 'workspaces', pathMatch: 'full' },
              {
                path: 'workspaces', component: AdminAppsCategoryComponent,
                canActivate: [PermissionGuard],
                data: {
                  permissions: [UserPermissions.GatewayWorkspacesAdd],
                }
              },
              {
                path: 'tools', component: AdminAppsComponent,
                canActivate: [PermissionGuard],
                data: {
                  permissions: [UserPermissions.GatewayToolsRead]
                }
              },
              {
                path: 'inlinetraining', component: TourStepsComponent,
                canActivate: [PermissionGuard],
                data: {
                  permissions: [UserPermissions.GatewayInlineTrainingRead]
                }
              }
            ]
          },
          {
            path: 'reporting',
            canActivate: [PermissionGuard],
            data: {
              permissions: [UserPermissions.InteractiveReportsCategoryAdd, UserPermissions.GatewayExperienceReportingView],
              redirectUrl: 'unauthorizedaccess'
            },
            children: [
              { path: '', redirectTo: 'categories', pathMatch: 'full' },
              {
                path: 'categories',
                component: AreasComponent,
                canActivate: [PermissionGuard],
                data: {
                  permissions: [UserPermissions.InteractiveReportsCategoriesSettingsView],
                  redirectUrl: 'unauthorizedaccess'
                }
              },
              {
                path: 'reports',
                component: ReportsComponent,
                canActivate: [PermissionGuard],
                data: {
                  permissions: [UserPermissions.InteractiveReportsReportsSettingsView],
                  redirectUrl: 'unauthorizedaccess'
                }
              }
            ],
          },
          {
            path: 'safr',
            canActivate: [PermissionGuard],
            data: {
              permissions: [UserPermissions.GatewayExperienceSafrView],
              redirectUrl: 'unauthorizedaccess'
            },
            children: [
              { path: '', redirectTo: 'field-management', pathMatch: 'full' },
              {
                path: 'field-management',
                component: FieldsWrapperComponent,
                canActivate: [PermissionGuard],
                data: {
                  permissions: [UserPermissions.SafrFieldManagementSettingsView],
                  redirectUrl: 'unauthorizedaccess'
                },
                children: [
                  {
                    path: ':id', children: [
                      {
                        path: 'edit', component: EditModalWrapperComponent, outlet: 'modal',
                        canActivate: [PermissionGuard],
                        data: { permissions: [UserPermissions.SafrFieldManagementSettingsView], redirectUrl: 'unauthorized' }
                      }
                    ]
                  }
                ]
              },
              {
                path: 'overview-access',
                component: SafrOverviewWrapperComponent,
                canActivate: [PermissionGuard],
                data: {
                  permissions: [UserPermissions.SafrFieldManagementSettingsView],
                  redirectUrl: 'unauthorizedaccess'
                },
                children: [
                  {
                    path: 'new', component: SafrOverviewModalWrapperComponent, outlet: 'modal',
                    canActivate: [PermissionGuard],
                    data: { permissions: [UserPermissions.SafrFieldManagementSettingsView], redirectUrl: 'unauthorized' }
                  },
                  {
                    path: ':id', children: [
                      {
                        path: 'edit', component: SafrOverviewModalWrapperComponent, outlet: 'modal',
                        canActivate: [PermissionGuard],
                        data: { permissions: [UserPermissions.SafrFieldManagementSettingsView], redirectUrl: 'unauthorized' }
                      }
                    ]
                  }
                ]
              }
            ],
          },
          {
            path: 'sanctions',
            canActivate: [PermissionGuard],
            data: {
              permissions: [UserPermissions.GatewayExperienceSanctionsView],
              redirectUrl: 'unauthorizedaccess'
            },
            children: [
              { path: '', redirectTo: 'database', pathMatch: 'full' },
              {
                path: 'database',
                component: SanctionsDatabaseComponent,
                canActivate: [PermissionGuard],
                data: {
                  permissions: [UserPermissions.SanctionsDatabaseEdit],
                  redirectUrl: 'unauthorizedaccess'
                }
              },
            ],
          },
          {
            path: 'sanctions-referral',
            canActivate: [PermissionGuard],
            data: {
              permissions: [UserPermissions.GatewayExperienceSrtView],
              redirectUrl: 'unauthorizedaccess'
            },
            children: [
              { path: '', redirectTo: 'field-management', pathMatch: 'full' },
              {
                path: 'field-management',
                component: ReferralsFieldWrapperComponent,
                canActivate: [PermissionGuard],
                data: {
                  permissions: [UserPermissions.SrtFieldManagementSettingsView],
                  redirectUrl: 'unauthorizedaccess'
                },
                children: [
                  {
                    path: ':id', children: [
                      {
                        path: 'edit', component: EditFieldModalWrapperComponent, outlet: 'modal',
                        canActivate: [PermissionGuard],
                        data: { permissions: [UserPermissions.SrtFieldManagementSettingsView], redirectUrl: 'unauthorized' }
                      }
                    ]
                  }
                ]
              },
              {
                path: 'overview-access',
                component: SrtOverviewWrapperComponent,
                canActivate: [PermissionGuard],
                data: {
                  permissions: [UserPermissions.SrtOverviewAccessRead],
                  redirectUrl: 'unauthorizedaccess'
                },
                children: [
                  {
                    path: 'new', component: SrtOverviewAccessModalWrapperComponent,
                    canActivate: [PermissionGuard],
                    data: { permissions: [UserPermissions.SrtOverviewAccessAdd], redirectUrl: 'unauthorized' }
                  },
                  {
                    path: ':id', children: [
                      {
                        path: 'edit', component: SrtOverviewAccessModalWrapperComponent,
                        canActivate: [PermissionGuard],
                        data: { permissions: [UserPermissions.SrtOverviewAccessEdit], redirectUrl: 'unauthorized' }
                      }
                    ]
                  }
                ]
              }
            ]
          }]
      }]
  }
];


@NgModule({
    declarations: [
        AdminComponent,
        AdminAppsComponent,
        AdminAppsCategoryComponent,
        AdminAppsCategoryCreateUpdateComponent,
        AdminAppsCreateUpdateComponent,
        AdminDeleteComponent,
        AdminSecurityGroupsComponent,
        AdminSecurityGroupsCreateUpdateComponent,
        TourStepsComponent,
        TourStepsCreateUpdateComponent,
        TourStepClearHistoryComponent,
        AdminCardComponent,
        UserModalWrapperComponent,
        UserModalComponent,
        ServicePrincipleModalComponent,
        InfoModalComponent
    ],
    imports: [
        CommonModule,
        WreToolkitLibModule,
        WreCoreLibModule,
        FormsModule,
        NgbModule,
        SecurityAdminModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        ReportingAdminModule,
        SafrAdminModule,
        AdminSharedModule,
        SharedModule,
        SanctionsAdminModule,
        SrtAdminModule
    ],
    exports: [RouterModule]
})
export class AdminModule { }
