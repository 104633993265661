<div class="modal-header d-flex align-items-center">
    <h5 class="modal-title mb-0">
        Clear Inline Training Viewed History
    </h5>
    <div>
        <button type="button" class="btn btn-icon" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()" tabindex="-1">
          <i class="fal fa-times"></i>
    </button>
    </div>
</div>
<form novalidate #newTourStepAppsForm="ngForm" (ngSubmit)="onClearHistory(newTourStepAppsForm)">
    <div class="modal-body">
        <div class="form-group" style="min-height: 70px;" *ngIf="!isNoContentMessageVisible">
            <div *ngIf="this.isloaderVisible" class="d-flex align-items-center justify-content-center">
                <div class="loading"></div>
            </div>
            <div *ngIf="!this.isloaderVisible">

                <div class="col-6 form-group">
                    <label class="form-control-label" for="lblAppName">Apps</label>
                    <select class="form-control" [(ngModel)]="newTourStepApps.appName" (change)="getInlineTrainingAppRoutes()" id="appName" style="width: 100%;" #appName="ngModel" [ngClass]="{'is-invalid': ((appName.touched || appName.dirty) && !appName.valid) || (!appName.valid && newTourStepAppsForm.submitted) }"
                        required name="appName">
            <option [ngValue]="null">Select App</option>
            <option *ngFor="let app of newTourStepApps" [value]="app.appName"
              >{{ app.appName }}</option
            >
          </select>
                    <span class="invalid-feedback">
            <span *ngIf="appName.errors?.required">
              Please select App.
            </span>
                    </span>
                </div>

                <div class="col-6 form-group" *ngIf="newTourStepRoutes.length !== undefined">
                    <label class="form-control-label" for="lblRouteName">Routes</label>
                    <select class="form-control" [(ngModel)]="newTourStepRoutes.routeName" id="routeName" style="width: 100%;" #routeName="ngModel" [ngClass]="{'is-invalid': ((routeName.touched || routeName.dirty) && !routeName.valid) || (!routeName.valid && newTourStepAppsForm.submitted) }"
                        required name="routeName">
              <option [ngValue]="null">Select Route</option>
              <option  *ngFor="let route of newTourStepRoutes" [value]="route.routeName"
                >{{ route.routeName }}</option
              >
            </select>
                    <span class="invalid-feedback">
              <span *ngIf="routeName.errors?.required">
                Please select Route.
              </span>
                    </span>
                </div>

                <div class="col-6" *ngIf="newTourStepRoutes.length === undefined">
                    <label class="form-control-label" for="lblRouteName">Routes</label>
                    <select class="form-control" [(ngModel)]="newTourStepRoutes.routeName" id="routeName" style="width: 100%;" #routeName="ngModel" [ngClass]="{'is-invalid': ((routeName.touched || routeName.dirty) && !routeName.valid) || (!routeName.valid && newTourStepAppsForm.submitted) }"
                        required name="routeName">
                <option [ngValue]="null">Select Route</option>
              </select>
                    <span class="invalid-feedback">
                <span *ngIf="routeName.errors?.required">
                  Please select Route.
                </span>
                    </span>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-12">
                <div [hidden]="!isNoContentMessageVisible" [ngClass]="isNoContentMessageVisible ? 'alert alert-warning' : ''">
                    <strong>{{ noContentMessage }}</strong>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer" *ngIf="!isNoContentMessageVisible">
        <button type="submit" class="btn btn-primary ms-3">
          <span>Clear History</span>
        </button>
    </div>
</form>
