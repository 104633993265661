import { LookupUser } from 'wre-authlib';
import { UserType } from './../models/user-type';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WizardComponent } from 'wre-toolkit-lib';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ErrorMessages } from '../models/role-summary-view';
import { SecurityWorkspaceService } from '../services/security-workspace.service';
import { SecurityWorkspace } from '../models/security-workspace';
import { SecurityManagementService } from '../services/security-management.service';
import { RoleDetailsView } from '../models/role-details-view';
import { filter } from 'rxjs/operators';
import { UserGroup } from '../models/user-group';
import { ConfirmCloseModalComponent } from './confirm-close-modal/confirm-close-modal.component';
import { Subscription } from 'rxjs';
import { UserPermissions } from 'src/app/models/admin';
import { PermissionGroup } from '../models/permission-group';

@Component({
  selector: 'wre-role-modal',
  templateUrl: './role-modal.component.html',
  styleUrls: ['./role-modal.component.sass']
})
export class RoleModalComponent implements OnInit, OnDestroy {

  @ViewChild(WizardComponent)
  private wizard: WizardComponent;

  loading = false;
  errorMessageInfo: string;
  errorMessage = false;
  roleForm: UntypedFormGroup;
  roleInfo = new RoleDetailsView();
  permissions: string[] = [];
  closeConfirmSubscription: Subscription;
  currentWorkspaceSubscription: Subscription;

  private _roleId: number;
  set roleId(roleId: number) {
    this._roleId = roleId;
    if (this.currentWorkspace != null) {
      this.loadRole();
    }
  }
  get roleId() {
    return this._roleId;
  }

  private originalRole: RoleDetailsView;

  private _currentWorkspace: SecurityWorkspace;
  set currentWorkspace(workspace: SecurityWorkspace) {
    this._currentWorkspace = workspace;
    if (this.roleId != null) {
      this.loadRole();
    }
  }
  get currentWorkspace(): SecurityWorkspace {
    return this._currentWorkspace;
  }

  get isEditMode() {
    return this.roleId != null;
  }

  get title() {
    return this.isEditMode ? 'Edit Role' : 'Create Role';
  }

  get nextButtonText() {
    if (this.wizard && this.wizard.selectedIndex === 3) {
      return this.isEditMode ? 'Update Role' : 'Create Role';
    }
    return 'Next';
  }

  get showBackButton() {
    return this.wizard && this.wizard.selectedIndex !== 0;
  }

  get roleName(): string {
    return !!this.roleForm.get('name') ? this.roleForm.get('name').value : '';
  }

  get permissionsForm(): UntypedFormArray {
    return this.roleForm.get('permissions') as UntypedFormArray;
  }

  get checkName(): boolean {
    return !(this.originalRole && this.originalRole.name.toLocaleLowerCase() === this.roleName.toLocaleLowerCase());
  }

  get userCount(): number {
    return (this.roleForm.get('users').value as LookupUser[]).filter(u => u.type === UserType.internalUser || u.type === UserType.externalUser).length;
  }

  get adGroupCount(): number {
    return (this.roleForm.get('users').value as LookupUser[]).filter(u => u.type === UserType.adGroup).length;
  }

  get servicePrincipleCount(): number { return (this.roleForm.get('users').value as UserGroup[]).filter(u => u.type === UserType.servicePrinciple).length; }

  get isCompleted(): boolean {
    return this.wizard && this.wizard.selected.completed;
  }

  get hasRolePermission(): boolean {
    return this.securityWorkspaceService.hasRequiredPermission(UserPermissions.RolesAdd);
  }

  get hasMemberPermission(): boolean {
    return this.securityWorkspaceService.hasRequiredPermission(UserPermissions.MembersAdd) || this.securityWorkspaceService.hasRequiredPermission(UserPermissions.AuthorisedMembersAdd);
  }

  get hasEnablePermission(): boolean {
    return this.securityWorkspaceService.hasRequiredPermission(UserPermissions.PermissionEnable);
  }

  get hasRoleAdminAddPermission(): boolean {
    return this.securityWorkspaceService.hasRequiredPermission(UserPermissions.RoleAdminsAdd);
  }

  get admins() {
    return this.roleForm.get('admins').value as UserGroup[];
  }

  get rolePermissions() {
    return this.roleForm.get('permissions').value as PermissionGroup[];
  }

  get users() {
    return this.roleForm.get('users').value as UserGroup[];
  }

  constructor(private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private securityWorkspaceService: SecurityWorkspaceService,
    private securityManagementService: SecurityManagementService,
    private modalService: NgbModal) { }


  ngOnInit() {
    this.currentWorkspaceSubscription = this.securityWorkspaceService.currentWorkspace$
      .pipe(filter(w => w != null))
      .subscribe((workspace: SecurityWorkspace) => {
        this.currentWorkspace = workspace;
        this.roleInfo.applicationName = workspace.applicationName;
        this.initialiseForm();
      });
  }

  ngOnDestroy() {
    if (this.closeConfirmSubscription) { this.closeConfirmSubscription.unsubscribe(); }
    if (this.currentWorkspaceSubscription) { this.currentWorkspaceSubscription.unsubscribe(); }
  }

  loadRole() {
    this.loading = true;
    this.securityManagementService.getRole(this.currentWorkspace, this.roleId).then(async role => {
      this.originalRole = role.body;
      this.patchOriginalRoleIntoForm();
      this.roleInfo.id = this.originalRole.id;
      this.loading = false;
    });
  }

  clearErrorMessage() {
    this.errorMessage = false;
    this.errorMessageInfo = '';
  }

  executeStep() {
    switch (this.wizard.selectedIndex) {
      case 0:
        this.loading = true;
        this.clearErrorMessage();
        this.assignRoleFormToInfo();
        if (this.checkName) {
          this.securityManagementService.checkRoleExists(this.roleInfo).then(
            response => {
              if (response.body) {
                this.errorMessage = true;
                this.errorMessageInfo = ErrorMessages.duplicateRoleError;
              } else {
                this.clearErrorMessage();
                this.wizard.next();
              }
              this.loading = false;
            },
            error => {
              this.errorMessage = true;
              this.errorMessageInfo = error.error;
              this.loading = false;
            });
        } else {
          this.clearErrorMessage();
          this.loading = false;
          this.wizard.next();
        }
        break;

      case 1:
        this.wizard.next();
        break;

      case 2:
        this.wizard.next();
        break;

      case 3:
        this.handleFormSubmission();
        break;
    }

  }

  backStep() {
    this.wizard.previous();
  }

  confirmCloseModal() {
    this.assignRoleFormToInfo();
    if (!this.displayConfirmCloseModal()) {
      this.dismissModal();
      return;
    }

    const modalOptions: NgbModalOptions = {
      size: 'md',
      centered: true,
      scrollable: true
    };

    const closeModalRef = this.modalService.open(
      ConfirmCloseModalComponent,
      modalOptions
    );

    this.closeConfirmSubscription =
      (closeModalRef.componentInstance as ConfirmCloseModalComponent).closeConfirmed.subscribe(this.dismissModal);
  }

  private initialiseForm() {
    this.roleForm = this.fb.group({
      users: this.fb.control([]),
      admins: this.fb.control({ value: [], disabled: !this.hasRoleAdminAddPermission }),
      name: [{ value: '', disabled: !this.hasRolePermission },
      [Validators.required, Validators.pattern('[a-zA-Z0-9 _-]*'),
      Validators.pattern('.*[^ ].*')]],
      isActive: [{ value: true, disabled: !this.hasRolePermission }, Validators.required],
      permissions: this.fb.array([])
    });
  }

  private patchOriginalRoleIntoForm() {
    this.roleForm.patchValue(this.originalRole);
    (this.roleForm.get('permissions') as UntypedFormArray).clear();
    this.originalRole.permissions.forEach(p => (this.roleForm.get('permissions') as UntypedFormArray).push(this.fb.control(p)));

    (this.roleForm.get('users') as UntypedFormControl)
      .setValue(this.originalRole.users.map(u => new UserGroup(u.id, u.displayName, u.type, u.mail, u.givenName, u.surname)));

    if (this.originalRole.admins) {
      (this.roleForm.get('admins') as UntypedFormControl)
        .setValue(this.originalRole.admins.map(u => new UserGroup(u.id, u.displayName, u.type, u.mail, u.givenName, u.surname)));
    }

    // If a role is a system role, only members can be altered
    if (this.originalRole.isSystem) {
      this.roleForm.disable();
      this.roleForm.get('users').enable();
    }

  }

  private assignRoleFormToInfo() {
    Object.assign(this.roleInfo, this.roleForm.getRawValue());
    this.roleInfo.permissions = JSON.parse(JSON.stringify(this.roleForm.get('permissions').value));
  }

  private clearRedundantProperties() {
    // remove redundant properties - only ID is needed to give the role relevant permission
    this.roleInfo.permissions.forEach(p => {
      delete p.type;
      delete p.description;
      delete p.name;
      delete p.isActive;
    });
  }

  private handleFormSubmission() {
    this.loading = true;
    this.assignRoleFormToInfo();
    this.clearRedundantProperties();
    if (!this.isEditMode) {
      this.securityManagementService.createRole(this.roleInfo)
        .then(this.closeModal, this.handleSubmissionError)
        .finally(() => this.loading = false);
    } else {
      this.securityManagementService.editRole(this.roleInfo)
        .then(this.closeModal, this.handleSubmissionError)
        .finally(() => this.loading = false);
    }
  }

  private closeModal = () => {
    this.activeModal.close();
  }

  private dismissModal = () => {
    this.activeModal.dismiss();
  }

  private handleSubmissionError = () => {
    this.errorMessage = true;
    this.errorMessageInfo = ErrorMessages.genericError;
  }

  private displayConfirmCloseModal(): boolean {
    if (this.isEditMode) {
      return !this.roleInfo.equals(this.originalRole);
    }
    return false;
  }
}
