import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { UserLookupMultiselectComponent } from './user-lookup-multiselect/user-lookup-multiselect.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmModalCloseComponent } from './confirm-modal-close/confirm-modal-close.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { UserLookupComponent } from './user-lookup/user-lookup.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ServiceNotAvailableComponent } from './not-available/not-available.component';
import { WreToolkitLibModule } from 'wre-toolkit-lib';

@NgModule({
  declarations: [
    ConfirmationComponent,
    UserLookupMultiselectComponent,
    ConfirmModalCloseComponent,
    MultiselectComponent,
    UserLookupComponent,
    ErrorMessageComponent,
    ServiceNotAvailableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    WreToolkitLibModule
  ],
  exports: [
    ConfirmationComponent,
    ConfirmModalCloseComponent,
    UserLookupMultiselectComponent,
    MultiselectComponent,
    UserLookupComponent,
    ErrorMessageComponent,
    ServiceNotAvailableComponent
  ]
})
export class SharedModule { }
