import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityWorkspaceService } from '../../services/security-workspace.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wre-access-tab-wrapper',
  templateUrl: './access-tab-wrapper.component.html',
})
export class AccessTabWrapperComponent implements OnInit, OnDestroy {

  workspacesCount: number;
  authWorkspacesSubscription: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private securityWorkspaceService: SecurityWorkspaceService) { }

  ngOnInit() {
    // if /access hit, redirect to first available security workspace
    this.authWorkspacesSubscription = this.securityWorkspaceService.authorizedWorkspaces$.subscribe(workspaces => {
      if (workspaces == null) { return; }
      this.workspacesCount = workspaces.length;
      if (workspaces.length > 0 && this.router.url.toLowerCase() === '/admin/security/access') {
        this.router.navigate([workspaces[0].url.toLocaleLowerCase()], { relativeTo: this.route });
      }
    });
  }

  ngOnDestroy() {
    this.authWorkspacesSubscription.unsubscribe();
  }

}
