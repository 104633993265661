export enum DynamicFieldType {
  ReferralDetailsSanctionsTeamReviewer = 0,
  ReferralDetailsClientRelationshipOfficeLocation = 1,
  ReferralDetailsRequesteeOfficeLocation = 2,
  ReferralDetailsRequesteeBusinessTeam = 3,
  PolicyDetailsQ6 = 4,
  ReinsuranceDetailsQ10 = 5,
  ReinsuranceDetailsQ13 = 6,
  SanctionsDetailsQ1 = 7,
  SanctionsDetailsQ3 = 8,
  RussiaQ8 = 9,
  RussiaQ9 = 10,
  RussianMarineQ7 = 11,
  RussianMarineQ8 = 12,
  VenezuelaQ1 = 13,
  VenezuelaQ3 = 14
}
