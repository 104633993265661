import { Component, Input } from '@angular/core';
import { SlipcaseNewsItem } from 'src/app/models/slipcase-news-item';

@Component({
  selector: 'app-slipcase-feed-item',
  templateUrl: './slipcase-card.component.html',
  styleUrls: ['./slipcase-card.component.css']
})
export class SlipcaseCardComponent {

  @Input() newsItem: SlipcaseNewsItem;

  constructor ( ) { }

}
