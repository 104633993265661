import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retryWhen } from 'rxjs/operators';
import { ApiService } from '../../overview-access/services/api.service';
import { SrtOverviewAccess } from '../models/srt-overview-access';
import { OverviewAccess } from '../../overview-access/models/overview-access';
import { AccessMapping } from '../../overview-access/models/access-mapping';

@Injectable({
  providedIn: 'root'
})
export class SrtOverviewAccessApiService extends ApiService<SrtOverviewAccess> {

  private srtUrl = environment.srtUrl;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getOverviewAccess(): Observable<OverviewAccess<SrtOverviewAccess>> {
    const url = `${ this.srtUrl }overview-access`;

    return this.httpClient.get<OverviewAccess<SrtOverviewAccess>>(url).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  getAccessMapping(userId: string): Observable<AccessMapping<SrtOverviewAccess>> {
    const url = `${ this.srtUrl }overview-access/${userId}`;

    return this.httpClient.get<AccessMapping<SrtOverviewAccess>>(url, { observe: 'body' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  createAccessMapping(request: AccessMapping<SrtOverviewAccess>): Observable<OverviewAccess<SrtOverviewAccess>> {
    const url = `${ this.srtUrl }overview-access`;

    return this.httpClient.post<OverviewAccess<SrtOverviewAccess>>(url, request).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  updateAccessMapping(request: AccessMapping<SrtOverviewAccess>): Observable<OverviewAccess<SrtOverviewAccess>> {
    const url = `${ this.srtUrl }overview-access/${request.user.id}`;

    return this.httpClient.put<OverviewAccess<SrtOverviewAccess>>(url, request).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  deleteAccessMapping(userId: string): Observable<boolean> {
    const url = `${ this.srtUrl }overview-access/${userId}`;

    return this.httpClient.delete<boolean>(url).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }
}
