import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './../../../services/http.service';
import { environment } from './../../../../environments/environment';
import { retryWhen } from 'rxjs/operators';
import { Field, FieldOptionsRequest, SafrType } from '../models/safr-type';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SafrAdminService extends HttpService {
  private safrApiUrl = `${ environment.safrApiUrl }`;

  constructor(http: HttpClient) {
    super(http);
  }

  async getSafrTypes(): Promise<HttpResponse<SafrType>> {
    const url = `${ this.safrApiUrl }admin/safrtypes`;

    return this.http.get<SafrType>(url, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  public getFields(type: string = null, subType: string = null,
                   includeOptions: boolean = true, displayAll: boolean = false): Observable<Field[]> {
    let url: string;
    if (type !== null) {
      if (subType !== null) {
        url = `${ this.safrApiUrl }admin/fields?type=${ type }&subtype=${ subType }&includeOptions=${includeOptions}&displayAll=${displayAll}`;
      } else {
        url = `${ this.safrApiUrl }admin/fields?type=${ type }&includeOptions=${includeOptions}&displayAll=${displayAll}`;
      }
    } else {
      url = `${ this.safrApiUrl }admin/fields?includeOptions=${includeOptions}&displayAll=true`;
    }

    return this.http.get<Field[]>(url).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  public getField(fieldId: string): Observable<Field> {
    const url = `${ this.safrApiUrl }admin/fields/${fieldId}`;

    return this.http.get<Field>(url).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  public updateField(fieldId: string, request: FieldOptionsRequest): Observable<any> {
    const url = `${ this.safrApiUrl }admin/fields/${fieldId}`;

    return this.http.put<any>(url, request).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }
}
