import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreLoginGuard } from 'wre-authlib';
import { UserTypeSelectionComponent } from './user-type-selection.component';

const routes: Routes = [
  { path: '', component: UserTypeSelectionComponent, canActivate: [PreLoginGuard] },
];

@NgModule({
  declarations: [UserTypeSelectionComponent],
  imports: [[CommonModule, RouterModule.forChild(routes)]],
  exports: [RouterModule],
})
export class UserTypeSelectionModule {}
