import { Component, Input } from '@angular/core';
import { PermissionGroup } from '../../../models/permission-group';

@Component({
  selector: 'wre-permission-group-unit',
  templateUrl: './permission-group-unit.component.html',
  styleUrls: ['./permission-group-unit.component.sass']
})
export class PermissionGroupUnitComponent {

  @Input() isSelected = false;
  @Input() isDisabled = false;
  @Input() permissionGroup: PermissionGroup;

}
