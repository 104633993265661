<!--<div class="card" [ngClass]="{'external': searchAvailable === false}">
    <div class="row">
        <div class="welcome-container col-sm-12 col-lg-8 d-flex align-items-center position-absolute">

            <div class="welcome-msg">
                <span class="generic-welcome">Welcome to <strong>Gallagher Re Gateway</strong></span>
              <span *ngIf="user && user.friendlyName" class="personal-welcome d-flex align-items-center">
                <i class="far fa-user me-4"></i>{{user.friendlyName}}
              </span>

                <div *ngIf="searchAvailable === true" tourAnchor="searchExperience.anchor" class="mt-2 p-3 welcome-search-cont">
                    <wre-search-experience></wre-search-experience>
                </div>
            </div>
            <span class="overlay-1"></span>
            <span class="overlay-2"></span>
            <span class="overlay-3"></span>
        </div>
    </div>
</div> -->

<div class="card no-search" [ngClass]="{'external': searchAvailable === false}">
    <div class="row">
        <div class="welcome-container col-sm-12 col-lg-8 d-flex align-items-center position-absolute">

            <div class="welcome-msg">
              <span *ngIf="user && user.friendlyName" class="personal-welcome d-flex">
                <i class="far fa-user me-2"></i>
                <div>
                    Welcome to Gallagher Re Gateway <span class="user-name d-block">{{user.friendlyName}}</span>
                </div>
              </span>

                <!-- <div *ngIf="searchAvailable === true" tourAnchor="searchExperience.anchor" class="mt-2 p-3 welcome-search-cont">
                    <wre-search-experience></wre-search-experience>
                </div> -->
            </div>
            <span class="overlay-1"></span>
            <span class="overlay-2"></span>
            <span class="overlay-3"></span>
        </div>
    </div>
</div>
