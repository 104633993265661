import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SafrOverviewAccessModalComponent } from './safr-overview-access-modal/safr-overview-access-modal.component';

@Component({
  selector: 'wre-safr-overview-modal-wrapper',
  template: ``,
  styles: []
})
export class SafrOverviewModalWrapperComponent implements OnInit {

  id: string;

  constructor(private modalService: NgbModal,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');

      const modalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        size: 'lg',
        centered: true,
        scrollable: true,
        windowClass: 'wre-questionnaireModal'
      };

      const modalRef = this.modalService.open(
        SafrOverviewAccessModalComponent,
        modalOptions
      );

      modalRef.componentInstance.userId = this.id;

      const redirectUrl = 'admin/safr/overview-access';

      modalRef.result.then(
        (resolved) => this.router.navigate([redirectUrl]),
        (rejected) => this.router.navigate([redirectUrl])
      );

    });
  }

}
