import { Component, OnInit, AfterViewInit } from '@angular/core';
import items from '../../../../../assets/json/placement-excellence/placement-status.json';
import { ActivatedRoute } from '@angular/router'; 
import { PlacementExcellenceService } from 'src/app/services/placement-excellence.service';

@Component({
  selector: 'app-placement-status',
  templateUrl: './placement-status.component.html',
  styleUrls: ['./placement-status.component.css']
})
export class PlacementStatusComponent implements AfterViewInit {

  tableItems = items;

  constructor (private route: ActivatedRoute, private placementExcellenceService: PlacementExcellenceService) { }

  ngAfterViewInit() {
    this.route.fragment.subscribe(f => this.placementExcellenceService.scroolToElementById(f));
  }

}
