import { WreAuthlibModule } from 'wre-authlib';
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedComponentsModule } from "src/app/components/shared/shared-components.module";
import { WreToolkitLibModule } from "wre-toolkit-lib";
import { AccessTabWorkspaceWrapperComponent } from "./components/access-wr/workspace-wr/access-tab-workspace-wrapper.component";
import { AccessPaginationComponent } from "./components/access-wr/workspace-wr/access-tab/access-pagination/access-pagination.component";
import { AccessPermissionsComponent } from "./components/access-wr/workspace-wr/access-tab/access-permissions/access-permissions.component";
import { AccessRolesComponent } from "./components/access-wr/workspace-wr/access-tab/access-roles/access-roles.component";
import { AccessTabComponent } from "./components/access-wr/workspace-wr/access-tab/access-tab.component";
import { RolesPermissionsGridComponent } from "./components/access-wr/workspace-wr/access-tab/roles-permissions-grid/roles-permissions-grid.component";
import { PermissionsAuditTableComponent } from "./components/access-wr/workspace-wr/roles-sidebar-wr/history-tables/permissions-table/permissions-audit-table.component";
import { RoleHistoryDataTableComponent } from "./components/access-wr/workspace-wr/roles-sidebar-wr/history-tables/role-history-data-table.component";
import { UserAuditTableComponent } from "./components/access-wr/workspace-wr/roles-sidebar-wr/history-tables/user-audit-table/user-audit-table.component";
import { RoleHistoryCardComponent } from "./components/access-wr/workspace-wr/roles-sidebar-wr/role-history-card/role-history-card.component";
import { RoleHistoryComponent } from "./components/access-wr/workspace-wr/roles-sidebar-wr/role-history/role-history.component";
import { RolesSidebarWrapperComponent } from "./components/access-wr/workspace-wr/roles-sidebar-wr/roles-sidebar-wrapper.component";
import { RolesTableComponent } from "./components/access-wr/workspace-wr/access-tab/roles-table/roles-table.component";
import { AccessTabWrapperComponent } from "./components/access-wr/access-tab-wrapper.component";
import { SecurityAdminWrapperComponent } from "./components/security-admin-wrapper/security-admin-wrapper.component";
import { UserDetailsWrapperComponent } from "./components/users-tab/user-details-wrapper/user-details-wrapper.component";
import { UserAppDetailsComponent } from "./components/users-tab/user-details-wrapper/user-details/user-app-details/user-app-details.component";
import { UserDetailsComponent } from "./components/users-tab/user-details-wrapper/user-details/user-details.component";
import { UsersTabComponent } from "./components/users-tab/users-tab.component";
import { UsersTableAdminComponent } from "./components/users-tab/users-table-admin/users-table-admin.component";
import { RoleModalWrapperComponent } from "./role-modal-wrapper/role-modal-wrapper.component";
import { ConfirmCloseModalComponent } from "./role-modal/confirm-close-modal/confirm-close-modal.component";
import { RoleDetailsStepComponent } from "./role-modal/role-details-step/role-details-step.component";
import { RoleInfoHeaderComponent } from "./role-modal/role-info-header/role-info-header.component";
import { RoleModalComponent } from "./role-modal/role-modal.component";
import { PermissionGroupUnitComponent } from "./role-modal/role-permissions-step/permission-group-unit/permission-group-unit.component";
import { RolePermissionsStepComponent } from "./role-modal/role-permissions-step/role-permissions-step.component";
import { PermissionsTableComponent } from "./role-modal/role-review-step/permissions-table/permissions-table.component";
import { RoleReviewStepComponent } from "./role-modal/role-review-step/role-review-step.component";
import { RoleUsersStepComponent } from "./role-modal/role-users-step/role-users-step.component";
import { UsersTableComponent } from "./role-modal/role-users-step/users-table/users-table.component";
import { UserDropdownLookupComponent } from "./role-modal/user-dropdown-lookup/user-dropdown-lookup.component";
import { UserLookupMultiselectComponent } from "./role-modal/user-lookup-multiselect/user-lookup-multiselect.component";
import { RolesFilterContainerComponent } from './components/access-wr/workspace-wr/access-tab/roles-filter-container/roles-filter-container.component';
import { DetailsComponent } from './components/access-wr/workspace-wr/roles-sidebar-wr/details/details.component';
import { SidebarComponent } from './components/access-wr/workspace-wr/roles-sidebar-wr/sidebar/sidebar.component';
import { ServicePrincipalsTableAdminComponent } from "./components/users-tab/service-principals-table/service-principals-table.component";

import { WreCoreLibModule } from 'wre-core-lib';

@NgModule({
    declarations: [
        AccessTabComponent,
        UsersTabComponent,
        AccessTabWrapperComponent,
        RolesTableComponent,
        SecurityAdminWrapperComponent,
        AccessTabWorkspaceWrapperComponent,
        AccessRolesComponent,
        AccessPermissionsComponent,
        RoleModalComponent,
        RoleDetailsStepComponent,
        RoleUsersStepComponent,
        RolePermissionsStepComponent,
        RoleReviewStepComponent,
        RoleInfoHeaderComponent,
        UserDropdownLookupComponent,
        UsersTableComponent,
        RoleModalWrapperComponent,
        PermissionGroupUnitComponent,
        ConfirmCloseModalComponent,
        PermissionsTableComponent,
        UsersTableAdminComponent,
        UserDetailsWrapperComponent,
        UserDetailsComponent,
        UserAppDetailsComponent,
        UsersTableAdminComponent,
        RolesPermissionsGridComponent,
        AccessPaginationComponent,
        UserLookupMultiselectComponent,
        RolesSidebarWrapperComponent,
        RoleHistoryComponent,
        RoleHistoryCardComponent,
        RoleHistoryDataTableComponent,
        UserAuditTableComponent,
        PermissionsAuditTableComponent,
        RolesFilterContainerComponent,
        DetailsComponent,
        SidebarComponent,
        ServicePrincipalsTableAdminComponent
    ],
    imports: [
        CdkStepperModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        RouterModule,
        NgbModule,
        WreToolkitLibModule,
        WreCoreLibModule,
        SharedComponentsModule,
        WreAuthlibModule
    ],
    exports: [
        AccessTabComponent
    ]
})
export class SecurityAdminModule { }
