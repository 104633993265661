import { Injectable } from '@angular/core';
import { RoleSummaryView } from '../../models/role-summary-view';
import { BehaviorSubject } from 'rxjs';
import { SecurityManagementService } from '../security-management.service';
import { ActivatedRoute } from '@angular/router';
import { SecurityWorkspaceService } from '../security-workspace.service';
import { SecurityWorkspace } from '../../models/security-workspace';

@Injectable({
  providedIn: 'root'
})
export class RolesDataService {

  get roles(): RoleSummaryView[] { return  this._roles$.getValue(); }
  get loading(): boolean { return  this._loading$.getValue(); }

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _roles$ = new BehaviorSubject<RoleSummaryView[]>([]);

  get roles$() { return this._roles$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }

  // to refresh role details of details slideout
  get toggledRoleId(): number { return this._toggledRoleId; }
  private _toggledRoleId: number;

  private _currentWorkspace: SecurityWorkspace;
  get currentWorkspace(): SecurityWorkspace { return this._currentWorkspace; }
  set currentWorkspace(workspace: SecurityWorkspace) {
    if (workspace == null) { return; }
    this._currentWorkspace = workspace;
    this.loadRoles();
  }

  constructor(private route: ActivatedRoute,
              private securityService: SecurityManagementService,
              public securityWorkspaceService: SecurityWorkspaceService) {

    this.securityWorkspaceService.currentWorkspace$.subscribe((workspace: SecurityWorkspace) => {
      this.currentWorkspace = workspace;
    });
  }

  public loadRoles() {
    this._loading$.next(true);
    this.securityService.getRoles(this.currentWorkspace)
      .then(data => this._roles$.next(data.body))
      .catch(err => this._roles$.error(err))
      .finally(() => this._loading$.next(false));
  }

  public toggleRoleStatus(role: RoleSummaryView) {
    this._loading$.next(true);
    this.securityService.updateRoleStatus(this.currentWorkspace, role.id, !role.isActive)
      .then(_ => { this.loadRoles(); this._toggledRoleId = role.id })
      .catch(err => this._roles$.error(err))
      .finally(() => this._loading$.next(false));
  }

}
