import { Component, OnInit } from '@angular/core';
import items from '../../../../../assets/json/placement-excellence/quick-reference.json';

@Component({
  selector: 'app-quick-reference',
  templateUrl: './quick-reference.component.html',
  styleUrls: ['./quick-reference.component.css']
})
export class QuickReferenceComponent implements OnInit {

  tableItems = items;

  constructor () { }

  ngOnInit() {
  }

}
