<div ngbDropdown container="body" class="dropdown flex-sm-column flex-lg-row">
  <label *ngIf="labelText" [for]="inputId">{{labelText}}</label>
  <div class="flex-row w-100">

    <input
        type="text"
        class="form-control dropdown-toggle dropdown w-100"
        [class.custom-invalid]="isInvalid && (control.touched)"
        (blur)="onBlur()"
        data-boundary="viewport"
        [id]="inputId"
        [name]="name"
        (input)="this.searchTerm = $event.target.value"
        [ngModel]="this.searchTerm"
        (keyup)="onKeyUp($event)"
        [placeholder]="placeholderValue"
        [tabindex]="inputTabIndex"
        autocomplete="off"
    >

    <!--    The actual form field is hidden so that we can send the LookupUser object rather than just a string-->
    <span ngbDropdownToggle style="visibility: hidden; display: block;"></span>
    <input hidden [formControl]="control">


    <div id="{{inputId}}-adUsersList" ngbDropdownMenu class="dropdown-menu">
      <a class="dropdown-item" *ngFor="let user of searchedUsers" ngbDropdownItem (click)="selectUser(user)">{{
        user.displayName }} - {{ user.mail }}</a>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-md-12 reviewers filters-selected d-sm-block d-lg-inline-block mt-3">
    <button *ngFor="let v of users;"
            (click)="removeItem(v)"
            class="btn badge me-2 mb-2"
            ngClass="{{ isNewUser(v) ? 'btn-newly-added' : 'bg-info' }}"
            type="button"
    >
      <span>{{ v.name != null ? v.name : v.displayName }}</span>
      <span class="ps-3">×</span>
    </button>
  </div>
</div>
