import { Component, AfterViewInit } from '@angular/core';
import items from '../../../../../assets/json/placement-excellence/client-onboarding-and-status-disclosure.json';
import { ActivatedRoute } from '@angular/router'; 
import { PlacementExcellenceService } from 'src/app/services/placement-excellence.service';

@Component({
  selector: 'app-client-onboarding-and-status-disclosure',
  templateUrl: './client-onboarding-and-status-disclosure.component.html',
  styleUrls: ['./client-onboarding-and-status-disclosure.component.css']
})

export class ClientOnboardingAndStatusDisclosureComponent implements AfterViewInit {
  tableItems = items;

  constructor (private route: ActivatedRoute, private placementExcellenceService: PlacementExcellenceService) { }

  ngAfterViewInit() {
    this.route.fragment.subscribe(f => this.placementExcellenceService.scroolToElementById(f));
  }
}
