<wre-toolkit-modal-popup [title]="title" [preventDefaultClose]="true" (closeClicked)="confirmCloseModal()">


    <div class="d-flex align-items-center justify-content-center">
        <div class="loading" [hidden]="errorMessage" *ngIf="loading"></div>
    </div>
    <div class="py-3" *ngIf='errorMessage'>
        <div class="wre-form-error">
            <i class="fas fa-exclamation pe-2"></i> {{ errorMessageInfo }}
            <i class="far fa-times ms-auto" (click)="this.errorMessage = false"></i>
        </div>
    </div>

    <wre-toolkit-wizard #wizard linear [hidden]="loading">

        <cdk-step label="Details" [completed]="roleForm.valid">
            <div class="wre-wizard-inner-container lookup-step position-relative" id='policyStepContainer'>
                <wre-role-details-step #roleDetailsStep [form]="roleForm" [hasRoleAdminAddPermission]="hasRoleAdminAddPermission">
                </wre-role-details-step>
            </div>
        </cdk-step>

        <cdk-step label="Users" [completed]="true">
            <div class="wre-wizard-inner-container questionnaire-step">
                <wre-role-info-header [roleInfo]="roleForm" [userCount]="userCount" [adGroupCount]="adGroupCount" [adminCount]="admins.length" [servicePrincipleCount]="servicePrincipleCount">
                </wre-role-info-header>
                <wre-role-users-step [form]="roleForm" [hasMemberPermissions]="hasMemberPermission" [userCount]="userCount" [adGroupCount]="adGroupCount" [servicePrincipleCount]="servicePrincipleCount"></wre-role-users-step>
            </div>
        </cdk-step>

        <cdk-step label="Permission" [completed]="true">
            <div class="wre-wizard-inner-container review-step">
                <wre-role-info-header [roleInfo]="roleForm" [displayUsersStats]="true" [userCount]="userCount" [adGroupCount]="adGroupCount" [adminCount]="admins.length" [servicePrincipleCount]="servicePrincipleCount">
                </wre-role-info-header>
                <wre-role-permissions-step [hasEnablePermissions]="hasEnablePermission" [permissionsArray]="permissionsForm">
                </wre-role-permissions-step>
            </div>
        </cdk-step>

        <cdk-step label="Review" [completed]="true">
            <div class="wre-wizard-inner-container confirmation-step">
                <wre-role-info-header [roleInfo]="roleForm"></wre-role-info-header>
                <wre-role-review-step [form]="roleForm" [userCount]="userCount" [adGroupCount]="adGroupCount" [admins]="admins" [permissions]="permissions" [servicePrincipleCount]="servicePrincipleCount">
                </wre-role-review-step>
            </div>
        </cdk-step>

    </wre-toolkit-wizard>

    <div footer style="width: 100%;">

        <div class="row">
            <div class="col-sm-12 d-flex justify-content-end">
                <button *ngIf="!!showBackButton" [disabled]="loading" type="button" class="btn wre-btn btn-secondary ms-3" (click)="backStep()">
          Back
        </button>

                <button type="button" [disabled]="!isCompleted || loading" class="btn wre-btn btn-primary ms-3" (click)="executeStep()">
          {{ nextButtonText}}
        </button>
            </div>
        </div>
    </div>

</wre-toolkit-modal-popup>
