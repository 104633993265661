<div class="pb-4">
    <div id="accordion">
        <div class="card" style="border-radius: 3px;">
            <div class="card-header" id="headingOne">
                <div data-bs-toggle="collapse" data-bs-target="#collapse-users" aria-expanded="true"
                    aria-controls="collapse-users" class="accordion-toggle w-100 d-flex justify-content-between">
                    <div class="card-title">
                        <strong>
                            {{ displayName }}
                        </strong>
                    </div>
                    <div class="accordion-info d-flex">
                        <div class="d-flex me-3">
                            <div>
                                <span class="header-label">
                                    Roles :
                                </span>
                                <span class="header-value">
                                    <strong><span *ngIf="roleCount < 10">0</span>{{ roleCount }} </strong>
                                </span>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <i class="fas fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div id="collapse-users" class="collapse show" aria-labelledby="collapse-users-heading"
                data-bs-parent="#accordion">
                <div *ngIf="roles && roles.length > 0">
                    <div *ngFor="let role of roles">
                        <div class="card-body p-0">
                            <div class="accordion-title py-3 border-bottom">
                                <strong>
                                    {{role.roleName}}
                                </strong>
                                <span *ngIf="role.applicationRoleAdmins.length > 0" class="badge bg-success outline ms-2">
                                    Admin
                                </span>
                                <span *ngIf="!role.isActive" class="badge bg-dark outline ms-2">Disabled</span>
                            </div>

                            <div *ngIf="role.permissionGroups && role.permissionGroups.length > 0">
                                <div class="accordion-list border-bottom d-flex justify-content-between"
                                    *ngFor="let permission of role.permissionGroups">
                                    <span class="d-flex align-items-center">{{permission.name}}</span>

                                    <span class="badge" [ngClass]="{
                                    'bg-success': permission.type !== 'Application',
                                    'bg-info':  permission.type === 'Application'
                                    }">
                                        <i class="me-1 far" [ngClass]="{
                                        'fa-lock':  permission.type !== 'Application',
                                        'fa-mouse-pointer':  permission.type === 'Application'}"></i>
                                        {{ permission.type !== 'Application' ? 'Security' : 'App' }}
                                    </span>
                                </div>
                            </div>

                            <div class="accordion-list border-bottom"
                                *ngIf="role.permissionGroups && role.permissionGroups.length === 0">
                                No permissions assigned.
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
