<div class="row justify-content-center" [hidden]="!this.isloaderVisible">
  <div class="col-lg-8 col-md-12 pb-4">
    <div style="height:70px;" class="d-flex align-items-center justify-content-center">
      <div style="" class="loading"></div>
    </div>
  </div>
</div>
<div [hidden]="this.isloaderVisible">
  <div #tableContainer class="table-responsive">
    <table class="table wre-table fixed-column mb-0">
      <thead>
        <tr>
          <th class="fixed-column action">&nbsp;</th>
          <th class="id" hidden="true">ID</th>
          <th class="active text-center">Active</th>
          <th class="name">Name</th>
        </tr>
      </thead>
      <tbody #tableBody class="mb-0 pb-0">
        <tr *ngFor="let item of areasList" [hidden]="!showInActiveReports && !item.isActive">
          <td class="fixed-column action">
            <div ngbDropdown container="body">
              <button class="btn btn-secondary" ngbDropdownToggle>Action</button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem (click)="onEdit(item.id,item.name)">Edit</button>
                <button ngbDropdownItem (click)="onDelete(item.id,item.name)" class="delete">Delete</button>
              </div>
            </div>
            <!-- <div class="dropdown">
              <button
                style="z-index:0;"
                class="btn btn-secondary dropdown-toggle dropdown"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>Action</span> <span class="caret"></span>
              </button>
              <div class="dropdown-menu" >
                <a class="dropdown-item" href="javascript:;" (click)="onEdit(item.id,item.name)">Edit</a>
                <a class="dropdown-item" href="javascript:;" (click)="onDelete(item.id,item.name)">Delete</a>
              </div>
            </div> -->
          </td>
          <td class="id" hidden="true">{{ item.id }}</td>
          <td class="active text-center">
            <i class="fa fa-check" aria-hidden="true" *ngIf="item.isActive"></i>
            <i class="fa fa-times" aria-hidden="true" *ngIf="!item.isActive"></i>
          </td>
          <td class="name">{{ item.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

