<div class="row" *ngIf="(loading$ | async)">
  <div class="col-12">
    <wre-toolkit-loader></wre-toolkit-loader>
  </div>
</div>

<div class="wre-table-container" *ngIf="!(loading$ | async)">
  <div #tableContainer *ngIf="!isNoSearchResult" class="table-responsive">
    <table class="table wre-table fixed-column table-hover mb-0">
      <thead class="text-center">
        <tr>
          <th class="text-nowrap column-action fixed-column">&nbsp;</th>
          <th class="text-nowrap col-ellipsis column-users">Name</th>
          <th class="text-nowrap col-ellipsis column-name">Id</th>
          <th class="text-nowrap col-ellipsis column-domain text-center">App Count</th>
          <th class="text-nowrap col-ellipsis column-domain text-center">Role Count</th>
        </tr>
      </thead>
      <tbody>
        <wre-core-app-loading [isLoading]="isLoading"></wre-core-app-loading>
        <ng-container *ngIf="!isLoading">
          <tr *ngFor="let item of items$ | async;">
            <td class="text-nowrap sticky-column column-action fixed-column">
              <div ngbDropdown container="body">
                <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle>Action</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem routerLinkActive #rla="routerLinkActive"
                    [routerLink]="[userType + '/' + item.mail, {outlets: { sidebar: 'details' } } ]">Details</button>
                  <button ngbDropdownItem routerLinkActive *ngIf="hasEditPermission"
                    [routerLink]="[userType + '/' + item.mail, {outlets: { modal: 'edit' } } ]">Edit</button>
                </div>
              </div>
              <!-- <div class="dropdown">
                                <button style="z-index:0;" class="btn wre-btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span>Action</span> <span class="caret"></span>
                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" routerLinkActive #rla="routerLinkActive" [routerLink]="[userType + '/' + item.mail, {outlets: { sidebar: 'details' } } ]">
                    Details
                  </a>
                                    <a class="dropdown-item" routerLinkActive *ngIf="hasEditPermission" [routerLink]="[userType + '/' + item.mail, {outlets: { modal: 'edit' } } ]">
                    Edit
                  </a>
                                </div>
                            </div> -->
            </td>
            <td class="text-nowrap col-ellipsis column-users">
              <ngb-highlight [result]="item.surname" [term]="searchTerm"></ngb-highlight>
            </td>
            <td class="text-nowrap col-ellipsis column-name">
              <ngb-highlight [result]="item.mail" [term]="searchTerm"></ngb-highlight>
            </td>
            <td class="text-nowrap col-ellipsis column-domain text-center">
              <span *ngIf="item.appCount < 10">0</span>{{ item.appCount }}
            </td>
            <td class="text-nowrap col-ellipsis column-domain text-center">
              <span *ngIf="item.roleCount < 10">0</span>{{ item.roleCount }}
            </td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
  <div *ngIf="isNoSearchResult">
    <div class="no-results">
      No items were found based on your search criteria. Please try again.
    </div>
  </div>
</div>
<div class="mt-3" *ngIf="!isNoSearchResult">
  <wre-toolkit-pagination [isLoading]="isLoading" [pageSize]="principalsListService.pageSize"
    (pageSizeChange)="principalsListService.pageSize=$event" [page]="principalsListService.page"
    (pageChange)="principalsListService.page=$event" [maxSize]="5" [boundaryLinks]="true" [total$]="total$">
  </wre-toolkit-pagination>
</div>
