import { Component, Input, OnInit } from '@angular/core';
import { SlipcaseNewsItem } from '../../models/slipcase-news-item';
import { RecentlyViewedItemRequest, UserRecentlyViewedService } from 'wre-core-lib';
import { SlipcaseNewsService } from '../../services/slipcase-news-service';
import moment from 'moment';

@Component({
  selector: 'app-slipcase-item-content',
  templateUrl: './slipcase-item-content.component.html',
  styleUrls: ['./slipcase-item-content.component.css', './slipcase-item-content.component.sass']
})
export class SlipcaseItemContentComponent implements OnInit {

  @Input() newsItem: SlipcaseNewsItem;
  @Input() isCompact = false;
  @Input() showPublishDate = true;
  @Input() showCategory = true;
  @Input() showItemHostname = true;
  @Input() showTopics = true;

  @Input() isMostPopular = false;

  hostnameUrl: string;

  constructor(
    private recentlyViewedService: UserRecentlyViewedService,
    private slipcaseNewsService: SlipcaseNewsService
  ) { }

  ngOnInit() {
    if (this.newsItem.external_url !== undefined) {
      this.hostnameUrl = new URL(this.newsItem.external_url).hostname;
    }
  }

  newsItemClicked(event: MouseEvent) {
    if (event.button === 0 || event.button === 1) {
      this.recentlyViewedService.createRecentlyViewedItem(
        new RecentlyViewedItemRequest('News', 'Slipcase',
          this.newsItem.web_url, this.newsItem.heading, new Date(Date.now()))
      ).subscribe();

      this.slipcaseNewsService.recordNewsVisit(this.newsItem).subscribe();
    }
  }

  getDate(date: Date) {
    return moment(date);
  }

 

}
