export enum RefreshDataType {
  GatewayWorkspaces,
  GatewayTools,
  GatewaySecurityGroups,
  GatewayInlineTraining,
  ReportingCategories,
  ReportingReports
}

export enum TabTitles {
  Workspaces = 'workspaces',
  Tools = 'tools',
  SecurityGroups = 'security groups',
  InlineTraining = 'inline training',
  Reports = 'reports',
  Categories = 'categories',
  FieldManagement = 'fields',
  OverviewAccess = 'users',
  Database = 'database'
}
