import moment from 'moment';
import { DateRange } from './date-range';

export enum RoleFilterProperty {
  OnlyActive,
  OnlyAuthorised,
  DomainInternal,
  DomainExternal,
  CreatedDate,
  LastUpdateDate
}

export class RoleFilterItem {
  property: RoleFilterProperty;
  label: string;

  constructor(property: RoleFilterProperty, dateRange?: DateRange) {
    this.property = property;

    switch (property) {
      case RoleFilterProperty.OnlyActive:
        this.label = 'Active';
        return;

      case RoleFilterProperty.OnlyAuthorised:
        this.label = 'Authorised to manage only';
        return;

      case RoleFilterProperty.DomainInternal:
        this.label = 'INT';
        return;

      case RoleFilterProperty.DomainExternal:
        this.label = 'EXT';
        return;

      case RoleFilterProperty.CreatedDate:
        this.label = `Created between ${moment(dateRange.begin).format('DD/MMM/YYYY')} and ${moment(dateRange.end).format('DD/MMM/YYYY')}`;
        return;

      case RoleFilterProperty.LastUpdateDate:
        this.label = `Last updated between ${moment(dateRange.begin).format('DD/MMM/YYYY')} and ${moment(dateRange.end).format('DD/MMM/YYYY')}`;
        return;
    }
  }
}
