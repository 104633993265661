<wre-toolkit-loader *ngIf="loading$ | async"></wre-toolkit-loader>

<div *ngIf="!(loading$ | async)">
  <div *ngIf="displayError" class="p-3">
    Failed to retrieve tools.
  </div>

  <div *ngIf="!displayError" #tableContainer class="table-responsive apps-table">
    <table class="table wre-table fixed-column mb-0">
      <thead>
        <tr>
          <th class="fixed-column action">&nbsp;</th>
          <th class="active text-center">Active</th>
          <th class="order text-center">Order No.</th>
          <th class="new text-center">New Tool</th>
          <th class="new-tab text-center">Open in new tab</th>
          <th class="is-tool text-center">Is Tool</th>
          <th class="off-vpn text-center">Available Off Vpn</th>
          <th class="workspace">Workspace</th>
          <th class="name">Name</th>
          <th class="url">Url</th>
          <th class="security-group">Security Roles</th>
        </tr>
      </thead>
      <tbody class="mb-0 pb-0">
        <tr *ngFor="let app of apps">
          <td class="fixed-column action">

            <div ngbDropdown container="body">
              <button class="btn btn-secondary" ngbDropdownToggle>Action</button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem (click)="onEdit(app)" *ngIf="hasEditPermission">Edit</button>
                <button ngbDropdownItem (click)="onEnableDisableApp(app)" *ngIf="hasEditPermission">{{app.isActive ? 'Disable' : 'Enable' }}</button>
                <button ngbDropdownItem (click)="onDelete(app)" *ngIf="hasDeletePermission" class="delete">Delete</button>
              </div>
            </div>

            <!-- <div class="dropdown">
              <button
                  style="z-index:0;"
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
              >
                <span>Action</span> <span class="caret"></span>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="onEdit(app)" *ngIf="hasEditPermission">Edit</a>
                <a class="dropdown-item" (click)="onEnableDisableApp(app)" *ngIf="hasEditPermission">
                  {{app.isActive ? 'Disable' : 'Enable' }}
                </a>
                <a class="dropdown-item" (click)="onDelete(app)" *ngIf="hasDeletePermission">Delete</a>
              </div>
            </div> -->
          </td>
          <td class="active text-center">
            <i class="fa fa-check wre-gateway-web-apps-color-icon-fa-check" aria-hidden="true" *ngIf="app.isActive"></i>
            <i class="fa fa-times wre-gateway-web-apps-color-icon-fa-times" aria-hidden="true" *ngIf="!app.isActive"></i>
          </td>
          <td class="order text-center">{{ app.orderNo }}</td>
          <td class="new text-center">
            <i class="fa fa-check wre-gateway-web-apps-color-icon-fa-check" aria-hidden="true" *ngIf="app.isNew"></i>
            <i class="fa fa-times wre-gateway-web-apps-color-icon-fa-times" aria-hidden="true" *ngIf="!app.isNew"></i>
          </td>
          <td class="new-tab text-center">
            <i class="fa fa-check wre-gateway-web-apps-color-icon-fa-check" aria-hidden="true" *ngIf="app.openInNewTab"></i>
            <i class="fa fa-times wre-gateway-web-apps-color-icon-fa-times" aria-hidden="true" *ngIf="!app.openInNewTab"></i>
          </td>
          <td class="is-tool text-center">
            <i class="fa fa-check wre-gateway-web-apps-color-icon-fa-check" aria-hidden="true" *ngIf="app.isTool"></i>
            <i class="fa fa-times wre-gateway-web-apps-color-icon-fa-times" aria-hidden="true" *ngIf="!app.isTool"></i>
          </td>
          <td class="off-vpn text-center">
            <i class="fa fa-check wre-gateway-web-apps-color-icon-fa-check" aria-hidden="true" *ngIf="app.isAvailableOffVpn"></i>
            <i class="fa fa-times wre-gateway-web-apps-color-icon-fa-times" aria-hidden="true" *ngIf="!app.isAvailableOffVpn"></i>
          </td>
          <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="workspace">{{ app.category?.name }}</td>
          <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="name">{{ app.name }}</td>
          <td class="url"><a target="_blank" rel="noopener" title="{{app.url}}" href="{{app.url}}">Link</a></td>
          <td wreToolkitTitleWhenTruncated ngbTooltip container="body" class="security-group">
            <span *ngFor="let role of app.securityRoles; let first = first;">
              <span [ngClass]= "{'ms-2 ps-2 border-start': !first}">
                {{ role.name }}
              </span>
            </span>
        </tr>
      </tbody>
    </table>
  </div>
</div>
