import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanctionsDatabaseComponent } from './sanctions-database/sanctions-database.component';
import { FileUploadComponent } from './sanctions-database/file-upload/file-upload.component';
import { WreToolkitLibModule } from 'wre-toolkit-lib';



@NgModule({
  declarations: [
    SanctionsDatabaseComponent,
    FileUploadComponent
  ],
  imports: [
    CommonModule,
    WreToolkitLibModule
  ],
  exports: [
    SanctionsDatabaseComponent
  ]
})
export class SanctionsAdminModule { }
