<div class="card-header">
    <h6 class="card-title me-auto">Roles</h6>
    <div class="card-options d-flex align-items-center">
        <div class="d-flex">
            <div class="clickable-icon" *ngIf="hasRolePermission">
                <button tourAnchor="newRole.anchor" [routerLink]="[ {outlets: { modal: 'new' } } ]" class="btn wre-btn btn-primary" type="button" [disabled]="!hasRolePermission">
          <i class="far fa-plus me-1 me-md-2"></i>Add
        </button>
            </div>

            <div #filterButton class="clickable-icon collapsed border-start border-end me-3 ms-3" data-bs-toggle="collapse" data-bs-target="#filters-container" aria-expanded="false">
                <button class="btn btn-icon btn-filter">
          <i class="far fa-filter"></i>
        </button>
            </div>

            <div class="input-group prepend">
                <div class="d-flex">
                    <label for="rolesSearchText"></label>
                    <input tourAnchor="rolesSearch.anchor" [(ngModel)]="rolesService.searchTerm" [disabled]="(rolesService.roles$ | async)?.length === 0 && !rolesService.isSearchMode" class="form-control" id="rolesSearchText" maxlength="50" name="searchTerm" autocomplete="off"
                        placeholder="Search...">
                    <span class="input-group-text" id="basic-addon2"><i class="far fa-search"></i></span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="border-bottom collapse" id="filters-container">
    <div class="filters">
        <wre-roles-filter-container #filtersContainer (collapseFilterPane)="filterButton.click()">
        </wre-roles-filter-container>
    </div>
</div>

<div *ngIf="filtersContainer.selectedFilters.length > 0" id="filters-selected" class="border-bottom">
    <button *ngFor="let filterItem of filtersContainer.selectedFilters" (click)="filtersContainer.removeFilter(filterItem)" class="badge btn btn-success me-2" type="button">
        <span class="pe-2">×</span>
    <span>{{ filterItem.label }}</span>
  </button>
</div>

<div class="card-body">
    <div class="row">
        <div class="col-12">
            <ng-container *ngIf="(loading$ | async); then loadingBlock else tableBlock"></ng-container>

            <ng-template #loadingBlock>
                <div class="d-flex justify-content-center">
                    <div class="d-flex align-items-center" style="height: 70px;">
                        <div class="loading"></div>
                    </div>
                </div>
            </ng-template>

            <ng-template #tableBlock>
                <ng-container *ngIf="(error$ | async); then errorBlock else resultBlock"></ng-container>
                <ng-template #errorBlock>
                    <div class="no-results">
                        Failed to retrieve the roles.
                    </div>
                </ng-template>

                <ng-template #resultBlock>
                    <ng-container *ngIf="(rolesService.roles$ | async)?.length === 0; then noResult else rolesTableBlock">
                    </ng-container>

                    <ng-template #noResult>
                        <ng-container *ngIf="rolesService.isSearchMode; then noSearchResult else noRecordResult"></ng-container>

                        <ng-template #noSearchResult>
                            <div class="no-results">
                                No items were found based on your search criteria. Please try again.
                            </div>
                        </ng-template>

                        <ng-template #noRecordResult>
                            <div class="no-results">
                                No records to display
                            </div>
                        </ng-template>
                    </ng-template>


                    <ng-template #rolesTableBlock>
                        <wre-roles-table></wre-roles-table>
                        <wre-access-pagination [total]="rolesService.total$ | async" [(pageSize)]="rolesService.pageSize" [(page)]="rolesService.page"></wre-access-pagination>
                    </ng-template>

                </ng-template>
            </ng-template>
        </div>
    </div>
</div>
<router-outlet name="sidebar"></router-outlet>
<router-outlet name="modal"></router-outlet>
