import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListService } from 'src/app/services/base-list.service';
import { UserGroup } from '../models/user-group';
import { UserType } from '../models/user-type';
import { SecurityManagementService } from './security-management.service';

@Injectable({
    providedIn: 'root'
})

export class UsersListService extends BaseListService<UserGroup> {

    userType = UserType.users;

    constructor(private securityService: SecurityManagementService) {
        super();
    }

    matches(item: UserGroup, term: string) {
        return item.mail?.toLowerCase().includes(term.toLowerCase())
            || item.displayName.toLowerCase().includes(term.toLowerCase());
    }

    async getAllItems(): Promise<UserGroup[] | HttpResponse<UserGroup[]>> {
        const users = await this.securityService.getUsers();
        if (this.userType === UserType.users) {
            return users.body.filter(f => f.type !== UserType.adGroup && f.type !== UserType.servicePrinciple);
        } else {
            return users.body.filter(f => f.type === UserType.adGroup);
        }
    }

    async load() {
        this.loadData(null,
            { sortColumn: 'displayName', sortDirection: 'asc', pageSize: this.pageSize });
    }

}

