import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityWorkspaceService } from '../../../services/security-workspace.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { SecurityWorkspace } from '../../../models/security-workspace';

@Component({
  selector: 'wre-access-tab-workspace-wrapper',
  templateUrl: './access-tab-workspace-wrapper.component.html',
})
export class AccessTabWorkspaceWrapperComponent implements OnInit, OnDestroy {

  combinedSubscription: Subscription;
  sharedRolesWithPermissionsSubscription = new Subscription();

  currentWorkspace$: Observable<SecurityWorkspace>;
  authorizedWorkspaces$: Observable<SecurityWorkspace[]>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private securityWorkspaceService: SecurityWorkspaceService) {
    this.currentWorkspace$ = this.securityWorkspaceService.currentWorkspace$;
    this.authorizedWorkspaces$ = this.securityWorkspaceService.authorizedWorkspaces$;
  }

  ngOnInit() {
    this.combinedSubscription = combineLatest(this.route.paramMap, this.authorizedWorkspaces$).subscribe(
      ([params, workspaces]) => {

        if (workspaces == null) { return; }

        if (workspaces.length === 0) {
          this.router.navigate(['admin', 'security']);
          return;
        }

        const currentWorkspace = params.get('workspace').toLocaleLowerCase();

        if (workspaces.map(w => w.url.toLocaleLowerCase()).includes(currentWorkspace)) {
          this.securityWorkspaceService.setWorkspace(currentWorkspace);
        } else if (workspaces.length !== 0) {
          this.router.navigate(['admin', 'security', 'access', workspaces[0].url]);
        } else {
          this.router.navigate(['unauthorizedaccess']);
        }
      }
    );
  }

  ngOnDestroy() {
    this.combinedSubscription.unsubscribe();
    this.sharedRolesWithPermissionsSubscription.unsubscribe();
  }

}
