import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RLSRole } from '../../../../models/admin';

@Component({
  selector: 'wre-reports-rls-roles-list',
  templateUrl: './reports-rls-roles-list.component.html',
  styleUrls: ['./reports-rls-roles-list.component.sass']
})
export class ReportsRlsRolesListComponent {

  @Input() rlsRolesForm: UntypedFormControl;
  @Input() rlsRoleWithAASDataSource: boolean;

  get rlsRoles(): RLSRole[] {
    return this.rlsRolesForm.value as RLSRole[];
  }

  deleteRole(idx: number) {
    this.rlsRoles.splice(idx, 1);
  }

}
