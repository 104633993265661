import { Injectable, OnDestroy } from '@angular/core';
import { PermissionGroup } from '../models/permission-group';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SecurityManagementService } from './security-management.service';
import { SecurityWorkspaceService } from './security-workspace.service';
import { SecurityWorkspace } from '../models/security-workspace';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionsListService implements OnDestroy {

  private responseBasePermissions: PermissionGroup[] = [];

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _basePermissions$ = new BehaviorSubject<PermissionGroup[]>([]);

  private currentWorkspace: SecurityWorkspace;
  private currentWorkspaceSubscription: Subscription;

  get loading$() { return this._loading$.asObservable(); }
  get basePermissions$() { return this._basePermissions$.asObservable(); }

  get basePermissions(): PermissionGroup[] {
    return this._basePermissions$.getValue();
  }

  constructor(private securityService: SecurityManagementService,
              private securityWorkspaceService: SecurityWorkspaceService) {
    this.currentWorkspaceSubscription = this.securityWorkspaceService.currentWorkspace$
      .pipe(filter(w => w != null)).subscribe((workspace: SecurityWorkspace) => {
        this.currentWorkspace = workspace;
        this.loadBasePermissions();
      });
  }

  ngOnDestroy(): void {
    if (this.currentWorkspaceSubscription) { this.currentWorkspaceSubscription.unsubscribe(); }
  }

  public loadBasePermissions() {
    this._loading$.next(true);
    this.securityService.getPermissionGroups(this.currentWorkspace)
      .then(data => {
        this.responseBasePermissions = data.body;
        this._basePermissions$.next(this.responseBasePermissions);
      })
      .catch(err => this._basePermissions$.error(err))
      .finally(() => this._loading$.next(false));
  }
}
