import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { WfmFrame, WorkFlowManagerService } from '../../../services/work-flow-manager.service';

@Component({
  selector: 'wre-wfm-frame',
  templateUrl: './wfm-frame.component.html',
  styleUrls: ['./wfm-frame.component.scss']
})
export class WfmFrameComponent implements OnInit {
  frame: WfmFrame;

  constructor(
    private route: ActivatedRoute,
    private wfmService: WorkFlowManagerService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = +params.get('wfmFrameId');
      this.frame = this.wfmService.getFrame(id);
    });
  }

}
