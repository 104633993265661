import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from '../../../services/http.service';
import { OptionsRequest, ReferralField } from '../models/referral-field';
import { retryWhen } from 'rxjs/operators';
import { ReferralType } from '../enums/referral-type';

@Injectable({
  providedIn: 'root'
})
export class ReferralFieldService extends HttpService {

  private srtUrl = environment.srtUrl;

  constructor(http: HttpClient) {
    super(http);
  }

  public loadFields(referralType: ReferralType): Observable<ReferralField[]> {
    const url = `${ this.srtUrl }admin/fields?referralType=${referralType}`;

    return this.http.get<ReferralField[]>(url).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  public getField(id: string): Observable<ReferralField> {
    const url = `${this.srtUrl}admin/fields/${id}`;

    return this.http.get<ReferralField>(url).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  public updateField(id: string, options: OptionsRequest) {
    const url = `${ this.srtUrl }admin/fields/${id}`;

    return this.http.put<any>(url, options).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }
}
