<div *ngIf="roles$ | async" class="wre-table-container">
  <div #tableContainer
       class="table-responsive"
  >
    <table class="table wre-table fixed-column table-hover">
      <thead class="text-center">
        <tr>
          <th class="text-nowrap column-action fixed-column">&nbsp;</th>
          <th class="text-nowrap col-system">&nbsp;</th>
          <th class="text-nowrap col-ellipsis column-name">Name</th>
          <th class="text-nowrap col-ellipsis column-active text-center">Active</th>
          <th class="text-nowrap col-ellipsis column-admins text-center">Admins</th>
          <th class="text-nowrap col-ellipsis column-users text-center">Users</th>
          <th class="text-nowrap col-ellipsis column-adgroups text-center">Ad Groups</th>
          <th class="text-nowrap col-ellipsis column-sps text-center">Service Principles</th>
          <th class="text-nowrap col-ellipsis column-permissions text-center">Permissions</th>
          <th class="text-nowrap col-ellipsis column-domain">Domain</th>
          <th class="text-nowrap col-ellipsis column-createddate">Created Date</th>
          <th class="text-nowrap col-ellipsis column-createdby">Created By</th>
          <th class="text-nowrap col-ellipsis column-editeddate">Last Update</th>
          <th class="text-nowrap col-ellipsis column-editedby">Updated By</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let role of (roles$ | async)">
          <td class="text-nowrap sticky-column column-action fixed-column">
            <div class="dropdown" ngbDropdown container="body">
              <button ngbDropdownToggle
                      class="btn wre-btn btn-secondary dropdown-toggle"
                      type="button"
                      [disabled]="isRoleDisabled(role)"
              >
                <span>Action</span> <span class="caret"></span>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button *ngIf="canViewRoleDetails" [routerLink]="[ {outlets: { sidebar: ['details' , role.id] } }]" class="dropdown-item">
                  Details
                </button>
                <button *ngIf="canEditRole && role.canEditRole" [routerLink]="[ {outlets: { modal: ['edit' , role.id] } }]" class="dropdown-item">
                  Edit
                </button>
                <button (click)="toggleRoleStatus(role)" *ngIf="canDisableRole && !role.isSystem" class="dropdown-item">{{ role.isActive ? 'Disable' : 'Enable' }}</button>
                <button (click)="openDeleteWindow(role)" *ngIf="canDeleteRole && !role.isSystem" class="dropdown-item delete">Delete</button>
              </div>
            </div>
          </td>
          <td class="text-nowrap col-system">
            <span *ngIf="role.isSystem" ngbTooltip="System Role" placement="right" [openDelay]="700">
              <i class="fas fa-desktop system-icon" ></i>
            </span>
          </td>
          <td class="text-nowrap col-ellipsis column-name">
            <div wreToolkitTitleWhenTruncated ngbTooltip container="body">
              <ngb-highlight [result]="role.name" [term]="rolesService.highlightTerm"></ngb-highlight>
            </div>
          </td>
          <td class="text-nowrap col-ellipsis column-active text-center">
            <i class="fa fa-check" aria-hidden="true" *ngIf="role.isActive"></i>
            <i class="fa fa-times" aria-hidden="true" *ngIf="!role.isActive"></i>
          </td>
          <td class="text-nowrap col-ellipsis column-admins text-center">{{ role.adminCount }}</td>
          <td class="text-nowrap col-ellipsis column-users text-center">{{ role.userCount }}</td>
          <td class="text-nowrap col-ellipsis column-adgroups text-center">{{ role.adGroupCount }}</td>
          <td class="text-nowrap col-ellipsis column-sps text-center">{{ role.servicePrincipalCount }}</td>
          <td class="text-nowrap col-ellipsis column-permissions text-center">{{ role.permissions?.length }}</td>
          <td class="text-nowrap col-ellipsis column-domain">
            <span *ngIf="role.domain.internal" class="badge bg-warning me-2">INT</span>
            <span *ngIf="role.domain.external" class="badge bg-secondary">EXT</span>
          </td>
          <td class="text-nowrap col-ellipsis column-createddate">{{ role.createdDate | date :'dd/MMM/yyyy hh:mm:ss a' }}</td>
          <td class="text-nowrap col-ellipsis column-createdby">
            <ngb-highlight [result]="role.createdBy" [term]="rolesService.highlightTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap col-ellipsis column-editeddate">{{ role.latestAudit ? (role.latestAudit.updatedDate + 'z' | date :'dd/MMM/yyyy hh:mm:ss a') : '&mdash;' }}</td>
          <td class="text-nowrap col-ellipsis column-editedby">
            <ngb-highlight *ngIf="role.latestAudit" [result]="role.latestAudit?.updatedBy" [term]="rolesService.highlightTerm"></ngb-highlight>
            <ng-container *ngIf="!role.latestAudit">&mdash;</ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
