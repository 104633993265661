import { HttpClient, HttpResponse } from '@angular/common/http';
import { delay, mergeMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { AccessMapping } from '../models/access-mapping';
import { OverviewAccess } from '../models/overview-access';

export abstract class ApiService<T> {

  protected constructor(private http: HttpClient) { }

  abstract getOverviewAccess(): Observable<OverviewAccess<T>>;

  abstract getAccessMapping(id: string): Observable<AccessMapping<T>>;

  abstract deleteAccessMapping(id: string): Observable<any>;

  abstract createAccessMapping(request: AccessMapping<T>): Observable<OverviewAccess<T>>;

  abstract updateAccessMapping(request: AccessMapping<T>): Observable<any>;

  protected handleRetry(errors: Observable<any>) {
    let retries = 2;
    return errors
      .pipe(mergeMap((error: HttpResponse<any>) =>
        // All 4xx status codes we do not retry
        ((error.status !== undefined && error.status.toString().indexOf('4') === 0)) || retries-- === 0 ?
          throwError(error) :
          of(error).pipe(delay(1000))));
  }
}
