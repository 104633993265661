
    <div class="row justify-content-center advocate-list-table-container mt-3">
        <div class="col-12">
            <div class="card">
                <div class="card-header" role="toolbar" aria-label="Toolbar Actions">
                    <h6 class="card-title">Client Advocate List</h6>
                    <span class="ms-3" [hidden]="this.isLoading">Last updated on {{listUpdated | date:'MMMM d, y, h:mm:ss a'}} </span>
                    <div class="ms-auto">
                        <div class="input-group prepend">
                            <input class="form-control" [disabled]="this.isLoading" id="advocateListSearchText" maxlength="50" name="advocateListSearchText" (input)="this.searchTerm = $event.target.value" (keyup)="this.subject.next(undefined)" type="text" placeholder="Search...">
                            <span class="input-group-text" id="basic-addon2"><i class="far fa-search"></i></span>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-3" style="min-height: 100px;">
                    <div style="">
                        For queries or update requests please contact <a href="mailto:laura_molloy@gallagherre.com?Subject=Client%20Advocacy%20Query" title="Email Laura Molloy">Laura Molloy</a>.
                    </div>
                    <div class="row justify-content-center pt-3" *ngIf="this.isLoading">
                        <div class="col-lg-12 col-md-12 pb-4">
                            <div style="min-height: 70px;" class="d-flex align-items-center justify-content-center">
                                <div class="loading"></div>
                            </div>
                        </div>
                    </div>

                    <div id="advocate-list-table" class="pt-3 mb-0" [hidden]="this.isLoading"></div>
                </div>
            </div>
        </div>
    </div>
