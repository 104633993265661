<wre-toolkit-modal-popup [title]="popupTitle" *ngIf="!this.isViewContent" (closeClicked)="confirmClose()" [preventDefaultClose]="true">

  <wre-toolkit-loader *ngIf="isLoaderVisible"></wre-toolkit-loader>

  <div *ngIf="!isLoaderVisible && error && tourStepForm.pristine" class="mb-4">
    <div class="alert alert-danger d-flex justify-content-between align-items-center" role="alert">
      <div class="me-2" style="white-space: pre-wrap">{{ error }}</div>
      <i class="far fa-times ms-2" role="button" (click)="clearError()"></i>
    </div>

  </div>

  <form *ngIf="!isLoaderVisible" id="stepForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12 col-sm-6 mb-3">
        <wre-toolkit-text-field class="w-100" labelText="App Name" inputId="appName" [control]="tourStepForm.get('appName')">
        </wre-toolkit-text-field>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('appName')" errorName="whitespace" message="Application name cannot be empty.">
        </wre-toolkit-validation-error-message>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('appName')" errorName="maxlength"
            message="Application name cannot exceed 200 characters.">
        </wre-toolkit-validation-error-message>
      </div>

      <div class="col-12 col-sm-6 mb-3">
        <wre-toolkit-text-field class="w-100" labelText="Route Name" inputId="routeName" [control]="tourStepForm.get('routeName')">
        </wre-toolkit-text-field>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('routeName')" errorName="required" message="Please enter route name.">
        </wre-toolkit-validation-error-message>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('routeName')" errorName="pattern"
            message="Route has to start with the slash (/) and cannot contain any spaces.">
        </wre-toolkit-validation-error-message>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('routeName')" errorName="maxlength"
            message="Route name cannot exceed 50 characters.">
        </wre-toolkit-validation-error-message>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 mb-3">
        <wre-toolkit-text-field class="w-100" labelText="Anchor Id" inputId="anchorId" [control]="tourStepForm.get('anchorId')">
        </wre-toolkit-text-field>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('anchorId')" errorName="required" message="Please enter anchor ID.">
        </wre-toolkit-validation-error-message>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('anchorId')" errorName="pattern"
            message="Anchor has to end with .anchor and cannot contain any spaces or special characters other than dash or underscore.">
        </wre-toolkit-validation-error-message>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('anchorId')" errorName="maxlength"
            message="Anchor cannot exceed 50 characters.">
        </wre-toolkit-validation-error-message>
      </div>

      <div class="col-12 col-sm-6 mb-3">
        <label for="isMobile" class="form-control-label">View Type</label>
        <select class="form-control w-100" id="isMobile" [formControl]="tourStepForm.get('isMobile')">
          <option [ngValue]="null" disabled selected hidden>Select View Type</option>
          <option *ngFor="let view of viewType" [ngValue]="view.viewId">{{view.viewValue}}</option>
        </select>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('isMobile')"
            errorName="required" message="Please select view type.">
        </wre-toolkit-validation-error-message>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 mb-3">
        <wre-toolkit-text-field class="w-100" labelText="Title" inputId="title" [control]="tourStepForm.get('title')">
        </wre-toolkit-text-field>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('title')" errorName="pattern"
            message="Title cannot contain any special characters other than dash or underscore.">
        </wre-toolkit-validation-error-message>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('title')" errorName="whitespace" message="Title cannot be empty.">
        </wre-toolkit-validation-error-message>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('title')" errorName="maxlength" message="Anchor cannot exceed 75 characters.">
        </wre-toolkit-validation-error-message>
      </div>

      <div class="col-12 col-sm-6 mb-3">
        <wre-toolkit-number-field [min]="1" class="w-100" labelText="Order No" inputId="stepId" [control]="tourStepForm.get('stepId')">
        </wre-toolkit-number-field>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('stepId')" errorName="required" message="Please enter order number.">
        </wre-toolkit-validation-error-message>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <wre-toolkit-text-area class="w-100" labelText="Content" inputId="content"
                               [control]="tourStepForm.get('content')" [rows]="4"></wre-toolkit-text-area>
        <wre-toolkit-validation-error-message
            [control]="tourStepForm.get('content')" errorName="whitespace" message="Content cannot be empty.">
        </wre-toolkit-validation-error-message>
      </div>
    </div>

    <div class="row">
      <div class="col-auto">
        <wre-toolkit-toggle-switch name="isActive" labelText="Active" [control]="tourStepForm.get('isActive')">
        </wre-toolkit-toggle-switch>
      </div>
    </div>
  </form>

  <div footer class="w-100">
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <button type="submit" form="stepForm" class="btn btn-primary ms-3"
                [disabled]="isLoaderVisible || tourStepForm.pristine || tourStepForm.invalid">
          <span>{{ buttonAction }}</span>
        </button>
      </div>
    </div>
  </div>
</wre-toolkit-modal-popup>


<wre-toolkit-modal-popup [title]="viewContentTitle" *ngIf="this.isViewContent" [hideFooter]="true">
  {{ viewContentData }}
</wre-toolkit-modal-popup>
