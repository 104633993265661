<wre-toolkit-slideout [width]="1200" [isopened]="isOpen">
    <div class="slideout-header d-flex align-items-center">
        <h3 class="slideout-title">Role Details</h3>
        <div class="slideout-options ms-auto">
            <button type="button" class="btn btn-icon" aria-label="Close" id="sidebarCloseButton"
                (click)="closeSidebar()" tabindex="-1"><i class="fal fa-times"></i>
            </button>
        </div>
    </div>
    <div class="slideout-body">
      <wre-sidebar [roleId]="roleId"></wre-sidebar>
    </div>
</wre-toolkit-slideout>
