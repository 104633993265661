import { RolesDataService } from './roles-data.service';
import { RoleSummaryView } from '../../models/role-summary-view';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { DateRange } from '../../models/date-range';
import moment from 'moment';
import { RoleFilter } from '../../models/role-filter';
import { State } from '../../models/state';
import { SecurityWorkspaceService } from '../security-workspace.service';

export class SearchResult {
  roles: RoleSummaryView[];
  total: number;
}

const DEFAULT_PAGE_SIZE = 10;

@Injectable()
export abstract class RolesSearchService implements OnDestroy {

  private _search$ = new Subject<void>();

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _error$ = new BehaviorSubject<Error>(null);
  private _roles$ = new BehaviorSubject<RoleSummaryView[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _searchLoading$ = new BehaviorSubject<boolean>(true);

  private _state: State = new State(DEFAULT_PAGE_SIZE);
  private _defaultFilter$ = new BehaviorSubject<RoleFilter>(new RoleFilter());

  get loading$() { return this._loading$.asObservable(); }
  get error$() { return this._error$.asObservable(); }
  get roles$() { return this._roles$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get defaultFilter$() { return this._defaultFilter$.asObservable(); }

  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get filter() { return this._state.filter; }
  get highlightTerm() { return this.searchTerm.trim(); }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize: +pageSize }); }
  set searchTerm(searchTerm: string) {
    if (searchTerm.trim().length === 1) { return; }
    this._set({ page: 1, searchTerm });
  }
  set filter(filter: RoleFilter) { this._set({ page: 1, filter }); }

  get isSearchMode(): boolean {
    return this.searchTerm !== '' || !this._state.filter.equal(this._defaultFilter$.getValue());
  }

  private subscriptions: Subscription[] = [];

  protected constructor(protected rolesListService: RolesDataService,
                        protected securityWorkspaceService: SecurityWorkspaceService) {
    // Loading is set to true if the call to API is being made or the search is performed
    const loadingSub = combineLatest(this._searchLoading$, this.rolesListService.loading$).subscribe(
      ([searchLoading, rolesLoading]) => this._loading$.next(searchLoading || rolesLoading)
    );

    this._search$.pipe(
      debounceTime(400),
      tap(() => this._searchLoading$.next(true)),
      switchMap(() => this.search())
    ).subscribe(
      result => {
        this._roles$.next(result.roles);
        this._total$.next(result.total);
        this._error$.next(null);
        this._searchLoading$.next(false);
      },
      error => {
        this._error$.next(error);
        this._searchLoading$.next(false);
      }
    );

    const rolesSub = this.rolesListService.roles$.subscribe(
      _ => this._search$.next(),
      error => this._search$.error(error)
    );

    const workspaceSub = this.securityWorkspaceService.currentWorkspace$.subscribe(_ => {
      const canViewAllReadOnly = this.securityWorkspaceService.canViewAllReadOnly();
      const defaultFilter = new RoleFilter();
      defaultFilter.onlyAuthorised = !canViewAllReadOnly;
      this._defaultFilter$.next(defaultFilter);
      this.filter = this._defaultFilter$.getValue().copy();
    });

    this.subscriptions.push(loadingSub, rolesSub, workspaceSub);

  }

  static dateInRange(date: Date, dateRange: DateRange): boolean {
    if (date == null && (dateRange.begin != null || dateRange.end != null)) { return false; }

    return (date == null && dateRange.begin == null && dateRange.end == null) ||
      ((dateRange.begin == null || moment(moment(date).format('L')).isSameOrAfter(moment(dateRange.begin))) &&
        (dateRange.end == null || moment(moment(date).format('L')).isSameOrBefore(moment(dateRange.end))));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private search(): Observable<SearchResult> {
    // 1. Filter
    let roles = this.rolesListService.roles.filter(r => this.matchesSearchTerm(r));

    // 2. Filter
    roles = roles.filter(r => this.matchesFilter(r));

    const total = roles.length;

    // 3. Paginate
    roles = roles.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

    return of({ roles, total });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  public reload() {
    this.rolesListService.loadRoles();
  }

  public toggleRoleStatus(role: RoleSummaryView) {
    this.rolesListService.toggleRoleStatus(role);
  }

  public reset() {
    this._state = new State(DEFAULT_PAGE_SIZE);
    this.filter = this._defaultFilter$.getValue().copy();
  }

  private matchesFilter(role: RoleSummaryView): boolean {
    if (this.filter.onlyActive && !role.isActive) { return false; }
    if (this.filter.onlyAuthorised && !role.canEditRole) { return false; }

    if (!((!this.filter.domain.internal && !this.filter.domain.external) ||
          (this.filter.domain.internal && role.domain.internal) ||
          (this.filter.domain.external && role.domain.external)
    )) { return false; }

    if (!RolesSearchService.dateInRange(role.createdDate, this.filter.createdDate)) { return false; }

    return RolesSearchService.dateInRange(role.latestAudit?.updatedDate, this.filter.lastUpdateDate);
  }

  abstract matchesSearchTerm(role: RoleSummaryView): boolean;

}
