<wre-toolkit-modal-popup [title]="this.title" [preventDefaultClose]="true" (closeClicked)="confirmCloseModal()">
    <wre-error-message [loading]='loading' [errorMessage]='errorMessage' [errorMessageInfo]='errorMessageInfo'></wre-error-message>

    <form [formGroup]="userForm" *ngIf="!loading">
        <div class="row">
            <div class="col-md-4">
                <wre-toolkit-text-field class="w-100" inputId="firstName" labelText="First Name" [control]="userForm.get('firstName')"></wre-toolkit-text-field>
            </div>
            <div class="col-md-4">
                <wre-toolkit-text-field class="w-100" inputId="lastName" labelText="Last Name" [control]="userForm.get('lastName')"></wre-toolkit-text-field>
            </div>
            <div class="col-md-4">
                <wre-toolkit-text-field class="w-100" inputId="mail" labelText="Email" [control]="userForm.get('mail')">
                </wre-toolkit-text-field>
            </div>
        </div>
    </form>

    <div footer style="width: 100%;">
        <div class="row">
            <div class="col-sm-12 d-flex justify-content-end">

                <button type="button" [disabled]="loading || userForm.invalid || !isUserInfoChanged" class="btn wre-btn btn-primary ms-3" (click)="saveChanges()">
          Save Changes
        </button>
            </div>
        </div>
    </div>

</wre-toolkit-modal-popup>
