import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PlacementExcellenceComponent } from './placement-excellence/placement-excellence.component';
// tslint:disable-next-line: max-line-length
import { ClientOnboardingAndStatusDisclosureComponent } from './placement-excellence/cue-cards/client-onboarding-and-status-disclosure/client-onboarding-and-status-disclosure.component';
import { PlacementExcellenceHomeComponent } from './placement-excellence/home/placement-excellence-home.component';
import { CardComponent } from './placement-excellence/cue-cards/card/card.component';
import { ClientDueDiligenceComponent } from './placement-excellence/cue-cards/client-due-diligence/client-due-diligence.component';
// tslint:disable-next-line: max-line-length
import { MarketingProtocolsDataGatheringComponent } from './placement-excellence/cue-cards/marketing-protocols-data-gathering/marketing-protocols-data-gathering.component';
import { ObtainQuotationsComponent } from './placement-excellence/cue-cards/obtain-quotations/obtain-quotations.component';
// tslint:disable-next-line: max-line-length
import { ClientConfirmsFirmOrderComponent } from './placement-excellence/cue-cards/client-confirms-firm-order/client-confirms-firm-order.component';
import { PlacementStatusComponent } from './placement-excellence/cue-cards/placement-status/placement-status.component';
import { ContractDocumentationComponent } from './placement-excellence/cue-cards/contract-documentation/contract-documentation.component';
import { ClassicViewComponent } from './placement-excellence/cue-cards/classic-view/classic-view.component';
import { QuickReferenceComponent } from './placement-excellence/cue-cards/quick-reference/quick-reference.component';
// tslint:disable-next-line: max-line-length
import { LegalDocumentRevenueImpactComponent } from './placement-excellence/cue-cards/legal-document-revenue-impact/legal-document-revenue-impact.component';
import { GlossaryComponent } from './placement-excellence/cue-cards/glossary/glossary.component';
// tslint:disable-next-line: max-line-length
import { PlacementExcellenceContentCardComponent } from './placement-excellence/cue-cards/placement-excellence-content-card/placement-excellence-content-card.component';
import { EfillingGuidanceComponent } from './placement-excellence/cue-cards/e-filling-guidance/e-filling-guidance.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { ReceivesQuotationsComponent } from './placement-excellence/cue-cards/receives-quotations/receives-quotations.component';
import { CoreStepsComponent } from './placement-excellence/core-steps/core-steps.component';

const routes: Routes = [
  {
    path: '', component: PlacementExcellenceComponent,
    children: [
      {
        path: '', pathMatch: 'full', redirectTo: 'home'
      },
      {
        path: 'home',
        component: PlacementExcellenceHomeComponent,
      },
      {
        path: 'client-onboarding-and-status-disclosure',
        component: ClientOnboardingAndStatusDisclosureComponent,
      },
      {
        path: 'client-due-diligence',
        component: ClientDueDiligenceComponent,
      },
      {
        path: 'marketing-approval-and-data-gathering',
        component: MarketingProtocolsDataGatheringComponent,
      },
      {
        path: 'obtain-quotations-from-reinsurers',
        component: ObtainQuotationsComponent,
      },
      {
        path: 'client-receives-quotation',
        component: ReceivesQuotationsComponent,
      },
      {
        path: 'client-confirms-firm-order',
        component: ClientConfirmsFirmOrderComponent,
      },
      {
        path: 'placement-status-update-and-completion',
        component: PlacementStatusComponent,
      },
      {
        path: 'client-receives-contract-documentation',
        component: ContractDocumentationComponent,
      },
      {
        path: 'glossary',
        component: GlossaryComponent,
      },
      {
        path: 'legal-document-revenue-impact',
        component: LegalDocumentRevenueImpactComponent,
      },
      {
        path: 'classic-view',
        component: ClassicViewComponent,
      },
      {
        path: 'quick-reference',
        component: QuickReferenceComponent,
      },
      {
        path: 'e-filling-guidance',
        component: EfillingGuidanceComponent,
      }
    ]
  },
];


@NgModule({
  declarations: [
    PlacementExcellenceComponent,
    ClientOnboardingAndStatusDisclosureComponent,
    PlacementExcellenceHomeComponent,
    CardComponent,
    ClientDueDiligenceComponent,
    MarketingProtocolsDataGatheringComponent,
    ObtainQuotationsComponent,
    ReceivesQuotationsComponent,
    ClientConfirmsFirmOrderComponent,
    PlacementStatusComponent,
    ContractDocumentationComponent,
    ClassicViewComponent,
    QuickReferenceComponent,
    LegalDocumentRevenueImpactComponent,
    GlossaryComponent,
    PlacementExcellenceContentCardComponent,
    EfillingGuidanceComponent,
    CoreStepsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TourMatMenuModule,
  ],
  exports: [RouterModule]
})
export class PlacementExellenceModelModule { }
