import { Component, OnInit } from '@angular/core';
import items1 from '../../../../../assets/json/placement-excellence/client-onboarding-and-status-disclosure.json';
import items2 from '../../../../../assets/json/placement-excellence/client-due-diligence.json';
import items3 from '../../../../../assets/json/placement-excellence/marketing-protocols-data-gathering.json';
import items4 from '../../../../../assets/json/placement-excellence/obtain-quotations.json';
import items5 from '../../../../../assets/json/placement-excellence/receives-quotations.json';
import items6 from '../../../../../assets/json/placement-excellence/client-confirms-firm-order.json';
import items7 from '../../../../../assets/json/placement-excellence/placement-status.json';
import items8 from '../../../../../assets/json/placement-excellence/contract-documentation.json';

@Component({
  selector: 'app-classic-view',
  templateUrl: './classic-view.component.html',
  styleUrls: ['./classic-view.component.css']
})
export class ClassicViewComponent implements OnInit {

  cueCards = [items1, items2, items3, items4, items5, items6, items7, items8];

  constructor () { }

  ngOnInit() {

  }

}
