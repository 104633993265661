import { Component, Inject, OnInit } from '@angular/core';
import { SlipcaseNewsItem } from 'src/app/models/slipcase-news-item';
import { SlipcaseNewsService } from 'src/app/services/slipcase-news-service';
import { AppInsightsService } from 'wre-core-lib';
import { AuthorisationService } from 'wre-authlib';
import { ProtectedBaseComponent } from 'src/app/components/shared/protected-base/protected-base.component';
import { RolesConfig } from 'src/app/models/roles-config';

@Component({
  selector: 'wre-most-viewed-news',
  templateUrl: './most-viewed-news.component.html',
  styleUrls: ['./most-viewed-news.component.scss']
})
export class MostViewedNewsComponent extends ProtectedBaseComponent implements OnInit {

  currentOffset = 0;
  mostPopularData: SlipcaseNewsItem[] = [];
  mostPopularLoading = true;
  mostPopularSelectedFilter = '1 Month';
  mostPopularFilterItems = [
    {
      'name': '1 Month',
      'months': 1
    },
    {
      'name': '3 Months',
      'months': 3
    },
    {
      'name': '6 Months',
      'months': 6
    }
  ];

  errorOccured: boolean;


  constructor(
    rolesConfig: RolesConfig,
    private appInsightsService: AppInsightsService,
    private slipcaseNewsService: SlipcaseNewsService,
    protected authService: AuthorisationService) {

    // Security
    super(authService);
    this.setRoles(rolesConfig.slipcaseFeed);
    // ----

    this.appInsightsService.componentName = 'MostViewedNewsComponent';
  }
  async ngOnInit() {
    if (await this.checkRoles() === true) {
      this.getMostPopularNewsItems(10, 1);
    }
  }

  applyMostPopularFilter(filter) {
    this.errorOccured = false;
    this.mostPopularLoading = true;
    this.mostPopularSelectedFilter = filter.name;
    this.getMostPopularNewsItems(10, filter.months);
  }

  private getMostPopularNewsItems(numberOfItems = 10, months) {
    this.slipcaseNewsService.getMostPopularNewsItems(numberOfItems, months).subscribe(
      newsItems => {
        this.mostPopularData.length = 0;
        this.handleNewsItems(newsItems.body.map(x => JSON.parse(x.data)), this.mostPopularData);
        this.mostPopularLoading = false;
      },
      ex => {
        this.appInsightsService.trackException(ex);
        this.errorOccured = true;
        this.mostPopularLoading = false;
      });
  }


  private handleNewsItems(newsItems, newsData) {
    newsItems.forEach(element => {
      if (element.featured_image_url !== undefined) {
        const lastindex = element.featured_image_url.lastIndexOf('/');
        element.featured_image_url = element.featured_image_url.slice(0, lastindex) + '/resize_626x500';
      }
      newsData.push(element);
    });
    this.currentOffset += newsItems.length;
  }

}
