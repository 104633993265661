<div tabindex="-1" style="display: block;" aria-modal="true">
	<div class="modal-content">
		<div class="modal-header d-flex align-items-center">
			<h5 class="modal-title"> Edit Field Options </h5>
			<button type="button" class="btn btn-icon" data-bs-dismiss="modal" aria-label="Close" (click)="confirmClose()"
				tabindex="-1"><i class="fal fa-times"></i></button>
		</div>
		<div class="modal-body wre-modal-body">
			<div class="d-flex align-items-center justify-content-center">
				<div class="loading" *ngIf="loading"></div>
			</div>
			<div class="row" *ngIf="!loading">
				<div class="form-group col-12 w-100 mb-0">
					<label class="form-control-label">User Lookup</label>
					<wre-user-lookup-multiselect class="w-100" inputId="reviewers"
						inputName="reviewers" allowOnlyInternalUsers="true"
						[control]="form.get('reviewers')"
					  [useFriendlyDisplayName]="true"
						[isInvalid]="form.get('reviewers').errors?.noReviewersProvided"
						[distributionListsIncluded]="false" placeholderValue="Search user...">
					</wre-user-lookup-multiselect>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<div footer>
				<div class="row">
					<div class="col d-flex justify-content-end">
						<button type="button" class="btn wre-btn btn-secondary" data-bs-dismiss="modal"
							(click)="confirmClose()">Cancel</button>
						<button type="button" class="btn wre-btn btn-primary ms-3" data-bs-dismiss="modal" (click)="saveChanges()" [disabled]="isDisabled">
							Save Changes </button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
