import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ExternalUser } from '../../models/external-user';
import { UserGroup } from '../../models/user-group';
import { SecurityWorkspaceService } from '../../services/security-workspace.service';
import { UserType } from '../../models/user-type';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserModalComponent } from '../../components/users-tab/user-modal-wrapper/user-modal/user-modal.component';
import { ServicePrincipleModalComponent } from '../../components/users-tab/user-modal-wrapper/service-principle-modal/service-principle-modal.component';
import { Subscription } from 'rxjs';
import { UserTypeFlag } from 'wre-authlib';
import { ApplicationUserTypes } from '../../models/applicationUserTypes.enum';

@Component({
  selector: 'wre-role-users-step',
  templateUrl: './role-users-step.component.html',
  styleUrls: ['./role-users-step.component.sass']
})
export class RoleUsersStepComponent implements OnDestroy {

  @Input() form: UntypedFormGroup = null;
  @Input() userCount: number;
  @Input() adGroupCount: number;
  @Input() servicePrincipleCount: number;
  @Input() hasMemberPermissions = false;

  userModalRef: NgbModalRef;
  createdUser = new UserGroup();
  externalUserDetails = new ExternalUser();
  selectedType: UntypedFormControl = new UntypedFormControl(UserTypeFlag.InternalUsers | UserTypeFlag.AdGroups);
  errorMessage: string = null;
  permissions: string[] = [];
  availableUserTypes = [
    { id: UserType.internalUser.replace(/\s/g, ''), displayName: UserType.internalUserGroup, value: UserTypeFlag.InternalUsers | UserTypeFlag.AdGroups, hide: false },
    { id: UserType.externalUser.replace(/\s/g, ''), displayName: UserType.externalUser, value: UserTypeFlag.ExternalUsers, hide: this.isInternalApplication },
    { id: UserType.servicePrinciple.replace(/\s/g, ''), displayName: UserType.servicePrinciple, value: UserTypeFlag.ServicePrinciples, hide: false },
  ];

  get placeholderText(): string { return 'Search for ' + this.availableUserTypes.find(v => v.value === this.selectedType.value).displayName; }
  get noSearchResultText(): string {
    switch(this.selectedType.value) {
      case UserTypeFlag.InternalUsers | UserTypeFlag.AdGroups:
        return 'No users/groups found';
      case UserTypeFlag.ExternalUsers:
        return 'No external users found';
      case UserTypeFlag.ServicePrinciples:
        return 'No service principles found';
      default:
        return 'No users found';
    }
  }

  subscription: Subscription;

  get control() { return this.form.get('users'); }
  get isExternalUser(): boolean { return this.selectedType?.value === UserTypeFlag.ExternalUsers; }
  get canAddNew(): boolean {
    return this.hasMemberPermissions && (this.selectedType?.value === UserTypeFlag.ExternalUsers || this.selectedType?.value === UserTypeFlag.ServicePrinciples);
  }
  get isInternalApplication() {
    return this.securityWorkspaceService.currentWorkspace.applicationUserTypeId === ApplicationUserTypes.InternalUsers;
  }

  constructor(private modalService: NgbModal, private securityWorkspaceService: SecurityWorkspaceService) { }

  add() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true
    };

    switch (this.selectedType.value) {
      case UserTypeFlag.ExternalUsers:
        this.userModalRef = this.modalService.open(UserModalComponent, modalOptions);
        break;
      case UserTypeFlag.ServicePrinciples:
        this.userModalRef = this.modalService.open(ServicePrincipleModalComponent, modalOptions);
        break;
      default:
        throw new Error("Not Implemented");
    }
    this.initialiseUserAddedSubscription();
  }

  ngOnDestroy() { this.subscription?.unsubscribe(); }

  private initialiseUserAddedSubscription() {
    this.subscription = this.userModalRef.componentInstance.userAddedEvent.subscribe(user => {
      this.createdUser = new UserGroup();
      this.createdUser = Object.assign(this.createdUser, user);

      const currentValue = this.control.value !== undefined ? this.control.value : [];
      const isNewUser = currentValue.filter(v => v.type !== UserType.externalUser && v.mail === this.createdUser.mail).length === 0;
      const isNewAdGroup = currentValue.filter(v => v.type === UserType.adGroup && v.displayName === this.createdUser.displayName).length === 0;
      const isNewServicePrinciple = currentValue.filter(v => v.type === UserType.servicePrinciple && v.displayName === this.createdUser.displayName).length === 0;

      if (isNewUser || isNewAdGroup || isNewServicePrinciple) {
        if (isNewServicePrinciple) { this.createdUser.displayName = this.createdUser.displayName.replace(',','') }
        currentValue.push(this.createdUser);
        this.control.setValue(currentValue);
        this.control.markAsDirty();
      }
    });
  }

}
