import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class OverviewValidators {

  static userLookupValidator = (): ValidatorFn => {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const val = control.value;
      const isValid =
        val !== undefined && val !== null &&
        val.name !== undefined && val.name !== null &&
        val.email !== undefined && val.email !== null;

      return isValid ? null : { 'invalidUser': true };
    };
  }

}
