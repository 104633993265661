import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retryWhen } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AreaData, Report } from '../../../models/admin';
import { ExcelModel } from '../../../models/reporting/excel-model';
import { environment } from '../../../../environments/environment';
import { HttpService } from '../../../services/http.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingService extends HttpService {
  private reportingApiUrl = environment.powerBiApiUri;

  constructor(http: HttpClient) {
    super(http);
  }

  getReports(): Promise<Report[]> {
    const url = `${ this.reportingApiUrl }reports`;
    const httpOptions = {
      params: new HttpParams().set('displayAll', String(true))
    };
    return this.http.get<Report[]>(url, httpOptions).pipe(retryWhen((errors) => this.handleRetry(errors))).toPromise();
  }

  addReport(report: Report): Promise<HttpResponse<any[]>> {
    const url = `${ this.reportingApiUrl }areas/${ report.areaId }/reports`;
    return this.http.post<any[]>(url, report, { observe: 'response' }).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    ).toPromise();
  }

  editReport(report: Report): Promise<HttpResponse<any[]>> {
    const url = `${ this.reportingApiUrl }reports/${ report.id }`;
    return this.http.put<any[]>(url, report, { observe: 'response' })
      .pipe(retryWhen((errors) => this.handleRetry(errors))).toPromise();
  }

  getPowerBiAreas(): Promise<AreaData[]> {
    const url = `${ this.reportingApiUrl }areas`;
    const httpOptions = {
      params: new HttpParams()
        .set('reportsInclude', String(false))
        .set('displayAll', String(true))
    };
    return this.http.get<any[]>(url, httpOptions)
      .pipe(retryWhen((errors) => this.handleRetry(errors))).toPromise();
  }

  getPowerBiModels(): Promise<ExcelModel[]> {
    const url = `${ this.reportingApiUrl }models`;
    const httpOptions = {
      params: new HttpParams()
        .set('reportsInclude', String(false))
        .set('displayAll', String(true))
    };
    return this.http.get<any[]>(url, httpOptions)
      .pipe(retryWhen((errors) => this.handleRetry(errors))).toPromise();
  }

  async addPowerBiAreas(areaData: any): Promise<Observable<any[]>> {
    const url = `${ this.reportingApiUrl }areas`;
    return this.http.post<any[]>(url, areaData)
      .pipe(retryWhen((errors) => this.handleRetry(errors)));
  }

  async editPowerBiAreas(areaData: any): Promise<Observable<any[]>> {
    const url = `${ this.reportingApiUrl }areas/${ areaData.id }`;
    return this.http.put<any[]>(url, areaData)
      .pipe(retryWhen((errors) => this.handleRetry(errors)));
  }

  async deletePowerBiAreas(areaId: number): Promise<Observable<HttpResponse<Object>>> {
    const url = `${ this.reportingApiUrl }areas/${ areaId }`;
    return this.http.delete(url, { observe: 'response' })
      .pipe(retryWhen((errors) => this.handleRetry(errors)));
  }

  async deletePowerBiReport(reportId: number): Promise<Observable<HttpResponse<Object>>> {
    const url = `${ this.reportingApiUrl }reports/${ reportId }`;
    return this.http.delete(url, { observe: 'response' })
      .pipe(retryWhen((errors) => this.handleRetry(errors)));
  }

  async getPowerBiAreasById(areaId: number): Promise<Observable<any>> {
    const url = `${ this.reportingApiUrl }areas/${ areaId }`;
    const httpOptions = {
      params: new HttpParams()
        .set('displayExtendedMetadata', String(true))
    };
    return this.http.get<any[]>(url, httpOptions)
      .pipe(retryWhen((errors) => this.handleRetry(errors)));
  }

  checkIfReportExists(reportName: string): Promise<boolean> {
    const url = `${ this.reportingApiUrl }reports/exists`;
    const httpOptions = {
      params: new HttpParams().set('reportName', reportName)
    };
    return this.http.get<boolean>(url, httpOptions).pipe(retryWhen(this.handleRetry)).toPromise();
  }

}
