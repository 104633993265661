import { LookupUser } from "src/app/models/lookup-user";

export class SafrType {
	id: string;
	options: SafrOption[];
}

export class SafrOption {
	type: string;
	subType: string;
	code: string;
}

export class Field {
	id: string;
	name: string;
	optionsCount: number;
	options: FieldOption[];

	lastUpdated: Date;
	lastUpdatedBy: string;
}

export class FieldOption {
	name: string;
	email: string;
	id?: string;
	active: boolean;

	constructor(name: string, email: string, id?: string, active: boolean = true) {
		this.name = name;
		this.email = email;
		this.active = active;
		if (id) {
			this.id = id;
		}
	}
}

export class FieldOptionsRequest {
	options: FieldOption[];

	constructor(options?: FieldOption[]) {
		this.options = options;
	}
}

export enum SubType {
  IntlSpecs = 'International-UK',
	UriEncodedIntlNSpecs = 'International-UK'
}

export enum FieldType {
	Reviewer = 'Reviewer',
	Team = 'Team',
	ReportingGroup = 'Reporting Group'
}
