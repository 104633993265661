import { Injectable } from '@angular/core';
import { RolesDataService } from './roles-data.service';
import { RoleSummaryView } from '../../models/role-summary-view';
import { RolesSearchService } from './roles-search.service';
import { SecurityWorkspaceService } from '../security-workspace.service';

@Injectable()
export class RolesMainListService extends RolesSearchService {

  constructor(protected rolesListService: RolesDataService,
              protected securityWorkspaceService: SecurityWorkspaceService) {
    super(rolesListService, securityWorkspaceService);
  }

  matchesSearchTerm(role: RoleSummaryView): boolean {
    const searchTerm = this.searchTerm.trim().toLocaleLowerCase();
    return role.name.toLocaleLowerCase().includes(searchTerm) ||
      role.createdBy.toLocaleLowerCase().includes(searchTerm) ||
      (role.latestAudit && role.latestAudit.updatedBy.toLocaleLowerCase().includes(searchTerm));
  }
}
