<div tabindex="-1" style="display: block;" aria-modal="true">
	<div class="modal-content">

		<div class="modal-header d-flex align-items-center">
			<h5 class="modal-title">Edit Field Options</h5>
			<button type="button" class="btn btn-icon" data-bs-dismiss="modal" aria-label="Close" (click)="confirmClose()" tabindex="-1">
				<i class="fal fa-times"></i>
			</button>
		</div>

		<div class="modal-body wre-modal-body">
			<wre-toolkit-loader *ngIf="loading"></wre-toolkit-loader>

			<div class="row" *ngIf="!loading">
				<div class="col">

					<wre-user-lookup *ngIf="isReviewer"
													 [control]="optionControl"
													 labelText="User Lookup"
													 inputId="reviewers-option" inputName="reviewers-option"
													 (userSelected)="addReviewerOption($event)"
													 [userTypes]="UserTypeFlag.InternalUsers">
					</wre-user-lookup>

					<ng-container *ngIf="!isReviewer">
						<div class="row">
							<div class="form-group col w-100 mb-0">
								<wre-toolkit-text-field inputId="srt-fieldName"
																				labelText="Field"
																				[control]="optionControl"
																				placeholder="Enter option...">
								</wre-toolkit-text-field>
							</div>

							<div class="col-auto">
								<div class="d-flex align-items-end h-100">
									<button class="btn btn-secondary"
													type="button"
													title="Add Option"
													(click)="addOption()">
													<i class="far fa-plus"></i>
									</button>
								</div>
							</div>
						</div>
					</ng-container>

				</div>
			</div>
			<div class="row" *ngIf="!loading && allOptions.length">
				<div class="col mt-3">
					<div class="drag-and-drop-container">
						<wre-options-drag-and-drop
								[existingOptions]="existingOptions"
								[allOptions]="allOptions"
								[isUserType]="isReviewer"
								[orderDisabled]="!isOrderEnabled">
						</wre-options-drag-and-drop>
					</div>
				</div>
			</div>

			<div class="row" *ngIf="!loading && !allOptions.length">
				<div class="col mt-3">
					No options provided
				</div>
			</div>

		</div>
		<div class="modal-footer">
			<div footer>
				<div class="row">
					<div class="col d-flex justify-content-end">
						<button type="button" class="btn wre-btn btn-secondary" data-bs-dismiss="modal" (click)="confirmClose()">
							Cancel
						</button>
						<button type="button" class="btn wre-btn btn-primary ms-3" data-bs-dismiss="modal"
										(click)="saveChanges()"
										[disabled]="isDisabled">
							Save Changes
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
