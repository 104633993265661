<div class="modal-header d-flex align-items-center">
  <h5 class="modal-title">
    {{ title }}
  </h5>
  <div>
    <button class="btn btn-icon" type="button" data-bs-dismiss="modal" aria-label="Close" (click)="confirmClose()" tabindex="-1">
      <i class="fal fa-times"></i>
    </button>
  </div>
</div>
<form novalidate #newAreaForm="ngForm" (ngSubmit)="onAdd(newAreaForm)">
  <div *ngIf="this.isloaderVisible">
    <div class="d-flex align-items-center justify-content-center" style="height: 70px;">
      <div class="loading"></div>
    </div>
  </div>
  <div class="modal-body" *ngIf="!this.isloaderVisible">

    <div *ngIf="isNoContentMessageVisible || errorMessage">
      <div role="alert" class="alert alert-danger">
        <i class="fas fa-exclamation pe-2"></i>
        <ng-container *ngIf="isNoContentMessageVisible; else errorMessageBlock">
          A Category with the name&nbsp;<span class="font-weight-medium">{{ areaName }}</span>&nbsp;already exists
          in the system.
        </ng-container>
        <ng-template #errorMessageBlock>{{ errorMessage }}</ng-template>
        <i class="far fa-times ms-auto pt-1" (click)="resetErrors()"></i>
      </div>
    </div>

    <div class="form-group">
      <div class="row">
        <div class="col-12">
          <label class="form-control-label" for="name">Name</label>
          <input type="text" class="form-control" id="name" name="name" style="width: 100%;" [(ngModel)]="newArea.name"
            required #name="ngModel"
            [ngClass]="{'is-invalid': ((name.touched || name.dirty) && !name.valid) || (!name.valid && newAreaForm.submitted) }">
          <span class="invalid-feedback">
            <span *ngIf="name.errors?.required">
              Please enter Category name.
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-12">
          <wre-toolkit-toggle-switch name="isActive" #isActive="ngModel" [(ngModel)]="newArea.isActive"
            labelText="Active">
          </wre-toolkit-toggle-switch>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-top">
    <button type="submit" class="btn btn-primary ms-3">
      <span>{{ buttonAction }}</span>
    </button>
  </div>
</form>
