import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retryWhen } from 'rxjs/operators';
import { ApiService } from '../../../overview-access/services/api.service';
import { SafrOverviewAccess } from '../models/safr-overview-access';
import { OverviewAccess } from '../../../overview-access/models/overview-access';
import { AccessMapping } from '../../../overview-access/models/access-mapping';
import { SafrOverviewUser } from '../models/safr-overview-user';
import { SafrAccessMapping } from '../models/safr-access-mapping';

@Injectable({
  providedIn: 'root'
})
export class SafrOverviewAccessApiService extends ApiService<SafrOverviewAccess> {

  private safrUrl = environment.safrApiUrl;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getOverviewAccess(): Observable<OverviewAccess<SafrOverviewAccess>> {
    const url = `${ this.safrUrl }admin/overview-access`;

    return this.httpClient.get<OverviewAccess<SafrOverviewAccess>>(url).pipe(
      map(overviewAccess => {
        overviewAccess.mappings.forEach(mapping => {
          mapping.user.displayName = (mapping.user as SafrOverviewUser).name;

          if ((mapping as unknown as SafrAccessMapping).lastUpdated != null) {
            mapping.lastUpdateDate =  new Date((mapping as unknown as SafrAccessMapping).lastUpdated);
          }
        });

        return overviewAccess;
      }),
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  getAccessMapping(userId: string): Observable<AccessMapping<SafrOverviewAccess>> {
    const url = `${ this.safrUrl }admin/overview-access/${ userId }`;

    return this.httpClient.get<SafrAccessMapping>(url).pipe(
      map(mapping => SafrAccessMapping.toAccessMapping(mapping)),
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  createAccessMapping(request: AccessMapping<SafrOverviewAccess>): Observable<OverviewAccess<SafrOverviewAccess>> {
    const url = `${ this.safrUrl }admin/overview-access`;

    const transformedRequest = SafrAccessMapping.toSafrAccessMapping(request);

    return this.httpClient.post<OverviewAccess<SafrOverviewAccess>>(url, transformedRequest).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  updateAccessMapping(request: AccessMapping<SafrOverviewAccess>): Observable<OverviewAccess<SafrOverviewAccess>> {
    const url = `${ this.safrUrl }admin/overview-access`;

    const transformedRequest = SafrAccessMapping.toSafrAccessMapping(request);

    return this.httpClient.put<OverviewAccess<SafrOverviewAccess>>(url, transformedRequest).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

  deleteAccessMapping(userId: string): Observable<boolean> {
    const url = `${ this.safrUrl }admin/overview-access/${ userId }`;

    return this.httpClient.delete<boolean>(url).pipe(
      retryWhen((errors) => this.handleRetry(errors))
    );
  }

}
