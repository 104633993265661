import { Component, AfterViewInit } from '@angular/core';
import items from '../../../../assets/json/placement-excellence-items.json';
import { UserRecentlyViewedService, RecentlyViewedItemRequest } from 'wre-core-lib';

@Component({
  selector: 'app-placement-excellence-home',
  templateUrl: './placement-excellence-home.component.html',
  styleUrls: ['./placement-excellence-home.component.css']
})

export class PlacementExcellenceHomeComponent implements AfterViewInit {

  itemCollection: object[] = items;

  isOpenCoreSteps = false;

  constructor (private recentlyViewedService: UserRecentlyViewedService) { }

  ngAfterViewInit() {
    this.integrateWithRecentlyViewed();
  }

  integrateWithRecentlyViewed() {
    const linksToRegister = document.getElementById('placement-excellence-home-container').getElementsByTagName('a');

    if (linksToRegister !== undefined && linksToRegister.length > 0) {
      for (let i = 0; i < linksToRegister.length; i++) {
        const title = linksToRegister[i].parentElement.parentElement.getElementsByClassName('card-header-title')[0].textContent;
        linksToRegister[i].addEventListener('mouseup', (event) => {
          if (event.button === 0 || event.button === 1) {
            this.recentlyViewedService.createRecentlyViewedItem(
              new RecentlyViewedItemRequest('RQM', 'Placement CEM',
                linksToRegister[i].href, title, new Date(Date.now()), false)
            ).subscribe();
          }
        });
      }
    }
  }
}
