<ng-container *ngIf="(!isDetailsView && permissions.length > 0) || (selectedPermissions.length > 0 && isDetailsView); else noUsers">
  <div class="wre-table-container w-100">
    <div #tableContainer class="table-responsive">
      <table class="table wre-table fixed-column table-hover p-0 m-0">
        <thead class="text-center px-0 pt-2 m-0 d-block">
          <tr class="t-header">
            <th class="text-nowrap col-ellipsis column-name">Name</th>
            <th class="text-nowrap col-ellipsis column-description">Description</th>
            <th class="text-nowrap col-ellipsis column-type">Type</th>
            <th class="text-nowrap col-ellipsis column-selected" *ngIf="!isDetailsView">Selected</th>
          </tr>
        </thead>
        <tbody class="p-0 m-0 table-height">
          <tr *ngFor="let permission of permissions ; last as isLast"
              class="tr-padding" [ngClass]="{'border-bottom': isLast, 'disabled': !isSelected(permission.id), 'hide': !isSelected(permission.id) && isDetailsView}"
          >
            <td class="text-nowrap col-ellipsis column-name">{{ permission.name }}</td>

            <td class="text-nowrap col-ellipsis column-description">
              {{ permission.description }}
            </td>
            <td class="text-nowrap col-ellipsis column-type">
              <span class="badge" [ngClass]="{
                'bg-success': permission.type === PermissionType.Security,
                'bg-info': permission.type !== PermissionType.Security,
                'not-selected': !isSelected(permission.id)}"
              >
                <i class="me-1 far" [ngClass]="{
                  'fa-lock': permission.type === PermissionType.Security,
                  'fa-mouse-pointer': permission.type !== PermissionType.Security}"></i>
                {{ permission.type === PermissionType.Security ? 'Security' : 'App' }}
              </span>
            </td>
            <td class="text-nowrap col-ellipsis column-selected text-center" *ngIf="!isDetailsView">
                <i class="far" [ngClass]=" {'fa-check': isSelected(permission.id), 'fa-times': !isSelected(permission.id)}"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<ng-template #noUsers>
  <div class="no-admin-text p-3">No permissions have been selected.</div>
</ng-template>
