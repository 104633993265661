import { Component } from '@angular/core';
import { FileUploadStatus } from '../../models/file-upload-status';
import { FileSelectService } from '../../services/file-select.service';

@Component({
  selector: 'wre-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.sass']
})
export class FileUploadComponent {

  get file(): File {
    return this.fileSelectService.file;
  }

  get status(): FileUploadStatus {
    return this.fileSelectService.status;
  }

  supportedExtensions: string[] = ['.xls', '.xlsx'];
  maxFileSize = 10485760; // 10 MB

  FileUploadStatus = FileUploadStatus;

  heading = 'Select your file to upload';

  constructor(private fileSelectService: FileSelectService) { }

  onFileSelected(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    const file = files.item(0);
    if (file.size > this.maxFileSize) {
      return;
    }
    this.heading = file.name;
    this.fileSelectService.selectFile(file);
  }

}
