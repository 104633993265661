export class PermissionGroup {​​​​​​​​
id: number;
name: string;
constructor(id?: number, name?: string) {​​​​​​​​
this.id = id;
this.name = name;
  }​​​​​​​​
}​​​​​​​​

export class Role {​​​​​​​​
roleId: number;
roleName: string;
permissionGroups: PermissionGroup[];
applicationRoleAdmins: [];

constructor(roleId?: number, roleName?: string, permissionGroups?: PermissionGroup[]) {​​​​​​​​
this.roleId = roleId;
this.roleName = roleName;
this.permissionGroups = permissionGroups;
  }​​​​​​​​
}​​​​​​​​

export class Application {​​​​​​​​
applicationId: number;
applicationName: string;
roles: Role[];

constructor(applicationId?: number, applicationName?: string, roles?: Role[]) {​​​​​​​​
this.applicationId = applicationId;
this.applicationName = applicationName;
this.roles = roles;
  }​​​​​​​​
}​​​​​​​​

export class User {
  userId: number;
  principleName: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  applications: Application[];

  constructor(userId?: number, principleName?: string, firstName?: string, lastName?: string, displayName?: string, email?: string, applications?: Application[]) {
    this.userId = userId;
    this.principleName = principleName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.displayName = displayName;
    this.email = email;
    this.applications = applications;
  }
}

