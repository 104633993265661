<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ userId ? 'Edit' : 'Add' }} User</h4>
  <button
    type="button"
    class="btn btn-icon"
    data-bs-dismiss="modal"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="confirmCloseModal()"
    tabindex="-1">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="loading; else modalBlock">
    <wre-toolkit-loader></wre-toolkit-loader>
  </ng-container>

  <ng-template #modalBlock>
    <div class="py-3" *ngIf="error">
      <div class="wre-form-error">
        <i class="fas fa-exclamation pe-2"></i> {{ error }}
        <i class="far fa-times ms-auto" (click)="error = null"></i>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-3 mb-lg-0">
        <wre-user-lookup
          class="w-100"
          inputId="userlookup"
          labelText="User Lookup"
          [control]="form.get('user')"
          [disabled]="form.get('user').disabled"
          [isInvalid]="form.get('user').errors?.invalidUser"
          [userTypes]="userTypes">
        </wre-user-lookup>
      </div>
      <div class="col-lg-3 mb-3 mb-lg-0">
        <wre-multiselect
          #teamsContainer
          inputTabIndex="1"
          inputId="teams"
          inputName="teams"
          labelText="Teams"
          placeholder="Please select"
          [control]="form.get('access').get('teams')"
          [isInvalid]="
            !form.get('access').get('teams').valid && !form.get('access').get('teams').pristine
          "
          [list]="teams">
        </wre-multiselect>
      </div>
      <div class="col-lg-3">
        <wre-multiselect
          #reportingGroupsContainer
          inputTabIndex="1"
          inputId="reportingGroups"
          inputName="reportingGroups"
          labelText="Reporting Groups"
          placeholder="Please select"
          [control]="form.get('access').get('reportingGroups')"
          [isInvalid]="
            !form.get('access').get('reportingGroups').valid &&
            !form.get('access').get('reportingGroups').pristine
          "
          [list]="reportingGroups">
        </wre-multiselect>
      </div>
    </div>

    <div class="selected-container-flex">
      <div class="teams">
        <p class="teams-title">Teams</p>
        <div class="teams-container">
          <span
            *ngFor="let item of selectedTeams"
            class="badge me-2 mb-2"
            [ngClass]="{
              'bg-info': teamsContainer.existingItems.includes(item),
              'bg-success': !teamsContainer.existingItems.includes(item)
            }"
            (click)="teamsContainer.toggle(item)">
            {{ item }}<span class="ps-2"><i class="far fa-times"></i></span>
          </span>
          <span class="default-message" *ngIf="selectedTeams?.length === 0">
            No teams selected.
          </span>
        </div>
      </div>
      <div class="reporting-groups">
        <p class="reporting-groups-title">Reporting Groups</p>
        <div class="reporting-groups-container">
          <span
            *ngFor="let item of selectedReportingGroups"
            class="badge me-2 mb-2"
            [ngClass]="{
              'bg-secondary-dark': reportingGroupsContainer.existingItems.includes(item),
              'bg-secondary': !reportingGroupsContainer.existingItems.includes(item)
            }"
            (click)="reportingGroupsContainer.toggle(item)">
            {{ item }}<span class="ps-2"><i class="far fa-times"></i></span>
          </span>
          <span class="default-message" *ngIf="selectedReportingGroups?.length === 0">
            No reporting groups selected.
          </span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="modal-footer">
  <button
    type="button"
    ngbAutofocus
    class="btn btn-primary"
    (click)="submit()"
    [disabled]="loading || isDisabled">
    {{ userId ? 'Update' : 'Add' }} User
  </button>
</div>
