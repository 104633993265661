import { UserPermissions } from 'src/app/models/admin';
import { Component, Input, OnInit } from '@angular/core';
import { of, Subscription, Observable } from 'rxjs';
import { ServicePrincipal } from '../../../models/user-group';
import { SecurityWorkspaceService } from '../../../services/security-workspace.service';
import { ServicePrincipalsListService } from '../../../services/service-principals-list.service';
import { UserType } from '../../../models/user-type';

@Component({
  selector: 'wre-service-principals-table-admin',
  templateUrl: './service-principals-table.component.html',
  styleUrls: ['./service-principals-table.component.sass']
})
export class ServicePrincipalsTableAdminComponent implements OnInit {

  items$ = of<ServicePrincipal[]>([]);
  total$ = of<number>();
  isLoading = true;

  @Input() set searchTerm(value) { this.principalsListService.searchTerm = value.trim() ? value.trim(): ''; }
  get searchTerm() { return this.principalsListService.searchTerm; }
  get loading$(): Observable<boolean> { return this.principalsListService.loading$; }

  get isNoSearchResult(): boolean { return this.searchTerm.length > 0 && this.principalsListService.items.length === 0; }

  offset = '0';
  subscription: Subscription;
  roles = [];

  get hasEditPermission() {
    return this.roles.includes(UserPermissions.GatewaySecurityUsersTabEdit);
  }

  userType = UserType.servicePrinciple;

  constructor(
    private securityWorkspaceService: SecurityWorkspaceService,
    public principalsListService: ServicePrincipalsListService) {
    this.roles = this.securityWorkspaceService.currentPermissions;
  }
  async ngOnInit(): Promise<void> {
    this.items$ = this.principalsListService.items$;
    this.total$ = this.principalsListService.total$;

    await this.principalsListService.load();

    this.isLoading = false;
  }

}
