import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
declare var twttr: any;

@Component({
  selector: 'app-twitter-feed',
  templateUrl: './twitter-feed.component.html',
  styleUrls: ['./twitter-feed.component.sass']
})

export class TwitterFeedComponent implements AfterViewInit {
  noTwitterFeed: boolean;

  isLoading = true;
  @ViewChild('twitterHost', { read: ElementRef, static: true }) public twitterHost: ElementRef;

  constructor() { }
  ngAfterViewInit(): void {
  this.loadTwitterTimelineScript();
    setTimeout(() => {
      this.checkTwitterLoaded()
    }, 30000); //TODO - Mena Investigate to remove the timeout
  }

  handleDomChange(event) {
    // Timeout needed to allow content dom added to iframe
    setTimeout(() => {
      this.checkTwitterLoaded()
    }, 2000);

  }

  private checkTwitterLoaded() {
    const frame = document.getElementById('twitter-widget-0');
    this.noTwitterFeed = !frame || frame?.outerHTML?.length === 0;
    this.isLoading = false;
  } 

  loadTwitterTimelineScript() {
    let script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    document.body.appendChild(script);
  }
}
