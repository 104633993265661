<ng-container *ngIf="componentAvailableSub.getValue() === true">

    <div class="card card-md most-popular-card">
        <div class="btn-toolbar border-bottom align-items-center card-header" tourAnchor="mostViewed.anchor">
            <h5 class="mb-0 me-auto card-title">Most Viewed (Last 1 Month)</h5>

            <ng-container *ngIf="errorOccured === false">
                <button style="display:none" id="btnGroupDrop1" type="button" class="btn btn-primary me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <span class="d-none inline-block">{{mostPopularSelectedFilter}}</span>
                    <span class="caret d-none inline-block"></span></button>
                <div id="most-viewed-term-selector" class="dropdown-menu dropdown-menu-right shadow" aria-labelledby="btnGroupDrop1" x-placement="bottom-end" style="display:none; position: absolute; transform: translate3d(-26px, 33px, 0px); top: 0px; left: 0px; will-change: transform;">
                    <div *ngFor="let item of mostPopularFilterItems">
                        <a class="dropdown-item" (click)="applyMostPopularFilter(item)">{{item.name}}</a>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="card-body pt-0 most-popular-card-body">
            <app-slipcase-feed class="most-popular-feed" [isloaderVisible]="mostPopularLoading" [useInputFeed]="true" [newsData]="mostPopularData" [showItemHostname]="false" [showPublishDate]="false" [showTopics]="false"></app-slipcase-feed>

            <div *ngIf="errorOccured === true" class="container-fluid px-0 pt-4">
                <div class="row">
                    <div class="col">
                        <wre-not-available serviceName="Most viewed" [errorOccured]="errorOccured"></wre-not-available>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
