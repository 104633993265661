import { RoleFilter } from './role-filter';

export class State {
  page: number;
  pageSize: number;
  searchTerm: string;
  filter: RoleFilter;

  constructor(defaultPageSize: number) {
    this.page = 1;
    this.pageSize = defaultPageSize;
    this.searchTerm = '';
    this.filter = new RoleFilter();
  }
}
