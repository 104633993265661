<div id="alertDanger" class="modal fade show" tabindex="-1" role="dialog" style="display: block;"aria-modal="true">
  <div style="max-width:450px;" class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center">
        <h3 class="modal-title">{{title}}</h3>
        <div class="modal-options">
          <button class="btn btn-icon" type="button" data-bs-dismiss="modal" aria-label="Close"  (click)="closeModal()" tabindex="-1">
              <i class="fal fa-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div *ngIf="isLoading" class="row justify-content-center loading-wrapper">
          <div class="loading-background"></div>
          <div class="d-flex align-items-center">
            <div class="loading"></div>
          </div>
        </div>
        <div class="d-flex row">
            <!-- <i *ngIf="this.isConfirmModal" class="fa fa-times-circle" style="font-size: 48px; color: red"></i>
            <i *ngIf="!this.isConfirmModal" class="fa fa-info-circle" style="font-size: 48px;"></i>  -->
          <div class="col-lg-12">
              <h5 style="font-size: 14px; font-weight: 500;" class="">{{deleteEntityMessage_h5}} <strong>{{entityName}}</strong>{{ punctuationMark }}</h5>
            <p class="mb-0">{{deleteEntityMessage_p}}</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">{{buttonAction}}</button>
        <button type="button"
                class="btn ms-3 btn-error"
                data-bs-dismiss="modal"
                (click)="deleteEntityConfirm()"
                [hidden]="!isConfirmModal"
                [disabled]="isLoading"
        >
          Yes, delete
        </button>
      </div>
    </div>
  </div>
</div>
