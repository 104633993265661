import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SrtOverviewWrapperComponent } from './srt-overview-wrapper/srt-overview-wrapper.component';
import { SrtAccessOverviewTableComponent } from './srt-overview-wrapper/srt-access-overview-table/srt-access-overview-table.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WreToolkitLibModule } from 'wre-toolkit-lib';
import { ReferralsFieldWrapperComponent } from './referrals-field-wrapper/referrals-field-wrapper.component';
import { ReferralsFieldsComponent } from './referrals-field-wrapper/referrals-fields/referrals-fields.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { EditFieldModalWrapperComponent } from './referrals-field-wrapper/edit-field-modal-wrapper/edit-field-modal-wrapper.component';
import { EditFieldModalComponent } from './referrals-field-wrapper/edit-field-modal-wrapper/edit-field-modal/edit-field-modal.component';
import { SrtOverviewAccessModalWrapperComponent } from './srt-overview-wrapper/srt-overview-access-modal-wrapper/srt-overview-access-modal-wrapper.component';
import { SrtOverviewAccessModalComponent } from './srt-overview-wrapper/srt-overview-access-modal-wrapper/srt-overview-access-modal/srt-overview-access-modal.component';
import { OverviewAccessModule } from '../overview-access/overview-access.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OptionsDragAndDropComponent } from './referrals-field-wrapper/edit-field-modal-wrapper/edit-field-modal/options-drag-and-drop/options-drag-and-drop.component';



@NgModule({
  declarations: [
    SrtOverviewWrapperComponent,
    SrtAccessOverviewTableComponent,
    ReferralsFieldWrapperComponent,
    ReferralsFieldsComponent,
    EditFieldModalWrapperComponent,
    EditFieldModalComponent,
    SrtOverviewAccessModalWrapperComponent,
    SrtOverviewAccessModalComponent,
    OptionsDragAndDropComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    WreToolkitLibModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    OverviewAccessModule,
    DragDropModule
  ],
  exports: [
    ReferralsFieldWrapperComponent,
    ReferralsFieldsComponent
  ]
})
export class SrtAdminModule { }
